#main-dashboard-container {
	color: #444;
	background-color: $defaultNeutralLight;
	display: flex;
	font-family: "Bariol";
	position: relative;
	flex: 1;
	width: 100%;
	padding: 1em;
	gap: 10px;
	align-items: flex-start;
	.dash-grid {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 10px;
		.dashboard-card-container {
			display: grid;

			$gridGap: 1.5em;
			$numColumns: 3;
			$numRows: 3;
			$elementSize: 550px;

			gap: $gridGap;
			grid-template-columns: repeat($numColumns, 1fr);
			grid-template-rows: repeat(auto-fill, $elementSize);
			grid-auto-flow: dense;

			grid-template-areas:
				"col1 col2 col3"
				"col1 col2 col3";

			@mixin grid-span($rows, $columns) {
				.span-row-#{$rows} {
					grid-row: span #{$rows};
					height: calc($elementSize * #{$rows} + $gridGap * (#{$rows} - 1));
				}

				.span-column-#{$columns} {
					grid-column: span #{$columns};

					@media screen and (max-width: $tabletBreak) and (orientation: portrait) {
						grid-column: span 1;
					}
				}
			}

			// Loop to create classes for rows and columns
			@for $i from 1 through $numColumns {
				@for $j from 1 through $numRows {
					@include grid-span($j, $i);
				}
			}

			@media screen and (max-width: $tabletBreak) {
				$numColumns: 2;

				grid-template-columns: repeat($numColumns, 1fr);
				grid-template-rows: repeat($numRows, $elementSize);
				grid-template-areas:
					"col1 col2"
					"col1 col2";
			}

			@media screen and (max-width: $tabletBreak) and (orientation: portrait) {
				$numColumns: 1;

				grid-template-columns: 1fr;
				grid-template-rows: repeat($numRows, $elementSize);

				grid-template-areas:
					"col1"
					"col2"
					"col3";
			}

			@media screen and (max-width: $mobileLargeBreak) {
				$numColumns: 1;

				grid-template-columns: 1fr;
				grid-template-rows: repeat($numRows, $elementSize);

				grid-template-areas:
					"col1"
					"col2"
					"col3";
			}
		}
	}

	.category-card {
		// flex: 1 1 calc(50% - 40px);
		box-sizing: border-box;
		background-color: #fff;
		padding: 0.25em 1em 1em;
		position: relative;
		border: 1px solid #ccc;
		border-radius: 1em;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 1em;
		overflow-y: auto;
		* {
			overflow-wrap: break-word;
		}
		.category-text-container {
			display: flex;
			color: $darkblue;
			border-bottom: 0.5px solid lightgray;
			align-items: center;
			padding: 0.5em 0;
			gap: 10px;
			justify-content: flex-start;
			position: relative;
			flex: 0 0 fit-content;
			.left-container {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 10px;
				flex: 1;
			}
			.card-circle {
				--circle-size: 32px;
				flex: 0 0 var(--circle-size);
				width: var(--circle-size);
				height: var(--circle-size);
				border-radius: 50%;
				background-color: $defaultNeutralLight;
				transition: background-color 0.15s;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					width: 18px;
					height: 18px;
				}
			}
			.card-title {
				font-family: "Bariol";
				font-weight: bold;
				font-size: 1.2em;
			}

			.card-header-actions {
				display: flex;
				justify-content: flex-end;
				gap: 0.5em;
				align-items: center;
				flex-wrap: wrap;
				flex: 1;
				.btn.transparent {
					width: fit-content;
					min-width: fit-content;
					max-width: 100%;
					border-radius: 0.4em;
					padding: 0.4em 0.8em;
					text-align: center;
					font-size: 0.95em;
					justify-content: center;

					display: flex;
					align-items: center;
					gap: 0.25em;
					svg {
						width: 18px;
						height: auto;
					}
				}
			}

			.btn {
				// padding: 6px 15px;
			}

			#close-icon {
				position: absolute;
				right: 0;
				width: 20px;
				height: auto;
				cursor: pointer;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		@media screen and (max-width: $tabletBreak) {
			flex: 1 1 min(100%, 450px);
		}
	}
	.category-card.current-projects {
		.projects-sub-container {
			font-family: "Bariol";
			flex: 1;
			overflow-y: auto;
			.wrapper {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
				@media screen and (max-width: $mobileLargeBreak) {
					grid-template-columns: 1fr;
				}
			}
			* {
				text-decoration: none;
				text-align: left;
			}

			.sub-container-card {
				flex: 0 0 calc(50% - 10px);
				box-sizing: border-box;
				width: 100%;
				padding: 20px;
				border-radius: 20px;
				border: 1px solid #ccc;

				.sub-container-details {
					font-family: "Bariol";
					display: flex;
					flex-direction: column;
					gap: 5px;

					.sub-container-header {
						p {
							font-weight: bold;
							color: $darkblue;
							font-size: 1.05em;
						}
					}

					.sub-container-status {
						font-weight: bold;
						font-size: 1em;
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						gap: 5px;
						font-size: 0.9em;

						.project-status {
							color: white;
							padding: 5px;
							font-size: 0.9125em;
							border-radius: 5px;
						}
					}
				}
			}
		}

		.current-projects-table {
			width: 100%;
			font-family: "Bariol";
			border-collapse: collapse;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			row-gap: 0.75em;
			thead,
			tbody,
			tr {
				display: contents;
			}

			th,
			td {
				padding: 0.25em;
				border: none;
				text-align: left;
				vertical-align: middle;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $darkblue;
				.project-status {
				}

				a {
					color: $darkblue;
					text-decoration: none;
					text-align: center;
					&:hover {
						text-decoration: underline;
					}
				}
			}

			th {
				font-weight: bold;
				border-bottom: 1px solid #d3d3d3;
			}
		}

		.projects-view-all {
			display: flex;
			align-items: center;
			width: 100%;
			gap: 10px;
			.project-divider {
				flex: 1;
				border: none;
				border-top: 1.9px solid $defaultNeutral;
			}

			.view-all-button {
				padding: 8px 15px;
				color: $defaultNeutral;
				background-color: white;
				border: 1px solid $defaultNeutral;
				border-radius: 20px;
				cursor: pointer;
				text-align: center;
				font-size: 1em;
				transition: all 0.15s;
				text-decoration: none;

				&:hover {
					background-color: $darkblue;
					color: white;
					border-color: $darkblue;
				}
			}
		}
	}

	.welcome-text-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.col {
			flex: 0 0 fit-content;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
		}
		.welcome-text {
			font-family: "Bariol";
			font-weight: bold;
			color: $darkblue;
			font-size: 1.45em;
		}
		.photo-container {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			#profile-photo-large {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
				border-radius: 50%;
				margin: 0;
			}
		}
	}

	.category-card.dashboard-inbox {
		grid-area: col3;
		@media screen and (max-width: $tabletBreak) {
			grid-area: inherit;
		}

		&.notifications-dropdown {
			display: flex;
			flex-direction: column;
			gap: 1em;
			position: absolute;
			top: calc(100% + 0.25em);
			right: -1em;
			max-height: 100%;
			transition: all 0.15s;

			height: fit-content;
			max-height: max(450px, calc(90vh - 2em));
			width: min(80vw, 650px);
			box-shadow: 0px 0px 10px $defaultNeutralLight;

			opacity: 1;
			visibility: visible;
			&.minified {
				visibility: hidden;
				opacity: 0;
			}
		}

		.messages-sub-container {
			overflow-y: auto;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 0.75em;

			.new-day-message {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5em;
				p {
					flex: 0 0 fit-content;
					color: $defaultNeutral;
				}
				hr {
					flex: 1;
					border: none;
					border-top: 1px solid $defaultNeutral;
				}
			}

			.no-notifications {
				text-align: center;
				font-size: 1.2em;
			}
			.notification-container {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				gap: 5px;
				text-decoration: none;
				position: relative;
				cursor: pointer;
				border-bottom: 1px;
				* {
					color: $darkblue;
				}
				.remove-notification {
					cursor: pointer;
					width: 22px;
					height: auto;
					fill: $danger;
					color: $danger;
				}

				#read-indicator {
					width: 9px;
					border-radius: 50%;
					background-color: $danger;
					aspect-ratio: 1;

					position: absolute;
					top: 0;
					top: 50%;
					transform: translateY(-50%);
					right: 0.25em;
				}

				&:has(#read-indicator) {
					padding-right: 1.2em;
				}
			}

			.notification-headline {
				display: flex;
				position: relative;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 10px;
				flex: 1;

				.notification {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 5px;
					flex: 1;
					* {
						text-align: left;
						width: 100%;
						overflow-wrap: anywhere;
					}
					.notification-header {
						font-family: "Bariol";
						color: $darkblue;
						font-size: 1.15em;
						font-weight: bold;
						width: fit-content;
						position: relative;
						&:has(#read-indicator) {
							padding-right: 35px;
							font-weight: bold;
						}
						#read-indicator {
							font-weight: normal;
							background-color: $tmsgreen;
							color: white;
							padding: 2px 5px;
							font-size: 0.6125em;
							width: fit-content;
							height: fit-content;
							position: absolute;
							right: 0px;
							top: -2px;
							line-height: normal;
						}
					}
					.notification-message {
						font-family: "Bariol";
						font-size: 0.95em;
						word-break: break-all;
					}
					.actions {
						display: flex;
						flex-direction: column;
						gap: 5px;
						&:not(:has(*)) {
							display: none;
						}
					}
					.user-message {
						font-size: 1em;
						font-family: "Bariol";
						font-style: italic;
					}
					.quick-reply {
						width: 100%;
						display: none;
						input {
							padding: 8px 12px;
							border-radius: 15px;
							border: 1px solid $defaultNeutral;
						}
					}
					.btn.transparent {
						width: fit-content;
						min-width: auto;
						padding: 0.35em 0.75em;
						border-radius: 0.55em;
						&:hover {
							color: white;
						}
					}
				}
			}
		}

		.btn-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			.btn.darkblue {
				width: fit-content;
				max-width: 100%;
				margin: 0;
			}
		}
	}

	.generic-circle {
		flex: 0 0 32px;
		width: 32px;
		aspect-ratio: 1/1;
		border-radius: 50%;
		background-color: $defaultNeutralLight;
		transition: background-color 0.15s;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 20px;
			height: auto;
		}
	}

	.category-card.project-status,
	.category-card.workday-lists {
		.status-sub-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			height: fit-content;
			overflow-y: auto;
			max-height: 100%;
			gap: 15px;
			.listWidth {
				display: flex;
				gap: 1em;
				flex-direction: column;
				width: 95%;
			}
			.status-card {
				display: flex;
				text-decoration: none;
				justify-content: space-between;
				border-radius: 10px;
				width: 100%;
				background-color: $defaultNeutralLight;
				padding: 0.65rem;
				align-items: center;
				gap: 1em;
				align-items: center;
				p {
					font-family: "Bariol Bold";
					font-size: 1.2em;
				}
				.status-count {
					color: $tmsgreen;
					font-size: 1.3em;
					display: flex;
					align-items: center;
					gap: 0.25em;
				}
				svg {
					flex: 0 0 20px;
					width: 20px;
					height: auto;
					color: $tmsgreen;
				}
				.status-text {
					color: $defaultNeutral;
				}

				&:hover {
					background-color: $darkblue;
					.status-text {
						color: white;
					}

					transition: all 0.15s;
				}
			}
		}
		#loading-box {
			position: relative;
			left: auto;
			background-color: transparent;
			z-index: 0;
			width: 100%;
			height: 100%;
			min-height: fit-content;
			svg {
				width: 100px;
				height: 100px;
			}
		}
	}

	.category-card.dashboard-calendar {
		.calendar-wrapper {
			width: 100%;
			height: 100%;
			padding: 0;
			gap: 10px;
			.calendar-loaders {
				#loading-box {
					svg {
						flex: 0 0 40px;
						width: 40px;
						height: 40px;
					}
				}
			}
			.fc-theme-standard {
				width: 100%;
				height: 100%;
			}
		}
		.fc-button {
			padding: 2px !important;
			border-radius: 30px !important;
		}
		.fc-toolbar-title {
			font-size: 20px !important;
		}
	}

	.category-card.dashboard-health-status {
		.status-container {
			display: flex;
			flex-direction: column;
			gap: 0.75em;
			flex: 1;
			overflow-y: auto;
			.status-card {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0.45em;
				.status-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					flex: 0 0 30px;
					background-color: transparent;
					margin: 0;
					padding: 0;

					svg {
						width: 100%;
						height: auto;
						fill: $darkblue;
						margin: 0;
						padding: 0;
					}
					&.success {
						svg {
							fill: $tmsgreen;
						}
					}
					&.warning {
						svg {
							fill: $warning;
						}
					}
					&.error {
						svg {
							fill: $danger;
						}
					}
				}
				.status-text {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					flex: 1;
					gap: 0.2em;
					p {
						font-size: 1em;
						text-align: left;
						&.status-title {
							font-size: 1em;
							color: $darkblue;
						}
						&.status-subtext {
							font-size: 0.85em;
							font-style: italic;
							font-family: "Source Sans Pro Light", sans-serif;
						}
					}
				}
			}
		}
	}

	.category-card.recent-uploads {
		.uploads-container {
			display: flex;
			flex-direction: column;
			gap: 15px;
			height: 100%;
			overflow-y: auto;
		}
		.upload {
			display: flex;
			gap: 15px;
			align-items: flex-start;
			justify-content: flex-start;
			text-decoration: none;
			color: $darkblue;
			.upload-image {
				flex: 0 0 80px;
				aspect-ratio: 1 / 1;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				.lazy-load-image {
					width: 100%;
					height: 100%;
					img {
						max-width: 100%;
						max-height: 100%;
						object-fit: cover;
					}
				}
			}
			.upload-info {
				flex: 1;
				text-align: left;
				display: flex;
				flex-direction: column;
				* {
					font-family: "Bariol";
					color: $darkblue;
				}
				h3 {
					font-weight: bold;
				}
				p {
					font-size: 1em;
					font-weight: normal;
					text-transform: lowercase;
					&.upload-time {
						font-size: 0.9em;
						font-weight: normal;
						color: $defaultNeutral;
					}
				}
			}
			&:hover {
				h3 {
					text-decoration: underline;
				}
			}
		}
	}

	.category-card.email-analytics {
		.chart-wrapper {
			display: flex;
			flex-direction: column;
			gap: 15px;
			details {
				cursor: pointer;

				summary {
					padding: 10px;
					display: flex;
					align-items: center;
					gap: 20px;
					background-color: $darkblue;
					* {
						color: white;
					}

					h3 {
						flex: 1;
						text-align: left;
						font-size: 1em;
						font-family: "Source Sans Pro", sans-serif;
						font-weight: normal;
						line-height: normal;
					}
					svg {
						color: $darkblue;
						&.open {
							display: block;
						}
						&.close {
							display: none;
						}
					}
				}
				.content {
					padding: 1em;
				}
				&[open] {
					summary {
						svg {
							&.open {
								display: none;
							}
							&.close {
								display: block;
							}
						}
					}
				}
			}
			.chart-container {
				display: flex;
				gap: 10px;
				width: 100%;
				justify-content: center;
				flex-wrap: wrap;
				p {
					text-align: right;
					&.last-updated {
						flex: 0 0 100%;
					}
				}
				.pie-chart-container {
					flex: 1 1 100%;
					max-width: 400px;
					aspect-ratio: 4/3;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	.notification-toggle {
		position: absolute;
		top: 20px;
		right: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 5px;
		.card-circle {
			flex: 0 0 40px;
			width: 40px;
			height: 40px;
			position: relative;
			svg {
				width: 24px;
				height: 24px;
			}
			.n-count {
				border-radius: 50%;
				width: 20px;
				height: 20px;
				background: $danger;
				text-align: center;
				font-size: 0.7125em;
				color: #fff;
				position: absolute;
				top: 0;
				right: -18%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.card-circle {
	flex: 0 0 32px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: white;
	transition: background-color 0.15s;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	svg {
		width: 18px;
		height: 18px;
	}
}

// #close {
// 	height: 20px;
// 	width: 20px;
// 	border-radius: 5px;
// 	cursor: pointer;
// 	&::after {
// 		position: absolute;
// 		content: "×";
// 		font-size: 35px;
// 		color: #000;
// 		font-weight: bold;
// 		top: 5px;
// 		right: 18px;
// 	}
// }

.demo-text {
	color: black;
	b {
		cursor: pointer;
	}
}

// @media screen and (max-width: $mobileLargeBreak) {
//     .navigation-container {
//         width: 100%;
//         z-index: 901;
//         left: -700px;
//         transition: left 0.3s ease;

//         .close {
//             position: absolute;
//             right: 10px;
//             top: 5px;
//             height: 30px;
//             width: 30px;
//             cursor: pointer;
//         }

//         &.open {
//             left: 0;
//         }
//     }
// }
