#reports-page {
    #inner-container {
        padding: 30px;
        ol {
            li {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        #report-view-container {
            width: 100%;
            height: 800px;
            text-align: center;
            p.back-button {
                cursor: pointer;
                width: 30px;
                text-decoration: underline;
            }
            #section-header {
                margin-top: 20px;
            }
            iframe {
                display: block;
                margin: 0 auto;
                padding: 20px 20px 40px 20px;
                width: inherit;
                height: inherit;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #reports-page {
        #inner-container {
            // max-width: calc(100% - 50px);
            padding: 30px;
            ol {
                padding-left: 10px;
                li {
                    font-size: 1.2em;
                }
            }
            #report-view-container {
                width: 100%;
                height: 350px;
                iframe {
                    padding: 0;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}

.marketing-services-report-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    #category-filter {
        max-height: 800px;
        height: 100svh;
        padding: 20px 0;
        margin: 0;
        position: relative;
        width: 320px;
        #category {
            #category-item {
                margin: 30px 0;
                &:first-of-type {
                    margin-top: 0;
                }
                select {
                    width: 100% !important;
                    // max-width: 200px;
                    display: block;
                    margin: 10px auto !important;
                }
            }
        }
    }
    .services-results-wrapper {
        width: calc(100% - 340px);
        margin-left: 0px;
        .csv-download {
            width: fit-content;
            text-decoration: none;
            margin: 0 auto;
            padding: 10px 30px;
        }
        .report-table-wrapper {
            width: 100%;
            max-width: $contentMaxWidth;
            margin: 30px auto;
            display: block;
            #sub-header {
                font-size: 2.25em;
                margin-top: 40px;
                display: block;
            }
            #index {
                display: flex;
                justify-content: space-between;
                right: 0;
                margin: 10px 0 15px;
                margin-right: 0;
                width: fit-content;
                gap: 20px;
                margin-left: auto;
                .container {
                    width: 20px;
                    height: 20px;
                }
                .checkmark {
                    margin-top: -8px;
                    margin-left: 7px;
                    height: 14px;
                    width: 6px;
                }
                #close {
                    &:before {
                        font-size: 16px;
                        margin: 0 !important;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                div {
                    display: flex;
                    span {
                        font-size: 15px;
                        &:nth-of-type(2) {
                            height: fit-content;
                            margin: auto 0px auto 5px;
                        }
                    }
                }
            }
            .category-wrapper {
                padding: 20px 0;
                .col-md-6 {
                    padding: 10px 40px;
                    background-color: $darkblue;
                    border-bottom-left-radius: 20px;
                    border-top-right-radius: 20px;
                    h3 {
                        color: white;
                        font-family: "Bariol";
                        font-family: 1.45em;
                        text-transform: uppercase;
                    }
                    &.service {
                        background-color: white;
                        display: flex;
                        justify-content: space-between;
                        h3 {
                            color: $tmsblue;
                        }
                    }
                }
            }
        }
        .container {
            width: 27px;
            height: 27px;
            position: relative;
            display: block;
            margin: 0;
            border-radius: 50%;
            &.enrolled {
                background: #74b368;
            }
            &.just-enrolled {
                background: #2a60a5;
            }
            &.prev-enrolled {
                background: rgb(150, 150, 150);
            }
        }
        .checkmark {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            margin-left: 10px;
            transform: rotate(45deg);
            height: 17px;
            border-bottom: 2px solid white;
            border-right: 2px solid white;
            width: 7px;
        }
        #close {
            height: 20px;
            width: 20px;
            border-radius: 5px;
            // cursor: pointer;
            &:before {
                position: absolute;
                content: "X";
                font-size: 20px;
                color: white;
                font-family: Bariol;
                margin-top: 50%;
                margin-left: 50%;
                transform: translate(-50%, -50%);
                font-weight: bold;
                top: 0px;
            }
        }
        #users-table {
            border-collapse: collapse;
            width: 100%;
            max-width: 100%;
            display: block;
            overflow-x: auto;
            thead {
                th {
                    background-color: $darkblue;
                    padding: 20px;
                    font-family: "Bariol";
                    position: relative;
                    i {
                        border-color: white;
                        border-width: 0 2px 2px 0;
                    }
                }
            }
            tbody {
                td {
                    padding: 20px;
                }
            }
        }
    }
    .services-filters {
    }
    #my-projects-filters {
        text-align: center;
        display: flex;
        margin: 30px auto;
        width: fit-content;
        max-width: 1050px;
        justify-content: space-between;
        ul li a {
            color: grey;
        }
        ul li a:active {
            color: darkblue;
            font-weight: bold;
        }
        ul li a:hover {
            color: black;
        }
        #tab-options {
            list-style-type: none;
            padding: 0;
            margin: 20px auto;
            width: 38vw;
            text-align: center;
            display: flex;
            flex-direction: row;
        }
        #tab-option-item {
            text-transform: uppercase;
            flex: 1 0 25%;
            margin: 0;
            font-size: 1em;
            text-align: center;
            color: #c4c4c4;
            padding: 0;
            border-bottom: 1px solid #c4c4c4;
            cursor: pointer;
            &.active {
                color: $darkblue;
                font-weight: bold;
                border-bottom: 2px solid $darkblue;
            }
        }
    }
    #filters-div {
        width: fit-content;
        margin: 30px auto;
        .filter-select {
            margin: 20px auto;
            width: 300px;
        }
    }
}

#stats-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    display: block;
    overflow-x: auto;
    thead {
        th {
            background-color: $darkblue;
            padding: 20px;
            font-family: "Bariol";
            position: relative;
            color: white;
            width: fit-content;
            max-width: 200px;
            &:first-of-type {
                width: 400px;
            }
            i {
                border-color: white;
                border-width: 0 2px 2px 0;
            }
        }
    }
    tbody {
        td {
            padding: 20px;
            text-align: center;
        }
    }
    &.total-stats {
        margin-top: 0px;
        thead {
            th {
                &:first-of-type {
                    width: fit-content;
                }
            }
        }
    }
    &.all-stats {
        margin-top: 30px;
    }
}

.embr-stats-report-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    #category-filter {
        max-height: 800px;
        height: 100svh;
        padding: 20px 0;
        margin: 0;
        position: relative;
        width: 360px;
        #category {
            #category-item,
            .category-item {
                margin: 30px 0;
                &:first-of-type {
                    margin-top: 0;
                }
                select {
                    width: 100% !important;
                    // max-width: 200px;
                    display: block;
                    margin: 10px auto !important;
                }
                .date-range-wrapper {
                    display: flex;
                    justify-content: space-between;
                    input[type="date"] {
                        width: 145px !important;
                        min-width: auto;
                    }
                }
            }
        }
    }
    .services-results-wrapper {
        width: calc(100% - 380px);
        margin-left: 0px;
        .csv-download {
            width: fit-content;
            text-decoration: none;
            margin: 0 auto;
            padding: 10px 30px;
        }
        .report-table-wrapper {
            width: 100%;
            max-width: $contentMaxWidth;
            margin: 30px auto;
            display: block;
            #sub-header {
                font-size: 2.25em;
                margin-top: 40px;
                display: block;
            }
            #index {
                display: flex;
                justify-content: space-between;
                right: 0;
                margin: 10px 0 15px;
                margin-right: 0;
                width: fit-content;
                gap: 20px;
                margin-left: auto;
                .container {
                    width: 20px;
                    height: 20px;
                }
                .checkmark {
                    margin-top: -8px;
                    margin-left: 7px;
                    height: 14px;
                    width: 6px;
                }
                #close {
                    &:before {
                        font-size: 16px;
                        margin-top: 0px;
                        margin-left: 6px;
                    }
                }
                div {
                    display: flex;
                    span {
                        font-size: 15px;
                        &:nth-of-type(2) {
                            height: fit-content;
                            margin: auto 0px auto 5px;
                        }
                    }
                }
            }
            .category-wrapper {
                padding: 20px 0;
                .col-md-6 {
                    padding: 10px 40px;
                    background-color: $darkblue;
                    border-bottom-left-radius: 20px;
                    border-top-right-radius: 20px;
                    h3 {
                        color: white;
                        font-family: "Bariol";
                        font-family: 1.45em;
                        text-transform: uppercase;
                    }
                    &.service {
                        background-color: white;
                        display: flex;
                        justify-content: space-between;
                        h3 {
                            color: $tmsblue;
                        }
                    }
                }
            }
        }
        .container {
            width: 27px;
            height: 27px;
            position: relative;
            display: block;
            margin: 0;
            border-radius: 50%;
            &.enrolled {
                background: #74b368;
            }
            &.just-enrolled {
                background: #2a60a5;
            }
            &.prev-enrolled {
                background: rgb(150, 150, 150);
            }
        }
        .checkmark {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            margin-left: 10px;
            transform: rotate(45deg);
            height: 17px;
            border-bottom: 2px solid white;
            border-right: 2px solid white;
            width: 7px;
        }
        #close {
            height: 20px;
            width: 20px;
            border-radius: 5px;
            // cursor: pointer;
            &:before {
                position: absolute;
                content: "X";
                font-size: 20px;
                color: white;
                font-family: Bariol;
                margin-top: 50%;
                margin-left: 50%;
                transform: translate(-50%, -50%);
                font-weight: bold;
                top: 0px;
            }
        }
        #users-table {
            border-collapse: collapse;
            width: 100%;
            max-width: 100%;
            display: block;
            overflow-x: auto;
            thead {
                th {
                    background-color: $darkblue;
                    padding: 20px;
                    font-family: "Bariol";
                    position: relative;
                    i {
                        border-color: white;
                        border-width: 0 2px 2px 0;
                    }
                }
            }
            tbody {
                td {
                    padding: 20px;
                }
            }
        }
    }
    .services-filters {
    }
    #my-projects-filters {
        text-align: center;
        display: flex;
        margin: 30px auto;
        width: fit-content;
        max-width: 1050px;
        justify-content: space-between;
        ul li a {
            color: grey;
        }
        ul li a:active {
            color: darkblue;
            font-weight: bold;
        }
        ul li a:hover {
            color: black;
        }
        #tab-options {
            list-style-type: none;
            padding: 0;
            margin: 20px auto;
            width: 38vw;
            text-align: center;
            display: flex;
            flex-direction: row;
        }
        #tab-option-item {
            text-transform: uppercase;
            flex: 1 0 25%;
            margin: 0;
            font-size: 1em;
            text-align: center;
            color: #c4c4c4;
            padding: 0;
            border-bottom: 1px solid #c4c4c4;
            cursor: pointer;
            &.active {
                color: $darkblue;
                font-weight: bold;
                border-bottom: 2px solid $darkblue;
            }
        }
    }
    #filters-div {
        width: fit-content;
        margin: 30px auto;
        .filter-select {
            margin: 20px auto;
            width: 300px;
        }
    }
}
