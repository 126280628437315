// Marketing services styles
// /my-marketing/my-services

#main-container.services{
    width: calc(100% - 20px);
    margin: 0 auto;
    input[type="text"]{
        width: 120px;
        padding: 8px;
    }

    p.save-prompt{
        background-color: $danger;
        color: #fff;
        padding: 20px;
        width: 600px;
        margin: 0 auto;
        margin-bottom: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
  
    .table-wrapper{
        margin-bottom: 100px;
        table{
            z-index: 1;
            tr{
                &:nth-child(even){
                    background-color: #f6f6f9;
                }
            }
            th{
                text-align: center;
                &:first-child{
                    text-align: left;
                 }
            }
            td{
                position: relative;
                text-align: center;
                &:first-child{
                   width:  500px;
                   text-align: left;
                }
                input[type="checkbox"]{
                    cursor: pointer;
                    min-width: fit-content;
                }
                svg{
                    width: 18px;
                    height: 18px;
                    fill: #666;
                    cursor: pointer;
                }
            }    
        }
    }
    .tool-tip{
        display: none;
        width: 540px;
        padding: 40px;
        position: absolute;
        top: 100%;
        background-color: $tmsblue;
        z-index: 1000000;
        color: #fff; 
        line-height: 1.5;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        &.is-visible{
            display: block;
        }
        a{
            color: #92c7f0;
        }
    }   
}

@media screen and (max-width: 600px) {
    #main-container.services{
        #table-container{
            width: calc(100% - 20px);
            max-width: calc(100% - 20px);
        }
        img {
            width: 120px;
        }
        .tool-tip{
            width: 320px;
        }
    }
}


@media screen and (max-width: 450px) {
    #main-container.services{
        #table-container{
            width: calc(100% - 20px);
            max-width: calc(100% - 20px);
        }
        .tool-tip{
            width: 270px;
            margin-bottom: 30px;
        }
    }
}