.admin-upload-form {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.form-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2em;
		.col {
			display: flex;
			flex-direction: column;
			gap: 1.5em;
		}
	}
	.form-item {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
	}

	.supporting-file-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		padding-top: 0.75em;
		h3 {
			width: fit-content;
			color: $darkblue;
		}
	}
}

#services-form {
	width: 40vw;
	margin: 0 auto;
	#container {
		justify-content: space-between;
		display: flex;
		h3 {
			color: $tmsblue;
			font-family: "Bariol";
		}
	}
}

@media screen and (max-width: 1200px) {
	#services-form {
		width: 70vw;
	}
}

@media screen and (max-width: 800px) {
	#services-form {
		width: 90vw;
		#container {
			#col-left {
				margin-bottom: 20px;
			}
			flex-direction: column;
		}
	}
}

.form-wrapper {
	display: flex;
	justify-content: space-between;
}

// Flyer Admin Styles
#admin-upload {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	.sub-header {
		margin: 20px auto;
		width: fit-content;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.sf-fields-mapping {
		margin: 40px 0;
		display: flex;
		flex-wrap: wrap;
		column-gap: 10px;
		justify-content: space-between;
		.form-item {
			flex: 0 0 30%;
			margin: 3px 0;
			label {
				text-transform: none;
			}
		}
		input {
			width: fit-content !important;
		}
		h3 {
			font-size: 25px;
			font-weight: bold;
			width: 100%;
			line-height: 0.75em;
			margin-bottom: 20px;
		}
		@media screen and (max-width: $tabletBreak) {
			.form-item {
				flex: 0 0 45%;
			}
		}

		@media screen and (max-width: $mobileLargeBreak) {
			flex-direction: column;
			.form-item {
				flex: 0 0 100%;
				padding: 0;
			}
		}
		#question-wrapper {
			margin: 10px 0;
		}
	}
	.pricing-container {
		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		.pricing {
			margin: 30px 0px;
		}
	}
	.hide-form {
		display: none;
	}

	.form-item {
		text-align: left;
		width: fit-content;
		margin: 0;
		&.toggle {
			display: flex;
		}
		label {
			text-align: left;
		}
		.preview-image {
			max-width: 145px;
			height: auto;
			margin-bottom: 10px;
		}
	}
	input[type="text"],
	textarea,
	select {
		padding: 5px;
		min-width: 300px !important;
		font-size: 16px;
	}
	select {
		cursor: pointer;
	}

	.upload-container {
		label.file-upload {
			text-align: center;
			color: white;
			padding: 0.5em 1.25em;
			width: fit-content;
			max-width: 100%;
		}
	}

	textarea {
		width: 100%;
		min-height: 200px;
		max-width: 600px;
	}
	.form-field {
		margin: 0;
		button {
			padding: 10px 20px;
			font-size: 18px;
		}
	}
	.btn {
		width: min(300px, 100%);
	}
	.product-addons {
		margin: 10px 0;
		.form-item {
			margin: 5px 0;
			width: fit-content;
		}
		label {
			width: fit-content;
		}
	}
	.switch {
		position: relative;
		display: inline-block;
		width: 46px;
		height: 24px;
	}
	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border: 1px solid #a8a8a8;
		border-radius: 34px;
	}
	.slider:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: 1px;
		bottom: 1px;
		background-color: #a8a8a8;
		-webkit-transition: 0.25s;
		transition: 0.25s;
		border-radius: 50%;
	}
	input:checked + .slider {
		background-color: white;
		border: 1px solid $tmsgreen;
	}
	input:checked + .slider:before {
		-webkit-transform: translateX(22px);
		-ms-transform: translateX(22px);
		transform: translateX(22px);
		background-color: $tmsgreen;
	}
	.answers-wrapper {
		margin-top: 30px;
		.container {
			margin: 10px 0;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 20px;
		}
	}
	#socialad-form {
		display: flex;
		flex-direction: column;
		gap: 2em;
		width: 100%;
		.col {
			display: flex;
			flex-direction: column;
			gap: 20px;
			flex: 1;
		}
		.form-wrapper {
			display: flex;
			flex-direction: row;
			gap: 2em;
			justify-content: space-between;
			&.carousel {
				flex-direction: column;
			}
		}
		#images-wrapper {
			display: flex;
			gap: 20px;
			flex-wrap: wrap;
		}
		#image-preview-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;
			flex-wrap: wrap;
			flex: 0 0 min(100%, 200px);
			img {
				width: 100%;
				height: auto;
			}
			#close {
				display: none;
				height: 100%;
				width: 100%;
				z-index: 9999;
				border-radius: 5px;
				position: absolute;
				top: 0px;
				left: 0px;
				background-color: rgba(0, 0, 0, 0.6);
			}
			#close:after {
				position: absolute;
				content: "\d7";
				font-size: 36px;
				color: #ffffff;
				font-weight: bold;
				cursor: pointer;
				top: -5px !important;
				right: 5px !important;
				display: block;
			}
			&:hover {
				#close {
					display: block;
				}
				#close:after {
					display: block;
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#admin-upload {
		.form-item {
			padding-top: 10px;
		}
	}
}

#flyer-preview-wrapper {
	width: 8.75in;
	.btn {
		margin: 0 auto;
	}
	#flyer-upload-preview {
		width: 8.75in;
		height: 11.25in;
		border: 1px solid #666;
		position: relative;
		margin-bottom: 30px;
		img {
			width: 100%;
		}
		.box-signature {
			width: 3.5in;
			height: 1.25in;
			&.col-2 {
				width: 2.6in;
			}
		}
		.box-company {
			width: 2.5in;
			height: 1.25in;
		}
		.box-branch {
			width: 7.25in;
			height: auto;
			text-align: left;
			padding-top: 20px;
			font-size: 8px;
			line-height: 1;
			text-align: justify;
		}

		.box-qrcode {
			width: 1in;
			height: 1in;
		}
	}
}

.box {
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer !important;
	text-align: center;
	background-color: rgba(239, 28, 190, 0.7);
	color: #fff;
	margin: 0;
}
/*
.postcard-sig{
	width:  3.233in;
	height: 0.13in;
	top: 224px;
}*/
.user-container {
	width: 35%;
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	a {
		text-align: center;
		display: block;
	}
}

.resource-container {
	margin-left: 40px;
}

@media screen and (max-width: 600px) {
	.user-container {
		display: flex;
		flex-direction: row;
		width: calc(100% - 50px);
	}
	#roles-page .user-container {
		flex-direction: column;
	}
}

.td-delete {
	color: blue !important;
	text-decoration: underline;
	cursor: pointer;
}

#post-card-sig {
	position: relative;
	width: 7in;
	height: 5in;
	border: 1px dotted #888;
}
#auto-assign-users-wrapper {
	width: 100%;
	max-width: $contentMaxWidth;
	margin: 0 auto;
	#settings-container {
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding: 0;
		.table-wrapper {
			display: flex;
			flex-direction: column;
			gap: 10px;
			h3 {
				font-size: 1.4em;
				color: $darkblue;
			}
		}
		#auto-assign-form {
			display: flex;
			flex-direction: column;
			gap: 12px;
			.btn {
				width: fit-content;
				min-width: 155px;
			}
		}
		.trash-icon {
			width: 11px;
			vertical-align: middle;
			fill: slategrey;
			cursor: pointer;
		}
		table {
			border-collapse: collapse;
			width: 100%;
			tr {
				width: 100%;
				border-bottom: 1px solid slategray;
				th {
					font-weight: normal;
				}
				td {
					padding: 5px;
				}
			}
		}
		select {
			padding: 3px;
			margin-right: 10px;
		}
		input[type="submit"] {
			border: none;
			max-width: 225px;
			margin: 10px 0;
			background-color: $tmsgreen;
			color: #fff;
			padding: 5px 10px;
			cursor: pointer;
		}
		#auto-assign-table {
			tr {
				th {
					padding: 5px;
					background-color: slategray;
					color: #fff;
					&:first-child {
						text-align: left;
					}
				}
				td {
					border-right: 1px solid slategray;
					&:first-child {
						font-weight: bold;
						border-right: none;
						border-left: 1px solid slategray;
					}
					span {
						padding-right: 20px;
						position: relative;
						&:hover {
							.trash-icon {
								display: block;
							}
						}
						.trash-icon {
							position: absolute;
							top: 4px;
							right: 7px;
							display: none;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#settings-page {
		#settings-container {
			table {
				width: 100%;
			}
		}
	}
}

.categories-container {
	width: 100%;
	max-width: $contentMaxWidth;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	text-align: center;
	column-gap: 50px;
	flex-wrap: wrap;
	row-gap: 30px;
}

@media screen and (max-width: $tabletBreak) {
	.categories-container {
		width: 70vw;
		flex-wrap: wrap;
		.col-left {
			margin: 15px auto;
			flex: 1 1 45%;
		}
	}
}

@media screen and (max-width: 600px) {
	.categories-container {
		flex-direction: column;
		.col-left {
			margin: 10px auto;
			flex: 1 1 100%;
		}
	}
}

.requests-tabs-container {
	width: fit-content;
	margin: 25px auto;
	display: flex;
	padding: 0px;
	border-radius: 25px;
	.tab {
		padding: 5px 10px;
		text-align: center;
		cursor: pointer;
		width: 160px;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		background: #d7d7d7;
		color: #808080;
		input:focus {
			outline: none;
		}
		&:last-of-type {
			border-top-right-radius: 25px;
			border-bottom-right-radius: 25px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&.active {
			background: $tmsblue;
			color: white;
		}
	}
}

.package-category-wrapper,
.addon-category-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 30px;
	&.tiers {
		.form-item {
			input {
				margin-right: 5px;
				width: fit-content !important;
			}
		}
	}
	#packages-category {
		width: fit-content;
		h3 {
			font-size: 1.6em;
			font-weight: 100;
			margin-bottom: 10px;
		}
		.form-item {
			margin: 8px 0;
			label {
				word-wrap: break-word;
			}
			input {
				margin-right: 8px;
				width: 15px !important;
				height: 15px !important;
			}
		}
	}
}

.unenroll-users-admin {
	.unenroll-custom-checkbox {
		display: flex;
		width: fit-content;
		margin: 0 auto;
	}
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		width: 20px;
		height: 20px;
		min-width: fit-content;
	}
	.checkbox-custom {
		margin: auto;
		height: 20px;
		width: 20px;
		background-color: transparent;
		border-radius: 5px;
		border: 1px solid $tmsblue;
		cursor: pointer;
		&::after {
			position: absolute;
			content: "";
			left: 12px;
			top: 12px;
			height: 0;
			width: 0;
			border-radius: 5px;
			border: solid $tmsblue;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(0deg) scale(0);
			-ms-transform: rotate(0deg) scale(0);
			transform: rotate(0deg) scale(0);
			opacity: 0;
		}
	}
	input:checked ~ .checkbox-custom {
		background-color: white;
		border-radius: 5px;
		-webkit-transform: rotate(0deg) scale(1);
		-ms-transform: rotate(0deg) scale(1);
		transform: rotate(0deg) scale(1);
		opacity: 1;
		border: 1px solid $tmsblue;
	}
	input:checked ~ .checkbox-custom::after {
		-webkit-transform: rotate(40deg) scale(1);
		-ms-transform: rotate(40deg) scale(1);
		transform: rotate(40deg) scale(1);
		opacity: 1;
		left: 5px;
		top: 0px;
		height: 15px;
		width: 7px;
		border: solid $tmsblue;
		border-width: 0 2px 2px 0;
		background-color: transparent;
		border-radius: 0;
	}
}

#admin-user-actions {
	width: 100%;
	gap: 20px;
	.content {
		display: flex;
		width: 100%;
		gap: 20px;
		flex-wrap: wrap;
		align-items: center;
		.btn {
			display: block;
			cursor: pointer;
			border: none;
			padding: 10px 15px;
			border-radius: 20px;
		}
	}
}

.branch-users-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	.users-list {
		display: flex;
		flex-wrap: wrap;
		gap: 3em;
		width: 100%;
		.card {
			flex: 0 0 min(100%, 125px);
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.25em;
			text-decoration: none;
			color: $darkblue;
			.lazy-load-image-background {
				width: 100%;
				height: auto;
			}
			* {
				width: 100%;
				text-align: center;
				word-wrap: break-word;
				overflow-wrap: break-word;
				text-decoration: none;
				line-height: normal;
			}
			img {
				width: 100%;
				height: auto;
				border-radius: 50%;
			}
			p {
				font-size: 1.05em;
				&.inactive {
					font-size: 0.85em;
				}
			}
			&.inactive {
				// opacity: 0.5;
			}
		}
	}
}
