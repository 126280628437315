// open house flyer generator
.oh-flyer-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    // used for the color scheme of the component
    $colorScheme: $danger;

    .preview-container {
        flex: 0 0 min(100%, 275px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        h4 {
            text-align: center;
            line-height: normal;
        }
        img {
            width: 100%;
            display: block;
            border: 1px solid $defaultNeutral;
        }
        .preview {
            z-index: -9999;
        }
        #overlay-text {
            border: 1px solid darkgrey;
            height: 500px;
            max-width: 400px;
            position: relative;
            width: 390px;
            h3 {
                color: $darkblue;
                font-family: "Bariol", sans-serif;
                font-size: 2.1em;
                padding-bottom: 0px;
                text-align: center;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: relative;
                width: 80%;
                line-height: 1.5em;
            }
        }
        a.btn {
            color: white;
            background-color: $colorScheme;
            width: fit-content;
        }
    }
    .footer-wrapper {
        width: 100%;
        text-align: center;
        padding: 0;
        .footer {
            position: relative;
            padding: 1em 0;
            width: 100%;
            display: flex;
            border-top: 1px solid #eff3f4;
            justify-content: space-between;
            button,
            a {
                font-size: 1.25em;
                padding: 0.5em 1.5em;
                border: 0;
                outline: none;
                width: fit-content;
                max-width: 100%;
                cursor: pointer;
                text-align: center;
                font-family: "Bariol";
                text-transform: lowercase;
                text-decoration: none;
                border-radius: 2em;
                color: white;
                background-color: $colorScheme;
            }
            .previousBtn {
                opacity: 1;
                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .continueBtn {
                opacity: 0.3;
                pointer-events: none;
                &.active {
                    opacity: 1;
                    cursor: pointer;
                    pointer-events: all;
                }
            }
        }
    }
    .oh-flyer-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        flex: 1;

        * {
            transition: all 0.15s ease-in-out;
        }

        .left-container {
            background-color: $colorScheme;
            background-image: url("/images/backgrounds/generators_background.png");
            background-position: center;
            background-size: cover;
            flex: 0 0 min(20%, 275px);
            padding: 1.5em 0.75em;
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            img {
                display: block;
                margin: 0;
                width: min(90%, 100px);
                flex: 0 0 fit-content;
            }
        }
        .progress-bar-wrapper {
            width: 100%;
            .progress-bar {
                text-align: center;
                width: 100%;
                height: fit-content;
                position: relative;
                h2 {
                    margin: 20px 0;
                    text-transform: lowercase;
                    font-size: 3.7em;
                    font-family: "Source Sans Pro";
                    color: $darkblue;
                    font-weight: 200;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    vertical-align: middle;
                    list-style: none;
                    margin-bottom: 0px;
                    margin: auto;
                    width: max-content;
                    max-width: 100%;
                    text-align: center;
                    gap: 1.5em;
                    li {
                        font-size: 1.15rem;
                        color: white;
                        text-align: center;
                        position: relative;
                        width: fit-content;
                        font-family: "Bariol Light";
                        display: flex;
                        text-transform: lowercase;
                        gap: 1em;
                        align-items: center;
                        cursor: pointer;
                        padding-left: 1.2em;
                        &::after {
                            content: "";
                            position: absolute;
                            top: calc(50% + 7px);
                            left: 0;
                            width: 1px;
                            height: calc(0.75em + 3px);
                            background: white;
                            z-index: 0;
                            opacity: 0.5;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: calc(50% + 7px);
                            left: 0;
                            width: 1px;
                            height: calc(0.75em + 2px);
                            background: white;
                            z-index: 0;
                            opacity: 0.5;
                        }
                        &:first-of-type {
                            &::before {
                                display: none;
                            }
                        }
                        &:last-of-type {
                            &::after {
                                display: none;
                            }
                        }
                        span.step-dot {
                            display: block;
                            margin: 0;
                            position: relative;
                            @include spherize(14px, "none");
                            background-color: white;
                            border: 1px solid white;
                            border: none;
                            opacity: 0.5;

                            position: absolute;
                            left: -7px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        span.step-label {
                            line-height: normal;
                            font-weight: 100;
                            opacity: 0.5;
                        }
                    }
                    li.active {
                        span.step-dot {
                            background-color: #fff;
                            color: #fff;
                            opacity: 1;
                        }
                        span.step-label {
                            opacity: 1;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .right-container {
            flex: 1;
            max-width: $contentMaxWidth;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 0.75em 0 0;
            .request-form {
                flex: 1;
                overflow-y: auto;
                display: flex;
                gap: 2em;
                flex-wrap: wrap;
                .step-container {
                    flex: 1 0 min(750px, 100%);
                    animation: fadeIn 0.3s ease-in-out;
                }
                & > * {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                }

                @media screen and (max-width: $tabletBreak) {
                    flex-direction: column;
                    .step-container {
                        width: 100%;
                        flex: 0 0 fit-content;
                    }
                }
            }
            h1 {
                color: $colorScheme;
                font-family: "Bariol Light";
                width: fit-content;
                font-size: 3.75em;
                font-weight: 100;
                padding-bottom: 10px;
                border-bottom: 1px solid $colorScheme;
                text-transform: lowercase;
                line-height: 1em;
            }

            h4 {
                color: $darkblue;
                font-family: "Bariol";
                width: fit-content;
                font-size: 1.2em;
                margin: 0;
            }

            .detail-options {
                display: flex;
                flex-direction: row;
                width: 100%;
                max-width: $contentMaxWidth;
                justify-content: flex-start;
                gap: 30px;
                flex-wrap: wrap;
                margin: 30px 0;
                .option {
                    flex: 0 0 200px;
                    color: #656565;
                    padding: 15px 30px;
                    border-radius: 5px;
                    font-family: "Bariol";
                    position: relative;
                    border: 1px solid #656565;
                    text-align: center;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    .check {
                        display: none;
                    }
                    &.active {
                        border: 2px solid $colorScheme;
                        color: $colorScheme;
                        font-weight: bold;
                    }
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.4;
                    }
                }
            }
            .home-address-container,
            .contact-container,
            .loan-details-container,
            .review-container,
            .property-details-container {
                .tabs-wrapper {
                    display: flex;
                    width: 100%;
                    margin: 0;
                    .subtab {
                        font-family: "Bariol";
                        flex: 0 0 min(100%, 350px);
                        text-align: center;
                        font-weight: bold;
                        padding: 8px 12px;
                        border-radius: 0;
                        color: $darkblue;
                        cursor: pointer;
                        background-color: white;
                        border: 2px solid #d3d3d3;

                        font-size: 1em;
                        border-radius: 0;

                        &:first-of-type {
                            border-top-left-radius: 0.75em;
                            border-right: none;
                        }

                        &:last-of-type {
                            border-left: none;
                            border-top-right-radius: 0.75em;
                        }

                        &.active {
                            background-color: $darkblue;
                            border-color: $darkblue;
                            color: white;
                        }
                    }
                }
                &.forward {
                    animation: fadein 0.5s ease-in-out forwards;
                }
                &.reverse {
                    animation: fadeout 0.5s ease-in reverse;
                }
            }
            .home-address-container {
                #loading-box {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    background: white;
                    min-height: auto;
                    display: block;
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: center;
                    gap: 0.75em;
                    z-index: 0;
                    h1 {
                        position: relative;
                        color: $darkblue;
                        margin: 0;
                        text-align: left;
                        padding: 0;
                        border: none;
                    }
                    svg {
                        width: fit-content;
                        height: fit-content;
                        max-height: 80px;
                        margin: 0;
                    }
                }
                #home-address-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .form-item {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        justify-content: flex-start;
                        margin: 0;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: black;
                            font-weight: 100;
                        }
                        input {
                            width: 100% !important;
                            background: white !important;
                            @media screen and (max-width: $mobileLargeBreak) {
                                min-width: auto;
                                max-width: 100%;
                            }
                        }
                    }
                    #form-item-wrapper {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 20px;

                        .form-item {
                            flex: 1;
                            .input {
                            }
                        }
                        &:last-of-type {
                            .form-item {
                                width: 100% !important;
                                input,
                                select {
                                    background: white !important;
                                    width: 100% !important;
                                }
                            }
                        }
                        @media screen and (max-width: $mobileLargeBreak) {
                            flex-direction: column;
                            gap: 20px;
                            .form-item {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .open-house-lander-details {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                h3 {
                    color: $darkblue;
                    font-size: 1.4em;
                }
                .form-item {
                    margin: 0;
                    label {
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    margin: 0;
                    flex-wrap: wrap;
                    .btn {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        max-width: 180px;
                        width: fit-content;
                        justify-content: center;
                        padding: 10px 25px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                .detail-options {
                    margin: 0;
                }
            }
            .contact-container {
                .user-photos-wrapper {
                    display: flex;
                    width: 100%;
                    margin: 0;
                    flex-wrap: wrap;
                    gap: 30px;
                    .col {
                        flex: 1 0 300px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: 10px;
                        #profile-photo-large,
                        img {
                            margin: 0;
                            width: 175px;
                            height: auto;
                        }
                        h4 {
                            font-size: 1.4em;
                        }
                        .sub-col {
                            padding-top: 10px;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            justify-content: center;
                            text-align: center;
                            h4 {
                                margin: 0;
                                font-size: 1.2em;
                            }
                            .detail-options {
                                margin: 0;
                                justify-content: center;
                            }
                        }
                    }
                    #company-logo-lg {
                        min-width: 180px;
                        max-width: 245px;
                        max-height: 100%;
                    }
                    @media screen and (max-width: $tabletBreak) {
                        flex-direction: column;
                    }
                }
                .realtor-container {
                    h4 {
                        a {
                            text-decoration: underline;
                            font-family: "Bariol";
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                &.inactive {
                    svg {
                        transform: rotate(270deg);
                    }
                    .realtor-container {
                        display: none;
                    }
                    #contact-form {
                        display: none;
                    }
                }
                .realtor-container {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .realtor-select-wrapper {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    gap: 20px;
                    .form-item {
                        margin: 0;
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: #000;
                            position: relative;
                            padding: 0;
                        }
                    }
                    .form-item.select {
                        width: min(100%, 650px);
                    }
                }
                h4 {
                    margin: 0;
                }
                .authorize-banner {
                    max-width: 750px;
                    width: fit-content;
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
                .form-item {
                    label {
                        width: fit-content;
                        position: relative;
                        padding: 0;
                    }

                    .filter-select {
                        .react-select__multi-value {
                            background-color: #eeeeee;
                        }
                    }

                    &.open-house-dates {
                        box-shadow: 0px 0px 5px 0 #d6d6d6;
                        border-radius: 20px;
                        padding: 20px;
                        width: max-content;
                        max-width: 100%;
                        position: relative;
                        margin-left: 5px;
                        label {
                            font-family: "Bariol Bold";
                            color: $darkblue;
                            font-weight: normal !important;
                        }
                        .dates-wrapper {
                            display: flex;
                            width: 100%;
                            gap: 20px;
                            flex-wrap: wrap;
                            label {
                                font-family: "Source Sans Pro";
                            }
                            .date-container {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                label {
                                    padding: 0;
                                }
                                .date {
                                    position: relative;
                                }
                                input::-webkit-inner-spin-button,
                                input::-webkit-clear-button {
                                    // -webkit-appearance: none;
                                    // display: none;
                                }
                                input[type="datetime-local"] {
                                    cursor: pointer;
                                    margin: 0;
                                    padding: 8px 12px !important;
                                }

                                input::-webkit-calendar-picker-indicator {
                                    color: rgba(0, 0, 0, 0);
                                    opacity: 0;
                                    width: 25px;
                                    height: 25px;
                                    right: 15px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    position: absolute;
                                    filter: invert(1);
                                    cursor: pointer;
                                }
                                .calendar {
                                    cursor: pointer;
                                    width: 25px;
                                    position: absolute;
                                    right: 15px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    pointer-events: none;
                                }
                            }
                        }
                        .close {
                            width: 20px;
                            height: 20px;
                            color: #000;
                            cursor: pointer;
                            position: absolute;
                            right: -30px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                h3 {
                    font-size: 1.45em;
                    color: $darkblue;
                }
                .add-open-house {
                    display: flex;
                    align-items: center;
                    display: flex;
                    background: transparent;
                    align-items: center;
                    border: 1px solid $colorScheme;
                    color: $colorScheme;
                    width: fit-content;
                }
                #contact-form {
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    gap: 15px;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .form-item {
                        flex: 0 0 min(650px, 45%);
                        margin: 0;
                        label {
                            color: $darkblue;
                        }
                        input,
                        select {
                            width: 100% !important;
                            min-width: inherit;
                        }
                        select {
                        }
                        textarea {
                            width: 100%;
                            height: 260px;
                            max-width: 100%;
                            resize: none;
                            max-height: 260px;
                        }
                    }
                    @media screen and (max-width: $tabletBreak) {
                        padding-left: 30px;
                    }

                    @media screen and (max-width: $mobileLargeBreak) {
                        flex-direction: column;
                        width: 90%;
                        margin: 0 auto;
                        align-content: center;
                        padding: 0;
                        .form-item {
                            flex: 1;
                            margin: 0 auto;
                            width: 100%;
                            &:last-of-type {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }
            .property-details-container {
                #property-details-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 2em;
                    #property_description_container {
                        display: flex;
                        flex-direction: column;
                        gap: 1em;
                        .form-item {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            margin: 0;
                        }
                    }
                    .form-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin: 0;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: black;
                            font-weight: 100;
                        }
                        input,
                        select {
                            width: 100% !important;
                            min-width: inherit;
                        }
                        select {
                            text-align: center;
                        }
                        textarea {
                            width: 100%;
                            min-height: 260px;
                            max-width: 100%;
                            resize: vertical;
                            max-height: 500px;
                            line-height: 1.45rem;
                        }
                        &.toggle {
                            flex-direction: row-reverse;
                            align-items: center;
                            flex-wrap: wrap;
                            .switch {
                                width: 48px;
                            }
                            input:checked + .slider {
                                border-color: $colorScheme;
                            }
                            input:checked + .slider:before {
                                background-color: $colorScheme;
                            }
                        }
                        .checkbox-container-group {
                            margin: 0;
                        }
                    }
                    #form-item-wrapper {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                        gap: 2em;
                    }

                    .customizations-wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1.25em;
                        h3 {
                            color: $darkblue;
                            font-family: "Bariol";
                            width: fit-content;
                            font-size: 1.6em;
                            text-transform: lowercase;
                        }
                        .container {
                            display: flex;
                            flex-direction: column;
                            gap: 2em;
                            align-items: flex-start;
                            .form-item {
                            }
                            &:empty {
                                display: none;
                            }
                        }
                        &:not(:has(.form-item)) {
                            display: none;
                        }
                    }
                }
            }
            .loan-details-container {
                #loan-details {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 1em;
                    p {
                        flex: 0 0 fit-content;
                        font-size: 1.1em;
                    }
                }
                #loan-details-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    @media screen and (max-width: $tabletBreak) {
                        width: 100%;
                        padding: 0 10px;
                    }

                    .form-item {
                        width: 100%;
                        max-width: $contentMaxWidth;
                        margin: 0;
                        &.hoa {
                            max-width: 300px;
                        }
                        label {
                            text-align: left;
                            width: fit-content;
                            color: $darkblue;
                        }
                        .input-number {
                            flex: 1;
                        }
                        .slider-input-wrapper {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 1em;
                            .MuiSlider-root {
                                flex: 0 0 min(450px, 100%);
                            }
                        }
                    }

                    .products-wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1em;
                    }
                    h3 {
                        font-family: "Source Sans Pro";
                        width: fit-content;
                        font-size: 1.35em;
                        display: flex;
                        align-items: flex-start;
                        gap: 0.5rem;
                        flex-wrap: wrap;
                        flex-direction: column;
                        .btn {
                            font-size: 1rem;
                        }
                    }
                    .products-list {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        width: 100%;
                        gap: 1.25em;

                        details {
                            border: 1px solid #d3d3d3;
                            border-radius: 1em;

                            display: flex;
                            flex-direction: column;
                            gap: 0;

                            overflow: hidden;
                            summary {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 1em;
                                cursor: pointer;
                                h4 {
                                    font-family: "Bariol Light";
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    svg {
                                        fill: $tmsgreen;
                                        width: 24px;
                                        height: auto;
                                    }
                                }
                                svg {
                                    width: 20px;
                                    height: auto;
                                    color: $darkblue;
                                }
                                .fa-plus {
                                    display: block;
                                }

                                .fa-minus {
                                    display: none;
                                }
                            }

                            &[open] {
                                summary {
                                    background-color: $darkblue;
                                    * {
                                        color: white;
                                    }
                                    .fa-minus {
                                        display: block;
                                    }
                                    .fa-plus {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .scenario-wrapper {
                            // border: 1px solid #d3d3d3;
                            // border-radius: 1em;
                            padding: 1em;
                            width: 100%;
                            background-color: white;

                            display: flex;
                            flex-direction: column;
                            gap: 1em;

                            header {
                                display: flex;
                                align-items: center;
                                gap: 1em;
                                flex-wrap: wrap;
                                position: relative;
                                svg {
                                    position: absolute;
                                    right: 0;
                                    width: 24px;
                                    height: auto;
                                    cursor: pointer;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }

                            .form-item {
                                input,
                                select {
                                    width: min(100%, 325px);
                                }
                            }

                            .product-list-wrapper {
                                .product-checkbox {
                                    display: flex;
                                    align-items: center;
                                    gap: 15px;
                                    .container {
                                        flex: 0 0 fit-content;
                                        align-items: center;
                                        margin: 0;
                                    }
                                }
                                .disabled {
                                    pointer-events: none;
                                    opacity: 0.45;
                                }

                                .disabled-error {
                                    color: $colorScheme;
                                    opacity: 1;
                                }
                            }
                        }

                        .container {
                            display: flex;
                            width: fit-content;
                            position: relative;
                            line-height: 1.4em;
                            padding-left: 35px;
                            margin-bottom: 8px;
                            margin-top: 3px;
                            cursor: pointer;
                            font-size: 1.1em;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            color: #737272;
                            font-family: "Source Sans Pro";
                            text-transform: capitalize;
                            &.checked {
                                color: $colorScheme;
                                font-weight: bold !important;
                            }
                        }

                        /* Hide the browser's default checkbox */
                        .container input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            width: 25px;
                            aspect-ratio: 1/1;
                            background-color: $defaultNeutralLight;
                            border-radius: 50%;
                        }

                        /* On mouse-over, add a grey background color */
                        .container:hover input ~ .checkmark {
                            background-color: #ccc;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .container input:checked ~ .checkmark {
                            background-color: $colorScheme;
                        }

                        /* Create the checkmark/indicator (hidden when not checked) */
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        /* Show the checkmark when checked */
                        .container input:checked ~ .checkmark:after {
                            display: block;
                        }

                        /* Style the checkmark/indicator */
                        .container .checkmark:after {
                            left: 9px;
                            top: 3px;
                            width: 7px;
                            height: 16px;
                            border: solid white;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }

                        .product_pricing_container {
                            display: flex;
                            gap: 20px;
                            align-items: center;
                            flex: 0 0 100%;
                            h3 {
                                font-size: 1.1em;
                                margin: 0 !important;
                            }
                        }

                        .product-details-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 1em;
                            .error {
                            }

                            .product-details-container {
                                gap: 1em;
                                align-items: flex-start;
                                display: grid;
                                grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
                                .form-item {
                                    input,
                                    select {
                                        width: 100% !important;
                                    }

                                    .disabled-number-format {
                                        width: 100%;
                                        padding: 8px 15px;
                                        border-radius: 20px;
                                        font-size: 1em;
                                        border: 1px solid #c4c4c4;
                                        cursor: not-allowed;
                                    }

                                    .quantity-field-group {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .product_pricing_wrapper {
                            margin: 0;
                            .product-options-wrapper {
                                display: flex;
                                gap: 20px;
                            }
                            .error {
                                border: none;
                                background-color: transparent;
                                color: $colorScheme;
                                margin: 0;
                                padding: 0 0 5px;
                                font-size: 1.2em;
                                text-align: left;
                                justify-content: flex-start;
                            }
                            .product-pricing-wrapper {
                                display: flex;
                                gap: 20px;
                                align-items: flex-start;
                                flex-direction: row;
                                flex-wrap: wrap;
                                .form-item {
                                    margin: 0;
                                    flex: 0 0 fit-content;
                                }
                                .btn {
                                    height: fit-content;
                                    width: fit-content;
                                    min-width: fit-content;
                                    &.darkblue {
                                        min-width: 150px;
                                        padding: 8px 15px;
                                    }
                                }
                                @media screen and (max-width: $mobileLargeBreak) {
                                    flex-direction: column;
                                }
                            }

                            .ob-rates {
                                width: 100%;
                                margin: 0;
                                overflow-x: auto;
                                ::-webkit-scrollbar {
                                    height: 8px;
                                }
                                ::-webkit-scrollbar-track {
                                    // background: $defaultNeutralLight;
                                    // border-radius: 10px;
                                }
                                ::-webkit-scrollbar-thumb {
                                    // background: $darkblue;
                                    border-radius: 10px;
                                }
                                .ob-rates-table {
                                    display: flex;
                                    flex-direction: column;
                                    padding-bottom: 1em;
                                    width: 100%;
                                    overflow-x: auto;
                                    gap: 0.25em;

                                    #rate {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 1.25em;
                                        &.header {
                                            border: none;
                                            h4 {
                                                border-bottom: 1px solid $darkblue;
                                            }
                                        }
                                        .row {
                                            display: flex;
                                            align-items: flex-end;

                                            & > * {
                                                line-height: normal;
                                                padding: 0.25em;
                                            }

                                            h4,
                                            .rate-options {
                                                flex: 0 0 70px;
                                                align-items: flex-end;
                                            }

                                            h4,
                                            p {
                                                flex: 0 0 135px;
                                                text-align: center;
                                            }
                                        }
                                        .rate-options {
                                            display: flex;
                                            flex-direction: row;
                                            gap: 8px;
                                            label {
                                                padding: 0;
                                                width: 25px;
                                                height: 25px;
                                                margin: 0;
                                            }
                                            svg {
                                                width: 25px;
                                                height: auto;
                                                color: $darkblue;
                                                cursor: pointer;
                                                padding: 0;
                                            }
                                        }
                                    }
                                    h4 {
                                        padding: 0;
                                        color: $darkblue;
                                        width: 100%;
                                    }
                                }
                                .ob-rates-content {
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                            #scenario-edit-wrapper {
                                width: 100%;
                                border: 1px solid #d3d3d3;
                                padding: 1em;
                                border-radius: 1em;
                                margin: 0;
                                box-sizing: border-box;
                                margin: 0;

                                display: flex;
                                flex-direction: column;
                                gap: 0.75em;
                                .warning {
                                    display: flex;
                                    align-items: center;
                                    gap: 0.25em;
                                    margin: 0;
                                    h2 {
                                    }
                                    svg {
                                        width: 30px;
                                        height: auto;
                                        color: white;
                                    }
                                    p {
                                        font-size: 1em;
                                        text-align: left;
                                    }
                                }
                                #product-option-form {
                                    width: 100%;
                                    display: grid;
                                    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                                    gap: 1em;
                                    .form-item {
                                        width: 100%;
                                        margin: 0;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 0.35em;
                                        input {
                                            width: 100% !important;
                                            &:disabled {
                                                background-color: $defaultNeutralLight;
                                                cursor: not-allowed;
                                            }
                                        }
                                    }
                                }
                                .btn-wrapper {
                                    margin: 0 0;
                                    display: flex;
                                    justify-content: center;
                                    gap: 20px;
                                    .btn {
                                        width: fit-content;
                                        margin: 0;
                                        min-width: 150px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .photos-container {
                #photos-section-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: 30px;
                    .image-uploaded-success {
                        background-color: $tmsgreen;
                        color: white;
                        width: 100%;
                        padding: 10px;
                        margin-bottom: 20px;
                    }
                    #upload-container {
                        flex: 1;
                        display: flex;
                        gap: 30px;
                        flex-direction: column;
                    }
                    #upload-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        #upload {
                            display: flex;
                            justify-content: flex-start;
                            width: 100%;
                            max-width: 400px;
                            margin: 0;
                            gap: 20px;
                            .file-upload {
                                width: 240px;
                                padding: 15px 8px;
                                background: $colorScheme;
                            }
                            p {
                                height: fit-content;
                                font-size: 1.2em;
                                margin: auto 0;
                            }
                            .upload-container {
                                margin: 0;
                            }
                            #open-media {
                                min-width: 240px;
                                width: 240px !important;
                                height: fit-content;
                                border-radius: 40px;
                                margin-bottom: 0;
                                background: $colorScheme;
                                border: none;
                                padding: 15px 8px;
                                color: white;
                                font-weight: 100;
                                font-family: "Source Sans Pro";
                                text-align: center;
                                cursor: pointer;
                                span {
                                    width: 240px;
                                    overflow-wrap: break-word;
                                }
                            }
                        }
                        #image-preview {
                            width: 100%;
                            max-width: 650px;
                            margin: 0;
                            .file-details {
                                flex: 1;
                                p {
                                    flex: 1;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 100% !important;
                                }
                            }
                        }
                        .error {
                            margin: 10px 0;
                            width: fit-content;
                            max-width: 100%;
                        }
                    }
                }
            }
            .review-container {
                width: 100%;
                .container {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;
                    width: 100%;
                    gap: 40px;
                    flex-wrap: wrap;
                    .review-content {
                        flex: 1;
                        min-width: 450px;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 30px;
                        padding-left: 20px;
                        h2 {
                            color: $darkblue;
                            font-family: "Bariol", sans-serif;
                            font-size: 2.8em;
                            padding-bottom: 15px;
                            border-bottom: 2px solid $defaultNeutralLight;
                            width: fit-content;
                        }
                        .review-data-container {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            margin-top: 0;
                            width: 100%;
                            gap: 20px;
                            border-bottom: 1px solid $colorScheme;
                            padding-bottom: 20px;
                            .review-data {
                                flex: 0 0 fit-content;
                                padding: 0;
                                width: fit-content;
                                text-transform: capitalize;
                                svg {
                                    cursor: pointer;
                                }
                                &.disable {
                                    h3 {
                                        padding-bottom: 5px;
                                    }
                                    svg {
                                        display: none;
                                        pointer-events: none;
                                    }
                                }
                                h4 {
                                    padding: 3px 0;
                                    font-size: 1.1em;
                                }
                                h3 {
                                    color: $colorScheme;
                                    font-family: "Bariol";
                                    font-size: 1.3em;
                                    text-transform: lowercase;
                                    // border-bottom: 1px solid;
                                    width: fit-content;
                                    padding-right: 3px;
                                    margin-right: 15px;
                                }
                                .targeting {
                                    padding: 10px 0;
                                }
                                span {
                                    display: flex;
                                }
                                .filter-data {
                                    width: 100%;
                                    padding: 8px 0;
                                }
                                .email-data {
                                    width: 100%;
                                    padding: 8px 0;
                                }
                            }
                        }
                        .realtor-container {
                            margin-top: 30px;
                            padding-top: 30px;
                            border-top: 1px solid $colorScheme;
                            width: 100%;
                            .form-item {
                                margin: 0;
                                margin-bottom: 15px;
                                position: relative;
                                width: 100%;
                                max-width: 400px;
                                label {
                                    width: fit-content;
                                    text-transform: none;
                                    color: black;
                                    font-weight: 100;
                                }
                                input,
                                select {
                                    width: 100% !important;
                                    max-width: 320px !important;
                                    margin-top: 2px;
                                    padding: 12px 15px !important;
                                    background-color: white;
                                }
                                input::-webkit-inner-spin-button,
                                input::-webkit-clear-button {
                                    -webkit-appearance: none;
                                    display: none;
                                }
                                input[type="date"] {
                                    font-size: 1.05em;
                                    height: 45px;
                                    padding: 0px 15px !important;
                                    position: relative;
                                }
                                .calendar {
                                    width: 30px;
                                    position: absolute;
                                    right: 90px;
                                    top: 44px;
                                    pointer-events: none;
                                }
                                input::-webkit-calendar-picker-indicator {
                                    color: rgba(0, 0, 0, 0);
                                    opacity: 1;
                                    width: 30px;
                                    height: 30px;
                                    right: 90px;
                                    top: 42px;
                                    position: absolute;
                                    filter: invert(1);
                                }
                                select {
                                    text-align: left;
                                    padding: 8px 15px !important;
                                    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
                                        no-repeat right;
                                    -webkit-appearance: none;
                                    background-position-x: 95%;
                                }
                                textarea {
                                    width: 100%;
                                    height: 260px;
                                    max-width: 100%;
                                    resize: none;
                                    max-height: 260px;
                                }
                                .filter-select {
                                    margin-bottom: 20px;
                                    .react-select__multi-value {
                                        background-color: #eeeeee;
                                    }
                                }
                            }
                        }
                    }
                }
                .authorize-banner {
                    color: $darkblue;
                    background-color: $colorScheme;
                    padding: 7px 10px;
                    margin-bottom: 10px;
                    color: white;
                    margin-top: 12px;
                    display: flex;
                    gap: 5px;
                    width: fit-content;
                    svg {
                        margin: auto 5px auto 0;
                        display: block;
                        width: 18px;
                        height: 18px;
                    }
                    a {
                        color: white;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    &.success {
                        width: 100%;
                        background-color: $tmsgreen;
                        font-size: 1.1em;
                    }
                    &.notice {
                        width: fit-content;
                        max-width: 100%;
                        font-size: 1.1em;
                        background-color: $warning;
                        margin: 0;
                    }
                }
            }
            .compliance-questions-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .disclaimer {
                    display: flex;
                    flex-direction: row;
                    gap: 7px;
                    align-items: center;
                    color: $colorScheme;
                    font-size: 1.15em;
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                    a {
                        color: $colorScheme;
                        &:visited {
                            color: $colorScheme;
                        }
                    }
                }
                .card-wrapper {
                    display: flex;
                    gap: 25px;
                    flex-wrap: wrap;
                    padding: 0 20px;
                    &:not(:has(.card)) {
                        display: none;
                    }
                    .card {
                        flex: 0 0 250px;
                        min-height: 250px;
                        height: fit-content;
                        align-items: center;
                        box-shadow: 0px 1px 10px #d3d3d3;
                        border-radius: 20px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        h5 {
                            text-align: center;
                            color: $darkblue;
                            font-size: 1.1em;
                        }
                        .details {
                            width: 100%;
                            flex: 1;
                            p {
                                color: $darkblue;
                            }
                        }
                    }
                }
            }
        }
    }
    .image-preview {
        &.show {
            opacity: 1;
            z-index: 110;
            display: block;
            .footer-wrapper {
                padding: 20px 0 0 0;
                margin: 0;
            }
        }
        &.image {
            opacity: 1;
            z-index: 9999;
            display: block;
            .preview-content {
                opacity: 1;
                background: transparent;
                width: min(90vw, 1250px);
                height: auto;
                max-height: min(90vh, 1250px);
                p {
                    color: #d3d3d3;
                }
                img {
                    width: auto;
                    margin: 0 auto;
                    display: block;
                    max-width: 100%;
                }
            }
        }
        .preview-content {
            display: flex;
            flex-direction: column;
            text-align: center;
            height: fit-content;
            max-height: 90svh;
            padding: 25px;
            width: 100%;
            max-width: min(95vw, $contentMaxWidth);
            overflow: unset;
            background: #fff;
            border-radius: 20px;
            z-index: 999999;
            position: relative;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            gap: 20px;

            #header {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;
                h1 {
                    font-size: 30px;
                }
                #close {
                    font-size: 1.5em;
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                    color: $darkblue;
                }
            }
            #media-wrapper {
                flex: 1;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: center;
                gap: 1.5em;
                .image-container {
                    flex: 0 0 300px;
                    display: flex;
                    justify-content: center;
                    aspect-ratio: 16/9;
                    position: relative;
                    cursor: pointer;
                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        &.active {
                            border: 5px solid $tmsgreen;
                        }
                    }
                }
            }
            &.show {
                opacity: 1;
            }
            #media-footer {
                width: 100%;
                position: relative;
                border-top: 1px solid #d3d3d3;
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                height: fit-content;
                row-gap: 20px;
                flex-wrap: wrap;
                align-items: center;
                p {
                    position: relative;
                    color: black;
                    top: initial;
                    right: initial;
                    font-size: 20px;
                    margin: auto 0;
                }
                #media-buttons {
                    width: fit-content;
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                    .upload-container,
                    .preview-crop-button {
                        margin: 0;
                        width: fit-content;
                        font-size: 22px;
                        height: 50px;
                        padding: 11px 30px;
                        border: 0;
                        outline: none;
                        margin-top: 0px;
                        cursor: pointer;
                        text-align: center;
                        font-family: "Bariol";
                        font-weight: 100;
                        text-transform: lowercase;
                        color: white;
                        opacity: 1;
                        background-color: $colorScheme;
                        border-radius: 5px;
                        pointer-events: none;
                        opacity: 0.4;
                        label {
                            background: none;
                            padding: 0;
                            margin: 0;
                            font-family: "Bariol";
                            font-size: 22px;
                            cursor: pointer;
                        }
                        &.show {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    .upload-container {
                        opacity: 1;
                        pointer-events: all;
                    }
                    .file-upload {
                        width: fit-content;
                        font-weight: 100;
                    }
                }
                @media screen and (max-width: $tabletBreak) {
                    // flex-direction: column;
                    #media-buttons {
                        flex-wrap: wrap;
                    }
                }
            }
        }
        &.oh-lander-share {
            .preview-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 750px;
                #header {
                    justify-content: center;
                    h1 {
                        color: $darkblue;
                    }
                }
                .content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;
                }
                .btn-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    gap: 1em;
                    .btn {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        background-color: white;
                        align-items: center;
                        gap: 0.5em;
                        padding: 1em 2em;
                        max-width: 100%;
                        width: fit-content;
                        &.active {
                            border: 1px solid $darkblue;
                        }

                        img {
                            display: block;
                            width: auto;
                            height: auto;
                            max-width: min(60px, 100%);
                            max-height: 60px;
                        }
                        svg {
                            color: $darkblue;
                            width: 32px;
                            height: 32px;
                        }
                        span {
                            color: $darkblue;
                        }
                    }
                }
                .wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 10px;
                    .checkbox-wrapper {
                        display: flex;
                        gap: 7px;
                        align-items: center;
                        input[type="checkbox"] {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            min-width: fit-content;
                            width: 22px !important;
                            height: 22px !important;
                        }
                        .checkbox-custom {
                            margin: 0;
                            height: 22px;
                            width: 22px;
                            background-color: transparent;
                            border-radius: 5px;
                            border: 1px solid #c4c4c4;
                            cursor: pointer;
                            &::after {
                                position: absolute;
                                content: "";
                                left: 12px;
                                top: 12px;
                                height: 0;
                                width: 0;
                                border-radius: 5px;
                                border: solid $tmsblue;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(0deg) scale(0);
                                -ms-transform: rotate(0deg) scale(0);
                                transform: rotate(0deg) scale(0);
                                opacity: 0;
                            }
                        }
                        input:checked ~ .checkbox-custom {
                            background-color: white;
                            border-radius: 5px;
                            -webkit-transform: rotate(0deg) scale(1);
                            -ms-transform: rotate(0deg) scale(1);
                            transform: rotate(0deg) scale(1);
                            opacity: 1;
                            border: 2px solid #c4c4c4;
                        }
                        input:checked ~ .checkbox-custom::after {
                            -webkit-transform: rotate(40deg) scale(1);
                            -ms-transform: rotate(40deg) scale(1);
                            transform: rotate(40deg) scale(1);
                            opacity: 1;
                            left: 9px;
                            top: -10px;
                            height: 28px;
                            width: 10px;
                            border: solid $tmsblue;
                            border-width: 0 2px 2px 0;
                            background-color: transparent;
                            border-radius: 0;
                        }
                    }
                }
            }
            .authorize-banner {
                width: fit-content;
                max-width: 100%;
                min-width: 300px;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: $tabletBreak) {
    .oh-flyer-wrapper {
        // used for the color scheme of the component
        $colorScheme: $danger;

        .oh-flyer-container {
            flex-direction: column;
            gap: 20px;
            height: calc(100vh - 80px);
            .left-container {
                text-align: center;
                margin: 0 auto;
                width: 100%;
                flex: 0 0 fit-content;
                min-height: initial;
                position: relative;
                gap: 1.5rem;
                img {
                    width: min(90%, 100px);
                }
                .progress-bar {
                    text-align: center;
                    margin: 0;
                    width: 100%;
                    height: fit-content;
                    position: relative;
                    h2 {
                        margin: 20px 0;
                        text-transform: lowercase;
                        font-size: 3.7em;
                        font-family: "Source Sans Pro";
                        color: $darkblue;
                        font-weight: 200;
                    }
                    ul {
                        vertical-align: middle;
                        flex-direction: row;
                        list-style: none;
                        width: 100%;
                        gap: 0.5em;
                        flex: 0 0 fit-content;

                        li {
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            gap: 0.5em;
                            &::after {
                                display: block;
                                height: 1px;
                                width: calc(50% - 9px + 0.25em);
                                top: 0;
                                left: calc(50% + 9px);
                            }
                            &::before {
                                display: block;
                                height: 1px;
                                width: calc(50% - 9px + 0.25em);
                                top: 0;
                                right: calc(50% + 9px);
                                left: auto;
                                bottom: auto;
                            }

                            span.step-dot {
                                display: block;
                                margin: 0;
                                position: relative;
                                @include spherize(18px, "none");
                                background-color: white;
                                border: 1px solid #fff;
                                color: #fff;
                                position: absolute;
                                top: -9px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            span.step-label {
                                display: none;
                                padding-top: 0.65em;
                            }
                        }
                        li.active {
                            color: #fff;
                            margin-top: 0;
                            span.step-dot {
                            }
                            span.step-label {
                                display: block;
                            }
                        }
                    }
                }
            }
            .right-container {
                width: 100%;
                position: relative;
                padding: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;
                h1 {
                    width: 100%;
                    text-align: center;
                    font-size: 56px;
                }
                .home-address-container {
                    #home-address-form {
                        width: 100%;
                    }
                }
                .property-details-container {
                    width: 100%;
                    #property-details-form {
                        display: block;
                        width: 100%;
                        margin: auto;
                        padding: 0 10px;
                        #form-item-wrapper {
                            .form-item {
                                align-items: flex-start;
                            }
                        }
                    }
                }
                .photos-container {
                    width: 100%;
                    #photos-section-wrapper {
                        width: 100%;
                        flex-direction: column;
                        #upload-container {
                            width: fit-content;
                            margin: 20px 0;
                        }
                        .preview-container {
                            width: 100%;
                            padding: 0px 20px;
                            margin-bottom: 40px;
                            img {
                                width: 80%;
                                max-width: 400px;
                                max-height: initial;
                            }
                        }
                    }
                }
                .review-container {
                    width: 100%;
                    .container {
                        flex-direction: column;
                        .review-content {
                            width: 100%;
                            max-width: initial;
                            min-width: initial;
                            .realtor-container {
                                .form-item {
                                    width: 300px;
                                    .calendar {
                                        width: 31px;
                                        top: 41px;
                                        right: 20px;
                                    }
                                }
                            }
                        }
                        .preview-container {
                            width: 100%;
                            padding: 0px 20px;
                            margin-bottom: 40px;
                            img {
                                width: 80%;
                                max-width: 400px;
                                max-height: initial;
                            }
                        }
                    }
                }
                .footer-wrapper {
                    padding: 0;
                    margin: 0;
                    background-color: $colorScheme;
                    .footer {
                        width: 100%;
                        text-align: center;
                        margin: 0 auto;
                        padding: 1em;
                        justify-content: space-between;
                        align-items: center;
                        button {
                            width: fit-content;
                            padding: 1em;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        a {
                            text-align: center;
                            width: fit-content;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .continueBtn {
                            font-size: 1.5em;
                            width: fit-content;
                            padding: 10px 15px;
                            &::after {
                                content: "\1F86A";
                                margin: 16px 0 16px 6px;
                            }
                            &.submit {
                                background-color: $colorScheme;
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        .previousBtn {
                            font-size: 1.5em;
                            &.hide {
                                opacity: 0.4;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
        .image-preview {
            .preview-content {
                height: fit-content;
                max-height: 80svh;
            }
            &.oh-lander-share {
                .preview-content {
                    max-width: 60vw;
                    top: 100px !important;
                    transform: translate(-50%, 0) !important;
                    .btn-wrapper {
                        .btn {
                            img {
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobileLargeBreak) {
    .oh-flyer-wrapper {
        // used for the color scheme of the component
        $colorScheme: $danger;

        .image-preview {
            &.oh-lander-share {
                .preview-content {
                    max-width: 90vw;
                    top: 100px !important;
                    transform: translate(-50%, 0) !important;
                    .btn-wrapper {
                        .btn {
                            img {
                                padding: 0;
                                // width: 40px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// graphics generator
.graphics-generator-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    * {
        transition: all 0.15s ease-in-out;
    }

    .preview-container {
        flex: 0 0 min(100%, 300px);
        h3 {
            color: $darkblue;
            font-family: "Bariol", sans-serif;
            font-size: 2.1em;
            padding-bottom: 0px;
            text-align: center;
        }
        img {
            max-height: 450px;
            max-width: 100%;
            display: block;
            margin: auto;
            border: 1px solid darkgrey;
        }
        .preview {
            z-index: -9999;
        }
        #overlay-text {
            border: 1px solid darkgrey;
            height: 500px;
            max-width: 400px;
            position: relative;
            width: 390px;
            h2 {
                margin: 0 auto;
                top: 50%;
                position: relative;
                width: 80%;
                margin-top: -50px;
                line-height: 1.5em;
                text-align: center;
            }
        }
    }
    .footer-wrapper {
        width: 100%;
        text-align: center;
        .footer {
            position: relative;
            padding: 20px 0;
            width: 100%;
            max-width: $contentMaxWidth;
            display: flex;
            border-top: 1px solid #eff3f4;
            justify-content: space-between;
            button,
            a {
                font-size: 1.2em;
                padding: 10px 24px;
                border: 0;
                outline: none;
                min-width: 120px;
                max-width: fit-content;
                cursor: pointer;
                text-align: center;
                text-transform: lowercase;
                text-decoration: none;
                flex: 0 0 fit-content;
                width: fit-content;
            }
            .previousBtn {
                left: 0;
                color: white;
                background-color: $darkblue;
                border-radius: 25px;
                opacity: 1;
                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .continueBtn {
                right: 0;
                color: white;
                background-color: $darkblue;
                border-radius: 25px;
                opacity: 0.3;
                max-width: initial;
                cursor: default;
                pointer-events: none;
                &.active {
                    opacity: 1;
                    cursor: pointer;
                    pointer-events: all;
                }
                &.submit {
                    background-color: $darkblue;
                }
            }
        }
    }
    .graphics-generator-container {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 2em;
        justify-content: flex-start;
        flex: 1;
        .left-container {
            background-color: $tmsblue;
            background-image: url("/images/backgrounds/generators_background.png");
            background-position: center;
            // background-size: contain;
            flex: 0 0 min(20%, 275px);
            padding: 30px 0;
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            img {
                display: block;
                margin: 0;
                width: min(90%, 130px);
                flex: 0 0 fit-content;
            }
            img {
                display: block;
                margin: 0;
                width: min(90%, 130px);
                flex: 0 0 fit-content;
            }
        }
        .progress-bar {
            text-align: center;
            margin: 0;
            width: 100%;
            height: fit-content;
            position: relative;
            display: flex;
            h2 {
                margin: 20px 0;
                text-transform: lowercase;
                font-size: 3.7em;
                font-family: "Source Sans Pro";
                color: $darkblue;
                font-weight: 200;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 48px;
                vertical-align: middle;
                list-style: none;
                margin-bottom: 0px;
                margin: auto;
                width: max-content;
                max-width: 100%;
                text-align: center;
                li {
                    display: flex;
                    margin: 0;
                    font-size: 22px;
                    color: #9bb4c0;
                    text-align: center;
                    position: relative;
                    width: fit-content;
                    color: white;
                    font-family: "Bariol";
                    display: flex;
                    opacity: 0.6;
                    text-transform: lowercase;
                    align-items: center;
                    gap: 10px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 14px;
                        left: 7px;
                        width: 1px;
                        height: 61px;
                        background: white;
                        z-index: 0;
                        opacity: 0.6;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &:last-of-type::after {
                        display: none;
                    }

                    &.active,
                    &.completed {
                        opacity: 1;
                        cursor: pointer;
                        span {
                            transform: scale(1.1);
                            background-color: #fff;
                            color: #fff;
                        }
                    }
                }

                li > span {
                    display: block;
                    margin: 0;
                    position: relative;
                    @include spherize(14px, "none");
                    background-color: white;
                }
            }
        }
        .right-container {
            flex: 1;
            max-width: $contentMaxWidth;
            padding: 20px 0 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-height: 100%;

            .request-form {
                flex: 1;
                overflow-y: auto;
                overflow-x: initial;
                width: 100%;
            }

            h1 {
                color: $tmsblue;
                font-family: "Bariol Light";
                width: fit-content;
                font-size: 3.4em;
                font-weight: 100;
                padding-bottom: 15px;
                border-bottom: 1px solid $tmsblue;
                text-transform: lowercase;
            }
            .request-form {
                > :first-of-type {
                    display: flex;
                    flex-direction: column;
                    gap: 2em;
                    .content-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 3em;
                        align-items: flex-start;
                        width: 100%;
                    }
                }
            }

            .form-item {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .home-address-container {
                .error {
                    border: none;
                    width: fit-content;
                    text-align: left;
                    padding: 8px 15px;
                    font-weight: normal;
                }
                #loading-box {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    background: white;
                    min-height: auto;
                    display: block;
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: center;
                    gap: 10px;
                    margin-bottom: 20px;
                    z-index: 0;
                    h1 {
                        position: relative;
                        color: $darkblue;
                        margin: 0;
                        text-align: left;
                        padding: 0;
                        border: none;
                    }
                    svg {
                        width: fit-content;
                        height: fit-content;
                        max-height: 80px;
                        margin: 0;
                    }
                }
                #home-address-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .form-item {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        margin: 0;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: black;
                            font-weight: 100;
                        }
                        input {
                            width: 100% !important;
                            background: white !important;
                        }
                    }
                    #form-item-wrapper {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 1em;
                        .form-item {
                            flex: 1 0 300px;
                            input,
                            select {
                                width: 100%;
                                max-width: 100%;
                                min-width: 100%;
                            }
                        }
                    }
                }
            }
            .licensed-states-container {
                display: block;
                width: 100%;
                .container {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                #licensed-states-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-bottom: 20px;
                    .state {
                        padding: 4px 25px;
                        flex: 0 0 120px;
                        border: 1px solid $darkblue;
                        border-radius: 20px;
                        position: relative;
                        margin: 0;
                        text-align: center;
                        cursor: pointer;
                        #close {
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 6px;
                            &:after {
                                content: "\d7";
                                font-size: 24px;
                                color: black;
                                font-weight: bold;
                            }
                        }
                        &.active {
                            background-color: $tmsgreen;
                            border: 1px solid $tmsgreen;
                            color: white;
                            #close {
                                &:after {
                                    color: white;
                                }
                            }
                        }
                    }
                }

                .new-license-wrapper {
                    .form-item {
                        width: fit-content;
                        margin: 0;
                        label {
                            width: fit-content;
                            text-align: left;
                            margin: 0;
                        }
                    }
                }
            }
            .property-details-container {
                #property-details-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 2em;
                    flex: 1 0 min(750px, 100%);
                    #property_description_container {
                        margin-bottom: 40px;
                        .length {
                            text-align: right;
                            margin-right: 20px;
                            &.disable {
                                color: $danger;
                            }
                        }
                        .form-item {
                            margin: 0;
                        }
                    }
                    .form-item {
                        margin: 0;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: black;
                            font-weight: 100;
                        }
                        input,
                        select {
                            width: 100% !important;
                            min-width: inherit;
                        }
                        select {
                            text-align: center;
                        }
                        textarea {
                            width: 100%;
                            height: 260px;
                            max-width: 100%;
                            resize: none;
                            max-height: 260px;
                        }
                    }
                    #form-item-wrapper {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
                        gap: 2em;

                        .form-item.toggle {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;

                            label {
                                order: 2;
                            }

                            .switch {
                                order: 1;
                                position: relative;
                                display: inline-block;
                                width: 46px;
                                height: 24px;
                            }
                            .switch input {
                                opacity: 0;
                                width: 0;
                                height: 0;
                            }
                            .slider {
                                position: absolute;
                                cursor: pointer;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: white;
                                -webkit-transition: 0.4s;
                                transition: 0.4s;
                                border: 1px solid #a8a8a8;
                                border-radius: 34px;
                            }
                            .slider:before {
                                position: absolute;
                                content: "";
                                height: 20px;
                                width: 20px;
                                left: 1px;
                                bottom: 1px;
                                background-color: #a8a8a8;
                                -webkit-transition: 0.25s;
                                transition: 0.25s;
                                border-radius: 50%;
                            }
                            input:checked + .slider {
                                background-color: white;
                                border: 1px solid $tmsblue;
                            }
                            input:checked + .slider:before {
                                -webkit-transform: translateX(22px);
                                -ms-transform: translateX(22px);
                                transform: translateX(22px);
                                background-color: $tmsblue;
                            }
                        }
                        .form-item-container {
                            flex: 0 0 fit-content;
                            display: flex;
                            gap: 1em;
                            flex-direction: column;
                            .form-item {
                                flex: 0 0 fit-content;
                            }
                        }
                    }
                }
            }
            .contact-container {
                .user-photos-wrapper {
                    display: flex;
                    width: 100%;
                    margin: 0;
                    flex-wrap: wrap;
                    gap: 30px;
                    .col {
                        flex: 1 0 300px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: 15px;
                        img {
                            margin: 0;
                            &#profile-photo-large {
                                width: 175px;
                                aspect-ratio: 1;
                                margin: 0;
                                height: auto;
                            }
                        }
                        h4 {
                            font-size: 1.4em;
                            color: $darkblue;
                        }
                        .sub-col {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            justify-content: center;
                            text-align: center;
                            align-items: center;
                            h4 {
                                margin: 0;
                                font-size: 1.2em;
                                color: $darkblue;
                                font-family: "Bariol";
                                width: fit-content;
                                font-size: 1.2em;
                                text-align: center;
                            }
                            .detail-options {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                max-width: $contentMaxWidth;
                                justify-content: center;
                                gap: 30px;
                                flex-wrap: wrap;
                                margin: 0;
                                .option {
                                    flex: 0 0 200px;
                                    color: #656565;
                                    padding: 15px 30px;
                                    border-radius: 5px;
                                    font-family: "Bariol";
                                    position: relative;
                                    border: 1px solid #656565;
                                    text-align: center;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    .check {
                                        display: none;
                                    }
                                    &.active {
                                        border: 2px solid $danger;
                                        color: $danger;
                                        font-weight: bold;
                                    }
                                    &.disabled {
                                        pointer-events: none;
                                        opacity: 0.4;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: $tabletBreak) {
                            flex: 0 0 fit-content;
                        }
                    }
                    #company-logo-lg {
                        width: auto;
                        height: auto;
                        max-width: 205px;
                        max-height: 125px;
                    }
                    @media screen and (max-width: $tabletBreak) {
                        flex-direction: column;
                    }
                }
                #contact-form {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
                    gap: 2em;
                    .form-item {
                        margin: 0;
                        label {
                            width: fit-content;
                            font-weight: 100;
                            color: $darkblue;
                        }
                        input,
                        select {
                            width: 100% !important;
                            min-width: inherit;
                        }
                        textarea {
                            width: 100%;
                            height: 260px;
                            max-width: 100%;
                            resize: none;
                            max-height: 260px;
                        }
                    }

                    @media screen and (max-width: $mobileLargeBreak) {
                        width: 100%;
                        grid-template-columns: repeat(1, 1fr);
                        .form-item {
                            flex: 1;
                            margin: 0 auto;
                            width: 100%;
                            &:last-of-type {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
                .profile-photo {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 20px;
                    #profile-photo-large {
                        width: 100%;
                        max-width: 140px;
                        height: auto;
                    }
                    p {
                        font-size: 1.25em;
                        font-weight: 600;
                        margin-top: 10px;
                        font-family: "Bariol";
                        color: $tmsblue;
                        text-align: center;
                        max-width: 350px;
                        margin: 10px auto 0;
                    }
                }
            }
            .realtor-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .realtor-select-wrapper {
                    display: flex;
                    width: 100%;
                    align-items: flex-end;
                    gap: 20px;
                    .form-item {
                        margin: 0;
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: #000;
                            position: relative;
                            padding: 0;
                        }
                    }
                    .form-item.select {
                        width: 100%;
                        max-width: 450px;
                    }
                }

                .card-wrapper {
                    display: flex;
                    gap: 25px;
                    flex-wrap: wrap;
                    .card {
                        flex: 0 0 250px;
                        min-height: 250px;
                        height: fit-content;

                        box-shadow: 0px 1px 10px #d3d3d3;
                        border-radius: 20px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        h5 {
                            text-align: center;
                            color: $darkblue;
                            font-size: 1.1em;
                        }
                        .details {
                            flex: 1;
                            p {
                                color: $darkblue;
                            }
                        }
                    }
                }
            }
            .reviews-wrapper {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
                gap: 3em;
                justify-content: flex-start;
                margin: 0;
                width: 100%;
                padding: 0 1em 1em;

                .review-list-wrapper {
                    box-shadow: 1px 1px 10px #d3d3d3;
                    padding: 1em;
                    display: flex;
                    flex-direction: column;
                    border-radius: 1em;
                    justify-content: flex-start;
                    position: relative;
                    gap: 0.5em;
                    cursor: pointer;
                    &.disabled {
                        pointer-events: none;
                    }
                    @media screen and (max-width: $mobileLargeBreak) {
                        flex: 0 0 calc(100% - 50px);
                        margin: 0 auto;
                    }
                    .borrower-wrapper {
                        position: relative;
                        text-align: center;
                        font-family: "Source Sans Pro";
                        p {
                            font-weight: normal;
                            text-transform: capitalize;
                            &:first-of-type {
                                font-weight: bold;
                            }
                        }
                    }
                    .star-wrapper {
                        .star {
                            transform: rotate(35deg) scale(0.15);
                            margin: -15px -80px;
                        }
                    }
                    .quotation {
                        display: block;
                        width: 40px;
                        margin: 0 auto;
                    }
                    p.content {
                        flex: 1;

                        // overflow: hidden;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 6;
                        // -webkit-box-orient: vertical;
                        text-align: center;
                    }
                    .check {
                        display: none;
                    }
                    .error {
                        margin: 0;
                    }
                    &.active {
                        border: 3px solid $tmsblue;
                        box-shadow: 1px 1px 10px #fff;
                        // &::before {
                        // 	content: "";
                        // 	position: absolute;
                        // 	right: -10px;
                        // 	transform: rotate(90deg);
                        // 	width: 20px;
                        // 	top: 10px;
                        // 	border-bottom: 2px solid white;
                        // 	z-index: 20;
                        // }
                        .check {
                            position: absolute;
                            right: -10px;
                            top: -16px;
                            display: block;
                            transform: rotate(45deg);
                            height: 58px;
                            width: 28px;
                            background-color: white;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            border-bottom-left-radius: 5px;
                            border-bottom: 6px solid $tmsblue;
                            border-right: 6px solid $tmsblue;
                            z-index: 21;
                        }
                    }
                }
                .container {
                    display: block;
                    width: fit-content !important;
                    position: relative;
                    line-height: 1.4em;
                    padding: 0 0 0 35px;
                    margin: 2px 0;
                    cursor: pointer;
                    font-size: 1.1em;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    color: #737272;
                    font-family: "Source Sans Pro";
                    &.checked {
                        color: $danger;
                        font-weight: bold !important;
                    }
                }

                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    height: 24px;
                    width: 24px;
                    background-color: $defaultNeutralLight;
                    border-radius: 50%;
                }

                /* On mouse-over, add a grey background color */
                .container:hover input ~ .checkmark {
                    background-color: #ccc;
                    margin-top: -2px;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: $danger;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 9px;
                    top: 3px;
                    width: 7px;
                    height: 15px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                .authorize-banner {
                    color: $darkblue;
                    background-color: $danger;
                    padding: 7px 10px;
                    margin: 0;
                    color: white;
                    display: flex;
                    gap: 5px;
                    width: fit-content;
                    svg {
                        margin: auto 5px auto 0;
                        display: block;
                        width: 18px;
                        height: 18px;
                    }
                    a {
                        color: white;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    &.success {
                        width: 100%;
                        background-color: $tmsgreen;
                        font-size: 1.1em;
                    }
                    &.notice {
                        width: 100%;
                        font-size: 1.1em;
                        background-color: $warning;
                        margin: 0 0 20px;
                    }
                }
            }
            .photos-container {
                #photos-section-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    flex: 1 0 750px;
                    #upload-container {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;
                        flex: 1;
                    }
                    .image-uploaded-success {
                        background-color: $tmsgreen;
                        color: white;
                        width: 100%;
                        padding: 10px;
                        width: min(100%, 300px);
                    }
                    #upload-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        #upload {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 20px;
                            margin: 0;

                            p {
                                height: fit-content;
                                font-size: 1.2em;
                                margin: auto 0;
                            }
                            .upload-container {
                                margin: 0;
                            }
                            #open-media,
                            .file-upload {
                                width: fit-content;
                                height: fit-content;
                                min-width: 155px;
                                max-width: min(100%, 250px);
                                border-radius: 40px;
                                margin-bottom: 0;
                                background: $darkblue;
                                border: none;
                                padding: 12px 20px;
                                color: white;
                                font-weight: 100;
                                font-family: "Source Sans Pro";
                                text-align: center;
                                font-size: 1em;
                                cursor: pointer;
                                span {
                                    width: 240px;
                                    overflow-wrap: break-word;
                                }
                            }

                            .file-upload {
                                background: $tmsblue;
                            }
                        }
                    }
                }
            }
            .loan-details-container {
                #loan-details-form {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                    flex: 1 0 750px;
                    @media screen and (max-width: $tabletBreak) {
                        width: 100%;
                        padding: 0 10px;
                    }

                    .form-item {
                        width: fit-content;
                        margin: 0;
                        .input-number {
                            width: 100px !important;
                        }
                    }
                    h3 {
                        font-family: "Source Sans Pro";
                        width: fit-content;
                        font-size: 1.35em;
                    }
                    .products-list {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        gap: 15px;
                        .container {
                            display: block;
                            width: fit-content;
                            position: relative;
                            line-height: 1.4em;
                            padding-left: 35px;
                            cursor: pointer;
                            font-size: 1.1em;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            color: #737272;
                            font-family: "Source Sans Pro";
                            &.checked {
                                color: $tmsblue;
                                font-weight: bold !important;
                            }
                        }

                        /* Hide the browser's default checkbox */
                        .container input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            height: 22px;
                            width: 22px;
                            background-color: $defaultNeutralLight;
                            border-radius: 50%;
                        }

                        /* On mouse-over, add a grey background color */
                        .container:hover input ~ .checkmark {
                            background-color: #ccc;
                            margin-top: 2px;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .container input:checked ~ .checkmark {
                            background-color: $tmsblue;
                        }

                        /* Create the checkmark/indicator (hidden when not checked) */
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        /* Show the checkmark when checked */
                        .container input:checked ~ .checkmark:after {
                            display: block;
                        }

                        /* Style the checkmark/indicator */
                        .container .checkmark:after {
                            left: 8px;
                            top: 3px;
                            width: 6px;
                            height: 14px;
                            border: solid white;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                        .product_pricing_wrapper {
                            margin: 8px 35px;
                            .error {
                                border: none;
                                background-color: transparent;
                                color: $tmsblue;
                                margin: 0;
                                padding: 0 0 5px;
                                font-size: 1.2em;
                                justify-content: flex-start;
                            }
                        }
                    }
                    .authorize-banner {
                        color: white;
                        background-color: $danger;
                        padding: 7px 10px;
                        margin-bottom: 10px;
                        color: white;
                        margin-top: 12px;
                        display: flex;
                        gap: 5px;
                        width: fit-content;
                        svg {
                            margin: auto 5px auto 0;
                            display: block;
                            width: 18px;
                            height: 18px;
                        }
                        a {
                            color: white;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        &.success {
                            width: 100%;
                            background-color: $tmsgreen;
                            font-size: 1.1em;
                        }
                    }
                }
                .cobranded-wrapper {
                    .form-item {
                        margin: 0;
                        margin-bottom: 30px;
                        position: relative;
                        width: 100% !important;
                        max-width: 350px;
                        input[type="datetime-local"] {
                            cursor: pointer;
                            * {
                                cursor: pointer;
                            }
                        }
                        label {
                            width: fit-content;
                            text-transform: none;
                            color: black;
                            font-weight: 100;
                        }
                        input,
                        select {
                            width: 100% !important;
                            margin-top: 2px;
                            padding: 12px 15px !important;
                            background-color: white;
                        }
                        input::-webkit-inner-spin-button,
                        input::-webkit-clear-button {
                            -webkit-appearance: none;
                            display: none;
                        }
                        input[type="date"] {
                            font-size: 1.05em;
                            height: 45px;
                            padding: 0px 15px !important;
                            position: relative;
                        }
                        .calendar {
                            width: 30px;
                            position: absolute;
                            right: 15px;
                            top: 36px;
                            pointer-events: none;
                        }
                        input::-webkit-calendar-picker-indicator {
                            color: rgba(0, 0, 0, 0);
                            opacity: 1;
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            right: 0;
                            top: 32px;
                            position: absolute;
                            filter: invert(1);
                            width: 50%;
                        }
                        select {
                            text-align: left;
                            padding: 8px 15px !important;
                            background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
                                no-repeat right;
                            -webkit-appearance: none;
                            background-position-x: 95%;
                        }
                        textarea {
                            width: 100%;
                            height: 260px;
                            max-width: 100%;
                            resize: none;
                            max-height: 260px;
                        }
                    }
                }
            }
            .review-container {
                width: 100%;
                .detail-options {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    max-width: $contentMaxWidth;
                    justify-content: flex-start;
                    gap: 30px;
                    flex-wrap: wrap;
                    margin: 0;
                    .option {
                        flex: 0 0 200px;
                        color: #656565;
                        padding: 15px 30px;
                        border-radius: 5px;
                        position: relative;
                        border: 1px solid #656565;
                        text-align: center;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .check {
                            display: none;
                        }
                        &.active {
                            border: 2px solid $tmsblue;
                            color: $tmsblue;
                            font-weight: bold;
                        }
                        &.disabled {
                            pointer-events: none;
                            opacity: 0.4;
                        }
                    }
                }
                .review-edit-wrapper {
                    padding: 1em 2em;
                    border: 1px solid $defaultNeutral;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    background-color: $defaultNeutralLight;

                    // animation-name: slideDown;
                    // -webkit-animation-name: slideDown;
                    // animation-duration: 0.1s;
                    // -webkit-animation-duration: 0.1s;
                    // animation-timing-function: ease;
                    // -webkit-animation-timing-function: ease;
                    h3 {
                        color: $darkblue;
                        font-size: 1.45em;
                        font-family: "Bariol Bold";
                        text-align: center;
                        text-transform: lowercase;
                    }
                    .form-item {
                        margin: 0;
                        label {
                            text-align: left;
                            width: fit-content;
                        }
                        textarea {
                            resize: vertical;
                            width: 100%;
                            max-height: 250px;
                            min-height: 80px;
                        }
                    }
                    .length {
                        text-align: right;
                        margin-right: 0px;
                        &.disable {
                            color: $danger;
                        }
                    }
                }
                #loading-box {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    background: white;
                    min-height: auto;
                    display: block;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                    margin-bottom: 20px;
                    z-index: 0;
                    h1 {
                        position: relative;
                        color: $darkblue;
                        margin: 0;
                        text-align: left;
                        padding: 0;
                        border: none;
                        width: fit-content;
                    }
                    svg {
                        width: fit-content;
                        height: fit-content;
                        max-height: 80px;
                        margin: 0;
                    }
                }
                .load-more {
                    display: block;
                    width: fit-content;
                    min-width: 150px;
                    margin: 0 auto;
                }
                .container {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;
                    width: 100%;
                    gap: 30px;
                    flex-wrap: wrap;
                    .review-content {
                        width: 100%;
                        flex: 1;
                        padding: 0;
                        h2 {
                            color: $darkblue;
                            font-family: "Bariol", sans-serif;
                            font-size: 2.8em;
                            padding-bottom: 15px;
                            border-bottom: 2px solid $defaultNeutralLight;
                            width: fit-content;
                        }
                        .review-data-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            .review-data {
                                padding: 0;
                                width: fit-content;
                                text-transform: capitalize;
                                svg {
                                    cursor: pointer;
                                    width: 25px;
                                    height: 25px;
                                    color: #d3d3d3;
                                }
                                &.disable {
                                    h3 {
                                        padding-bottom: 5px;
                                    }
                                    svg {
                                        display: none;
                                        pointer-events: none;
                                    }
                                }
                                h4 {
                                    padding: 3px 0;
                                    font-size: 1.1em;
                                }
                                h3 {
                                    color: $tmsblue;
                                    font-family: "Bariol";
                                    font-size: 1.3em;
                                    text-transform: lowercase;
                                }
                                .targeting {
                                    padding: 10px 0;
                                }
                                span {
                                    display: flex;
                                    align-items: center;
                                    gap: 15px;
                                }
                                .filter-data {
                                    width: 100%;
                                    padding: 8px 0;
                                }
                                .email-data {
                                    width: 100%;
                                    padding: 8px 0;
                                }
                            }
                        }
                        .realtor-container {
                            margin-top: 30px;
                            padding-top: 30px;
                            border-top: 1px solid $tmsblue;
                            width: 100%;

                            .form-item {
                                margin: 0;
                                margin-bottom: 30px;
                                position: relative;
                                label {
                                    width: fit-content;
                                    text-transform: none;
                                    color: black;
                                    font-weight: 100;
                                }
                                input,
                                select {
                                    width: 100% !important;
                                    max-width: 300px !important;
                                    margin-top: 2px;
                                    padding: 12px 15px !important;
                                    background-color: white;
                                }
                                input::-webkit-inner-spin-button,
                                input::-webkit-clear-button {
                                    -webkit-appearance: none;
                                    display: none;
                                }
                                input[type="date"] {
                                    font-size: 1.05em;
                                    height: 45px;
                                    padding: 0px 15px !important;
                                    position: relative;
                                }
                                .calendar {
                                    width: 34px;
                                    position: absolute;
                                    right: 80px;
                                    top: 39px;
                                    pointer-events: none;
                                }
                                input[type="date"]::-webkit-calendar-picker-indicator {
                                    color: rgba(0, 0, 0, 0);
                                    opacity: 1;
                                    width: 25px;
                                    height: 27px;
                                    right: 18px;
                                    position: absolute;
                                }
                                select {
                                    text-align: left;
                                    padding: 8px 15px !important;
                                    background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
                                        no-repeat right;
                                    -webkit-appearance: none;
                                    background-position-x: 95%;
                                }
                                textarea {
                                    width: 100%;
                                    height: 260px;
                                    max-width: 100%;
                                    resize: none;
                                    max-height: 260px;
                                }
                            }
                        }
                        .dropdown-container {
                            cursor: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            h3 {
                                color: $darkblue;
                            }
                            .dd-text {
                                margin: 0;
                                display: flex;
                                align-items: center;
                                gap: 15px;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                            }
                            a {
                                flex: 0 0 175px;
                                text-decoration: none;
                            }
                        }
                        .authorize-banner {
                            * {
                                flex: 0 0 fit-content;
                            }
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                            a {
                                color: white;
                                text-decoration: underline;
                                cursor: pointer;
                                width: fit-content;
                                flex: 0 0 fit-content;
                            }
                            &.success {
                                width: 100%;
                                background-color: $tmsgreen;
                                font-size: 1.1em;
                            }
                            &.notice {
                                width: 100%;
                                font-size: 1.1em;
                                background-color: $warning;
                            }
                        }
                    }
                }
            }
        }
    }
    .image-preview {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.55);
        opacity: 0;
        &.show {
            opacity: 1;
            z-index: 121;
            display: block;
        }
        &.image {
            opacity: 1;
            z-index: 9999;
            display: block;
            .preview-content {
                opacity: 1;
                background: transparent;
                width: 100%;
                max-width: 950px;
                img {
                    width: auto;
                    margin: 0 auto;
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    &.white {
                        color: white;
                    }
                }
            }
        }
        .preview-content {
            text-align: center;
            height: 90svh;
            padding: 25px;
            width: min(90vw, $contentMaxWidth);
            overflow: unset;
            background: #fff;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 35px;
            z-index: 999999;
            position: relative;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            margin: 0;
            #header {
                display: flex;
                position: relative;
                width: 100%;
                h1 {
                    font-size: 30px;
                }
                #close {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 0px;
                    border-radius: 5px;
                    cursor: pointer;
                }
                #close:after {
                    position: absolute;
                    content: "\d7";
                    font-size: 45px;
                    color: #d3d3d3;
                    font-weight: bold;
                    top: -12px;
                    right: 0px;
                }
            }
            #media-wrapper {
                flex: 1;
                margin: 0px auto;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 2em;
                justify-content: space-evenly;

                width: 100%;
                // display: grid;
                // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                // grid-template-rows: repeat(300px);

                .image-container {
                    flex: 0 0 min(100%, 300px);
                    aspect-ratio: 16/9;
                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        max-width: 100%;
                        max-height: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                        &.active {
                            border: 4px solid $tmsgreen;
                        }
                    }
                }
            }
            &.show {
                opacity: 1;
                padding-bottom: 20px;
            }
            #media-footer {
                position: relative;
                border-top: 1px solid #d3d3d3;
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                height: fit-content;
                width: 100%;
                gap: 10px;
                flex-wrap: wrap;
                align-items: center;
                p {
                    position: relative;
                    color: black;
                    top: initial;
                    right: initial;
                    font-size: 20px;
                    margin: auto 0;
                }
                #media-buttons {
                    width: fit-content;
                    display: flex;
                    .upload-container,
                    .preview-crop-button {
                        margin: 0;
                        width: fit-content;
                        font-size: 22px;
                        height: 50px;
                        padding: 11px 30px;
                        border: 0;
                        outline: none;
                        margin-top: 0px;
                        cursor: pointer;
                        text-align: center;
                        font-family: "Bariol";
                        // font-weight: 100;
                        letter-spacing: 1px;
                        text-transform: lowercase;
                        color: white;
                        opacity: 1;
                        background-color: $tmsblue;
                        border-radius: 5px;
                        pointer-events: none;
                        opacity: 0.4;
                        label {
                            background: none;
                            padding: 0;
                            margin: 0;
                            font-family: "Bariol";
                            font-size: 22px;
                            width: fit-content;
                        }
                        &.show {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    .upload-container {
                        opacity: 1;
                        pointer-events: all;
                        margin-right: 10px;
                    }
                }
            }
        }
        &.social-details {
            .preview-content {
            }
            #header {
                flex: 0 0 fit-content;
                h1 {
                    text-align: center;
                    margin: 0 auto;
                    color: $darkblue;
                }
            }
            .wrapper {
                flex: 1;
                overflow-y: auto;
            }
            .footer-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 20px;
                padding: 0;
                margin: 0;
                #media-buttons {
                    width: fit-content;
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;
                    justify-content: center;
                    .btn {
                        width: fit-content;
                        min-width: 155px;
                        max-width: 100%;
                    }
                }
            }
            .social-details-content {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                gap: 25px;
                padding: 0 !important;
                .header {
                    display: flex;
                    gap: 10px;
                    margin: 0;
                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    img {
                        width: 40px;
                        border-radius: 50%;
                        margin: auto 0;
                        display: block;
                        padding: 0;
                    }
                    h2 {
                        text-align: left;
                        color: $tmsblue;
                        font-size: 1.2em;
                        text-align: left;
                        font-family: "Source Sans Pro";
                    }
                    h5 {
                        text-align: left;
                        color: #9197a3;
                        font-size: 0.8em;
                        font-family: "Source Sans Light";
                        display: flex;
                        justify-content: space-between;
                        gap: 7px;
                        width: fit-content;
                        svg {
                            margin: 3px 0;
                            height: fit-content;
                        }
                    }
                }
                img {
                    max-width: 500px;
                    display: block;
                    margin: 0 auto;
                }
                ::-webkit-scrollbar {
                    width: 5px;
                }
                p {
                    position: relative;
                    top: auto;
                    right: auto;
                    color: black;
                }
                .option-wrapper {
                    display: flex;
                    flex-direction: row;
                    margin: 0;
                    gap: 20px;
                    .container {
                        display: block;
                        width: fit-content;
                        position: relative;
                        line-height: 1.4em;
                        padding-left: 30px;
                        margin-bottom: 8px;
                        margin-top: 3px;
                        cursor: pointer;
                        font-size: 1.1em;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        color: #737272;
                        font-family: "Source Sans Pro";
                        &.checked {
                            color: $danger;
                            font-weight: bold !important;
                        }
                    }
                    /* Hide the browser's default checkbox */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    /* Create a custom checkbox */
                    .checkmark {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        height: 22px;
                        width: 22px;
                        background-color: white;
                        border-radius: 50%;
                        border: 1px solid #d3d3d3;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                        // background-color: $danger;
                        border: 1px solid $danger;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container input:checked ~ .checkmark:after {
                        display: block;
                    }

                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                        left: 1px;
                        top: 1px;
                        width: 18px;
                        height: 18px;
                        border: 2px solid white;
                        background-color: $danger;
                        border-radius: 50%;
                    }
                }
                .schedule-wrapper {
                    margin-top: 15px;
                    h3 {
                        text-align: left;
                    }
                    input {
                        text-align: left;
                        margin: 20px 0 10px;
                        font-size: 1em;
                        display: block;
                        border: 1px solid black;
                        padding: 5px;
                    }
                    .input-error {
                        color: $danger;
                        margin-bottom: 20px;
                        text-align: left;
                    }
                }
                label {
                    display: none;
                }
                textarea {
                    width: 100%;
                    height: 100px;
                    max-width: 100%;
                    min-width: 100%;
                    min-height: 100px;
                    max-height: 200px;
                    // resize: none;
                    border-radius: 0;
                    margin: 0 !important;
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                    .col {
                        img {
                            padding: 20px;
                            margin: 0 auto;
                        }
                    }
                }
                .disclaimer {
                    text-align: left;
                }
            }
            #media-footer {
                border: none;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: $tabletBreak) {
    .graphics-generator-wrapper {
        .graphics-generator-container {
            flex-direction: column;
            gap: 20px;
            .left-container {
                text-align: center;
                margin: 0 auto;
                flex: 0 0 fit-content;
                width: 100%;
                min-height: initial;
                position: relative;
                img {
                    width: min(90%, 120px);
                }
                .progress-bar {
                    margin: 10px auto;
                    h2 {
                        margin: 20px 0;
                        text-transform: lowercase;
                        font-size: 3.7em;
                        font-family: "Source Sans Pro";
                        color: $darkblue;
                        font-weight: 200;
                    }
                    ul {
                        vertical-align: middle;
                        flex-direction: row;
                        list-style: none;
                        margin-bottom: 0px;
                        gap: 0;
                        li {
                            display: inline-block;
                            margin: 0 auto;
                            font-size: 22px;
                            color: #9bb4c0;
                            text-align: center;
                            position: relative;
                            width: 122px;
                            color: #fff;
                            &::after {
                                top: 7px;
                                left: calc(50% + 11px);
                                width: calc(50% - 11px);
                                height: 2px;
                                background: white;
                                opacity: 0.6;
                                z-index: 1111;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                top: 7px;
                                right: calc(50% + 11px);
                                width: calc(50% - 11px);
                                opacity: 0.6;
                                height: 2px;
                                background: white;
                                z-index: 1111;
                            }
                            &:first-of-type::before {
                                display: none;
                            }
                            &:last-of-type::after {
                                display: none;
                            }
                        }
                        li.active {
                            color: #fff;
                            margin-top: 0;
                            span {
                                background-color: transparent;
                                color: #fff;
                                border: 2px solid;
                                @include spherize(22px, "none");
                                margin: -3px auto;
                            }
                        }
                        li > span {
                            display: block;
                            margin: 0 auto;
                            position: relative;
                            margin-bottom: 0px;
                            @include spherize(17px, "none");
                            background-color: white;
                            border: 1px solid #fff;
                            color: #fff;
                        }
                    }
                }
            }
            .right-container {
                width: 100%;
                position: relative;
                padding: 10px 0px 0;
                h1 {
                    text-align: center;
                    margin: 0 auto;
                }

                .photos-container {
                    width: 100%;
                    #photos-section-wrapper {
                        .preview-container {
                            width: 100%;
                            padding: 0px 20px;
                            margin-bottom: 40px;
                            img {
                                width: 80%;
                                max-width: 400px;
                                max-height: initial;
                            }
                        }
                    }
                }
                .review-container {
                    width: 100%;
                    padding: 0 20px;
                    .container {
                        flex-direction: column;
                        .review-content {
                            width: 100%;
                            max-width: initial;
                            min-width: initial;
                            .realtor-container {
                                .form-item {
                                    width: 300px;
                                    .calendar {
                                        width: 31px;
                                        top: 41px;
                                        right: 20px;
                                    }
                                }
                            }
                        }
                        .preview-container {
                            img {
                                width: min(100%, 400px);
                                max-height: initial;
                            }
                        }
                    }
                }
                .footer-wrapper {
                    padding: 0;
                    margin: 0;
                    .footer {
                        button {
                            width: fit-content;
                            max-width: 200px;
                            padding: 10px 15px;
                        }
                        a {
                            text-align: center;
                            width: fit-content;
                            margin: 0 auto;
                            padding: 0;
                        }
                        .continueBtn {
                            font-size: 1.3em;
                            width: fit-content;
                            max-width: 100%;
                            padding: 10px 15px;
                            &::after {
                                content: "\1F86A";
                                margin: 16px 0 16px 6px;
                            }
                            &.show {
                                margin: 0;
                            }
                            &.submit {
                                background-color: $danger;
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        .previousBtn {
                            font-size: 1.3em;
                            &.hide {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .image-preview {
            &.social-details {
                .preview-content {
                    max-width: 90vw;
                    width: 90vw;
                    max-height: 80svh;
                }
            }
            .preview-content {
                max-width: 90vw;
                width: 90vw;
                height: 85%;
                #media-wrapper {
                    .image-container {
                        flex: 1 0 50%;
                        margin-bottom: 0;
                        img {
                            width: 100%;
                            height: auto;
                            padding: 10px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

// email salesforce generator
.email-salesforce-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    $colorScheme: $salesforceblue;

    .preview-container {
        flex: 0 0 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        h4 {
            color: $darkblue;
            font-family: "Bariol", sans-serif;
            font-size: 1.2em;
            padding-bottom: 0px;
            text-align: center;
            width: 100%;
        }
        img {
            width: auto;
            max-width: 100%;
            display: block;
            margin: 0;
            border: 1px solid darkgrey;
        }
    }
    .repeat-recipients {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.55);
        opacity: 1;
        .preview-content {
            text-align: center;
            height: fit-content;
            max-height: 80svh;
            padding: 0 25px 25px;
            width: 90vw;
            max-width: 750px;
            overflow: unset;
            background: #fff;
            top: 50%;
            left: 50%;
            margin: 0;
            border-radius: 35px;
            z-index: 99999;
            position: relative;
            transition: all 0.3s ease-in-out;
            opacity: 1;
            position: absolute;
            transform: translate(-50%, -50%);
            .content {
                height: fit-content;
                max-height: calc(80vh - 100px);
                overflow-y: auto;
                display: block;
            }
            .header {
                padding: 25px 25px 10px;
                h1 {
                    color: $darkblue;
                }
            }
            .button-container {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                align-items: center;
                width: 100%;
                margin: 20px auto;
                button {
                    font-size: 1em;
                    padding: 8px 30px;
                    border: 0;
                    outline: none;
                    cursor: pointer;
                    text-align: center;
                    font-family: "Bariol";
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-transform: lowercase;
                    color: white;
                    opacity: 1;
                    min-width: 120px;
                    width: fit-content;
                    background-color: $colorScheme;
                    border-radius: 25px;
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                    gap: 20px;
                    width: 85%;
                    button {
                        width: 100%;
                    }
                }
            }
            a {
                font-size: 20px;
                padding: 12px 30px;
                background-color: $darkblue;
                border-radius: 20px;
                min-width: 100px;
                max-width: 225px;
                color: white;
                cursor: pointer;
                text-align: center;
                font-family: "Bariol";
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: lowercase;
                text-decoration: none;
            }
            h3 {
                margin: 20px auto 50px auto;
                color: black;
                &.sent-email {
                    color: #d3d3d3;
                    font-family: "Source Sans Pro", sans-serif;
                    font-weight: 100;
                    font-size: 1.5em;
                }
                &.email-sent {
                    font-family: "Bariol";
                    font-weight: bold;
                    color: $colorScheme;
                    font-size: 2.3em;
                }
            }

            #close {
                height: 20px;
                width: 20px;
                border-radius: 5px;
                cursor: pointer;
            }
            #close:after {
                position: absolute;
                content: "\d7";
                font-size: 35px;
                color: black;
                font-weight: bold;
                top: 0;
                right: 15px;
            }
            #preview {
                width: 400px;
                margin: 0 auto;
            }
        }
    }
    .image-preview {
        position: fixed;
        // display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -110;
        background: rgba(0, 0, 0, 0.55);
        opacity: 0;
        &.show {
            opacity: 1;
            z-index: 9999;
            &.email-preview {
                .content-wrapper {
                    width: 100%;
                    height: 100%;
                    iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                        overflow: hidden;
                    }
                }
            }
        }
        .preview-content {
            text-align: center;
            height: 100%;
            max-height: 90svh;
            padding: 20px 30px;
            width: $contentMaxWidth;
            max-width: 95vw;
            overflow: unset;
            background: #fff;
            top: 50%;
            left: 50%;
            margin: 0;
            border-radius: 35px;
            z-index: 99999;
            position: relative;
            transition: all 0.3s ease-in-out;
            opacity: 1;
            position: absolute;
            transform: translate(-50%, -50%);

            .preview-content-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 0;
                gap: 1em;
                ::-webkit-scrollbar {
                    width: 4px;
                }
                @media screen and (max-width: $tabletBreak) {
                    padding: 0;
                }
            }
            .header,
            .footer {
                flex: 0 0 fit-content;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
                flex-wrap: wrap;
                h1 {
                    color: $darkblue;
                }
            }
            a {
                font-size: 20px;
                padding: 12px 30px;
                background-color: $darkblue;
                border-radius: 20px;
                width: fit-content;
                color: white;
                cursor: pointer;
                text-align: center;
                font-family: "Bariol";
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: lowercase;
                text-decoration: none;
            }
            h3 {
                margin: 10px auto;
                color: $darkblue;
                // &.sent-email {
                // 	color: $darkblue;
                // 	font-family: "Source Sans Pro", sans-serif;
                // 	font-weight: 100;
                // 	font-size: 1.5em;
                // }
                &.email-sent {
                    font-family: "Bariol";
                    font-weight: bold;
                    color: $colorScheme;
                    font-size: 2.3em;
                }
            }
            #close {
                height: 25px;
                width: 25px;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
            }
            #preview {
                width: 150px;
                max-width: 100%;
                margin: 0 auto;
            }
            #sending-preview {
                width: 250px;
                max-width: 100%;
                margin: 0 auto;
            }
            p {
                font-size: 1.15em;
                text-align: left;
            }
            .button-container {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                align-items: center;
                width: 100%;
                padding-top: 20px;
                flex: 0 0 fit-content;
                .btn {
                    font-size: 1.2rem;
                    padding: 8px 30px;
                    border: 0;
                    outline: none;
                    cursor: pointer;
                    text-align: center;
                    font-family: "Bariol";
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-transform: lowercase;
                    color: white;
                    min-width: 120px;
                    width: fit-content;
                    border-radius: 25px;
                    margin: 0;
                    &.disabled {
                        background-color: #d3d3d3;
                    }
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    margin: 0 auto;
                    button {
                        width: 100%;
                    }
                }
            }
            .content-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: 30px;
                flex: 1;
                overflow-y: auto;
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column-reverse;
                }
                .content {
                    position: relative;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex: 1;
                    gap: 20px;
                    .content-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        height: fit-content;
                        overflow-y: auto;
                        gap: 20px;
                        .steps-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            details {
                                display: flex;
                                flex-direction: column;
                                gap: 1em;
                                width: 100%;
                                summary {
                                    color: $darkblue;
                                    max-width: 100%;
                                    cursor: pointer;
                                    text-align: left;
                                    width: fit-content;
                                    display: flex;
                                    h3 {
                                        display: contents;
                                        text-align: left;
                                        margin: 0;
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                            fill: $tmsgreen;
                                        }
                                    }
                                }
                                .details-content {
                                    // padding: 10px 0;
                                }
                            }

                            .email-verify {
                                display: flex;
                                flex-direction: column;
                                gap: 0.5em;
                                margin: 0;
                                align-items: flex-start;
                                .btn-wrapper {
                                    display: flex;
                                    justify-content: flex-start;
                                    gap: 0.5em;
                                    flex-wrap: wrap;
                                    width: 100%;
                                }
                                .btn {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    #loading-box {
                                        position: relative;
                                        width: auto;
                                        height: auto;
                                        min-height: auto;
                                        background-color: transparent;
                                        svg {
                                            width: 25px;
                                            height: auto;
                                        }
                                    }
                                    &.green {
                                        min-width: 50px;
                                        max-width: 100%;
                                        display: flex;
                                        justify-content: center;
                                        svg {
                                            font-size: 1em;
                                        }
                                    }
                                }
                                .sent-email {
                                    flex-wrap: wrap;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;
                                    width: 100%;
                                    p {
                                        flex: 1;
                                    }
                                    .btn {
                                        flex: 0 0 fit-content;
                                    }
                                }
                            }
                        }
                        .btn-wrapper {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
                .recipients-list {
                    width: 100%;
                    display: flex;
                    height: fit-content;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    p {
                        text-align: center;
                        margin: 0 auto;
                        font-size: 1.25em;
                        width: fit-content;
                    }
                    li {
                        padding: 5px 0;
                        max-width: 240px;
                        margin-left: 0px;
                        overflow-wrap: break-word;
                    }
                    table {
                        border: 1px solid #d3d3d3;
                        border-radius: 10px;
                        width: 100%;
                        margin: 0;
                        thead {
                            tr {
                                border-bottom: 1px solid #d3d3d3;
                                &:last-of-type {
                                    border-bottom: 1px solid #d3d3d3;
                                }
                            }
                            td {
                                font-weight: bold;
                                color: $tmsblue;
                                text-align: center;
                            }
                        }
                        tr {
                            width: 100%;
                            border-bottom: 1px solid #d3d3d3;
                            display: flex;
                            flex-direction: row;
                            &:last-of-type {
                                border: none;
                            }
                            td {
                                flex: 1;
                                text-align: left;
                                padding: 10px;
                                text-transform: none;
                            }
                        }
                    }
                }
                .preview-container {
                    flex: 0 0 235px;
                    img {
                        width: 100%;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            @media screen and (max-width: $mobileLargeBreak) {
                overflow-y: auto;
                height: 80svh;
                .preview-container {
                    width: fit-content;
                    max-width: 100%;
                    height: fit-content;
                    margin: 0 auto;
                    img {
                        padding: 10px;
                        width: auto;
                        max-width: 100%;
                        max-height: 350px;
                        height: auto;
                    }
                }
                .repeat-recipients {
                    max-height: 250px;
                }
            }
        }

        &.email-ai-form {
            .preview-content {
                height: fit-content;
            }
            .header {
                display: flex;
                position: relative;
                #close {
                    position: absolute;
                    right: 0;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    z-index: 1;
                }
                h3 {
                    text-align: center;
                    font-size: 1.8em;
                    width: 100%;
                }
            }
            .content-wrapper {
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
    .footer-wrapper {
        width: 100%;
        text-align: center;
        .footer {
            position: relative;
            padding: 0.75rem 0;
            width: 100%;
            display: flex;
            border-top: 1px solid #eff3f4;
            justify-content: space-between;
            button,
            a {
                font-size: 1.15em;
                padding: 8px 30px;
                border: 0;
                outline: none;
                min-width: 100px;
                max-width: fit-content;
                cursor: pointer;
                text-align: center;
                font-family: "Source Sans Pro";
                text-transform: lowercase;
                text-decoration: none;
            }
            .previousBtn {
                color: white;
                background-color: $colorScheme;
                border-radius: 25px;
                opacity: 1;
                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .continueBtn {
                color: white;
                background-color: $colorScheme;
                border-radius: 25px;
                opacity: 0.3;
                cursor: default;
                &.active {
                    opacity: 1;
                    cursor: pointer;
                }
                &.submit {
                    background-color: $darkblue;
                }
            }
            .btn.email-preview {
                position: relative !important;
                top: auto !important;
                right: auto !important;
                left: auto !important;
            }
        }
    }
    .salesforce-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        flex: 1;
        .left-container {
            background-color: $colorScheme;
            background-image: url("/images/backgrounds/generators_background.png");
            background-position: center;
            background-size: cover;
            flex: 0 0 min(20%, 275px);
            padding: 1.5em 0.75em;
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            img {
                display: block;
                margin: 0;
                width: min(90%, 145px);
                flex: 0 0 fit-content;
            }
        }
        .progress-bar-wrapper {
            width: 100%;
            .progress-bar {
                text-align: center;
                width: 100%;
                height: fit-content;
                position: relative;
                h2 {
                    margin: 20px 0;
                    text-transform: lowercase;
                    font-size: 3.7em;
                    font-family: "Source Sans Pro";
                    color: $darkblue;
                    font-weight: 200;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    vertical-align: middle;
                    list-style: none;
                    margin-bottom: 0px;
                    margin: auto;
                    width: max-content;
                    max-width: 100%;
                    text-align: center;
                    gap: 1.5em;
                    li {
                        font-size: 1.25rem;
                        color: white;
                        text-align: left;
                        position: relative;
                        width: fit-content;
                        font-family: "Bariol Light";
                        display: flex;
                        text-transform: lowercase;
                        gap: 1em;
                        align-items: center;
                        cursor: pointer;
                        padding-left: 1.2em;
                        &::after {
                            content: "";
                            position: absolute;
                            top: calc(50% + 7px);
                            left: 0;
                            width: 1px;
                            height: calc(0.75em + 3px);
                            background: white;
                            z-index: 0;
                            opacity: 0.5;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: calc(50% + 7px);
                            left: 0;
                            width: 1px;
                            height: calc(0.75em + 2px);
                            background: white;
                            z-index: 0;
                            opacity: 0.5;
                        }
                        &:first-of-type {
                            &::before {
                                display: none;
                            }
                        }
                        &:last-of-type {
                            &::after {
                                display: none;
                            }
                        }
                        span.step-dot {
                            display: block;
                            margin: 0;
                            position: relative;
                            @include spherize(14px, "none");
                            background-color: white;
                            border: 1px solid white;
                            border: none;
                            opacity: 0.5;

                            position: absolute;
                            left: -7px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        span.step-label {
                            line-height: normal;
                            font-weight: 100;
                            opacity: 0.5;
                        }
                    }
                    li.active {
                        span.step-dot {
                            background-color: #fff;
                            color: #fff;
                            opacity: 1;
                        }
                        span.step-label {
                            opacity: 1;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .right-container {
            flex: 1;
            max-width: $contentMaxWidth;
            padding: 0px;
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            position: relative;
            h1 {
                color: $colorScheme;
                font-family: "Bariol Light";
                width: fit-content;
                font-size: 3.4em;
                font-weight: 100;
                padding-bottom: 15px;
                border-bottom: 1px solid $colorScheme;
                text-transform: lowercase;
                margin: 0;
                line-height: 1em;
            }
            h3 {
                color: $darkblue;
                font-family: "Bariol";
                width: fit-content;
                font-size: 1.3em;
                margin: 0;
                position: relative;
                line-height: normal;
                span {
                    // font-weight: 100;
                    font-family: "Bariol Light";
                }
            }
            h5 {
                color: $darkblue;
                font-family: "Bariol Light";
                width: fit-content;
                font-size: 1em;
                font-style: italic;
            }
            .step-container {
                position: relative;
                flex: 1;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                gap: 1.5em;
                .step-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 1em;
                    .btn.email-preview {
                        justify-self: flex-end;
                    }
                }
            }
            .target-container {
                .target {
                    display: flex;
                    justify-content: space-between;
                    gap: 60px;
                }
                p {
                    color: #d0d0d0;
                    font-size: 21px;
                }
                .tiles-container {
                    padding: 40px 0 20px;
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: flex-start;
                    row-gap: 60px;
                    column-gap: 30px;
                    .tile {
                        flex: 0 0 275px;
                        margin: 0;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        #icon {
                            margin: 0;
                            position: absolute;
                            $tile-icon-width: 30px;
                            top: calc(-1 * $tile-icon-width);
                        }
                        p {
                            color: $colorScheme;
                            font-size: 1.55em;
                            padding: 0;
                        }
                        &.disabled {
                            pointer-events: none;
                        }
                        &.checked {
                            border: 3px solid $colorScheme;
                        }
                    }
                }
            }
            .edit-container {
                .form {
                    display: flex;
                    flex-direction: column;
                    gap: 2.5em;
                    .form-item {
                        .form-item-header {
                            display: flex;
                            justify-content: flex-start;
                            column-gap: 100px;
                            row-gap: 20px;
                            flex-wrap: wrap;
                            align-items: center;
                        }
                        input {
                            width: 325px !important;
                            max-width: 100%;
                            min-width: auto;
                        }
                        textarea {
                            &#emailContent {
                                width: 100% !important;
                                min-height: 325px !important;
                                max-height: 80svh;
                            }
                        }
                        .btn-wrapper {
                            display: flex;
                            gap: 20px;
                            button {
                                background-color: white;
                                border: 1px dotted $darkblue;
                                padding: 8px 15px;
                                cursor: pointer;
                            }
                        }
                        #customEmailFooter-wrapper {
                            #customEmailFooter {
                            }
                        }
                        .detail-options {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            max-width: $contentMaxWidth;
                            justify-content: flex-start;
                            gap: 30px;
                            flex-wrap: wrap;
                            margin: 0;
                            .option {
                                flex: 0 0 200px;
                                color: #656565;
                                padding: 15px 30px;
                                border-radius: 5px;
                                position: relative;
                                border: 1px solid #656565;
                                text-align: center;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                .check {
                                    display: none;
                                }
                                &.active {
                                    border: 2px solid $colorScheme;
                                    color: $colorScheme;
                                    font-weight: bold;
                                }
                                &.disabled {
                                    pointer-events: none;
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                    .attachments-container {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        .form-upload {
                            .upload-container {
                                margin: 0;
                                label {
                                    color: white;
                                    margin: 0;
                                    font-size: 1em;
                                }
                            }
                        }
                        #image-preview {
                            margin: 0;
                            width: 100%;
                            max-width: min(625px, 90%);
                        }

                        .email-attachment {
                            padding: 20px;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            background-color: $defaultNeutralLight;
                            width: min(100%, 750px);
                            border-radius: 10px;
                            text-decoration: none;
                            * {
                                color: $darkblue;
                                text-decoration: none;
                            }
                            .btn.preview {
                                border-radius: 50%;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 6px;
                                min-width: auto;
                                height: auto;
                                svg {
                                    fill: white;
                                    color: white;
                                }
                            }
                            svg {
                                width: 22px;
                                height: 22px;
                                flex: 0 0 22px;
                                &#close {
                                    cursor: pointer;
                                }
                            }
                            p {
                                flex: 1;
                                word-break: break-all;
                                overflow-wrap: break-word;
                            }
                        }
                    }
                    // .schedule-wrapper {
                    //     display: flex;
                    //     flex-direction: column;
                    //     gap: 10px;
                    //     margin: 0;
                    //     width: 100%;

                    //     h3 {
                    //         color: $darkblue;
                    //         text-align: left;
                    //         margin: 0;
                    //     }

                    //     input {
                    //         text-align: left;
                    //         margin: 0;
                    //         font-size: 1em;
                    //         display: block;
                    //         border: 1px solid #d3d3d3;
                    //         padding: 8px 5px;
                    //         width: 100%;
                    //         max-width: 100%;
                    //     }

                    //     input[type="datetime-local"] {
                    //         width: min(100%, 300px);
                    //     }
                    // }
                }

                .option-wrapper {
                    display: flex;
                    flex-direction: row;
                    margin: 0;
                    gap: 2em;
                    flex-wrap: wrap;
                    .container {
                        display: block;
                        width: fit-content;
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        font-size: 1.1em;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        color: #737272;
                        font-family: "Source Sans Pro";
                        text-align: left;
                        &.checked {
                            color: $colorScheme;
                            font-weight: bold !important;
                        }
                    }
                    /* Hide the browser's default checkbox */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    /* Create a custom checkbox */
                    .checkmark {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        height: 22px;
                        width: 22px;
                        background-color: white;
                        border-radius: 50%;
                        border: 1px solid #d3d3d3;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                        // background-color: $danger;
                        border: 1px solid $colorScheme;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container input:checked ~ .checkmark:after {
                        display: block;
                    }

                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 90%;
                        height: 90%;
                        border: 2px solid white;
                        background-color: $colorScheme;
                        border-radius: 50%;
                    }
                }
                .schedule-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    margin: 0;
                    padding: 1em 0;
                    width: 100%;

                    h3 {
                        color: $darkblue;
                        text-align: left;
                        margin: 0;
                    }

                    input {
                        text-align: left;
                        margin: 0;
                        font-size: 1em;
                        display: block;
                        border: 1px solid #d3d3d3;
                        padding: 8px 5px;
                        width: 100%;
                        max-width: 100%;
                    }

                    input[type="datetime-local"] {
                        width: min(100%, 300px);
                    }
                }
            }
            .email-ai-form-container {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;
                align-items: center;
                .email-ai-form-inputs {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    width: 100%;

                    .form-item {
                        // flex: 1 1 min(100%, 300px);
                        flex: 1 0 250px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        label {
                            color: $darkblue;
                            width: fit-content;
                        }
                        input,
                        textarea {
                            width: 100% !important;
                            padding: 8px 15px;
                            box-sizing: border-box;
                            border-radius: 20px;
                            font-size: 1em;
                            border: 1px solid #c4c4c4;
                        }
                        textarea {
                            min-width: 100%;
                            max-width: 100%;
                            min-height: 200px;
                            resize: vertical;
                        }
                        .input-label {
                            font-family: "Bariol";
                            color: $darkblue;
                            font-weight: bold;
                            font-size: 1.1em;
                        }
                        &.textarea {
                            flex: 1 0 100%;
                        }
                    }
                }
            }
            .filters-container {
                flex: 1;
                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .filters-form {
                    color: #666;
                    display: block;
                    height: fit-content;
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .form-item {
                        width: fit-content;
                        display: flex;
                        margin: 0;
                        flex-wrap: wrap;
                        max-width: 100%;
                        label {
                            width: 100%;
                            text-align: left;
                        }

                        h3 {
                            margin: 0 !important;
                            width: fit-content;
                            font-family: "Bariol";
                            font-size: 1.3em;
                            letter-spacing: 0.5px;
                            flex: 0 0 fit-content;
                        }
                        .filter-select {
                            max-width: 100%;
                            min-width: 300px;
                            input {
                                padding: 8px 0 !important;
                            }
                        }
                        .Select-control {
                            min-width: 200px;
                            max-width: 350px;
                            .Select-placeholder {
                                cursor: pointer;
                                z-index: 999999;
                            }
                            .Select-multi-value-wrapper {
                                .Select-value {
                                    margin-bottom: 3px;
                                    margin-top: 3px;
                                }
                                &:nth-last-child(n-1) {
                                    margin-right: 15px;
                                }
                            }
                        }

                        textarea {
                            min-width: 600px;
                            width: 600px;
                            min-height: 250px;
                            max-height: 380px;
                            max-width: 600px;
                            padding: 20px 25px !important;
                            &::placeholder {
                                opacity: 0.5;
                            }
                        }
                    }
                }
                .connector {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    hr {
                        flex: 1;
                    }
                    .text {
                        flex: 0 0 fit-content;
                    }
                }
            }
            .people-container {
                max-height: 85dvh;
                position: relative;
                .people-messages-wrapper {
                    width: 100%;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0.75em;

                    &:not(:has(p)) {
                        display: flex;
                    }

                    p {
                        color: $darkblue;
                        font-size: 1.2em;
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        &.authorize-banner {
                            background-color: white;
                            color: $warning;
                            display: flex;
                            align-items: center;
                            text-align: left;
                        }
                        svg {
                            flex: 0 0 24px;
                            margin: 0;
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .authorize-banner {
                        position: relative;
                        cursor: pointer;
                    }

                    .tooltip {
                        visibility: hidden;
                        width: 100%;
                        max-width: 100%;
                        border: 1px solid $defaultNeutral;

                        text-align: left;
                        padding: 1em;
                        border-radius: 1em;
                        z-index: 1;

                        transition: all 0.3s ease-in-out;

                        visibility: visible;
                        opacity: 1;

                        display: flex;
                        flex-direction: column;
                        gap: 0.75em;

                        position: relative;

                        * {
                            text-align: left;
                            color: $darkblue;
                            line-height: normal;
                        }

                        .close {
                            position: absolute;
                            top: 0.5em;
                            right: 0.5em;
                            width: 24px;
                            height: auto;
                            cursor: pointer;
                        }

                        h4 {
                            font-size: 1.125rem;
                        }
                        p {
                            font-size: 1em;
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            gap: 0.75em;
                            padding-inline-start: 1.35em;
                            li {
                            }
                        }
                    }
                }
                .no-records {
                    text-align: center;
                    font-size: 1.5em;
                    color: $darkblue;
                    width: 100%;
                    line-height: normal;
                }
                .people-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    gap: 20px;

                    .results-count {
                        text-align: right;
                        font-size: 1.4em;
                        color: $darkblue;
                    }

                    #search {
                        position: relative;
                        width: fit-content;
                        text-align: initial;
                        margin: 0;
                        flex: 0 1 550px;
                        .form-item {
                            width: 100%;
                        }
                        input {
                            border-radius: 20px;
                            min-width: 100%;
                            width: 100% !important;
                            padding: 8px 35px 8px 10px !important;
                            color: #555;
                            border: 1px solid #c4c4c4;
                            &::placeholder {
                                color: #c4c4c4;
                            }
                        }
                        svg {
                            position: absolute;
                            right: 10px;
                            width: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                            margin: 0;
                        }
                    }
                }

                .container {
                    display: block;
                    position: relative;
                    line-height: 1.4em;
                    cursor: pointer;
                    font-size: 16px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                }

                /* Hide the browser's default checkbox */
                .container input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 24px !important;
                    width: 24px !important;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 24px;
                    width: 24px;
                    background-color: white;
                    border: 0.5px solid #c4c4c4;
                    border-radius: 50%;
                    z-index: 0;
                }

                /* On mouse-over, add a grey background color */
                .container:hover input ~ .checkmark {
                    background-color: #ccc;
                    // margin-top: 2px;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: $colorScheme;
                    border: none;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 9px;
                    top: 4px;
                    width: 6px;
                    height: 14px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                .results-table {
                    margin: 0;
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;

                    .table-header {
                        display: grid;
                        width: 100%;

                        @mixin dynamic-grid-columns($count) {
                            &.grid-#{$count} {
                                grid-template-columns: repeat($count, 1fr);
                            }
                        }

                        @include dynamic-grid-columns(10);
                        @include dynamic-grid-columns(11);
                        @include dynamic-grid-columns(12);
                        @include dynamic-grid-columns(13);
                        @include dynamic-grid-columns(14);
                        @include dynamic-grid-columns(15);
                        @include dynamic-grid-columns(16);
                        @include dynamic-grid-columns(17);
                        @include dynamic-grid-columns(18);
                        @include dynamic-grid-columns(19);
                        @include dynamic-grid-columns(20);
                        @include dynamic-grid-columns(21);
                        @include dynamic-grid-columns(22);

                        .header {
                            background-color: $darkblue;
                            color: white;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 10px 5px;
                            margin: 0;
                            overflow-wrap: break-word;
                            word-wrap: break-word;
                            &:first-of-type {
                                padding: 0;
                            }

                            @mixin dynamic-col-span($span) {
                                &.span-col-#{$span} {
                                    grid-column: span $span / auto;
                                }
                            }

                            @include dynamic-col-span(1);
                            @include dynamic-col-span(2);
                            @include dynamic-col-span(3);
                            @include dynamic-col-span(4);
                            @include dynamic-col-span(5);
                            @include dynamic-col-span(6);
                            @include dynamic-col-span(7);
                            @include dynamic-col-span(8);
                        }
                    }
                    .table-body {
                        height: 100%;
                        width: 100%;
                        overflow-y: scroll;
                        margin: 0;
                        display: block;
                        .row {
                            padding: 0;
                            display: grid;
                            width: 100%;

                            @mixin dynamic-grid-columns($count) {
                                &.grid-#{$count} {
                                    grid-template-columns: repeat($count, 1fr);
                                }
                            }

                            @include dynamic-grid-columns(10);
                            @include dynamic-grid-columns(11);
                            @include dynamic-grid-columns(12);
                            @include dynamic-grid-columns(13);
                            @include dynamic-grid-columns(14);
                            @include dynamic-grid-columns(15);
                            @include dynamic-grid-columns(16);
                            @include dynamic-grid-columns(17);
                            @include dynamic-grid-columns(18);
                            @include dynamic-grid-columns(19);
                            @include dynamic-grid-columns(20);
                            @include dynamic-grid-columns(21);
                            @include dynamic-grid-columns(22);

                            &:nth-of-type(2n) {
                                background-color: #f1f1f1;
                            }
                            .row-data {
                                color: $darkblue;
                                text-align: left;
                                padding: 10px 5px;
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                                align-self: center;

                                @mixin dynamic-col-span($span) {
                                    &.span-col-#{$span} {
                                        grid-column: span $span / auto;
                                    }
                                }

                                @include dynamic-col-span(1);
                                @include dynamic-col-span(2);
                                @include dynamic-col-span(3);
                                @include dynamic-col-span(4);
                                @include dynamic-col-span(5);
                                @include dynamic-col-span(6);
                                @include dynamic-col-span(7);
                                @include dynamic-col-span(8);
                            }
                        }

                        .form-item {
                            margin: 10px 0;
                        }
                    }
                }
            }
            .review-container {
                .container {
                    display: flex;
                    max-width: 100%;
                    width: 100%;
                    gap: 30px;
                    .review-content {
                        flex: 1;
                        padding: 0;

                        .review-data-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 1em;
                            .review-data {
                                padding: 0;
                                width: fit-content;

                                display: flex;
                                flex-direction: column;
                                gap: 0.5em;

                                &.disable {
                                    h3 {
                                        padding-bottom: 5px;
                                    }
                                    svg {
                                        display: none;
                                        pointer-events: none;
                                    }
                                }
                                h4 {
                                    padding: 3px 0;
                                    font-size: 1.1em;
                                }
                                h3 {
                                    color: $colorScheme;
                                    border-bottom: 1px solid;
                                    width: fit-content;
                                    font-family: "Source Sans Pro";
                                }
                                span {
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;
                                    svg {
                                        cursor: pointer;
                                        fill: #d3d3d3;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .btn.email-preview {
                width: fit-content;
                max-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                justify-self: flex-end;
                // position: absolute;
                // right: 0;
                // top: 0;
            }
        }
    }
}

@media screen and (max-width: $tabletBreak) {
    .email-salesforce-wrapper {
        $colorScheme: $salesforceblue;

        .image-preview {
            opacity: 0;
            display: block;
            &.show {
                opacity: 1;
                z-index: 99999;
            }
            .preview-content {
                .content-wrapper {
                    flex-direction: column;
                    height: 100%;
                    overflow-y: auto;
                    .preview-container {
                        flex: 1;
                        img {
                            max-width: 220px;
                        }
                    }
                }
                a {
                    max-width: fit-content;
                    border-radius: 25px;
                }
                &.show {
                    opacity: 1;
                }
                h3 {
                    font-size: 1.45em;
                }
                #preview {
                    width: 100%;
                    padding: 20px;
                    max-width: 220px;
                }
                #close {
                    &::after {
                        right: 18px;
                        font-size: 40px;
                        // top: 5px;
                    }
                }
            }
        }
        .salesforce-container {
            flex-direction: column;
            flex: 0 0 fit-content;

            .left-container {
                text-align: center;
                margin: 0 auto;
                width: 100%;
                flex: 0 0 fit-content;
                min-height: initial;
                position: relative;
                gap: 1.5rem;
                img {
                    width: min(90%, 180px);
                }
                .progress-bar {
                    text-align: center;
                    margin: 0;
                    width: 100%;
                    height: fit-content;
                    position: relative;
                    h2 {
                        margin: 20px 0;
                        text-transform: lowercase;
                        font-size: 3.7em;
                        font-family: "Source Sans Pro";
                        color: $darkblue;
                        font-weight: 200;
                    }
                    ul {
                        vertical-align: middle;
                        flex-direction: row;
                        list-style: none;
                        width: 100%;
                        gap: 0.5em;
                        flex: 0 0 fit-content;

                        li {
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            gap: 0.5em;
                            &::after {
                                display: block;
                                height: 1px;
                                width: calc(50% - 9px + 0.25em);
                                top: 0;
                                left: calc(50% + 9px);
                            }
                            &::before {
                                display: block;
                                height: 1px;
                                width: calc(50% - 9px + 0.25em);
                                top: 0;
                                right: calc(50% + 9px);
                                left: auto;
                                bottom: auto;
                            }

                            span.step-dot {
                                display: block;
                                margin: 0;
                                position: relative;
                                @include spherize(18px, "none");
                                background-color: white;
                                border: 1px solid #fff;
                                color: #fff;
                                position: absolute;
                                top: -9px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            span.step-label {
                                display: none;
                                padding-top: 0.65em;
                            }
                        }
                        li.active {
                            color: #fff;
                            margin-top: 0;
                            span.step-dot {
                            }
                            span.step-label {
                                display: block;
                            }
                        }
                    }
                }
            }
            .right-container {
                width: 100%;
                position: relative;
                padding: 0;
                h1 {
                    width: 100%;
                    text-align: center;
                    padding: 0px 0 15px 0;
                    font-size: 2.8em;
                }
                .target-container {
                    overflow-y: scroll;
                    padding: 0 30px;
                    .target {
                        flex-direction: column;
                        .tiles-container {
                            width: 100%;
                        }
                        .preview-container {
                            width: 100%;
                            img {
                                margin: 0px auto 30px;
                            }
                        }
                    }
                    p {
                        text-align: center;
                        font-size: 23px;
                    }
                    .tiles-container {
                        justify-content: space-between;
                        margin: 0 auto;
                        .tile {
                            margin: 45px 0 35px !important;
                        }
                    }
                }
                .filters-container {
                    padding: 0 15px;
                    // height: 50svh;
                    // overflow-y: scroll;
                    .filters-form {
                        height: 100%;
                        .form-item {
                            h3 {
                                margin: 0 20px 0 0 !important;
                            }
                        }
                    }
                }
                .people-container {
                    padding: 0 15px;
                    #search {
                        margin-left: 15px;
                    }
                    .project-table {
                        padding-bottom: 20px;
                        thead {
                            th {
                                max-width: 26vw;
                                min-width: 26vw;
                                width: 26vw;
                                &:first-of-type {
                                    width: fit-content;
                                    min-width: 50px;
                                    max-width: 50px;
                                }
                                &:nth-of-type(2) {
                                    max-width: 32vw;
                                    min-width: 32vw;
                                    width: 32vw;
                                }
                                &:nth-of-type(n + 5) {
                                    display: none;
                                }
                            }
                        }
                        tbody {
                            .checkmark {
                                &.header {
                                    left: 2px;
                                }
                            }

                            &:nth-child(odd) {
                                tr {
                                    background-color: white;
                                }
                            }
                            .normal-row {
                                td {
                                    line-height: 1.4em;
                                    width: fit-content;
                                    max-width: 26vw;
                                    min-width: 26vw;
                                    width: 26vw;
                                    overflow-wrap: break-word;
                                    padding: 12px 0 12px 10px;
                                    &:first-of-type {
                                        width: fit-content;
                                        min-width: 50px;
                                        max-width: 50px;
                                    }
                                    &:nth-of-type(2) {
                                        max-width: 32vw;
                                        min-width: 32vw;
                                        width: 32vw;
                                    }
                                    &:nth-of-type(n + 5) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .review-container {
                    padding: 0 25px;
                    margin-bottom: 10px;
                    .container {
                        flex-direction: column;
                        .review-content {
                            width: 100%;
                            .review-data-container {
                                margin-top: 20px;
                            }
                        }
                        .preview-container {
                            margin: 0 auto;
                            img {
                                width: 200px;
                            }
                        }
                    }
                }
                .footer-wrapper {
                    padding: 0;
                    margin: 0;
                    background-color: $colorScheme;
                    .footer {
                        width: 100%;
                        text-align: center;
                        margin: 0 auto;
                        padding: 1em;
                        justify-content: space-between;
                        align-items: center;
                        button {
                            width: fit-content;
                            padding: 0.5em 1em;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        a {
                            text-align: center;
                            width: fit-content;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .continueBtn {
                            font-size: 1.5em;
                            width: fit-content;
                            padding: 10px 15px;
                            &.submit {
                                background-color: $colorScheme;
                            }
                        }
                        .previousBtn {
                            font-size: 1.5em;
                            &.hide {
                                opacity: 0.4;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobileLargeBreak) {
    .email-salesforce-wrapper {
        .salesforce-container {
            .left-container {
                .progress-bar {
                    ul {
                        li {
                            width: 110px;
                            &::after {
                                // top: 7px;
                                left: 64px;
                                width: 93px;
                                height: 2px;
                                background: #fff;
                                z-index: 1111;
                            }
                        }
                    }
                }
            }
            .right-container {
                .filters-container {
                    height: 100%;
                    overflow-y: scroll;
                }
                .people-container {
                    .project-table {
                        tbody {
                            th {
                                &:first-of-type {
                                    min-width: 40px !important;
                                    max-width: 40px !important;
                                    width: fit-content;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// event flyers generator
.event-flyers-generator-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .preview-container {
        flex: 0 0 min(100%, 300px);
        h3 {
            color: $darkblue;
            font-family: "Bariol", sans-serif;
            font-size: 2.1em;
            padding-bottom: 0px;
            text-align: center;
        }
        img {
            max-height: 500px;
            max-width: 100%;
            display: block;
            margin: auto;
            border: 1px solid darkgrey;
        }
        .preview {
            z-index: -9999;
        }
        #overlay-text {
            border: 1px solid darkgrey;
            height: 500px;
            max-width: 400px;
            position: relative;
            width: 390px;
            h2 {
                margin: 0 auto;
                top: 50%;
                position: relative;
                width: 80%;
                margin-top: -50px;
                line-height: 1.5em;
                text-align: center;
            }
        }
    }
    .footer-wrapper {
        width: 100%;
        text-align: center;
        .footer {
            position: relative;
            padding: 1em 0;
            width: 100%;
            max-width: $contentMaxWidth;
            display: flex;
            border-top: 1px solid #eff3f4;
            justify-content: space-between;
            button,
            a {
                font-size: 1.2em;
                padding: 10px 24px;
                border: 0;
                outline: none;
                min-width: 120px;
                max-width: fit-content;
                cursor: pointer;
                text-align: center;
                text-transform: lowercase;
                text-decoration: none;
                flex: 0 0 fit-content;
                width: fit-content;
            }
            .previousBtn {
                left: 0;
                color: white;
                background-color: $darkblue;
                border-radius: 25px;
                opacity: 1;
                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .continueBtn {
                right: 0;
                color: white;
                background-color: $darkblue;
                border-radius: 25px;
                opacity: 0.3;
                max-width: initial;
                cursor: default;
                pointer-events: none;
                &.active {
                    opacity: 1;
                    cursor: pointer;
                    pointer-events: all;
                }
                &.submit {
                    background-color: $darkblue;
                }
            }
        }
    }
    .generator-container {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 40px;
        justify-content: flex-start;
        flex: 1;
        .left-container {
            background-color: $darkblue;
            background-image: url("/images/backgrounds/generators_background.png");
            background-position: center;
            background-size: cover;
            flex: 0 0 min(20%, 325px);
            padding: 30px 0;
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            img {
                display: block;
                margin: 0;
                width: min(90%, 130px);
                flex: 0 0 fit-content;
            }
        }
        .progress-bar {
            text-align: center;
            margin: 0;
            width: 100%;
            height: fit-content;
            position: relative;
            display: flex;
            h2 {
                margin: 20px 0;
                text-transform: lowercase;
                font-size: 3.7em;
                font-family: "Source Sans Pro";
                color: $darkblue;
                font-weight: 200;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 48px;
                vertical-align: middle;
                list-style: none;
                margin-bottom: 0px;
                margin: auto;
                width: max-content;
                max-width: 100%;
                text-align: center;
                li {
                    display: flex;
                    margin: 0;
                    font-size: 22px;
                    color: #9bb4c0;
                    text-align: center;
                    position: relative;
                    width: fit-content;
                    color: white;
                    font-family: "Bariol";
                    display: flex;
                    opacity: 0.6;
                    text-transform: lowercase;
                    align-items: center;
                    gap: 10px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 22px;
                        left: 7px;
                        width: 1px;
                        height: 61px;
                        background: white;
                        z-index: 1;
                        opacity: 0.6;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &:last-of-type::after {
                        display: none;
                    }

                    &.active,
                    &.completed {
                        opacity: 1;
                        cursor: pointer;
                        span {
                            transform: scale(1.1);
                            background-color: #fff;
                            color: #fff;
                        }
                    }
                }

                li > span {
                    display: block;
                    margin: 0;
                    position: relative;
                    @include spherize(14px, "none");
                    background-color: white;
                }
            }
        }
        .right-container {
            flex: 1;
            max-width: $contentMaxWidth;
            padding: 20px 0 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-height: 100%;

            .request-form {
                flex: 1;
                overflow-y: auto;
                overflow-x: initial;
                width: 100%;
            }

            h1.step-header {
                color: $tmsblue;
                font-family: "Bariol Light";
                width: fit-content;
                font-size: 3.4em;
                font-weight: 100;
                padding-bottom: 15px;
                border-bottom: 1px solid $tmsblue;
                text-transform: lowercase;
            }

            .form-item {
                display: flex;
                flex-direction: column;
                gap: 5px;
                label {
                    text-align: left;
                    width: fit-content;
                }
                input,
                textarea,
                select {
                    width: 100% !important;
                    resize: vertical;
                    // &:hover {
                    //     border: 2px solid $darkblue;
                    // }
                }
                textarea {
                    min-height: 50px;
                    max-height: 150px;
                }

                .MuiFormControl-root {
                    cursor: pointer;
                    * {
                        cursor: pointer !important;
                        font-family: "Source Sans Pro" !important;
                    }
                    .MuiInputBase-formControl {
                        margin: 0;
                    }
                    input {
                        all: initial;
                        padding: 0.5em 0 0.75em;
                    }
                }
            }

            .step-wrapper {
                display: flex;
                flex-direction: column;
                gap: 1em;
                &.event-details {
                    .content-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 3em;
                        align-items: flex-start;
                        width: 100%;
                    }
                    .form-wrapper {
                        flex: 1 0 min(100%, 550px);
                        display: grid;
                        grid-template-columns: repeat(1, minmax(325px, 1fr));
                        gap: 1em;
                    }
                }
                &.contact-details {
                    .user-photos-wrapper {
                        display: flex;
                        width: 100%;
                        margin: 0;
                        flex-wrap: wrap;
                        gap: 30px;
                        .col {
                            flex: 1 0 300px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            gap: 15px;
                            img {
                                margin: 0;
                                &#profile-photo-large {
                                    width: 175px;
                                    aspect-ratio: 1;
                                    margin: 0;
                                    height: auto;
                                }
                            }
                            h4 {
                                font-size: 1.4em;
                                color: $darkblue;
                            }
                            .sub-col {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                justify-content: center;
                                text-align: center;
                                align-items: center;
                                h4 {
                                    margin: 0;
                                    font-size: 1.2em;
                                    color: $darkblue;
                                    font-family: "Bariol";
                                    width: fit-content;
                                    font-size: 1.2em;
                                    text-align: center;
                                }
                                .detail-options {
                                    display: flex;
                                    flex-direction: row;
                                    width: 100%;
                                    max-width: $contentMaxWidth;
                                    justify-content: center;
                                    gap: 30px;
                                    flex-wrap: wrap;
                                    margin: 0;
                                    .option {
                                        flex: 0 0 200px;
                                        color: #656565;
                                        padding: 15px 30px;
                                        border-radius: 5px;
                                        font-family: "Bariol";
                                        position: relative;
                                        border: 1px solid #656565;
                                        text-align: center;
                                        box-sizing: border-box;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                                        .check {
                                            display: none;
                                        }
                                        &.active {
                                            border: 2px solid $danger;
                                            color: $danger;
                                            font-weight: bold;
                                        }
                                        &.disabled {
                                            pointer-events: none;
                                            opacity: 0.4;
                                        }
                                    }
                                }
                            }
                            @media screen and (max-width: $tabletBreak) {
                                flex: 0 0 fit-content;
                            }
                        }
                        #company-logo-lg {
                            width: auto;
                            height: auto;
                            max-width: 205px;
                            max-height: 125px;
                        }
                        @media screen and (max-width: $tabletBreak) {
                            flex-direction: column;
                        }
                    }
                    #contact-form {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
                        gap: 25px;
                        .form-item {
                            margin: 0;
                            label {
                                width: fit-content;
                                color: $darkblue;
                            }
                            input,
                            select {
                                width: 100% !important;
                                min-width: inherit;
                            }
                            select {
                            }
                            textarea {
                                width: 100%;
                                height: 260px;
                                max-width: 100%;
                                resize: none;
                                max-height: 260px;
                            }
                        }

                        @media screen and (max-width: $mobileLargeBreak) {
                            width: 100%;
                            grid-template-columns: repeat(1, 1fr);
                            .form-item {
                                flex: 1;
                                margin: 0 auto;
                                width: 100%;
                                &:last-of-type {
                                    margin-bottom: 25px;
                                }
                            }
                        }
                    }
                    .profile-photo {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-bottom: 20px;
                        #profile-photo-large {
                            width: 100%;
                            max-width: 140px;
                            height: auto;
                        }
                        p {
                            font-size: 1.25em;
                            font-weight: 600;
                            margin-top: 10px;
                            font-family: "Bariol";
                            color: $tmsblue;
                            text-align: center;
                            max-width: 350px;
                            margin: 10px auto 0;
                        }
                    }
                }
                &.realtor-details {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 2em;
                    .realtor-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 2em;
                    }
                    .realtor-select-wrapper {
                        display: flex;
                        width: 100%;
                        align-items: flex-end;
                        gap: 20px;
                        .form-item {
                            margin: 0;
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            label {
                                width: fit-content;
                                text-transform: none;
                                color: #000;
                                position: relative;
                                padding: 0;
                            }
                        }
                        .form-item.select {
                            width: 100%;
                            max-width: 450px;
                        }
                    }

                    .card-wrapper {
                        display: flex;
                        gap: 2em;
                        flex-wrap: wrap;
                        padding: 0.5em;
                        .card {
                            flex: 0 0 250px;
                            min-height: 250px;
                            height: fit-content;

                            box-shadow: 0px 1px 10px #d3d3d3;
                            border-radius: 20px;
                            padding: 20px;
                            display: flex;
                            flex-direction: column;
                            gap: 0.5em;
                            align-items: center;
                            h5 {
                                text-align: center;
                                color: $darkblue;
                                font-size: 1.1em;
                            }
                            .details {
                                flex: 1;
                                p {
                                    color: $darkblue;
                                }
                            }
                        }
                    }
                }
            }

            .review-container {
                width: 100%;
                .detail-options {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    max-width: $contentMaxWidth;
                    justify-content: flex-start;
                    gap: 30px;
                    flex-wrap: wrap;
                    margin: 0;
                    .option {
                        flex: 0 0 200px;
                        color: #656565;
                        padding: 15px 30px;
                        border-radius: 5px;
                        position: relative;
                        border: 1px solid #656565;
                        text-align: center;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .check {
                            display: none;
                        }
                        &.active {
                            border: 2px solid $tmsblue;
                            color: $tmsblue;
                            font-weight: bold;
                        }
                        &.disabled {
                            pointer-events: none;
                            opacity: 0.4;
                        }
                    }
                }
                #loading-box {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    background: white;
                    min-height: auto;
                    display: block;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                    margin-bottom: 20px;
                    z-index: 0;
                    h1 {
                        position: relative;
                        color: $darkblue;
                        margin: 0;
                        text-align: left;
                        padding: 0;
                        border: none;
                        width: fit-content;
                    }
                    svg {
                        width: fit-content;
                        height: fit-content;
                        max-height: 80px;
                        margin: 0;
                    }
                }
                .load-more {
                    display: block;
                    width: fit-content;
                    min-width: 150px;
                    margin: 0 auto;
                }
                .container {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;
                    width: 100%;
                    gap: 30px;
                    flex-wrap: wrap;
                    .review-content {
                        width: 100%;
                        flex: 1;
                        padding: 0;
                        h2 {
                            color: $darkblue;
                            font-family: "Bariol", sans-serif;
                            font-size: 2.8em;
                            padding-bottom: 15px;
                            border-bottom: 2px solid $defaultNeutralLight;
                            width: fit-content;
                        }
                        .review-links-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 1em;
                            .review-data {
                                padding: 0;
                                width: fit-content;
                                text-transform: capitalize;
                                display: flex;
                                align-items: center;
                                gap: 0.75em;
                                h3 {
                                    color: $tmsblue;
                                    font-family: "Bariol";
                                    font-size: 1.3em;
                                    line-height: normal;
                                    text-transform: lowercase;
                                }
                                svg {
                                    cursor: pointer;
                                    width: 25px;
                                    height: auto;
                                    color: $defaultNeutral;
                                }
                                &.disable {
                                    * {
                                        pointer-events: none;
                                    }
                                    svg {
                                        display: none;
                                    }
                                }
                            }
                        }
                        .realtor-container {
                            margin-top: 30px;
                            padding-top: 30px;
                            border-top: 1px solid $tmsblue;
                            width: 100%;

                            .form-item {
                                margin: 0;
                                margin-bottom: 30px;
                                position: relative;
                                label {
                                    width: fit-content;
                                    text-transform: none;
                                    color: black;
                                    font-weight: 100;
                                }
                                input,
                                select {
                                    width: 100% !important;
                                    max-width: 300px !important;
                                    margin-top: 2px;
                                    padding: 12px 15px !important;
                                    background-color: white;
                                }
                                input::-webkit-inner-spin-button,
                                input::-webkit-clear-button {
                                    -webkit-appearance: none;
                                    display: none;
                                }
                                input[type="date"] {
                                    font-size: 1.05em;
                                    height: 45px;
                                    padding: 0px 15px !important;
                                    position: relative;
                                }
                                .calendar {
                                    width: 34px;
                                    position: absolute;
                                    right: 80px;
                                    top: 39px;
                                    pointer-events: none;
                                }
                                input[type="date"]::-webkit-calendar-picker-indicator {
                                    color: rgba(0, 0, 0, 0);
                                    opacity: 1;
                                    width: 25px;
                                    height: 27px;
                                    right: 18px;
                                    position: absolute;
                                }
                                select {
                                    text-align: left;
                                    padding: 8px 15px !important;
                                    background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
                                        no-repeat right;
                                    -webkit-appearance: none;
                                    background-position-x: 95%;
                                }
                                textarea {
                                    width: 100%;
                                    height: 260px;
                                    max-width: 100%;
                                    resize: none;
                                    max-height: 260px;
                                }
                            }
                        }
                        .dropdown-container {
                            cursor: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            h3 {
                                color: $darkblue;
                            }
                            .dd-text {
                                margin: 0;
                                display: flex;
                                align-items: center;
                                gap: 15px;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                            }
                            a {
                                flex: 0 0 175px;
                                text-decoration: none;
                            }
                        }
                        .authorize-banner {
                            * {
                                flex: 0 0 fit-content;
                            }
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                            a {
                                color: white;
                                text-decoration: underline;
                                cursor: pointer;
                                width: fit-content;
                                flex: 0 0 fit-content;
                            }
                            &.success {
                                width: 100%;
                                background-color: $tmsgreen;
                                font-size: 1.1em;
                            }
                            &.notice {
                                width: 100%;
                                font-size: 1.1em;
                                background-color: $warning;
                            }
                        }
                    }
                }
            }
        }
    }
    .image-preview {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.55);
        opacity: 0;
        &.show {
            opacity: 1;
            z-index: 121;
            display: block;
        }
        &.image {
            opacity: 1;
            z-index: 9999;
            display: block;
            .preview-content {
                opacity: 1;
                background: transparent;
                width: 100%;
                max-width: 950px;
                img {
                    width: auto;
                    margin: 0 auto;
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .preview-content {
            text-align: center;
            height: 90svh;
            padding: 25px;
            width: min(90vw, $contentMaxWidth);
            overflow: unset;
            background: #fff;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 35px;
            z-index: 999999;
            position: relative;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            margin: 0;
            #header {
                display: flex;
                position: relative;
                width: 100%;
                h1 {
                    font-size: 30px;
                }
                #close {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 0px;
                    border-radius: 5px;
                    cursor: pointer;
                }
                #close:after {
                    position: absolute;
                    content: "\d7";
                    font-size: 45px;
                    color: #d3d3d3;
                    font-weight: bold;
                    top: -12px;
                    right: 0px;
                }
            }
            #media-wrapper {
                flex: 1;
                margin: 10px auto;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-evenly;
                .image-container {
                    flex: 0 0 min(100%, 300px);
                    aspect-ratio: 16/9;
                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        max-width: 100%;
                        max-height: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                        &.active {
                            border: 4px solid $tmsgreen;
                        }
                    }
                }
            }
            &.show {
                opacity: 1;
                padding-bottom: 20px;
            }
            #media-footer {
                position: relative;
                border-top: 1px solid #d3d3d3;
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                height: fit-content;
                width: 100%;
                gap: 10px;
                flex-wrap: wrap;
                align-items: center;
                p {
                    position: relative;
                    color: black;
                    top: initial;
                    right: initial;
                    font-size: 20px;
                    margin: auto 0;
                }
                #media-buttons {
                    width: fit-content;
                    display: flex;
                    .upload-container,
                    .preview-crop-button {
                        margin: 0;
                        width: fit-content;
                        font-size: 22px;
                        height: 50px;
                        padding: 11px 30px;
                        border: 0;
                        outline: none;
                        margin-top: 0px;
                        cursor: pointer;
                        text-align: center;
                        font-family: "Bariol";
                        // font-weight: 100;
                        letter-spacing: 1px;
                        text-transform: lowercase;
                        color: white;
                        opacity: 1;
                        background-color: $tmsblue;
                        border-radius: 5px;
                        pointer-events: none;
                        opacity: 0.4;
                        label {
                            background: none;
                            padding: 0;
                            margin: 0;
                            font-family: "Bariol";
                            font-size: 22px;
                            width: fit-content;
                        }
                        &.show {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    .upload-container {
                        opacity: 1;
                        pointer-events: all;
                        margin-right: 10px;
                    }
                }
            }
        }
        &.social-details {
            .preview-content {
            }
            #header {
                flex: 0 0 fit-content;
                h1 {
                    text-align: center;
                    margin: 0 auto;
                    color: $darkblue;
                }
            }
            .wrapper {
                flex: 1;
                overflow-y: auto;
            }
            .footer-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 20px;
                padding: 0;
                margin: 0;
                #media-buttons {
                    width: fit-content;
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;
                    justify-content: center;
                    .btn {
                        width: fit-content;
                        min-width: 155px;
                        max-width: 100%;
                    }
                }
            }
            .social-details-content {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                gap: 25px;
                padding: 0 !important;
                .header {
                    display: flex;
                    gap: 10px;
                    margin: 0;
                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    img {
                        width: 40px;
                        border-radius: 50%;
                        margin: auto 0;
                        display: block;
                        padding: 0;
                    }
                    h2 {
                        text-align: left;
                        color: $tmsblue;
                        font-size: 1.2em;
                        text-align: left;
                        font-family: "Source Sans Pro";
                    }
                    h5 {
                        text-align: left;
                        color: #9197a3;
                        font-size: 0.8em;
                        font-family: "Source Sans Light";
                        display: flex;
                        justify-content: space-between;
                        gap: 7px;
                        width: fit-content;
                        svg {
                            margin: 3px 0;
                            height: fit-content;
                        }
                    }
                }
                img {
                    max-width: 500px;
                    display: block;
                    margin: 0 auto;
                }
                ::-webkit-scrollbar {
                    width: 5px;
                }
                p {
                    position: relative;
                    top: auto;
                    right: auto;
                    color: black;
                }
                .option-wrapper {
                    display: flex;
                    flex-direction: row;
                    margin: 0;
                    gap: 20px;
                    .container {
                        display: block;
                        width: fit-content;
                        position: relative;
                        line-height: 1.4em;
                        padding-left: 30px;
                        margin-bottom: 8px;
                        margin-top: 3px;
                        cursor: pointer;
                        font-size: 1.1em;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        color: #737272;
                        font-family: "Source Sans Pro";
                        &.checked {
                            color: $danger;
                            font-weight: bold !important;
                        }
                    }
                    /* Hide the browser's default checkbox */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    /* Create a custom checkbox */
                    .checkmark {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        height: 22px;
                        width: 22px;
                        background-color: white;
                        border-radius: 50%;
                        border: 1px solid #d3d3d3;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                        // background-color: $danger;
                        border: 1px solid $danger;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container input:checked ~ .checkmark:after {
                        display: block;
                    }

                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                        left: 1px;
                        top: 1px;
                        width: 18px;
                        height: 18px;
                        border: 2px solid white;
                        background-color: $danger;
                        border-radius: 50%;
                    }
                }
                .schedule-wrapper {
                    margin-top: 15px;
                    h3 {
                        text-align: left;
                    }
                    input {
                        text-align: left;
                        margin: 20px 0 10px;
                        font-size: 1em;
                        display: block;
                        border: 1px solid black;
                        padding: 5px;
                    }
                    .input-error {
                        color: $danger;
                        margin-bottom: 20px;
                        text-align: left;
                    }
                }
                label {
                    display: none;
                }
                textarea {
                    width: 100%;
                    height: 100px;
                    max-width: 100%;
                    min-width: 100%;
                    min-height: 100px;
                    max-height: 200px;
                    // resize: none;
                    border-radius: 0;
                    margin: 0 !important;
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                    .col {
                        img {
                            padding: 20px;
                            margin: 0 auto;
                        }
                    }
                }
                .disclaimer {
                    text-align: left;
                }
            }
            #media-footer {
                border: none;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: $tabletBreak) {
    .event-flyers-generator-wrapper {
        .generator-container {
            flex-direction: column;
            gap: 20px;
            .left-container {
                text-align: center;
                margin: 0 auto;
                flex: 0 0 fit-content;
                width: 100%;
                min-height: initial;
                position: relative;
                img {
                    width: min(90%, 120px);
                }
                .progress-bar {
                    margin: 10px auto;
                    h2 {
                        margin: 20px 0;
                        text-transform: lowercase;
                        font-size: 3.7em;
                        font-family: "Source Sans Pro";
                        color: $darkblue;
                        font-weight: 200;
                    }
                    ul {
                        vertical-align: middle;
                        flex-direction: row;
                        list-style: none;
                        margin-bottom: 0px;
                        gap: 0;
                        li {
                            display: inline-block;
                            margin: 0 auto;
                            font-size: 22px;
                            color: #9bb4c0;
                            text-align: center;
                            position: relative;
                            width: 122px;
                            color: #fff;
                            &::after {
                                top: 7px;
                                left: calc(50% + 11px);
                                width: calc(50% - 11px);
                                height: 2px;
                                background: white;
                                opacity: 0.6;
                                z-index: 1111;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                top: 7px;
                                right: calc(50% + 11px);
                                width: calc(50% - 11px);
                                opacity: 0.6;
                                height: 2px;
                                background: white;
                                z-index: 1111;
                            }
                            &:first-of-type::before {
                                display: none;
                            }
                            &:last-of-type::after {
                                display: none;
                            }
                        }
                        li.active {
                            color: #fff;
                            margin-top: 0;
                            span {
                                background-color: transparent;
                                color: #fff;
                                border: 2px solid;
                                @include spherize(22px, "none");
                                margin: -3px auto;
                            }
                        }
                        li > span {
                            display: block;
                            margin: 0 auto;
                            position: relative;
                            margin-bottom: 0px;
                            @include spherize(17px, "none");
                            background-color: white;
                            border: 1px solid #fff;
                            color: #fff;
                        }
                    }
                }
            }
            .right-container {
                width: 100%;
                position: relative;
                padding: 10px 0px 0;
                h1 {
                    text-align: center;
                    margin: 0 auto;
                }
                .home-address-container {
                    padding: 0 20px;
                    #home-address-form {
                        width: 100%;
                    }
                }

                .property-details-container {
                    width: 100%;
                    padding: 0 10px;
                    #property-details-form {
                        display: block;
                        width: 90%;
                        margin: auto;
                        padding: 0 10px;
                        #form-item-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            flex-grow: 1;
                            .form-item {
                                flex: 0 0 200px;
                            }
                        }
                    }
                }
                .photos-container {
                    width: 100%;
                    #photos-section-wrapper {
                        width: 100%;
                        flex-direction: column;
                        #upload-container {
                            width: fit-content;
                            margin: 20px auto;
                        }
                        .preview-container {
                            width: 100%;
                            padding: 0px 20px;
                            margin-bottom: 40px;
                            img {
                                width: 80%;
                                max-width: 400px;
                                max-height: initial;
                            }
                        }
                    }
                }
                .review-container {
                    width: 100%;
                    padding: 0 20px;
                    .container {
                        flex-direction: column;
                        .review-content {
                            width: 100%;
                            max-width: initial;
                            min-width: initial;
                            .realtor-container {
                                .form-item {
                                    width: 300px;
                                    .calendar {
                                        width: 31px;
                                        top: 41px;
                                        right: 20px;
                                    }
                                }
                            }
                        }
                        .preview-container {
                            img {
                                width: min(100%, 400px);
                                max-height: initial;
                            }
                        }
                    }
                }
                .footer-wrapper {
                    padding: 0;
                    margin: 0;
                    .footer {
                        button {
                            width: fit-content;
                            max-width: 200px;
                            padding: 10px 15px;
                        }
                        a {
                            text-align: center;
                            width: fit-content;
                            margin: 0 auto;
                            padding: 0;
                        }
                        .continueBtn {
                            font-size: 1.3em;
                            width: fit-content;
                            max-width: 100%;
                            padding: 10px 15px;
                            &::after {
                                content: "\1F86A";
                                margin: 16px 0 16px 6px;
                            }
                            &.show {
                                margin: 0;
                            }
                            &.submit {
                                background-color: $danger;
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        .previousBtn {
                            font-size: 1.3em;
                            &.hide {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .image-preview {
            &.social-details {
                .preview-content {
                    max-width: 90vw;
                    width: 90vw;
                    max-height: 80svh;
                }
            }
            .preview-content {
                max-width: 90vw;
                width: 90vw;
                height: 85%;
                #media-wrapper {
                    .image-container {
                        flex: 1 0 50%;
                        margin-bottom: 0;
                        img {
                            width: 100%;
                            height: auto;
                            padding: 10px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.quantity-field-group {
    height: fit-content;
    width: fit-content;
    margin: 0;
    padding: 0;
    border: 1px solid $defaultNeutral;
    display: flex;
    .btn {
        padding: 0.25em;
        display: unset;
        border-radius: 0;
        background-color: $darkblue;
        svg {
            display: block;
            margin: auto;
            width: 22px;
            height: auto;
        }
        &.red {
            background-color: $danger;
        }

        &.darkblue {
            background-color: $darkblue;
        }

        &.green {
            background-color: $tmsgreen;
        }

        &.blue {
            background-color: $tmsblue;
        }
    }
    input {
        width: 50px;
        text-align: center;
        color: $darkblue;
        border: none;
        font-size: 1.1em;
        padding: 0.25em 0.45em;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
