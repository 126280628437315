.survey-wrapper {
    width: 100%;
    margin-bottom: 30px;
    .header-wrapper {
        img {
            width: 100vw;
            position: relative;
            background-size: cover;
            text-align: center;
            margin-bottom: 0px;
        }
    }
    .survey-form {
        .col-md-6 {
            width: 100%;
            padding: 50px;
            h1 {
                width: fit-content;
                margin: auto;
                font-size: 2.25em;
                font-family: "Bariol";
                color: $darkblue;
            }
            .loan-size {
                display: block;
                width: fit-content;
                margin: 30px auto;
                background-color: $tmsblue;
                padding: 8px 50px;
                p {
                    color: white;
                    text-transform: uppercase;
                    font-size: 1.3em;
                    text-align: center;
                    font-weight: bold;
                }
            }
            .options {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: fit-content;
                justify-content: space-between;
                gap: 30px;
                margin: 20px auto;
                max-width: 100%;
                &.grid {
                    max-width: 950px;
                    span {
                        flex: 1 0 45%;
                    }
                }

                span {
                    padding: 12px 35px;
                    border-radius: 30px;
                    max-width: 450px;
                    min-width: 195px;
                    color: $tmsblue;
                    background-color: white;
                    width: fit-content;
                    font-family: "Bariol";
                    text-align: center;
                    border: 1px solid #d3d3d3;
                    font-size: 1.15em;
                    cursor: pointer;
                    &.active {
                        background-color: $tmsblue;
                        color: white;
                        border: none;
                        // font-family: "Bariol Bold";
                    }
                }
                &.col {
                    width: 450px;
                    flex-direction: column;
                    span {
                        width: 100%;
                    }
                }
                &.row-2 {
                    width: 720px;
                    max-width: 100%;
                    span {
                        width: 350px;
                    }
                }
                &.annual-production {
                    width: 690px;
                    max-width: 100%;
                    .production {
                        .form-item {
                            width: 300px;
                            margin: 20px 0;
                            label {
                                color: $darkblue;
                                font-size: 1.15em;
                                // font-family: "Bariol";
                                font-weight: bold !important;
                                padding-bottom: 10px;
                                text-transform: uppercase;
                            }
                            input {
                                width: 100% !important;
                                color: $tmsblue;
                                text-align: center;
                            }
                        }
                        .slider-wrapper {
                            width: 100%;
                            margin: 30px auto 0;
                            input[type="range"] {
                                -webkit-appearance: none;
                                height: 7px;
                                background: #e5e4e2;
                                border-radius: 5px;
                                padding: 0 !important;
                                background-repeat: no-repeat;
                                border: none;
                                background-image: linear-gradient($tmsblue, $tmsblue);
                                &::-webkit-slider-runnable-track {
                                    -webkit-appearance: none;
                                    box-shadow: none;
                                    border: none;
                                    width: 100%;
                                    display: block;
                                    background: transparent;
                                }
                                &::-webkit-slider-thumb {
                                    -webkit-appearance: none;
                                    height: 15px;
                                    width: 15px;
                                    background: $tmsblue;
                                    cursor: pointer;
                                    box-shadow: #bfd7e9 0px 0px 0px 8px;
                                    border-radius: 50%;
                                    display: block;
                                    &:hover {
                                        background-color: $tmsblue;
                                        box-shadow: #bfd7e9 0px 0px 0px 12px;
                                        transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                                            left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                                            bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    }
                                    &:active {
                                        box-shadow: #bfd7e9 0px 0px 0px 11px;
                                        border-radius: 50%;
                                        background-color: $tmsblue;
                                        transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                                            left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                                            bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    }
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $mobileLargeBreak) {
                        width: 100%;
                        .production {
                            width: 100%;
                            .form-item {
                                margin: 20px auto;
                                width: 100%;
                                max-width: 400px;
                            }
                        }
                    }
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                    width: 100%;
                    span {
                        width: 100%;
                    }
                }
            }
            &:nth-of-type(2n) {
                background-color: #f2f7fb;
            }
        }
    }
    .recommendations-wrapper {
        .col-md-6 {
            width: 100%;
            padding: 50px;
            h1 {
                width: fit-content;
                margin: auto;
                font-size: 2.25em;
                font-family: "Bariol";
                color: $darkblue;
            }
            &:nth-of-type(2n) {
                background-color: $darkblue;
                h1 {
                    color: white;
                }
            }
            .customized {
                a,
                a:visited,
                a:active {
                    text-decoration: none;
                }
                .btn {
                    width: fit-content;
                    margin: 20px auto;
                    font-size: 1.2em;
                    padding: 10px 30px;
                }
            }
            .standard {
                display: flex;
                max-width: 950px;
                flex-direction: column;
                margin: 20px auto;
                .standard-package-wrapper {
                    display: flex;
                    flex-direction: row;
                    width: fit-content;
                    max-width: 950px;
                    margin: 30px auto;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    row-gap: 30px;
                    column-gap: 20px;
                }
            }
            .recommendation-container {
                display: flex;
                flex-direction: column;
                max-width: 950px;
                width: 100%;
                margin: 20px auto;
                gap: 30px;
                h3 {
                    margin: 20px auto 0;
                    text-align: center;
                }
            }
        }
    }
    .addons-wrapper {
        .col-md-6 {
            width: 100%;
            padding: 50px 50px 0;
            h1 {
                width: fit-content;
                margin: auto;
                font-size: 2.25em;
                font-family: "Bariol";
                color: $darkblue;
            }
            .addons-container {
                display: flex;
                flex-direction: row;
                max-width: 1050px;
                flex-wrap: wrap;
                width: 100%;
                margin: 30px auto;
                gap: 30px;
                justify-content: space-between;
                #addon {
                    flex: 0 0 47%;
                    box-shadow: 0px 0px 8px #d3d3d3;
                    padding: 20px 30px;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    min-width: 450px;
                    margin: 0;
                    &.selected {
                        background-color: $tmsblue;
                        .col {
                            h2 {
                                color: white;
                            }
                            ul {
                                li {
                                    color: white;
                                }
                            }
                            .cost-wrapper {
                                .cost {
                                    color: white;
                                }
                            }
                            .btn {
                                border: 1px solid white;
                            }
                            .form-item {
                                label {
                                    color: white;
                                }
                            }
                        }
                    }
                    .col {
                        margin: 0;
                        h2 {
                            text-align: left;
                            color: $tmsblue;
                            font-family: "Bariol";
                            font-size: 1.8em;
                            width: fit-content;
                            margin-bottom: 20px;
                        }
                        .bottom {
                            display: flex;
                            margin-top: 20px;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;
                            .btn {
                                height: 40px;
                            }
                        }
                        iframe {
                            width: 100%;
                            aspect-ratio: 600 / 338;
                            margin-bottom: 20px;
                        }
                        .cost-wrapper {
                            display: flex;
                            width: fit-content;
                            margin: 0;
                            .cost {
                                color: $darkblue;
                                font-size: 1.55em;
                                text-align: center;
                                font-family: "Bariol";
                                width: fit-content;
                                margin: auto 5px;
                                height: fit-content;
                                &.strike-through {
                                    font-size: 1.25em;
                                    color: #d3d3d3;
                                    margin: auto 0;
                                    text-decoration: line-through;
                                }
                            }
                        }
                        .btn {
                            margin: 0;
                            text-align: center;
                            border-radius: 25px;
                            padding: 8px 30px;
                            span {
                                display: flex;
                                flex-direction: row;
                                margin: 0 auto;
                                width: fit-content;
                                svg {
                                    margin: auto 5px auto 0;
                                }
                                p {
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $tabletBreak) {
                        width: 100%;
                        min-width: auto;
                    }
                }
                @media screen and (max-width: $mobileLargeBreak) {
                    flex-direction: column;
                }
            }
        }
    }
}

.survey-footer {
    width: 100%;
    max-width: 1050px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.hide {
        display: none;
    }
    &.addons {
        .save-settings {
            margin: 25px 0;
        }
    }
    @media screen and (max-width: $tabletBreak) {
        padding: 0 50px;
    }
    @media screen and (max-width: $tabletBreak) {
        padding: 0 20px;
        .save-settings {
            width: fit-content !important;
            min-width: 135px;
            padding: 10px 30px;
        }
    }
}

.save-settings {
    width: fit-content;
    min-width: 250px;
    font-family: "Bariol";
    font-size: 1.2em;
    text-transform: lowercase;
    margin: 25px auto;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    &.disable {
        pointer-events: none;
        background-color: #bfbfbf !important;
        opacity: 0.7;
    }
    &.hide {
        display: none;
        pointer-events: none;
    }
    &.addons {
        width: 350px;
        text-transform: capitalize;
    }
}

#package {
    width: 300px;
    box-shadow: 0px 0px 8px #d3d3d3;
    padding: 20px 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    &.selected {
        background-color: $tmsblue;
        .col {
            h2 {
                color: white;
            }
            ul {
                li {
                    color: white;
                }
            }
            .cost-wrapper {
                .cost {
                    color: white;
                }
            }
            .btn {
                border: 1px solid white;
            }
            .form-item {
                label {
                    color: white;
                }
            }
        }
    }
    .col {
        margin: 20px auto 0;
        h2 {
            text-align: center;
            color: $tmsblue;
            font-family: "Bariol";
            font-size: 1.8em;
            width: fit-content;
            margin: 0 auto;
        }
        .package-banner {
            margin: 5px auto;
            width: 200px;
            text-align: center;
            background-color: $darkblue;
            color: white;
            font-size: 12px;
            letter-spacing: 1px;
            font-family: "Bariol";
            text-transform: uppercase;
            padding: 5px;
        }
        .cost-wrapper {
            display: flex;
            width: fit-content;
            margin: 10px auto 20px;
            .cost {
                color: $darkblue;
                font-size: 1.7em;
                text-align: center;
                font-family: "Bariol";
                width: fit-content;
                margin: auto 5px;
                height: fit-content;
                &.strike-through {
                    font-size: 1.3em;
                    color: #d3d3d3;
                    margin: auto 0;
                    text-decoration: line-through;
                }
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 0px 20px;
            li {
                margin: 5px 0;
                flex: 0 0 30%;
                color: $darkblue;
            }
        }
        .btn {
            margin: 8px auto;
            text-align: center;
            border-radius: 25px;
            padding: 8px 30px;
            span {
                display: flex;
                flex-direction: row;
                margin: 0 auto;
                width: fit-content;
                svg {
                    margin: auto 5px auto 0;
                }
                p {
                    font-size: 1.1em;
                }
            }
        }
        .compare-btn {
            display: flex;
            flex-direction: row-reverse;
            width: fit-content;
            margin: 0 auto;
            input {
                width: 15px !important;
                margin: auto 0;
                height: 15px !important;
                border: 1px solid $tmsblue !important;
            }
            label {
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: $tabletBreak) {
        width: 100%;
        max-width: 360px;
        margin: auto;
    }
}

#recommendation {
    width: 100%;
    box-shadow: 0px 0px 8px #d3d3d3;
    padding: 30px 30px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    &.selected {
        background-color: $tmsblue;
        .col {
            h2 {
                color: white;
            }
            ul {
                li {
                    color: white;
                }
            }
            .cost-wrapper {
                .cost {
                    color: white;
                }
            }
            .btn {
                border: 1px solid white;
            }
            .form-item {
                label {
                    color: white;
                }
            }
        }
    }
    .package-banner {
        position: absolute;
        top: 15px;
        left: -15px;
        background-color: $darkblue;
        color: white;
        font-size: 12px;
        letter-spacing: 1px;
        font-family: "Bariol";
        text-transform: uppercase;
        padding: 5px 10px;
    }
    .col {
        margin: 0 0 10px;
        width: 150px;
        &:nth-of-type(2) {
            margin-top: 15px;
            width: calc(100% - 150px);
        }
        h2 {
            text-align: left;
            color: $tmsblue;
            font-family: "Bariol";
            font-size: 1.9em;
            width: fit-content;
            margin: 0;
        }
        .cost-wrapper {
            display: flex;
            width: fit-content;
            flex-direction: column;
            margin: 10px auto 20px;
            .cost {
                color: $darkblue;
                font-size: 1.9em;
                text-align: center;
                font-family: "Bariol";
                width: fit-content;
                margin: auto;
                height: fit-content;
                &.strike-through {
                    font-size: 1.3em;
                    color: #d3d3d3;
                    margin: auto;
                    margin-bottom: -7px;
                    text-decoration: line-through;
                }
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 20px;
            margin: 20px 0;
            column-gap: 25px;
            row-gap: 10px;
            justify-content: flex-start;
            li {
                flex: 0 0 25%;
                color: $darkblue;
            }
        }
        .btn {
            margin: 8px auto;
            text-align: center;
            border-radius: 25px;
            width: fit-content;
            padding: 10px 25px;
            span {
                display: flex;
                flex-direction: row;
                margin: 0 auto;
                width: fit-content;
                svg {
                    margin: auto 5px auto 0;
                }
                p {
                    font-size: 1.1em;
                }
            }
        }
        .compare-btn {
            display: flex;
            flex-direction: row-reverse;
            width: fit-content;
            margin: 0 auto;
            input {
                width: 15px !important;
                margin: auto 0;
                height: 15px !important;
            }
            label {
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: $tabletBreak) {
        width: 100%;
        margin: auto;
    }
    @media screen and (max-width: $mobileLargeBreak) {
        flex-direction: column;
        gap: 10px;
        .col {
            width: 100% !important;
            margin-bottom: 0;
            ul {
                width: 100%;
                column-gap: 10px;
                row-gap: 5px;
                justify-content: space-between;
                li {
                    flex: 0 0 45%;
                }
            }
            .cost-wrapper {
                margin: 0 auto;
            }
        }
    }
}

#compare-packages-btn {
    position: fixed;
    background-color: $tmsblue;
    right: 30px;
    bottom: 30px;
    padding: 20px;
    border-radius: 50%;
    width: 114px;
    height: 114px;
    cursor: pointer;
    .compare-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .compare {
            display: flex;
            width: fit-content;
            justify-content: space-between;
            margin: 0 auto;
            span {
                color: $darkblue;
                padding: 10px 0px;
                background-color: white;
                height: 41px;
                border-radius: 4px;
                width: 22px;
                font-size: 1.2em;
                font-family: "Bariol Bold";
                text-align: center;
                &:nth-of-type(2) {
                    margin-top: 7px;
                    margin-left: 2.5px;
                }
            }
        }
        p {
            color: white;
            text-align: center;
            width: fit-content;
            margin: 0 auto;
            font-size: 0.9rem;
        }
    }
}

.compare-package-wrapper {
    width: 100%;
    max-width: 1050px;
    margin: 50px auto;
    padding: 0 40px;
    .subheader {
        text-align: center;
        color: $darkblue;
        font-family: "Bariol";
    }
    #packages-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 40px 0;
        position: relative;
        margin: 40px auto;
        #close {
            height: 0px;
            width: 0px;
            border-radius: 5px;
        }
        #close:after {
            position: absolute;
            content: "\d7";
            font-size: 40px;
            color: #d4d4d4;
            font-weight: bold;
            font-family: "Bariol";
            cursor: pointer;
            top: 0px;
            right: 20px;
        }
        #package {
            width: 100%;
            padding: 50px 0 20px;
            &:first-of-type {
                z-index: 1;
                width: calc(100% / 3);
                position: absolute;
                left: 0;
            }
            &:last-of-type {
                z-index: 1;
                width: calc(100% / 3);
                position: absolute;
                right: 0;
            }
            .col {
                margin: 0 auto;
                height: 110px;
                .form-item {
                    margin: 20px auto;
                    width: 200px !important;
                    select {
                        width: 100% !important;
                    }
                }
                h2 {
                    color: $darkblue;
                    font-size: 1.95em;
                }
                .btn {
                    width: 135px;
                    margin: 20px auto;
                    max-width: 100%;
                }
            }
            &:nth-of-type(2) {
                box-shadow: none;
                z-index: -1;
                .col {
                    margin: 0 auto;

                    h2 {
                        color: $darkblue;
                        font-size: 4em;
                        margin-top: -36px;
                        margin-bottom: -8px;
                    }
                    .btn {
                        display: none;
                    }
                }
            }
            .package-rows-wrapper {
                text-align: center;
                padding: 10px 0;
                .package-row {
                    height: 74px;
                    position: relative;
                    &:nth-of-type(2n) {
                        background-color: #f2f7fb;
                    }
                    p {
                        padding: 10px;
                    }
                    padding: 15px 0;
                    .checkmark {
                        // display: block;
                        // transform: rotate(45deg);
                        // height: 35px;
                        // width: 14px;
                        // margin: 4px auto;
                        // border-bottom: 6px solid $tmsgreen;
                        // border-right: 6px solid $tmsgreen;

                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                        display: block;
                        height: 35px;
                        width: 15px;
                        background-color: transparent;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-bottom: 6px solid $tmsgreen;
                        border-right: 6px solid $tmsgreen;
                    }
                    .crossmark {
                        margin: 0 auto;
                        background-color: $darkblue;
                        border-radius: 5px;
                        position: relative;
                        height: fit-content;
                        &:after {
                            position: absolute;
                            content: "\d7";
                            font-size: 65px;
                            color: $darkblue;
                            font-weight: bold;
                            font-family: "Bariol";
                            cursor: pointer;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -33%);
                        }
                    }
                }
            }
            @media screen and (max-width: $tabletBreak) {
                max-width: 100%;
                margin: 0;
            }
        }
    }
}
