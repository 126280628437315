// Submit a request
.request-wrapper {
	padding-bottom: 10px;
	.progress-bar-wrapper {
		width: 100%;
		#header-image {
			height: 150px;
			width: 100%;
			position: relative;
			background: url(/images/embr_desktop.png) no-repeat center;
			background-size: cover;
			text-align: center;
			margin-bottom: 50px;
			img {
				height: 150px;
				width: 100vw;
			}
		}
		.progress-bar {
			text-align: center;
			margin: 0 auto;
			width: fit-content;
			height: fit-content;
			position: relative;
			h2 {
				margin: 20px 0;
				text-transform: lowercase;
				font-size: 3.7em;
				font-family: "Source Sans Pro";
				color: $darkblue;
				font-weight: 200;
			}
			ul {
				display: inline-block;
				vertical-align: middle;
				list-style: none;
				margin-bottom: 0px;
				li {
					display: inline-block;
					margin-right: 0px;
					font-size: 22px;
					color: #9bb4c0;
					text-align: center;
					position: relative;
					width: 130px;
					color: $darkblue;
					&::after {
						content: "";
						position: absolute;
						top: 10px;
						left: 75px;
						width: 110px;
						height: 2px;
						background: $darkblue;
						z-index: 111;
					}
					&:last-of-type::after {
						display: none;
					}
				}
				li.active span {
					background-color: $darkblue;
					color: #fff;
				}
				li > span {
					display: block;
					margin: 0 auto;
					position: relative;
					margin-bottom: 5px;
					@include spherize(22px, "none");
					background-color: white;
					border: 1px solid $darkblue;
				}
			}
		}
	}

	.request-form {
		text-align: center;
		margin: 0 auto;
		width: 100%;
		max-width: $contentMaxWidth;
		.request-types-wrapper {
			display: block;
			animation: fadeout 0.5s ease-in-out forwards;
			&.forward {
				animation: fadein 0.5s ease-in-out forwards;
			}
			&.reverse {
				animation: fadeout 0.5s ease-in reverse;
			}
		}
		.authorize-banner {
			color: white;
			background-color: $danger;
			padding: 7px 10px;
			margin-bottom: 10px;
			color: white;
			margin: 0 auto 30px;
			display: flex;
			justify-content: center;
			width: fit-content;
			min-width: 350px;
			a {
				color: white;
				text-decoration: underline;
				cursor: pointer;
			}
			&.success {
				// width: 100%;
				background-color: $tmsgreen;
				font-size: 1.2em;
			}
		}
		.form-container {
			animation: fadeout 0.5s ease-in-out forwards;
			&.forward {
				animation: fadein 0.5s ease-in-out forwards;
			}
			&.reverse {
				animation: fadeout 0.5s ease-in reverse;
			}
		}
		h2 {
			margin-bottom: 50px;
		}
		h3 {
			font-size: 24px;
			font-family: "Bariol";
			font-weight: normal;
			margin-bottom: 30px;
			color: $darkblue;
			a {
				color: $darkblue;
				font-weight: 600;
				font-family: "Bariol";
			}
		}
		.promoDetails,
		.business_card_details {
			#options {
				display: flex;
				margin: 0 auto;
				width: fit-content;
				div {
					display: flex;
					margin: 0 20px;
					align-items: center;
					justify-content: flex-start;
					input {
						margin: auto;
						min-width: initial !important;
					}
					input[type="radio"] {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						min-width: fit-content;
						width: 22px !important;
						height: 22px !important;
					}
					.checkbox-custom {
						margin: auto;
						height: 22px;
						width: 22px;
						flex: 0 0 22px;
						background-color: transparent;
						border-radius: 5px;
						border: 1px solid #c4c4c4;
						cursor: pointer;
						&::after {
							position: absolute;
							content: "";
							left: 12px;
							top: 12px;
							height: 0;
							width: 0;
							border-radius: 5px;
							border: solid $tmsblue;
							border-width: 0 3px 3px 0;
							-webkit-transform: rotate(0deg) scale(0);
							-ms-transform: rotate(0deg) scale(0);
							transform: rotate(0deg) scale(0);
							opacity: 0;
						}
					}
					input:checked ~ .checkbox-custom {
						background-color: white;
						border-radius: 5px;
						-webkit-transform: rotate(0deg) scale(1);
						-ms-transform: rotate(0deg) scale(1);
						transform: rotate(0deg) scale(1);
						opacity: 1;
						border: 2px solid #c4c4c4;
					}
					input:checked ~ .checkbox-custom::after {
						-webkit-transform: rotate(40deg) scale(1);
						-ms-transform: rotate(40deg) scale(1);
						transform: rotate(40deg) scale(1);
						opacity: 1;
						left: 9px;
						top: -10px;
						height: 28px;
						width: 10px;
						border: solid $tmsblue;
						border-width: 0 2px 2px 0;
						background-color: transparent;
						border-radius: 0;
					}
					label {
						margin: 0 auto;
						margin-left: 5px;
						font-size: 24px;
						color: #888;
					}
				}
			}
		}
		.form-upload {
			text-align: center;
			width: fit-content;
			margin: 0 auto;
			h3 {
				display: block;
				margin: 0 auto;
				max-width: 600px;
				font-size: 28px;
				color: $tmsblue;
				text-transform: none;
				font-weight: 600;
				padding: 5px;
				font-family: "Bariol";
			}
			.upload-container {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
		}
		.form-item {
			&.headshots {
				margin-bottom: 20px;
				h3 {
					font-size: 25px;
					margin-top: 15px;
				}
			}
			&.video {
				margin-bottom: 30px;
				h3 {
					font-size: 25px;
					margin-top: 15px;
				}
			}
			#options {
				display: flex;
				margin: 0 auto;
				width: fit-content;
				div {
					display: flex;
					margin: 0 20px;
					input {
						margin: auto;
						min-width: initial !important;
					}
					label {
						margin: 0 auto;
						margin-left: 5px;
						font-size: 24px;
						color: #888;
					}
				}
			}
			.schedule {
				display: block;
				padding: 15px 0;
				font-weight: bold;
				font-family: "Bariol";
				font-size: 1.3em;
				text-align: center;
				cursor: pointer;
				border-radius: 40px;
				width: 265px;
				margin: 0px auto;
				color: white;
				text-decoration: none;
				position: relative;
				background-color: $darkblue;
				svg,
				span {
					margin: auto;
				}
			}
			h3,
			label {
				display: block;
				margin: 0 auto;
				max-width: 600px;
				margin-bottom: 5px !important;
				margin-top: 10px;
				font-size: 1.75em;
				color: $tmsblue;
				text-transform: none;
				font-weight: 600;
				font-family: "Bariol";
			}
			.react-datepicker-wrapper {
				margin: 0 auto;
			}
			.form-upload {
				label {
					font-size: 1.15em;
				}
			}
			h4 {
				margin: 0 auto;
				font-family: "Source Sans Pro";
				font-weight: 100;
				color: #888;
				max-width: 450px;
				line-height: 1em;
				margin-bottom: 15px;
				padding: 0;
				font-size: 18px;
				&.date-text {
					margin-top: 10px;
				}
			}
			input {
				text-align: center;
			}
			&.textOptIn {
				label {
					margin-top: 20px;
					font-size: 26px;
					input {
						min-width: initial !important;
					}
				}
			}
			select {
			}
			input[type="date"] {
				position: relative;
				padding: 10px !important;
				width: 195px !important;
			}
			input[type="date"]::-webkit-inner-spin-button {
				-webkit-appearance: none;

				display: none;
			}
			input[type="date"]::-webkit-calendar-picker-indicator {
				display: block;
				margin-left: 2px;
			}
			textarea {
				min-width: 600px;
				width: 600px;
				min-height: 250px;
				max-height: 400px;
				max-width: 600px;
				padding: 20px 25px !important;
				&::placeholder {
					opacity: 0.5;
				}
			}
			.tox-tinymce {
				width: 100%;
				min-height: 250px;
				max-height: 600px;
				max-width: 850px;
				margin: 0 auto;
			}
			.filter-select {
				width: 100%;
				max-width: 600px;
				padding: 8px 15px !important;
				border-radius: 20px;
				font-size: 1em;
				// border: 1px solid #c4c4c4;
				margin: 0 auto;
			}
			&.textOptIn {
				text-align: center;
				width: fit-content;
				display: flex;
				gap: 5px;
				position: relative;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				margin: 0 auto;
				label {
					margin: 0;
					color: $darkblue;
					font-size: 1.3em;
					max-width: 100%;
				}
				input[type="checkbox"] {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					width: 26px !important;
					height: 26px !important;
					left: 0;
					top: 50%;
					margin: 0;
					transform: translateY(-50%);
					z-index: 1;
				}
				.checkbox-custom {
					height: 26px;
					width: 26px;
					background-color: white;
					border-radius: 50%;
					border: 1px solid $darkblue;
					cursor: pointer;
					z-index: 0;
					margin: 0;
					&::after {
						position: absolute;
						content: "";
						left: 12px;
						top: 12px;
						height: 0;
						width: 0;
						border-radius: 5px;
						border: solid $darkblue;
						border-width: 0 3px 3px 0;
						-webkit-transform: rotate(0deg) scale(0);
						-ms-transform: rotate(0deg) scale(0);
						transform: rotate(0deg) scale(0);
						opacity: 0;
						z-index: 0;
					}
				}
				input:checked ~ .checkbox-custom {
					background-color: white;
					-webkit-transform: rotate(0deg) scale(1);
					-ms-transform: rotate(0deg) scale(1);
					transform: rotate(0deg) scale(1);
					opacity: 1;
					z-index: 0;
				}
				input:checked ~ .checkbox-custom::after {
					-webkit-transform: rotate(40deg) scale(1);
					-ms-transform: rotate(40deg) scale(1);
					transform: rotate(40deg) scale(1);
					opacity: 1;
					left: 9px;
					top: 3px;
					height: 16px;
					width: 7px;
					background-color: transparent;
					border-radius: 0;
					z-index: 0;
				}
			}
			&.checklist-group {
				text-align: center;
				width: fit-content;
				display: flex;
				gap: 15px;
				margin: 5px auto;
				position: relative;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				width: min(250px, 100%);
				label {
					flex: 1;
				}
				&.onboarding {
					flex-direction: column;
					justify-content: flex-start;
					.checklist-group-container {
						text-align: center;
						width: 100%;
						display: flex;
						gap: 15px;
						margin: 0;
						position: relative;
						justify-content: flex-start;
						align-items: center;
						.checkbox-custom {
							margin: 0;
						}
						&:nth-of-type(2) {
							flex-direction: column;
							align-items: flex-start;
							margin-bottom: 20px;
						}
					}
				}
				label {
					margin: 0 !important;
					font-size: 1.25em;
					color: #888;
				}
				input[type="checkbox"] {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					width: 24px !important;
					height: 24px !important;
					left: 0;
					top: 50%;
					margin: 0;
					transform: translateY(-50%);
					z-index: 1;
				}
				input[type="text"] {
					margin: 0;
					font-size: 18px;
				}
				.checkbox-custom {
					margin: auto;
					height: 24px;
					width: 24px;
					background-color: white;
					border-radius: 50%;
					border: 1px solid #888;
					cursor: pointer;
					z-index: 0;
					&::after {
						position: absolute;
						content: "";
						left: 12px;
						top: 12px;
						height: 0;
						width: 0;
						border-radius: 5px;
						border: solid $tmsblue;
						border-width: 0 3px 3px 0;
						-webkit-transform: rotate(0deg) scale(0);
						-ms-transform: rotate(0deg) scale(0);
						transform: rotate(0deg) scale(0);
						opacity: 0;
						z-index: 0;
					}
				}
				input:checked ~ .checkbox-custom {
					background-color: white;
					-webkit-transform: rotate(0deg) scale(1);
					-ms-transform: rotate(0deg) scale(1);
					transform: rotate(0deg) scale(1);
					opacity: 1;
					z-index: 0;
					border: 1px solid $tmsblue;
				}
				input:checked ~ .checkbox-custom::after {
					-webkit-transform: rotate(40deg) scale(1);
					-ms-transform: rotate(40deg) scale(1);
					transform: rotate(40deg) scale(1);
					opacity: 1;
					left: 8px;
					top: 2px;
					height: 16px;
					width: 7px;
					background-color: transparent;
					border-radius: 0;
					z-index: 0;
				}
			}
			.input-wrapper {
				display: flex;
				justify-content: center;
				gap: 20px;
				align-items: center;
				.btn {
					min-width: 120px;
					font-size: 1em;
				}
			}
		}
		.form-upload {
			label {
				color: white;
			}
		}
		.flyerSpecs,
		.postcardSpecs,
		.inline-block-label {
			label {
				display: inline-block;
				color: #888;
				margin-top: 0;
			}
		}
		input[type="text"],
		input[type="date"],
		select,
		textarea {
			width: 300px;
			font-size: 22px;
			color: #666;
		}
		select {
			cursor: pointer;
			width: min(325px, 100%) !important;
			margin: 0 auto;
		}
		input[type="text"].short-input,
		select.short-select {
			width: 100px;
		}
		.business_card_details,
		.flyerQuestions,
		.flyerDetails,
		.promoDetails {
			width: 100%;
			max-width: $contentMaxWidth;
			margin: 0 auto;
			.project-disclaimer {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: 1.2em;
				color: $darkblue;
				text-align: left;
				gap: 8px;
				max-width: 950px;
				width: 100%;
				margin: 0 auto;
				svg {
					flex: 0 0 24px;
					width: 24px;
					height: 24px;
				}
				span {
					flex: 1;
				}
			}
			#print-order {
				margin: 0;
			}
			&.form-container {
				display: flex;
				flex-direction: column;
				gap: 20px;
				animation: fadeout 0.5s ease-in-out forwards;
				&.forward {
					animation: fadein 0.5s ease-in-out forwards;
				}
				&.reverse {
					animation: fadeout 0.5s ease-in reverse;
				}
				.onboarding-survey-wrapper {
					.info-form-wrapper {
						row-gap: 20px !important;
						justify-content: center;
						.form-item {
							flex: 0 0 40%;
							margin: 0;
							display: flex;
							justify-content: center;
							flex-direction: column;
							align-items: center;
							p {
								margin: 0;
								margin-bottom: 0;
								font-size: 1.1em;
							}
							&.bio {
								order: 20;
							}
							textarea {
								background-color: white;
							}
						}
					}
				}
			}
			h3 {
				&.subheading {
					font-size: 1.5em;
				}
			}
			#info-form {
				width: min(1250px, 100%);
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 2em;
				.info-form-wrapper {
					width: 100%;
					column-gap: 5em;
					row-gap: 2em;
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
				}
				.col-left,
				.col-right {
					flex: 0 0 45%;
					margin: 0;
					display: flex;
					flex-direction: column;
					gap: 2em;
				}
				.form-item {
					label {
						margin: 0;
						font-size: 1.2em;
					}
					input[type="text"] {
						font-size: 1.2em;
						width: 100% !important;
					}
				}
				.branch_address {
					h3 {
						font-family: "Bariol Bold";
						font-size: 1.7em;
						margin-bottom: 15px;
					}
					p {
						font-size: 1.5em;
					}
				}
			}
			.cards-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 3em;
				justify-content: center;
				width: 100%;
				max-width: $contentMaxWidth;
				margin: 20px;
				.business-card-preview {
					margin: 0;
					flex: 0 0 min(100%, 350px);
					h3 {
						margin-top: 0;
						font-size: 1.4em;
					}
					.business-card-name {
						margin-top: 0 !important;
					}
					.preview-box {
						border: none;
						display: flex;
						flex-direction: column;
						gap: 20px;
						justify-content: center;
						align-items: center;
						img {
							width: 100%;
						}
					}
					&.active {
						.preview-box {
							border: 5px solid #5bb65d;
						}
					}
				}
			}
			.form-item.branch {
				display: flex;
				width: fit-content;
				margin: 20px auto;
				gap: 20px;
				h3 {
					width: fit-content;
					margin: auto !important;
					display: block;
				}
				select {
					width: fit-content;
					min-width: 100px;
					border-radius: 25px;
					padding: 4px 15px !important;
					height: fit-content;
					margin: auto 0;
				}
			}
			.promo-items-list {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				gap: 25px;
				.btn {
					width: 200px;
					margin-top: 20px;
				}
				#promo-item {
					display: flex;
					flex-direction: column;
					width: 100%;
					max-width: 950px;
					flex-wrap: wrap;
					gap: 5px;
					h4 {
						color: $darkblue;
						text-align: left;
						font-size: 1.3rem;
					}
					.item {
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
						margin: 0;
						gap: 20px;
						width: 100%;
						flex-wrap: wrap;
						.form-item {
							margin: 0;
							label {
								text-align: left;
								font-size: 1.5rem;
							}
						}
						svg {
							cursor: pointer;
							width: 25px;
							height: 25px;
							margin-bottom: 14px;
						}
					}
				}
			}
		}
		.business_card_details {
			display: flex;
			flex-direction: column;
			gap: 3em;
			animation: fadeout 0.5s ease-in-out forwards;
			&.forward {
				animation: fadein 0.5s ease-in-out forwards;
			}
			&.reverse {
				animation: fadeout 0.5s ease-in reverse;
			}
			.form-item {
				&.center {
					align-items: center;
					justify-content: center;
				}
			}
		}
		#print-order {
			h3 {
				font-size: 1.5em;
			}
		}
		#image-preview {
			margin: 20px auto;
			width: 100%;
			max-width: 400px;
			padding: 15px 10px;
			text-align: left;
		}
	}

	.quick-request-form {
		text-align: center;
		.left-col {
			width: fit-content;
			margin: 0 auto;
			.btn {
				width: 220px;
				margin: 25px auto 30px;
			}
		}
		.form-item {
			margin-bottom: 25px;
		}
		.form-item label {
			display: block;
			margin: 10px auto;
			max-width: 100%;
			font-size: 24px;
			color: $tmsblue;
		}
		.form-upload label {
			font-size: 14px;
		}
		.upload-container label {
			font-size: 16px;
		}
		input[type="text"],
		input[type="date"],
		select,
		textarea {
			padding: 8px;
			width: 300px;
			font-size: 18px;
			color: #666;
		}
		.file-upload {
			width: 250px;
			padding: 10px;
			color: white !important;
		}
		select {
			cursor: pointer;
		}
		input[type="text"].short-input,
		select.short-select {
			width: 100px;
		}
		textarea {
			clear: both;
			min-width: 600px;
			width: 600px;
			min-height: 250px;
			max-height: 400px;
			max-width: 600px;
			padding: 20px 25px !important;
			&::placeholder {
				opacity: 0.5;
			}
		}
	}
	// .row {
	//     width: 100%;
	//     text-align: center;
	//     clear: both;
	//     margin-bottom: 30px;
	//     .radio-wrapper{
	//         width: 180px;
	//         text-align: center;
	//         display: inline-block;
	//         label > input {
	//             visibility: hidden;
	//             position: absolute;
	//         }
	//     }
	// }
	.row {
		width: min(100%, 1250px);
		display: flex;
		margin: auto;
		clear: both;
		justify-content: space-evenly;
		text-align: center;
		flex-wrap: wrap;
		gap: 2em;
		label > input {
			visibility: hidden;
			position: absolute;
		}
		.tile {
			width: 350px;
			// height: 145px;
			margin: 30px auto;
			position: relative;
			box-shadow: 0 3px 3px #c8c8c8;
			cursor: pointer;
			#icon {
				position: absolute;
				left: 50%;
				margin-left: -30px;
			}
			.tile-text {
				line-height: 1.2em;
				width: 100%;
				height: fit-content;
				max-height: calc(100% - 45px);
				overflow: hidden;
				text-align: center;
				padding: 5px 15px 0 15px;
				font-size: 24px;
				font-family: "Bariol";
				color: $tmsblue;
				text-decoration: none;
				position: absolute;
				top: 50%;
				margin-top: -30px;
				p {
					all: revert;
					margin: 0;
					position: relative;
					text-transform: lowercase;
					line-height: 1.2em;
				}
				.subtext {
					margin-top: 4px;
					font-size: 0.6625em;
					color: $darkblue;
				}
			}
		}
		.disabled-tile {
			position: relative;
			opacity: 0.5;
		}

		.disabled-tile::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none; /* Allows interaction with elements behind the overlay */
			border-radius: 20px;
		}
	}
	span.svg-wrapper {
		display: block;
		@include spherize(126px);
		background-color: #0061aa;
		position: relative;
		cursor: pointer;
		margin: 0 auto;
		&:hover {
			background-color: #5bb65f;
		}
		svg {
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}
	span.svg-wrapper.active {
		background-color: #5bb65f;
	}
	.footer-wrapper {
		width: 100%;
		text-align: center;
		padding: 30px;
		margin-bottom: 75px;
		.footer {
			position: relative;
			margin: 0 auto;
			padding-top: 30px;
			width: 100%;
			max-width: $contentMaxWidth;
			border-top: 1px solid #eff3f4;
			button {
				position: absolute;
				font-size: 22px;
				padding: 8px 30px;
				border: 0;
				outline: none;
				min-width: 100px;
				max-width: 225px;
				cursor: pointer;
				text-align: center;
				font-family: "Bariol";
				font-weight: 600;
				letter-spacing: 1px;
				text-transform: lowercase;
			}
			.previousBtn {
				left: 0;
				color: white;
				background-color: $darkblue;
				border-radius: 25px;
			}
			.continueBtn {
				right: 0;
				color: white;
				background-color: $darkblue;
				border-radius: 25px;
				opacity: 0.3;
				cursor: default;
				&.active {
					opacity: 1;
					cursor: pointer;
				}
			}
		}
	}
	.project-capacity-banner {
		display: flex;
		justify-content: center;
		margin: 40px 0;
		.banner-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: white;
			padding: 10px 30px;
			background-color: $danger;
			width: 100%;
			max-width: 650px;
			border-radius: 10px;
			text-align: center;
			a {
				color: white;
			}
			a:visited {
				color: white;
			}
			a:link {
				color: white;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.request-wrapper {
		.request-form {
			padding: 10px;
		}
		.progress-bar-wrapper {
			.progress-bar {
				width: 100%;
				height: fit-content;
				margin-bottom: 20px;
				ul {
					margin: 20px 0;
					width: 100%;
					li {
						margin-right: 0px;
						margin-bottom: 20px;
						font-size: 1.5em;
					}
				}
			}
		}
		.row {
			margin-bottom: 10px;
			width: 85vw;
			.tile {
				width: 205px;
				height: 160px;
			}
			.radio-wrapper {
				padding: 12px 0;
				font-size: 24px;
			}
		}
		.footer-wrapper {
			padding: 10 0px;
			.footer {
				width: 75%;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	textarea {
		// max-width: 95% !important;
		// width: 95% !important;
		// min-width: 95% !important;
		// padding: 15px 20px !important;
	}
	.request-wrapper {
		.progress-bar-wrapper {
			.progress-bar {
				width: 90%;
				ul {
					margin-bottom: 0px;
					li > span {
						span {
							font-size: 1.3em;
						}
					}
				}
			}
		}
		.footer-wrapper {
			padding: 10 0px;
			.footer {
				width: 100%;
				height: 95px;
			}
		}
	}
}

#messages,
#adminCommentBox {
	height: auto;
	&.m-active {
		height: fit-content;
		background: transparent !important;
	}
	.markdown-key {
		padding: 5px 0 0 0 !important;
		font-size: 14px;
		color: #aaa;
	}
	#request-message-box {
		background-color: transparent;
		width: 100%;
		max-width: 100%;
		height: 450px;
		overflow: auto;
		border: 1px solid #ddd;
		border-bottom: none;
		position: relative;
		animation: fadein 1s;
		padding: 20px 10px;
		&.hide-messages {
			display: none;
		}
		#request-message-wrapper {
			display: flex;
			flex-direction: column;
			gap: 25px;
			.new-day-message {
				display: flex;
				align-items: center;
				justify-content: center;
				p {
					color: $neutral;
				}
				.line {
					flex-grow: 1;
					height: 1px;
					background-color: $neutral;
					margin: 0 10px;
				}
			}
		}
	}
	.message-bubble-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		&.right {
			justify-content: flex-end;
			.message-bubble {
				// &:after {
				// 	left: 100%;
				// 	top: 50%;
				// 	border: solid transparent;
				// 	content: " ";
				// 	height: 0;
				// 	width: 0;
				// 	position: absolute;
				// 	pointer-events: none;
				// 	border-color: rgba(255, 255, 255, 0);
				// 	border-left-color: #fff;
				// 	border-width: 12px;
				// 	margin-top: -12px;
				// }
			}
			img.profile-image {
				margin-left: 20px;
			}
		}
		&.left {
			.message-bubble {
				// &:after {
				// 	right: 100%;
				// 	top: 50%;
				// 	border: solid transparent;
				// 	content: " ";
				// 	height: 0;
				// 	width: 0;
				// 	position: absolute;
				// 	pointer-events: none;
				// 	border-color: rgba(255, 255, 255, 0);
				// 	border-right-color: #fff;
				// 	border-width: 12px;
				// 	margin-top: -12px;
				// }
			}
			img.profile-image {
				margin: 0;
			}
		}

		.message-bubble {
			position: relative;
			display: block;
			flex: 1;
			padding: 0;
			background: white;
			img.emojione {
				width: 22px;
				height: 22px;
				vertical-align: middle;
			}
			.message-header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				h3 {
					border: 0;
					padding-bottom: 0;
					margin-bottom: 5px;
					color: $tmsblue;
					font-weight: 600;
					text-align: left;
					font-size: 1.1em;
					span {
						font-weight: 100;
						font-size: 0.85em;
					}
				}
				.message-time {
					flex: 0 0 fit-content;
					margin: 0;
					color: $neutral;
					text-align: right;
					font-size: 0.9em;
					padding: 0;
					font-weight: normal;
				}
			}
			.message-body {
				max-width: 100%;
				overflow-wrap: break-word;
			}

			p {
				padding: 0;
				a {
					word-wrap: break-word;
				}
			}
		}

		img.profile-image {
			display: inline-block;
			vertical-align: middle;
			@include spherize(55px);
			border: none;
		}
	}
	input[type="text"] {
		width: 100%;
		max-width: 550px;
		border: 1px solid #ddd;
		outline: none;
		padding: 10px;
		padding-right: 110px;
	}
	#send-message {
		width: 100%;
		max-width: 100%;
		position: relative;
		display: flex;
		box-sizing: border-box;
		height: fit-content;
		padding: 10px;
		align-items: center;
		border: 1px solid #ddd;
		gap: 10px;
	}

	#newMessage,
	#editMessage {
		flex: 1;
		height: fit-content;
		max-height: 300px;
		border: none;
		outline: none;
		overflow: hidden;
		padding: 0;
		word-wrap: break-all;
		resize: none;
		order: 1;
	}

	#editMessage {
		width: 100%;
		border: 1px solid #ddd;
		padding: 10px;
		height: fit-content;
	}

	#msg-action-icons {
		display: flex;
		justify-content: flex-end;
		font-size: 1.5em;
		gap: 10px;
		svg {
			cursor: pointer;
			&.check-icon {
				color: green;
			}
			&.cross-icon {
				color: red;
			}
		}
	}

	#send-message-button {
		border: none;
		flex: 0 0 fit-content;
		color: $darkblue;
		border-radius: 25px;
		cursor: pointer;
		text-align: center;
		order: 2;
		display: flex;
		padding: 0;
		align-items: center;
		svg {
			width: 28px;
			height: 28px;
		}
		&.disabled {
			pointer-events: none;
			color: $neutral;
		}
	}

	#send-message ul {
		list-style-type: none;
		text-align: left;
		margin: 0;
		padding: 5px;
		//border: 1px solid black;
		width: 100%;
		max-width: 550px;
		//border-radius: 5px;
	}
	#send-message ul li {
		padding: 5px;
		&:hover {
			background-color: lightgray;
		}
	}
	#send-message #suggestionBox {
		position: absolute;
		left: 0;
		margin: 10px;
		bottom: 60px;
		width: 50%;
		border: 1px solid white;
		background-color: white;
		border-radius: 10px;
		box-shadow: 5px 5px 5px grey;
		color: black;
		//top: -40px;
		#suggestionTitle {
			color: $tmsblue;
			font-weight: bold;
			padding: 10px;
		}
	}
	#messageMenu {
		left: 0;
		top: 0;
		position: absolute;
		background-color: white;
		color: black;
		width: 150px;
		z-index: 7000;
		border: 1px solid lightgray;
		box-shadow: 1px 1px 1px lightgray;
		margin: 0;
		padding: 0;
	}
	#messageMenu ul {
		list-style-type: none;
		text-align: left;
		margin: 0;
		padding: 0;
		width: 100%;
	}
	#messageMenu ul li {
		padding: 10px;
		padding-left: 15px;
		&:hover {
			background-color: lightgray;
		}
	}
	#editsuggestionBox {
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media screen and (max-width: 800px) {
	#messages {
		#send-message {
			width: 100%;
			input {
				padding: 15px 105px 15px 10px;
			}
		}
	}
}

.supporting-files {
	display: flex;
	flex-direction: column;
	gap: 20px;
	#header-upload {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 30px;
		h2 {
			margin: 0;
		}
		.upload-container {
			margin: 0;
		}
	}
	.form-upload {
		#uploads {
			h3 {
				font-size: 22px;
				padding-bottom: 0;
			}
		}
		.form-item {
			margin-top: 20px;
		}
	}

	#image-preview {
		gap: 10px;

		.image-preview-content {
			flex: 1;
			max-height: fit-content;
			height: fit-content;
			display: flex;
			flex-direction: column;
			gap: 10px;
			p {
				color: $darkblue;
				font-size: 1.05em;
				font-family: "Source Sans Pro";
			}
			.preview-date {
				font-size: 0.85em;
				color: $neutral;
			}
		}
		#download-link {
			position: relative;
			margin: 0;
			svg {
				margin-top: 0;
			}
			&.edit-preview {
				cursor: pointer;
				svg {
					margin-top: 3px;
					margin-left: 2px;
				}
			}
			&.favorite {
				cursor: pointer;
				background-color: #eeeeee;
				border: 1px solid $tmsblue;
				svg {
					fill: $tmsblue;
				}
			}
		}
		#new-tab {
			width: fit-content;
			display: flex;
			gap: 5px;
			span {
				font-size: 1.05em;
			}
			svg {
				width: 22px;
				height: 22px;
			}
		}
	}
	.file-wrapper {
		display: flex;
		gap: 20px;
		flex-direction: column;
	}
	.edit-file-wrapper {
		border: 1px solid #d3d3d3;
		padding: 20px;
		box-shadow: 1px 1px 1px #d3d3d3;
		width: fit-content;
		border-radius: 20px;
		.form-item {
			label {
				text-align: left;
			}
		}
		textarea {
			max-height: 200px;
			max-width: 600px;
		}
		.btn {
			width: 250px;
			margin: 20px auto;
		}
		#time-worked {
			border: none !important;
		}
	}
}

@media screen and (max-width: 800px) {
	.supporting-files {
		#header-upload {
			width: 100%;
		}
	}
}

#question-files-preview {
	margin: 5px auto 0;
	background: white;
	width: 400px;
	height: fit-content;
	padding: 15px 10px;
	border-radius: 0px;
	display: flex;
	position: relative;
	border-bottom: 1px solid #c4c4c4;
	text-align: left;
	&:last-of-type {
		border: none;
	}
	div {
		width: 30px;
		height: 30px;
		position: relative;
		margin: auto 0;
		svg {
			width: 18px;
			height: 18px;
		}
	}
	p {
		margin: auto 0 !important;
		height: fit-content;
		max-width: 280px !important;
		padding-left: 10px !important;
		font-size: 16px;
		color: #888;
		font-family: sans-serif;
		line-height: 1.3em;
		overflow-wrap: break-word;
	}
	.close {
		height: 20px;
		width: 20px;
		border-radius: 5px;
	}
	.close:after {
		position: absolute;
		content: "\d7";
		font-size: 35px;
		color: #c4c4c4;
		font-weight: bold;
		top: 50%;
		margin-top: -22px;
		right: 4px;
		cursor: pointer;
	}
}

@media screen and (max-width: 600px) {
	#messages {
		// &.m-active {
		// 	height: 500px;
		// }
		#request-message-box {
			width: 100%;
			height: 500px;
		}
	}
}
#request-message-wrapper > div:nth-child(11) > div > p > p > a {
	word-break: break-word;
}
//questions page
#questions {
	padding-top: 100px;
	.btn {
		width: 300px;
		margin: 20px auto;
	}
	textarea {
		width: 45em;
		height: 12em;
		font-size: 22px;
	}
}

@media screen and (max-width: 1200px) {
	#questions {
		textarea {
			width: 70vw;
		}
	}
}

#message-info {
	width: 550px;
	.markdown-key {
		display: inline-block;
		width: 60%;
		&:last-child {
			width: 40%;
			text-align: right;
			padding-right: 2px !important;
		}
	}
}

.project-filter > div {
	display: inline-block;
	vertical-align: middle;
}

.project-filter {
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	// justify-content: space-between;
	flex: 1 60px;
	width: fit-content;
	margin: auto;
	margin-bottom: 0px;
	vertical-align: middle;
	#search {
		width: fit-content;
		svg {
			margin-right: 5px;
		}
		input {
			height: 36px;
			width: 155px;
			padding: 9px;
			border: 1px solid #ccc;
			border-radius: 4px;
		}
	}
	.filter-select {
		min-width: 180px;
		max-width: 350px;
		// margin-right: 20px;
		margin-bottom: 20px;
		.Select-placeholder {
			cursor: pointer;
		}
	}
	.my-lo-list-input-div {
		display: flex;
		gap: 5px;
		flex-direction: row;
		align-items: flex-start;
		input {
			margin-top: 5px;
		}
	}
}

.my-list-checkbox-wrapper {
	position: relative;
	.container {
		display: block;
		position: relative;
		line-height: 1.4em;
		padding-left: 35px;
		margin-bottom: 8px;
		margin-top: 3px;
		cursor: pointer;
		font-size: 16px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #737272;
		position: relative;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 22px;
		width: 22px;
		background-color: #eee;
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
		margin-top: 2px;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-color: $danger;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 8px;
		top: 3px;
		width: 6px;
		height: 14px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

@media screen and (max-width: 1100px) {
	.project-filter {
		width: 100%;
		div {
			margin-bottom: 0px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 1577px) {
	.request-wrapper .progress-bar-wrapper .progress-bar {
		ul {
			margin-bottom: 0;
		}
		img {
			display: none;
		}
	}
}

#main-container {
	#home-top {
		#projects-nav {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin: 0 auto;
			justify-content: center;
			flex-wrap: wrap;
			gap: 20px;
			#project-nav-items {
				display: flex;
				width: fit-content;
				border: 1px solid #c4c4c4;
				padding: 4px 13px;
				border-radius: 20px;
				justify-content: space-between;
				align-items: center;
				a,
				a:visited {
					color: #c4c4c4;
					margin-left: 7px;
					text-decoration: none;
					font-size: 0.9em;
				}
				svg {
					color: #c4c4c4;
				}
				&.active {
					border: 1px solid #2bb579;
					svg,
					a {
						color: #2bb579;
					}
				}
			}
		}
	}
	#my-projects-filters {
		text-align: center;
		display: flex;
		margin: 30px auto 15px;
		width: 100%;
		max-width: 1100px;
		justify-content: space-between;
		.filter-select {
			margin: 20px auto;
			max-width: 300px;
			flex: 1 1 50%;
		}

		ul li a {
			color: grey;
		}

		ul li a:active {
			color: darkblue;
			font-weight: bold;
		}

		ul li a:hover {
			color: black;
		}
	}

	#tab-options {
		list-style-type: none;
		padding: 0;
		margin: 20px auto;
		width: 100%;
		max-width: 1100px;
		text-align: center;
		display: flex;
		flex-direction: row;
	}

	#tab-option-item {
		text-transform: uppercase;
		flex: 1;
		margin: 0;
		font-size: 1em;
		text-align: center;
		color: #c4c4c4;
		padding: 0 10px;
		border-bottom: 1px solid #c4c4c4;
		cursor: pointer;
		&.active {
			color: $darkblue;
			font-weight: bold;
			border-bottom: 2px solid $darkblue;
		}
	}

	#sentinel {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// padding-bottom: 30px;
		.btn {
			all: unset;
			background: $tmsblue;
			color: white;
			font-weight: bold;
			padding: 5px 20px;
			cursor: pointer;
			border-radius: 20px;
		}
	}
}

@media screen and (max-width: 1200px) {
	#main-container {
		#my-projects-filters {
			margin: 10px auto;
		}
		#filters-div {
			.date-range-wrapper {
				width: 100%;
				justify-content: space-evenly;
			}
		}
		#tab-options {
			width: 90vw;
		}
	}
}

@media screen and (max-width: 500px) {
	#main-container {
		#projects-nav {
			width: 95vw !important;
		}
		#filters-div {
			.date-range-wrapper {
				width: 100%;
				justify-content: space-evenly;
				flex-direction: column;
			}
		}
		#tab-options {
			width: 90vw;
		}
	}
}

#editSuggestionBox {
	position: absolute;
	left: 0;
	bottom: 72px;
	//padding: 10px;
	border: 1px solid white;
	background-color: white;
	border-radius: 10px;
	box-shadow: 5px 5px 5px grey;
	color: black;
	#suggestionTitle {
		color: $tmsblue;
		font-weight: bold;
		padding: 10px;
	}
	ul {
		list-style-type: none;
		li {
			padding: 5px;
			padding-left: 10px;
			padding-right: 10px;
		}
		li:hover {
			background-color: lightgray;
		}
	}
}

.flex-display-date-range-div {
	display: flex;
	justify-content: center;
	gap: 10px;
	.form-item {
		margin: 0;
	}
}

.image-preview.requests {
	background: rgba(0, 0, 0, 0.5);
	.preview-content {
		width: 90vw;
		height: fit-content;
		max-width: 600px;
		overflow: hidden;
		padding: 30px;
		background-color: white;
		text-align: center;
		border-radius: 35px;
		z-index: 999999;
		position: relative;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		box-sizing: border-box;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: "Source Sans Pro";
		img {
			max-width: 150px;
			width: 100%;
			margin: 20px auto;
		}
		#close {
			height: 15px;
			width: 15px;
			border-radius: 5px;
		}
		#close:after {
			position: absolute;
			content: "\d7";
			font-size: 45px;
			color: black;
			font-weight: bold;
			cursor: pointer;
			top: 5px;
			right: 20px;
		}
		h2 {
			font-family: "Bariol Bold";
			color: $darkblue;
			font-size: 1.6em;
		}
		p {
			font-family: "Source Sans Pro";
			color: $darkblue;
			margin-top: 20px;
			position: relative;
			display: block;
			line-height: 1.5em;
			top: auto;
			right: auto;
			left: auto;
			bottom: auto;
		}
		.btn {
			display: block;
			margin: 20px auto;
			width: fit-content;
			min-width: 180px;
		}
	}
}

.project-filters-wrapper {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0;
	gap: 40px;
	flex-wrap: wrap;
	align-items: center;
	.options-wrapper {
		position: relative;
		.btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.25em;
		}
	}
	#filters-wrapper {
		display: block;
		padding: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		right: 0;
		width: min(1250px, 90vw);
		height: min(650px, 80vh);
		border-radius: 2em;
		z-index: 9999;

		overflow: hidden;
		border: none;
		margin: 0;
		@media screen and (max-width: $mobileLargeBreak) {
			left: 50%;
			transform: translateX(-50%);
			width: 95vw;
		}
		.container {
			display: flex;
			flex-direction: column;
			gap: 1em;
			height: 100%;
			background-color: white;
			& > * {
				padding: 1em;
			}
			.header {
				flex: 0 0 fit-content;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1em;
				position: relative;
				background-color: $darkblue;
				position: relative;
				h3 {
					font-family: "Bariol Bold";
					font-weight: bold;
					font-size: 1.35em;
					text-align: left;
					color: white;
					width: fit-content;
				}
				.btn {
					margin: 0;
					min-width: 150px;
					padding: 6px;
				}
				#close {
					border-radius: 5px;
					cursor: pointer;
					font-family: "Bariol Bold";
					position: absolute;
					top: 50%;
					right: 18px;
					transform: translateY(-50%);
					color: white;
					font-size: 1.55em;
				}
			}
			.filters-container {
				flex: 1;
				padding: 0 1em;
				overflow-y: auto;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				.col-md-4 {
					flex: 1 0 50%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 1em;
					h3 {
						padding-bottom: 10px;
					}
					&:nth-of-type() {
						border-left: 1px solid #d3d3d3;
						padding-right: 0;
					}
					&:nth-of-type(2n) {
						border-left: 1px solid #d3d3d3;
						padding-left: 20px;
					}
					.form-item {
						flex: 0 0 fit-content;
					}
					.container {
						display: block;
						position: relative;
						line-height: 1.4em;
						padding-left: 35px;
						margin-bottom: 8px;
						margin-top: 3px;
						cursor: pointer;
						font-size: 16px;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
						color: #737272;
						&:last-of-type {
							margin-bottom: 0;
						}
					}

					/* Hide the browser's default checkbox */
					.container input {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 0;
						width: 0;
					}

					/* Create a custom checkbox */
					.checkmark {
						position: absolute;
						top: 0;
						left: 0;
						height: 22px;
						width: 22px;
						background-color: #eee;
						border-radius: 50%;
					}

					/* On mouse-over, add a grey background color */
					.container:hover input ~ .checkmark {
						background-color: #ccc;
						margin-top: 2px;
					}

					/* When the checkbox is checked, add a blue background */
					.container input:checked ~ .checkmark {
						background-color: $danger;
					}

					/* Create the checkmark/indicator (hidden when not checked) */
					.checkmark:after {
						content: "";
						position: absolute;
						display: none;
					}

					/* Show the checkmark when checked */
					.container input:checked ~ .checkmark:after {
						display: block;
					}

					/* Style the checkmark/indicator */
					.container .checkmark:after {
						left: 8px;
						top: 3px;
						width: 6px;
						height: 14px;
						border: solid white;
						border-width: 0 2px 2px 0;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}
			}
			.footer {
				flex: 0 0 fit-content;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1em;
			}
		}
	}
	.csv-download {
		width: fit-content;
		margin: 0;
		text-decoration: none;
	}
	#search {
		position: relative;
		text-align: left;
		flex: 0 0 min(100%, 650px);
		.form-item {
			width: 100%;
			position: relative;
			box-sizing: border-box;
		}
		input {
			border-radius: 5px;
			width: 100%;
			padding: 9px 12px !important;
			padding-right: 35px;
			color: $darkblue;
			border: 1px solid #c4c4c4;
			&::placeholder {
				color: #c4c4c4;
			}
			&:focus {
				border: 2px solid $darkblue;
			}
		}
		svg {
			position: absolute;
			right: 7px;
			height: 20px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}
	}
}

#request-summary-wrapper {
	.content-wrapper {
		.content-heading {
			h2 {
				color: $tmsblue;
				font-size: 1.75rem;
				font-family: "Source Sans Pro";
				font-weight: normal;
			}
		}
		.content-details {
			display: flex;
			flex-wrap: wrap;
			//padding: 20px;
			justify-content: space-between;
			box-sizing: border-box;
			.content-row {
				flex: 0 0 50%;
				padding: 10px 30px 30px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: start;
				vertical-align: top;
				text-align: left;
				align-items: start;
				.label {
					font-size: 28px;
					color: $tmsblue;
					font-family: "Bariol";
				}

				.value {
					padding: 8px 0;
					img {
						width: 100%;
						max-width: 205px;
						height: auto;
					}
				}

				hr {
					width: 100%;
					text-align: left;
					color: #c5c5c5;
				}

				--column-gap: 4.5rem;
				&:nth-of-type(2n + 1) {
					padding-right: var(--column-gap);
					border-right: 1px solid #c5c5c5;
				}
				&:nth-of-type(2n + 2) {
					padding-left: var(--column-gap);
				}
				@media screen and (max-width: $tabletBreak) {
					--column-gap: 3rem;
				}
			}
			@media screen and (max-width: $mobileLargeBreak) {
				flex-direction: column;
				.content-row {
					flex: 1;
					padding: 20px;
					--column-gap: 20px !important;
					border: none !important;
				}
			}
		}
	}
}

#project-dashboard-v2 {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	gap: 20px;
	#project-tables-wrapper {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 0px;
		border-top: 1px solid $tmsblue;
		border-bottom: 1px solid $tmsblue;

		.table-wrapper {
			margin: 0;
			border-bottom: 1px solid $tmsblue;
			&:last-of-type {
				border: none;
			}
		}
		.project-table {
			margin: 0;
			table {
				margin: 0;
			}
			thead {
				.default,
				.manager-default {
					background-color: transparent;
					color: white;
					padding: 10px 0;
					th {
						background-color: transparent;
						color: $darkblue;
						font-size: 1em;
						font-family: "Bariol Light";
						display: flex;
						justify-content: flex-start;
						align-items: center;
						text-transform: uppercase;
						letter-spacing: normal;
						padding: 0 5px;
						&.count {
							color: $darkblue;
							flex: 0 0 60px;
							font-family: "Bariol Light";
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							span {
								background-color: transparent;
								color: $darkblue;
								width: auto;
								font-family: "Bariol Light";
								font-size: 1.1em;
								position: relative;
								top: auto;
								left: auto;
								transform: none;
							}
						}
						&.table-title,
						&.count {
							text-transform: uppercase;
							font-family: "Bariol";
							span {
								text-transform: uppercase;
								font-family: "Bariol";
							}
						}
					}
				}
			}
			tbody {
				&:nth-child(even) {
					tr.normal-row {
						background-color: rgba(0, 96, 169, 0.1);
					}
				}

				.normal-row {
					background-color: rgba(255, 255, 255);
					td {
						&:first-of-type {
							flex: 0 0 60px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 0;
							.arrow {
								position: relative;
								top: auto;
								left: auto;
								right: auto;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}

#project-timeline-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	h2 {
		margin: 0 !important;
	}
	.timelineContainer {
		height: fit-content;
		max-height: 650px;
		overflow-y: auto;
		.timelineDisplay {
			min-height: 200px;
			margin-top: 0;
			position: relative;
			display: flex;
			flex-direction: column;
			background-color: #eeeeee;
			padding: 15px 0;
			height: fit-content;
			&.hidden {
				h4 {
					font-family: "Bariol Bold";
					text-align: center;
					font-size: 1.25em;
					color: $darkblue;
					padding: 20px;
				}
				&:before {
					display: none;
				}
			}
			&:before {
				position: absolute;
				left: 25px;
				top: 0;
				content: " ";
				display: block;
				width: 2px;
				height: 100%;
				background: #009ddd;
				z-index: 0;
			}
		}
		.outgoingContainer {
			position: relative;
			padding: 5px 20px;
			.messageOutgoing {
				position: relative;
				margin: 10px 0;
				margin-left: auto;
				background-color: #fff;
				width: calc(100% - 35px);
				height: fit-content;
				text-align: left;
				font:
					400 0.9em "Open Sans",
					sans-serif;
				border-radius: 10px;
				padding: 35px 20px 10px 20px;

				.message-outgoing-header {
					position: absolute;
					right: 0;
					top: 0;
					padding: 5px 5px 5px 10px;
					width: calc(100% + 17px);
					letter-spacing: 0.4px;
					clip-path: polygon(4% 0, 100% 0, 100% 50%, 100% 100%, 4% 100%, 0 50%);
					margin: 0;
					background-color: $tmsblue;
					word-wrap: break-word;
					border-top-right-radius: 10px;
					font-family: "Source Sans Pro";
					// float: right;
					text-align: right;
					font-size: 12.5px;
					color: white;
				}
				.message-title {
					font-family: "Bariol Bold";
					font-size: 1em;
					margin: 0;
					color: $darkblue;
					padding: 5px 0;
				}
				.timeline-description {
					font-family: "Bariol";
					font-size: 1em;
					margin: 0;
					color: $darkblue;
					word-wrap: break-word;
				}
			}
		}
	}
}

#request-select-wrapper {
	.success .th {
		background-color: $tmsgreen;
	}
	.danger .th {
		background-color: $danger;
	}
	.neutral .th {
		background-color: $neutral;
	}
	.warning .th {
		background-color: $warning;
	}
	.normal .th {
		background-color: $normal;
	}
	.success .count {
		color: $tmsgreen;
	}
	.danger .count {
		color: $danger;
	}
	.neutral .count {
		color: $neutral;
	}
	.warning .count {
		color: $warning;
	}
	.normal .count {
		color: $normal;
	}
	padding: 20px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	.request-container-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 30px;
		gap: 50px;
	}

	#featured-file-form-wrapper {
		display: inline-block;
		width: 310px;
		margin-left: 10px;
		#featured {
			width: fit-content !important;
		}
		input,
		label {
			vertical-align: middle;
		}
		label {
			color: #444;
			cursor: pointer;
			&.disabled {
				color: #999;
			}
		}
	}
	#file-review {
		h2 {
			position: relative;
			color: $danger;
			font-size: 20px;
			span {
				position: absolute;
				top: -13px;
				left: -23px;
				display: block;
				@include spherize(25px, none);
				background-color: $danger;
				line-height: 23px;
				text-align: center;
				color: #fff;
				font-weight: bold;
				font-size: 18px;
			}
		}
		div {
			display: inline-block;
			width: 100px;
			vertical-align: top;
			&:nth-child(even) {
				margin-right: 20px;
			}
			img {
				width: 100px;
			}
			p {
				word-wrap: break-word;
			}
		}
	}
	.form-upload {
		label {
			vertical-align: top;
			display: inline-block;
			margin-right: 20px;
		}

		button {
			display: inline-block;
		}
	}

	input[type="text"] {
		padding: 8px 15px;
		font-size: 16px;
		width: 100%;
		border: 1px solid #d8d8d8;
	}
	textarea {
		width: 450px;
		height: 150px;
		font-size: 16px;
	}
	a.btn {
		//width: 200px;
		//color: #fff;
		//font-size: 18px;
		&.btn-small {
			width: 120px;
			font-size: 13px;
			padding: 5px;
		}
	}
	.update-request-button-wrapper {
		margin-bottom: 30px;
		height: 20px;
		a:first-of-type {
			display: block;
			position: absolute;
			right: 30px;
		}
	}
	#request-details-table {
		border-top: 1px solid #d8d8d8;
		border-bottom: 1px solid #d8d8d8;
		padding: 20px 0;
		margin-bottom: 30px;
		input[type="text"] {
			padding: 3px;
			font-size: 16px;
			width: 150px;
		}
		th {
			font-weight: 600;
			background-color: transparent;
			color: #387ab4;
		}
		tbody {
			tr {
				td {
					padding: 0 0 0 10px;
				}
			}
		}
	}
	#request-edit-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		&.active {
			margin-bottom: 35px;
		}
	}
	.btn.request-actions {
		width: fit-content;
		min-width: 120px;
		border: 1px solid $normal;
		padding: 8px 15px;
		background-color: transparent;
		cursor: pointer;
		font-family: "Source Sans Pro";
		font-size: 1em;
		text-transform: lowercase;
		&.edit-request {
			color: $normal;
			border: 1px solid $normal;
		}
		&.cancel-edit-request {
			color: $darkblue;
			border: 1px solid $darkblue;
		}
		&.update-request {
			color: white;
			background-color: $tmsgreen;
			border: 1px solid $tmsgreen;
		}
		&.delete-request {
			color: $danger;
			border: 1px solid $danger;
		}
		&.user-approval {
			color: white;
		}
	}
	h2,
	h3 {
		font-weight: normal;
	}
	h2 {
		font-size: 25px;
		font-weight: 600;
		color: $darkblue;
		font-family: "Bariol";
		text-transform: lowercase;
		margin-bottom: 10px;
	}
	.heading {
		margin: 0;
		text-align: center;
		width: fit-content;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.project-status {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		gap: 8px;
		.indicator {
			margin: 0;
		}
	}
	h3 {
		font-size: 28px;
		color: #888;
		font-weight: 300;

		text-align: center;
		position: relative;
		padding: 0;
		&:first-of-type {
			font-size: 36px;
			text-align: left;
			color: $tmsblue;
		}
		span {
			color: #888;
		}
	}
	.request-select-left {
		position: relative;
		flex: 1 0 65%;
		width: 65%;
		display: flex;
		flex-direction: column;
		gap: 30px;
		#desc-wrapper,
		#messages-wrapper {
			&.hidden {
				display: none;
			}
		}
		p {
			padding: 0px;
			max-width: 100%;
			word-wrap: break-word;
		}
		.admin-comment-box {
			margin-top: 30px;
			padding: 30px;
			border-radius: 20px;
			background-color: #f1f1f1;
			#messages {
				margin: 0;
				#request-message-box {
					background-color: white;
				}
				.message-bubble-wrapper {
					.message-bubble {
						background: #f3f7fb;
					}
					&.left {
						.message-bubble {
							&::after {
								border-right-color: #f3f7fb;
							}
						}
					}
					&.right {
						.message-bubble {
							&::after {
								border-left-color: #f3f7fb;
							}
						}
					}
				}
			}
		}
		#library-filters {
			margin: 0;
			justify-content: flex-start;
			gap: 0;
			margin-bottom: 30px;
			.tab1 {
				border: none;
				border-bottom: 1px solid #c4c4c4;
				border-radius: 0;
				align-items: center;
				background-color: transparent !important;
				align-items: flex-end;
				width: fit-content;
				max-width: fit-content !important;
				a {
					font-size: 1em;
					font-weight: bold;
					color: #c4c4c4;
					font-family: "Source Sans Pro";
					text-transform: uppercase;
				}
				svg {
					color: #c4c4c4;
					margin: 0 !important;
				}
				&.active {
					border-bottom: 2px solid $darkblue;
					a {
						color: $darkblue;
					}
					svg {
						color: $darkblue;
					}
				}
			}
		}
	}
	.request-select-right {
		flex: 0 0 30%;
		vertical-align: top;
		display: flex;
		flex-direction: column;
		gap: 25px;
		h3 {
			a.btn {
				position: absolute;
				top: 25px;
				right: 10px;
			}
		}
		select {
			padding: 7px 3px;
		}
		.status-priority-wrapper {
			display: flex;
			justify-content: space-between;
			gap: 40px;
			flex-wrap: wrap;
			.heading {
				position: relative;
				left: auto;
				transform: none;
			}
			.project-status {
				flex: 0 0 fit-content;
			}
			select {
				padding: 3px 5px 3px 2px;
			}
		}
		.edit-request-details {
			display: flex;
			flex-direction: column;
			gap: 20px;
			flex-wrap: wrap;
			border: 1px solid #ededed;
			padding: 20px;
			#time-worked {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 5px;
				.input-wrapper {
					display: flex;
					align-items: center;
					gap: 5px;
				}
				&.attention {
					border-color: pink;
				}
				h4 {
					font-weight: normal;
				}
				input,
				select {
					width: min(75px, 100%);
					color: #333;
					padding: 8px 5px;
					border: 1px solid #ddd;
				}
				label {
					color: #b1b1b1;
					&:first-of-type {
						margin-right: 5px;
					}
				}
				.lead-user-wrapper {
					display: flex;
					gap: 7px;
					align-items: center;
					position: relative;
					svg {
						width: 24px;
						height: 24px;
						color: #c4c4c4;
						cursor: pointer;
					}
				}
				.lead-users-popup {
					position: absolute;
					top: 40px;
					box-shadow: 1px 1px 4px #d3d3d3;
					width: fit-content;
					max-width: 100%;
					min-width: 300px;
					padding: 20px;
					z-index: 999;
					background-color: white;
					border-radius: 10px;
					height: fit-content;
					max-height: 300px;
					.user-list {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						margin-bottom: 30px;
					}
					.btn {
						padding: 8px 12px;
						width: fit-content;
						min-width: 150px;
						margin: 0 auto;
					}
					.container {
						display: block;
						position: relative;
						line-height: 1.4em;
						padding-left: 35px;
						margin-bottom: 8px;
						margin-top: 3px;
						cursor: pointer;
						font-size: 16px;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
						color: #737272;
						flex: 0 0 160px;
						&:last-of-type {
							margin-bottom: 0;
						}
					}

					/* Hide the browser's default checkbox */
					.container input {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 0;
						width: 0;
					}

					/* Create a custom checkbox */
					.checkmark {
						position: absolute;
						top: 0;
						left: 0;
						height: 22px;
						width: 22px;
						background-color: #eee;
						border-radius: 50%;
					}

					/* On mouse-over, add a grey background color */
					.container:hover input ~ .checkmark {
						background-color: #ccc;
						margin-top: 2px;
					}

					/* When the checkbox is checked, add a blue background */
					.container input:checked ~ .checkmark {
						background-color: $darkblue;
					}

					/* Create the checkmark/indicator (hidden when not checked) */
					.checkmark:after {
						content: "";
						position: absolute;
						display: none;
					}

					/* Show the checkmark when checked */
					.container input:checked ~ .checkmark:after {
						display: block;
					}

					/* Style the checkmark/indicator */
					.container .checkmark:after {
						left: 8px;
						top: 3px;
						width: 6px;
						height: 14px;
						border: solid white;
						border-width: 0 2px 2px 0;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	.request-select-module {
		position: relative;
		border-top: 1px solid #ccc;
		padding-top: 20px;
		margin-top: 20px;
		&.update-request-button-wrapper {
			min-height: 65px;
		}
	}

	.specs {
		display: flex;
		flex-wrap: wrap;
		background-color: white;
		max-width: 100%;
		justify-content: space-between;
		gap: 10px;
		#spec-item {
			flex: 0 0 calc(50% - 5px);
			border: 1px solid #c4c4c4;
			border-radius: 20px;
			padding: 7px 16px;
			font-size: 15px;
			text-align: center;
			vertical-align: middle;
			overflow-wrap: break-word;
			height: fit-content;
			input {
				width: 160px;
				border: none;
				border-bottom: 0.1px solid black;
			}
			&.double {
				width: 1 0 100%;
			}
			&.hidden {
				display: none;
			}
		}
		th {
			background-color: transparent;
			color: #555;
		}
		.tracking-info-wrapper {
			display: flex;
			flex-direction: column;
			gap: 15px;
			width: 100%;
			border: 1px solid #c4c4c4;
			border-radius: 20px;
			padding: 15px;
			font-family: "Bariol";
			.header {
				margin: 0;
				padding: 0;
				font-weight: bold;
				font-family: "Bariol";
				font-size: 1.2em;
			}
			.tracking-body {
				display: flex;
				flex-direction: column;
				gap: 20px;
				.tracking-info-container {
					display: flex;
					gap: 10px;
					align-items: center;
					.fields-wrapper {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 5px;
						p {
							font-weight: bold;
							font-size: 1.1em;
							font-family: "Source Sans Pro";
						}
						.label-value-div {
							display: flex;
							align-items: center;
							gap: 5px;
						}
					}
					input {
						border: none;
						border-bottom: 0.1px solid black;
						outline: none;
						flex: 1;
					}
					svg {
						cursor: pointer;
						width: 18px;
						height: 18px;
					}
				}
			}
			.tracking-footer {
				.add-div {
					background-color: $tmsgreen;
					width: fit-content;
					padding: 5px 20px;
					border-radius: 20px;
					color: white;
					cursor: pointer;
					font-size: 0.8em;
				}
			}
		}
		.sigle-line-div {
			display: flex;
			width: 100%;
			.add-tracking-info-div {
				background-color: $tmsgreen;
				width: fit-content;
				padding: 5px 20px;
				border-radius: 20px;
				color: white;
				cursor: pointer;
				font-size: 0.8em;
			}
		}
	}

	.project-team {
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 15px;
		h2 {
			margin: 0;
		}
		.header-assign-member {
			display: flex;
			justify-content: space-between;
			div {
				margin: 10px 0;
			}
		}
		#team-members-wrapper {
			display: none;
			text-align: center;
			margin-bottom: 0px;
			select {
				margin: 10px 0 20px;
				width: 80%;
			}

			.select-search {
				margin: 0;
			}
			&:has(.select-search) {
				display: block;
			}
		}
		.close {
			height: 20px;
			width: 20px;
			border-radius: 5px;
			cursor: pointer;
		}
		.project-owner,
		.team-member {
			position: relative;
			border-radius: 15px;
			padding: 8px 15px;
			background-color: #eee;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 15px;
			&:nth-of-type(2n + 1) {
				background-color: #fff;
			}
			p {
				font-size: 18px;
				font-family: "Bariol";
				color: $tmsblue;
				font-weight: 600;
				&:last-child {
					text-transform: uppercase;
					color: black;
					font-family: "Source Sans Pro";
					font-size: 12px;
					font-weight: 600;
				}
			}
			img {
				@include spherize(50px);
				vertical-align: top;
			}
			span {
				flex: 1;
			}
			.file-upload {
				width: fit-content;
				padding: 10px 20px;
				font-size: 0.9em;
				margin: auto 0;
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		.assign-team-member-button {
		}
	}
	.table {
		.table-row {
			.table-cell {
				padding-top: 3px;
				font-size: 18px;
			}
			.th {
				font-weight: bold;
				width: 130px;
				font-size: 15px;
				padding: 5px 0 0 10px;
			}
		}
	}
	#request-details-table {
		tr {
			background-color: transparent;
		}
		.table-row {
			.table-cell {
				padding-right: 25px;
			}
			.th {
				width: auto;
				padding-right: 0;
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	#request-select-wrapper {
		width: 100%;
		padding-bottom: 0;
		.request-select-left {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
		.request-select-right {
			width: 100%;
			padding: 0;
		}
		.specs {
			width: 75%;
		}
		.request-container-wrapper {
			flex-direction: column;
		}
	}
}

@media screen and (max-width: $tabletBreak) {
	#request-select-wrapper {
		.specs {
			width: 100%;
			#spec-item {
				font-size: 17px;
			}
		}
	}
}

#single-project-wrapper {
	width: 100%;
	margin: 0 auto;
	@media screen and (max-width: $tabletBreak) {
		padding: 0;
	}
}

#filters-wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
	align-items: flex-end;
	.filter {
		flex: 0 0 fit-content;
		margin: 0;
		display: flex;
		gap: 20px;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		.form-item {
			display: flex;
			flex-direction: column;
			gap: 5px;
			label {
				text-align: center;
			}
		}
		&.search {
			flex: 0 1 450px;
		}
		label {
			text-align: left;
		}
		input {
			width: 100% !important;
		}
	}
	.form-item {
		flex: 1;
		margin: 0;
	}
	.date-range-wrapper {
		display: flex;
		justify-content: space-between;
		.form-item {
			margin: 0;
		}
	}
	.filter-select {
		width: fit-content !important;
	}
	#nameDIV {
		text-align: center;
	}
	#datesDIV {
		text-align: center;
	}
}
