#image-wrapper {
    position: relative;
    #company-logo {
        width: auto;
        height: auto;
        max-width: min(100%, 180px);
        max-height: 180px;
        margin: auto;
    }
}

.enrolled-services {
    margin-top: 30px !important;
    width: fit-content !important;
    max-width: 90%;
    #search {
        position: relative;
        input {
            border-radius: 20px;
            width: 100% !important;
            max-width: 300px;
            padding: 8px 35px 8px 10px !important;
            height: 35px;
            color: #555;
            border: 1px solid #c4c4c4;
            &::placeholder {
                color: #555;
                opacity: 0.5;
            }
        }
        svg {
            position: absolute;
            right: 0px;
            height: 17px;
            top: 9px;
        }
    }
    #index {
        display: flex;
        justify-content: space-between;
        right: 0;
        margin: 10px 0 15px;
        margin-right: 0;
        width: 433.25px;
        margin-left: calc(100% - 433.25px);
        .container {
            width: 20px;
            height: 20px;
        }
        .checkmark {
            margin-top: -8px;
            margin-left: 7px;
            height: 14px;
            width: 6px;
        }
        #close {
            &:before {
                font-size: 16px;
                margin-top: 0px;
                margin-left: 6px;
            }
        }
        div {
            display: flex;
            span {
                font-size: 15px;
                &:nth-of-type(2) {
                    height: fit-content;
                    margin: auto 0px auto 5px;
                }
            }
        }
    }
    table {
        thead {
            #users-head {
                height: fit-content;
                th {
                    background: $darkblue;
                    // width: 230px !important;
                    padding: 10px 25px;
                    max-width: 230px !important;
                    font-family: "Bariol Bold";
                    text-align: center;
                    position: relative;
                    margin: 0 10px;
                    &.active {
                        .up {
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg) translate(-3px, -2px);
                        }
                    }
                    &:first-of-type {
                        background: transparent;
                        width: 180px !important;
                        margin: 0 auto;
                        padding: 0 10px;
                    }
                }
                .arrow {
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    position: initial;
                    padding: 4px;
                    margin-left: 9px;
                    margin-top: 10px;
                }

                .right {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }

                .left {
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }

                .up {
                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg) translate(-3px, -2px);
                }

                .down {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 35px;
                    width: initial !important;
                    position: relative;
                }
            }
        }
    }
    .container {
        width: 27px;
        height: 27px;
        position: relative;
        display: block;
        margin: auto;
        border-radius: 50%;
        &.enrolled {
            background: #74b368;
        }
        &.just-enrolled {
            background: #2a60a5;
        }
        &.prev-enrolled {
            background: rgb(150, 150, 150);
        }
    }
    .checkmark {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        margin-left: 10px;
        transform: rotate(45deg);
        height: 17px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        width: 7px;
    }
    #close {
        height: 20px;
        width: 20px;
        border-radius: 5px;
        // cursor: pointer;
        &:before {
            position: absolute;
            content: "X";
            font-size: 22px;
            color: white;
            font-family: Bariol;
            margin-top: -1px;
            margin-left: 8px;
            font-weight: bold;
            top: 0px;
        }
    }
}

#photos-wrapper {
    width: 100%;
    max-width: $contentMaxWidth;
    margin: 0px auto;
    padding-top: 30px;
    h3 {
        text-align: center;
        font-size: 2.4em;
        font-family: "Bariol Light";
        color: $darkblue;
        margin-bottom: 35px;
    }
}

#photos-container {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: $contentMaxWidth;
    justify-content: space-between;
    gap: 30px;
    #sub-header {
        display: none;
    }
    #profile-photo-container,
    #company-photo-container {
        flex: 1;
        text-align: center;
        margin: 0;
        flex-direction: column;
        #photos-top-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 20px;
            .photo-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                #profile-photo-large {
                    width: 180px;
                    aspect-ratio: 1 / 1;
                    height: auto;
                    max-width: 100%;
                    max-height: max-content;
                }
            }
            .btn-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
        }
        .disclaimer {
            p {
                font-size: 1.05em;
            }
        }
    }
    #company-photo-container {
        padding-left: 30px;
        border-left: 1px solid #c5c5c5;
    }
}

@media screen and (max-width: 900px) {
    #photos-wrapper {
        width: 90%;
        min-width: initial;
    }
    #photos-container {
        width: 100%;
        flex-direction: column;
        min-width: initial;
        #profile-photo-container,
        #company-photo-container {
            border: none;
            padding: 0 20px;
        }
    }
}

.user-credit-form {
    border: none !important;
}

#edit-user {
    width: 100%;
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
    #photos-container {
        flex: 0 0 min(25%, 450px);
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin: 0;
        #profile-photo-container,
        #company-photo-container {
            border: none;
            padding: 0;
        }
        @media screen and (max-width: $mobileLargeBreak) {
            flex: 0 0 100%;
        }
    }
    .profile-container {
        flex: 1;
    }
}

#profile-form {
    width: 100%;
    max-width: $contentMaxWidth;
    justify-content: flex-start;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    @media screen and (max-width: $tabletBreak) {
        gap: 20px;
    }
    #spotlight-user {
        margin: 20px 0;
        h3 {
            text-align: left;
            font-size: 1.4em;
        }
        #spotlight-container {
            display: flex;
            justify-content: space-between;
            .form-item {
                margin: 0;
            }
            input[type="checkbox"] {
                width: fit-content !important;
                margin-right: 10px;
                min-width: fit-content;
            }
        }
    }
    #branch-company {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        .form-item {
            flex: 0 0 calc(50% - 20px);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin: 0;
            input {
                width: 100%;
                border: 1px solid #c5c5c5;
                background-color: #f5f7fa;
                padding: 8px 15px;
                border-radius: 20px;
            }
            &.filter-select {
                flex: 1 0 100%;
                .filter-select {
                    cursor: pointer;
                    * {
                        cursor: pointer;
                    }

                    .react-select__value-container {
                        padding: 0.5em 0.75em;
                    }
                    .react-select__multi-value {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .user-registration-wrapper {
        .col {
            flex: 1;
            display: flex;
            margin: 20px 0;
            gap: 20px;
            .btn {
                margin: 0;
            }
        }
    }

    span {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 20px;
    }

    .form-btn-wrapper {
        width: min(90%, 225px);
        .btn {
            width: 100%;
        }
    }
}

#licensed-states-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .btn {
        margin: 0;
        height: 40px;
        padding: 4px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 200px;
    }

    #licensed-states-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        .info-item {
            display: flex;
            padding: 4px 10px;
            flex: 0 0 120px;
            border: 1px solid $darkblue;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            height: 40px;
            position: relative;
            margin: 0;
            #close {
                cursor: pointer;
            }
            #close:after {
                position: absolute;
                content: "\d7";
                font-size: 24px;
                color: black;
                font-weight: bold;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
            }
        }
        .form-item {
            flex: 1 0 100%;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }
    }
}

#social-accounts-container {
    display: flex;
    gap: 1em;
    flex-direction: column;
    margin: 0;
    border: 1px solid #b1b1b1;
    padding: 20px;
    border-radius: 25px;
    p {
        line-height: normal;
    }
    .description-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2em;
        row-gap: 1em;
        .col {
            flex: 1 0 450px;
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            .row-md-2 {
                display: flex;
                align-items: center;
                gap: 0.5em;
                justify-content: flex-start;
                p {
                }
                * {
                    text-align: left;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }
    .subheading {
        font-family: "Source Sans Pro";
        font-weight: normal;
        text-align: left;
        color: $darkblue;
        margin: 0;
        font-size: 1em;
    }
    .social-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin: 20px 0;
        flex-wrap: wrap;
        #social-authorize-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex: 1 0 350px;
            align-items: center;
        }
        .btn {
            width: fit-content;
            margin: 0;
            text-decoration: none;
            display: flex;
            font-size: 1.1em;
            align-items: center;
            gap: 0.5em;
            svg {
                flex: 0 0 25px;
                width: 25px;
                height: 25px;
                display: block;
            }
            span {
                width: 100%;
                max-width: auto;
            }
        }
    }
    .social-banner {
        width: fit-content;
        max-width: 100%;
        background-color: rgba($color: $tmsgreen, $alpha: 0.7);
        padding: 8px 15px;
        margin: 0 auto;
        p {
            text-align: center;
            font-size: 1.1em;
            color: white;
        }
        &.error {
            background-color: $danger;
            margin: 0 auto;
        }
    }
}

#users-table,
#branch-table,
#pages-table,
#partners-table {
    width: 100%;
    margin: 0;
    display: flex;
    gap: 20px;
    flex-direction: column;
    max-width: 100%;
    .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        * {
            margin: 0;
        }
        h2 {
        }
        .btn {
            margin: 0;
        }
    }
    &.companies {
        td {
            width: fit-content !important;
            min-width: 150px;
        }
    }
    h2 {
        margin: 20px auto;
        width: fit-content;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th {
        text-align: left;
        background: $darkblue;
        padding: 0.75em 1.5em;
        color: white;
        &:first-of-type {
            border-top-left-radius: 20px;
        }
        &:last-of-type {
            border-top-right-radius: 20px;
        }
    }

    td {
        width: max-content;
        min-width: 150px;
        text-align: left;
        padding: 0.75em 1.5em;
        margin: 0;
        &:first-of-type {
            min-width: 300px;
        }

        a {
            &:has(svg) {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $danger;
            }
            svg {
                width: 22px;
                height: auto;
            }
        }
    }
    #users-head {
        background: transparent;
        th {
            cursor: pointer;
        }
    }
    tr {
        border-bottom: 1px solid lightgray;
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }
    tr:nth-child(even) {
        background-color: #fafafa;
        td {
            color: black;
        }
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }

    .btn {
        width: fit-content;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    #users-table,
    #branch-table {
        width: calc(100% - 40px);
        margin: 0 20px;
        overflow: auto;
        table {
            margin-bottom: 10px;
        }
    }
}

#partner-photos {
    display: flex;
    width: 60%;
    margin: 20px auto 10px;
    justify-content: space-between;
}

@media screen and (max-width: 800px) {
    #partner-photos {
        flex-direction: column;
    }
}

#user-partners {
    margin: 0 auto;
    width: 100%;
    #users-table {
        width: 100%;
        margin: 0;
        table {
            width: 100%;
        }
    }
}

#create-partner,
#create-user {
    .form-wrapper {
        flex-direction: column;
        width: 100%;
        max-width: $contentMaxWidth;
        margin: 0 auto;
        gap: 2em;
        h3 {
            font-family: "Bariol";
            font-size: 1.25em;
            text-align: center;
            color: $tmsblue;
        }
    }
    .form-container {
        width: 100%;
        max-width: $contentMaxWidth;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        gap: 1em;
        .input-error {
            // text-align: center;
            // padding: 3px 0;
        }
        .uploads {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid #c4c4c4;
            border-bottom: 1px solid #c4c4c4;
            padding: 35px 0px;
            gap: 30px;
            flex-wrap: wrap;
            .form-item {
                width: fit-content;
                text-transform: lowercase;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin: 0;
                #image-preview {
                    background: #eeeeee;
                    width: fit-content;
                    min-width: 300px;
                    max-width: 100%;
                    height: fit-content;
                    padding: 6px 8px;
                    border-radius: 10px;
                    display: flex;
                    position: relative;
                    justify-content: flex-start;
                    div {
                        &:first-of-type {
                            width: 70px;
                            max-width: 30%;
                            max-height: 70px;
                            img {
                                height: 100%;
                            }
                        }
                    }
                    p {
                        font-size: 1em;
                        overflow-wrap: break-word;
                    }
                    span {
                        padding: 0 !important;
                    }
                    .close {
                        height: 20px;
                        width: 20px;
                        border-radius: 5px;
                    }
                }
                label {
                    text-align: center;
                    color: $darkblue;
                    font-weight: bold;
                    padding: 5px;
                    font-family: "Bariol";
                    font-size: 1.15em;
                }
                .upload-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 5px auto;
                    label {
                        text-transform: none;
                    }
                    span {
                        display: none;
                    }
                    .file-upload {
                        width: 290px;
                        border-radius: 40px;
                        background: $tmsblue;
                        border: none;
                        padding: 15px 0;
                        color: white;
                        font-family: "Source Sans Pro";
                        margin: 0;
                    }
                }
            }
            @media screen and (max-width: $mobileLargeBreak) {
                flex-direction: column;
            }
        }
        span {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            gap: 2em;
            width: 100%;
            padding: 0;
        }
        .form-item {
            margin: 0;
            width: fit-content;
            label {
            }
            .checkBox {
                text-align: left !important;
                // width: 200px;
            }
            input {
                width: 325px !important;
                max-width: 100% !important;
                padding: 8px 15px;
                border-radius: 20px;
                font-size: 1em;
                border: 1px solid #c4c4c4;
                &:focus {
                    outline: none;
                }
            }
            input[type="checkbox"] {
                width: fit-content !important;
                margin-right: 5px !important;
                min-width: fit-content;
            }
        }
        .col-left,
        .col-right {
            width: fit-content;
        }
        .submit-button {
            display: flex;
            justify-content: center;
            .btn {
                border-radius: 40px;
                padding: 8px 15px;
                background: $darkblue;
                font-family: "Bariol";
                font-size: 1.2em;
                width: fit-content;
                min-width: 155px;
                max-width: 100%;
            }
        }
    }

    .salesforce-records-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1em;

        .grid-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
            gap: 2em;

            .card {
                box-shadow: 1px 1px 10px #d3d3d3;
                border-radius: 1em;
                padding: 1em;
                display: flex;
                flex-direction: column;
                gap: 0.25em;
                align-items: center;
                justify-content: center;
                figure {
                    width: min(100%, 150px);
                    border-radius: 50%;
                    height: auto;
                    aspect-ratio: 1/1;
                    position: relative;
                    background-color: $defaultNeutralLight;
                    overflow: hidden;
                    img {
                        position: absolute;
                        max-width: 100%;
                        max-height: 100%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                h4 {
                    font-size: 1.05rem;
                }
            }
        }
    }
}

#create-user {
    .form-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1050px;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: row;
        .form-item {
            flex: 0 0 min(100%, 400px);
            margin: 0;
            label {
                padding: 0;
                .required-label {
                    width: fit-content;
                    display: block;
                }
            }
            input,
            select {
                width: 100% !important;
                max-width: 100% !important;
            }
        }
        #user-permissions {
            flex: 0 0 100%;
            display: flex;
            flex-direction: row;
            gap: 15px;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            h2,
            .input-error {
                text-align: center;
                flex: 0 0 100%;
            }
            .checkbox-container {
                flex: 0 0 min(100%, 300px);
                margin: 0;
            }
        }
        .btn {
            margin: 0 auto;
            width: fit-content;
            min-width: 185px;
            max-width: 100%;
        }
    }

    .error-wrapper {
        width: 100%;
        margin: 20px auto;
        max-width: 1050px;
        gap: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .error {
            margin: 0;
            flex: 0 0 fit-content;
        }
    }
}

@media screen and (max-width: 600px) {
    #create-partner {
        .form-container {
            width: 100%;
            span {
                flex-direction: column;
                width: fit-content;
                margin: auto;
                .col-left,
                .col-right {
                    .form-item {
                        margin-bottom: 0;
                    }
                }
            }
            .uploads {
                flex-direction: column;
                width: fit-content;
                margin: auto;
                margin: 40px auto;
                padding-bottom: 0;
                padding-top: 20px;
                .form-item {
                    margin-bottom: 20px;
                    margin-top: 20px;
                    #image-preview {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.send-invite-wrapper {
    width: 100%;
    margin: 0;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h3 {
        font-family: "Bariol";
        font-size: 1.6em;
        text-align: center;
        color: $tmsblue;
    }
    .authorize-banner {
        color: white;
        background-color: $danger;
        padding: 7px 10px;
        color: white;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        gap: 5px;
        width: fit-content;
        svg {
            margin: auto 5px auto 0;
            display: block;
            width: 18px;
            height: 18px;
        }
        a {
            color: white;
            text-decoration: underline;
            cursor: pointer;
        }
        &.success {
            width: fit-content;
            min-width: 400px;
            max-width: 100%;
            margin: 0px auto;
            background-color: $tmsgreen;
            font-size: 1.1em;
        }
    }
    .form-container {
        span {
            justify-content: center !important;
            gap: 1em;
            align-items: center;
            max-width: 100%;
            @media screen and (max-width: $tabletBreak) {
                flex-direction: column;
                gap: 0.75em !important;
            }
        }
        .form-item {
            margin: 0 !important;
            width: fit-content;
            max-width: 100%;
        }
        small {
            color: #c5c5c5;
        }
    }

    .email-invite-banner {
        padding-top: 2em;
        text-align: center;
        font-size: 1.1rem;
    }
    .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        flex-wrap: wrap;
        .btn {
            margin: 0 !important;
        }
    }
}

SelectInput,
.services {
    font-size: 1em;
    padding: 10px 8px !important;
    margin-bottom: 15px;
    select {
        width: 270px !important;
    }
}

#users-nav {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0;
    #users-nav-items {
        display: flex;
        width: fit-content;
        border: 1px solid #c4c4c4;
        padding: 5px 20px;
        border-radius: 20px;
        justify-content: space-between;
        a,
        a:visited {
            color: #c4c4c4;
            margin-left: 7px;
            text-decoration: none;
            font-size: 0.9em;
        }
        svg {
            color: #c4c4c4;
        }
        &.active {
            border: 1px solid #2bb579;
            svg,
            a {
                color: #2bb579;
            }
        }
    }
}

#facebook-picker-modal {
    display: block;
    position: fixed;
    width: 100vw;
    left: 0;
    height: 100svh;
    background: rgba(0, 0, 0, 0.55);
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .facebook-picker-wrapper {
        position: relative;
        background-color: white;
        width: min(90%, $contentMaxWidth);
        height: min(600px, 90svh);
        padding: 1.5em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;
        h2 {
            font-family: "Bariol Bold";
            color: $darkblue;
            font-size: 2em;
            text-align: center;
            border: none !important;
        }
        h4 {
            font-family: "Bariol";
            color: $tmsblue;
            font-size: 1.2em;
            text-align: center;
            border: none !important;
        }
        .facebook-disclaimer {
            font-size: 1em;
        }
        .no-content {
            width: fit-content;
            height: fit-content;
            margin: 0 auto;
            h3 {
                text-align: center;
                font-size: 1.2em;
                font-family: "Bariol";
            }
        }
        .pages-list-container {
            flex: 1;
            width: 100%;
            display: flex;
            overflow-y: auto;

            .pages-list {
                margin: 0;
                height: fit-content;
                width: 100%;

                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
                gap: 2em;

                .page-card {
                    flex: 1 1 calc(50% - 20px);
                    background-color: $defaultNeutralLight;
                    padding: 1em;
                    border-radius: 1em;

                    display: flex;
                    gap: 1em;
                    align-items: center;
                    .checkbox-container {
                        flex: 0 0 fit-content;
                        margin: 0;
                    }
                    .page-info {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25em;
                        align-items: flex-start;
                        * {
                            text-align: left;
                            line-height: normal;
                            margin: 0;
                            padding: 0;
                        }
                        h3 {
                            color: $tmsblue;
                            font-family: "Bariol";
                            font-size: 1.1em;
                        }
                        p {
                            display: flex;
                            align-items: center;
                            gap: 0.15em;
                        }
                    }
                }
            }
        }

        .btn-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            .btn {
                width: fit-content;
                min-width: 150px;
            }
        }
        #close {
            height: 20px;
            width: 20px;
            border-radius: 5px;
        }
        #close:after {
            position: absolute;
            content: "\d7";
            font-size: 40px;
            color: #c4c4c4;
            font-weight: bold;
            cursor: pointer;
            top: 15px;
            right: 25px;
        }
        .error {
            font-size: 1.2em;
            margin-top: 10px;
            border: none;
        }
    }
    @media screen and (max-width: $tabletBreak) {
        .facebook-picker-wrapper {
            position: absolute;
            top: 100px;
            width: 100%;
            max-width: 90vw;
            .pages-list {
            }
        }
    }

    @media screen and (max-width: $mobileLargeBreak) {
        .facebook-picker-wrapper {
            .pages-list {
                flex-direction: column;
            }
        }
    }
}

.fc-icon-chevron-left {
    position: relative;
    &::before {
        content: "\2039" !important;
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -75%);
        font-size: 2em;
        width: 100%;
        height: 100%;
    }
}

.fc-icon-chevron-right {
    position: relative;
    &::before {
        content: "\203A" !important;
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -75%);
        font-size: 2em;
        width: 100%;
        height: 100%;
    }
}

.fc-icon-x {
    position: relative;
    &::before {
        content: "\2715" !important;
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 10%);
        font-size: 0.9em;
        width: 100%;
        height: 100%;
        color: black;
    }
}

.user-filters {
    display: flex;
    width: 100%;
    max-width: $contentMaxWidth;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: space-between;
    .col {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1em;
    }
    .Select {
        width: 100%;
        max-width: 275px;
    }
    #search {
        width: fit-content;
        position: relative;
        text-align: left;
        .form-item {
            width: fit-content;
            position: relative;
            box-sizing: border-box;
        }
        input {
            border-radius: 5px;
            width: 450px !important;
            max-width: 100%;
            padding: 5px 35px 5px 8px !important;
            height: 35px;
            color: #555;
            border: 1px solid #c4c4c4;
            &::placeholder {
                color: #555;
                opacity: 0.5;
            }
        }
        svg {
            position: absolute;
            right: 7px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }
    }
}

.profile-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .header {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        color: $darkblue;
        h2 {
            font-family: "Bariol";
            font-size: 1.4em;
            font-weight: bold;
        }

        h4 {
            font-family: "Bariol";
            font-size: 1em;
        }
    }
    .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1em;
        .form-item {
            flex: 0 0 calc(50% - 20px);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin: 0;
            label {
                text-align: left;
                color: $darkblue;
                text-transform: lowercase;
            }
            input,
            select {
                width: 100%;
                border: 1px solid $defaultNeutral;
                background-color: $defaultNeutralLight;
                padding: 8px 15px;
                border-radius: 20px;
            }
            .checkbox-container {
                margin: 0;
            }
            &.user-permissions {
                gap: 15px;
            }
            &.user-tiers {
                gap: 15px;
                label {
                    text-transform: capitalize;
                }
            }
        }
        @media screen and (max-width: $mobileLargeBreak) {
            .form-item {
                flex: 0 0 100%;
                input {
                    width: 100% !important;
                }
            }
        }
    }
    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    &#assign-partner {
        .form {
            align-items: flex-end;
            justify-content: flex-start;
            .form-item {
                flex: 1;
            }
            .btn-container {
                flex: 0 0 min(20%, 300px);
                .btn {
                    width: 100%;
                }
            }
        }
    }
}

.profile-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .profile-row {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
        .preview-box {
            flex: 0 0 min(25%, 400px);
            border: 1px solid $defaultNeutral;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            #profile-photo-large {
                width: 200px;
                height: 200px;
                margin: 0;
            }
            #profile-data {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 5px;
                * {
                    line-height: normal;
                }
                .profile-name {
                    text-align: center;
                    font-family: "Bariol";
                    width: 100%;
                    font-size: 1.45em;
                    font-weight: bold;
                    color: $darkblue;
                }
                .profile-data-row {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    text-align: center;
                    width: 100%;
                    .profile-points {
                        width: 100%;
                        span {
                            font-size: 0.6125rem;
                        }
                    }
                }
            }
            #social-icons {
                display: flex;
                justify-content: center;
                gap: 5px;
                img {
                    cursor: pointer;
                }
            }
            #profile-edit-btn {
                display: flex;
                justify-content: center;
                padding: 7px 30px;
                font-family: "Bariol";
                font-weight: bold;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 1em;
            }
            @media screen and (max-width: $mobileLargeBreak) {
                flex: 0 0 100%;
            }
        }
        .edit-box {
            flex: 1;
            border: 1px solid #b1b1b1;
            border-radius: 25px;
            .edit-container {
                display: flex;
                height: 100%;
                padding: 20px;
                column-gap: 40px;
                row-gap: 20px;
                flex-wrap: wrap;
                @media screen and (max-width: $tabletBreak) {
                    flex-direction: column;
                }

                .header {
                    display: flex;
                    border-bottom: 1px solid $darkblue;
                    padding-bottom: 8px;
                    h2 {
                        font-family: "Bariol";
                        font-size: 1.4em;
                        font-weight: bold;
                        color: $darkblue;
                    }
                }
                .edit-forms {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    align-items: center;
                    justify-content: flex-start;

                    .edit-profile {
                        .edit-profile-title {
                            font-family: "Bariol";
                            font-size: 1.45em;
                            font-weight: bold;
                            color: $darkblue;
                        }
                    }
                }
                .edit-photos {
                    flex: 0 0 min(450px, 40%);
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    .disclaimer {
                        font-size: 1em;
                        color: $defaultNeutral;
                    }
                }
            }
        }
        .unclickable {
            opacity: 0.3;
            pointer-events: none;
            position: relative;
        }
        .unclickable:before {
            content: "";
            position: absolute;
            top: 0;
            border-radius: 25px;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            z-index: 1;
            pointer-events: none;
        }
    }
}

#profile-photo-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .header {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        color: $darkblue;
        h2 {
            font-family: "Bariol";
            font-size: 1.4em;
            font-weight: bold;
        }

        h4 {
            font-family: "Bariol";
            font-size: 1em;
        }
    }
    #photos-top-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        .photo-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin: 0;
                max-width: 180px;
                max-height: 180px;
            }
        }

        .btn-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            * {
                font-size: 1em;
                font-weight: normal;
            }
            a {
                margin: 0;
            }
            .upload-container {
                margin: 0;
            }
        }
    }
    .sub-container {
        flex: 0 0 50%;
        #image-wrapper {
            #company-logo {
                display: block;
                position: static;
                width: 160px;
                height: 160px;
                margin: 0 auto;
                border-radius: 100%;
                border: 1px solid #ccc;
            }
        }
    }
    .button-stack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upload-container {
            margin: 0;
        }
        button,
        .file-upload {
            padding: 10px 30px;
            border-radius: 5px;
            font-weight: bold;
            color: white;
            margin-bottom: 10px;
            font-size: 12px;
            width: inherit;
            cursor: pointer;
        }
        .upload-button {
            background-color: $tmsblue;
        }
        .manage-button {
            background-color: $darkblue;
        }
    }
    #profile-photo-large {
        width: 160px;
        height: 160px;
    }
}

.photos-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .photos-page-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        #sub-header {
            margin: 0;
            width: fit-content;
        }
    }
}

#photos-top {
    text-align: center;
    margin: 0;
    .file-upload {
        margin: 0;
        width: 100%;
        max-width: 225px;
    }
    p {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-family: "Source Sans Light";
        &:first-of-type {
            font-size: 25px;
            font-weight: 600;
            margin-top: 5px;
            font-family: "Bariol";
            color: $tmsblue;
        }
    }
    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 20px 0;
        .btn,
        .upload-container {
            width: 100%;
            max-width: 225px;
            margin: 0;
        }
    }
    #photos-top-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

#user-photos {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

#photo-options {
    text-align: center;
    margin: 0;
    .dropdown-container,
    .btn {
        display: inline-block;
    }

    .btn {
        width: 100%;
        max-width: 225px;
        font-size: 1.1em;
    }

    .dropdown-container {
        .dd-text {
            background-color: $tmsgreen;
            color: white;
            padding: 10px 10px;
            text-align: center;
            font-size: 22px;
            margin-right: 20px;
        }
        .dd-opt {
            li {
                max-height: 50px;
            }
            span {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                vertical-align: middle;
                margin-top: -5px;
                fill: #666;
            }
        }
        ul {
            display: none;
        }
    }
}

.profile-form-container#user-settings {
    .form-group {
        display: flex;
        flex-direction: column;
        gap: 2em;
        .form {
            gap: 1em;
            & > h3 {
                flex: 1 1 100%;
            }
            .form-header {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 1em;
                .form-item {
                    flex: 0 0 fit-content;
                    &.switch-wrapper {
                        gap: 1em;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        label {
                            display: flex;
                            align-items: center;
                            gap: 0.5em;
                            text-transform: none;
                            width: fit-content;
                            img {
                                width: 20px;
                                height: auto;
                            }
                        }
                        input {
                            flex: 0 0 55px;
                            width: 55px;
                        }
                    }
                }
            }
            h3 {
                text-align: left;
                font-family: "Bariol";
                font-size: 1.15em;
                font-weight: bold;

                display: flex;
                align-items: center;
                gap: 0.25em;
                svg {
                    width: 22px;
                    height: auto;
                    cursor: pointer;
                }
            }
        }
    }
}

.user-tier-indicator {
    background-color: transparent;
    text-align: center;
    color: white;
    padding: 0.45em 1.25em;
    border-radius: 1em;
    cursor: pointer;
}

#manage-users {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
