.services-wrapper {
    width: 100%;
    margin-bottom: 30px;
    .signing-doc {
        width: 90vw;
        display: block;
        height: 800px;
        margin: auto;
    }
    .save-settings {
        display: block;
        width: 100%;
        max-width: 275px;
        margin: 25px auto;
    }
    #preview-modal {
        display: block;
        position: fixed;
        width: 100vw;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        top: 0;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100svh;
        .preview-summary-wrapper {
            position: relative;
            background-color: white;
            width: 1200px;
            max-width: 70vw;
            height: fit-content;
            max-height: 80svh;
            min-height: 400px;
            padding: 30px;
            border-radius: 20px;
            #summary-wrapper {
                display: flex;
                justify-content: space-between;
                height: 100%;
                @media screen and (max-width: $tabletBreak) {
                    flex-direction: column;
                    overflow-y: auto;
                    padding: 0 25px;
                }
            }
            @media screen and (max-width: $tabletBreak) {
                overflow: auto;
                height: fit-content;
            }
            #close {
                height: 15px;
                width: 15px;
                border-radius: 5px;
            }
            #close:after {
                position: absolute;
                content: "\d7";
                font-size: 31px;
                color: black;
                font-weight: bold;
                cursor: pointer;
                top: 8px;
                right: 17px;
            }
            .services-summary {
                width: calc(100% - 430px);
                padding: 0 15px 0 0;
                height: 100%;
                h1 {
                    margin: 10px auto;
                    color: $tmsblue;
                    font-family: "Bariol Bold";
                }
                h4 {
                    // color: $neutral;
                    font-family: "Source Sans Pro";
                }
                .services-container {
                    padding: 20px;
                    height: calc(100% - 100px);
                    overflow-y: auto;
                    @media screen and (max-width: $tabletBreak) {
                        overflow: none;
                        height: fit-content;
                        padding: 20px 0;
                    }
                    .service {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;
                        p {
                            width: max-content;
                            font-size: 1.05em;
                        }
                        h3 {
                            font-family: "Bariol";
                            font-size: 1.2em;
                            max-width: calc(100% - 90px);
                            text-align: left;
                        }
                        &.opt-out {
                            text-decoration: line-through;
                        }
                    }
                }
            }
            .services-pricing {
                margin-top: 40px;
                padding: 30px;
                width: 420px;
                height: fit-content;
                .btn {
                    margin-top: 35px;
                }
                #pricing-container {
                    margin: 0;
                    width: 100%;
                    .price {
                        width: 100%;
                    }
                    hr {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $tabletBreak) {
        #preview-modal {
            .preview-summary-wrapper {
                max-width: 80vw;
                overflow-y: hidden;
                padding: 30px 0 45px;
                .services-summary {
                    width: 100%;
                    padding: 0;
                }
                .services-pricing {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                    .btn {
                        margin-top: 35px;
                    }
                    #pricing-container {
                        margin: 0;
                        width: 100%;
                        .price {
                            width: 100%;
                        }
                        hr {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .header-wrapper {
        img {
            width: 100vw;
            position: relative;
            background-size: cover;
            text-align: center;
            margin-bottom: 45px;
        }
        #sub-header {
            font-size: 3.45em;
            color: $darkblue;
            text-transform: lowercase;
            font-family: "Source Sans Light";
        }
        #sub-header-2 {
            font-size: 1.4em;
            color: $darkblue;
            text-transform: lowercase;
            font-weight: bold;
            font-family: "Source Sans Pro";
        }
        #user-package-banner {
            width: fit-content;
            height: fit-content;
            max-width: 1150px;
            margin: 0 auto;
            padding: 20px;
            background-color: $tmsgreen;
            .package-banner {
                display: flex;
                flex-direction: row;
                margin: 0 auto;
                gap: 20px;
                padding: 0 20px;
                @media screen and (max-width: $mobileLargeBreak) {
                    padding: 0;
                }
            }
            h3 {
                font-size: 1.15em;
                color: white;
                letter-spacing: 0.5px;
                text-align: left;
                font-family: "Source Sans Light";
                margin: auto;
                max-width: 950px;
            }
            .btn {
                background-color: white;
                color: $tmsgreen;
                height: fit-content;
                min-width: fit-content;
                max-width: 225px;
                margin: auto 0;
                @media screen and (max-width: $tabletBreak) {
                    max-width: 175px;
                }
            }
        }
    }
    .services-content {
        width: 80vw;
        margin: auto;
        .services-table-wrapper {
            margin: 40px auto;
            width: 80vw;
            .col-md-6 {
                border: 1px solid #d6d6d6;
                padding: 25px 40px;
                border-radius: 10px;
                box-shadow: 0 4px 5px 0 #d6d6d6;
                border-top: none;
                box-sizing: border-box;
            }
            #category-header {
                padding: 10px 40px;
                background-color: $darkblue;
                box-shadow: none;
                border-radius: 0;
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
                h1 {
                    color: white;
                    font-family: "Bariol";
                    font-size: 1.45em;
                    text-transform: uppercase;
                    letter-spacing: 4px;
                }
            }
            .service {
                margin: 10px 0;
                .service-header {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    .service-header-wrapper {
                        display: flex;
                        width: 50%;
                        cursor: pointer;
                        h3 {
                            font-size: 1.65em;
                            font-family: "Bariol Bold";
                            color: $tmsblue;

                            width: fit-content;
                            max-width: 90%;
                            word-wrap: break-word;
                        }
                        svg {
                            margin: 0 18px;
                            color: #a8a8a8;
                            height: 30px;
                            width: 24px;
                        }
                    }
                    #cost {
                        color: #a8a8a8;
                        width: 30%;
                        font-size: 1em;
                        font-family: "Source Sans Light";
                        label {
                            display: none;
                        }
                        select {
                            max-width: 250px !important;
                            appearance: button-arrow-down;
                            user-select: auto;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -webkit-padding-start: 2px;
                            -moz-padding-start: 2px;
                            background-color: white;
                            border: 1px solid #aaa;
                            border-radius: 4px;
                            // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
                            // color: black;
                            font-size: inherit;
                            margin: 0;
                            overflow: hidden;
                            padding: 11px 50px 11px 15px !important;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            background-image: linear-gradient(45deg, transparent 50%, #a8a8a8 50%),
                                linear-gradient(135deg, #a8a8a8 50%, transparent 50%),
                                linear-gradient(to right, white, white);
                            background-position:
                                calc(100% - 20px) calc(1em + 4px),
                                calc(100% - 15px) calc(1em + 4px),
                                100% 0;
                            background-size:
                                5px 5px,
                                5px 5px,
                                2.5em 2.5em;
                            background-repeat: no-repeat;
                        }
                    }
                }
                .desc-wrapper {
                    #cost {
                        display: none;
                    }
                    .service-desc {
                        margin: 15px 0;
                        max-width: 70%;
                        // display: flex;
                        // flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 20px;
                        div {
                            margin: 20px 0;
                        }
                        :is(h1, h2, h3, h4, h5, h6) {
                            font-weight: bold !important;
                            font-size: 19px !important;
                            margin: 5px 0 !important;
                            font-family: "Source Sans Pro" !important;
                        }
                        p {
                            font-weight: normal !important;
                            font-size: 16px !important;
                        }
                        ul {
                            padding-left: 20px;
                            li {
                                margin: 0;
                                font-family: "Source Sans Pro";
                            }
                        }
                        iframe {
                            max-width: 100%;
                            max-height: 600px !important;
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    .services-pricing {
        width: 100%;
        background-color: #eff4fa;
        --gap: 60px;
        padding: 30px calc(60px + var(--gap));
        position: relative;
        display: flex;
        #pricing-container {
            margin-left: auto;
            order: 1;
            width: fit-content;
            hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid $secBlue;
                margin: 1em 0;
                padding: 0;
                width: calc(100% + var(--gap));
            }
            .price {
                display: flex;
                justify-content: space-between;
                width: fit-content;
                width: calc(100% + var(--gap));
                &.total {
                    h4,
                    p {
                        color: $tmsblue;
                    }
                }
                h4 {
                    font-family: "Bariol";
                    font-size: 1.1em;
                    height: fit-content;
                    margin: auto 0;
                    color: $darkblue;
                }
                p {
                    font-size: 1.5em;
                    font-family: "Source Sans Light";
                    color: $darkblue;
                }
                span {
                    display: flex;
                    svg {
                        color: $secBlue;
                        width: 26px;
                        margin: auto 8px;
                        height: fit-content;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobileLargeBreak) {
    .services-wrapper {
        .header-wrapper {
            #sub-header {
                font-size: 3.15em;
            }
            #sub-header-2 {
                font-size: 1.25em;
            }
        }
        .services-content {
            width: 95vw;
            .services-table-wrapper {
                width: 90vw;
                .col-md-6 {
                    padding: 25px 18px;
                }
                #category-header {
                    padding: 10px 18px;
                    h1 {
                        font-size: 1.45em;
                    }
                }
                .service {
                    .service-header {
                        .service-header-wrapper {
                            width: calc(100% - 75px);
                            h3 {
                                font-size: 1.45em;
                            }
                            svg {
                                margin: 0 10px;
                            }
                        }
                        #cost {
                            display: none;
                            width: 25%;
                        }
                    }
                    .desc-wrapper {
                        #cost {
                            color: #a8a8a8;
                            display: block;
                            width: 100%;
                            margin: 15px 0;
                            font-size: 18px;
                            font-family: "Source Sans Light";
                            label {
                                display: none;
                            }
                            select {
                                border-radius: 0 !important;
                                max-width: 100% !important;
                                appearance: button-arrow-down;
                                -webkit-appearance: button-arrow-down;
                                -moz-appearance: button-arrow-down;
                                user-select: auto;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -webkit-padding-end: 20px;
                                -moz-padding-end: 20px;
                                -webkit-padding-start: 2px;
                                -moz-padding-start: 2px;
                                background-color: white;
                                border: 1px solid #aaa;
                                border-radius: 2px;
                                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
                                color: #555;
                                font-size: inherit;
                                margin: 0;
                                overflow: hidden;
                                padding-top: 2px;
                                padding-bottom: 2px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                        .service-desc {
                            max-width: 100%;
                            font-size: 18px;
                            flex-direction: column;
                            div {
                                flex: 1 0 45%;
                            }
                            :is(h1, h2, h3, h4, h5, h6, p) {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .services-pricing {
                width: 100%;
                --gap: 0px;
                padding: 30px;
                position: relative;
                display: flex;
                #pricing-container {
                    margin: 0;
                    width: 100%;
                    hr {
                        width: 100%;
                    }
                    .price {
                        width: 100%;
                        max-height: 40px;
                        span {
                            svg {
                                width: 26px;
                                position: relative;
                                margin: auto 8px;
                                height: fit-content;
                                max-height: 40px;
                            }
                            p {
                                height: fit-content;
                            }
                        }
                    }
                }
            }
        }
    }
}

.description-tooltip {
    display: block;
    padding: 15px 30px 15px 30px;
}
