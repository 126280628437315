.lazy-load-image-background {
	width: auto;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
		display: block;
	}
}

.ai-generator-suggestions-footer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	flex-wrap: wrap;
	margin: 0;
	width: fit-content;

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: #f5f5f5;
	padding: 10px 50px;
	box-sizing: border-box;
	height: 70px;
	z-index: 111;
	animation: slideUp 0.35s ease-in-out;
	.btn {
		width: fit-content;
		text-transform: capitalize;
	}
}

.btn.animated-generate-button {
	--generate-button-star-1-opacity: 0.25;
	--generate-button-star-1-scale: 1;
	--generate-button-star-2-opacity: 1;
	--generate-button-star-2-scale: 1;
	--generate-button-star-3-opacity: 0.5;
	--generate-button-star-3-scale: 1;
	--generate-button-dots-opacity: 0;

	--generate-button-scale: 1.1;
	--generate-button-shadow-wide: rgba(208, 173, 255, 0.4);
	--generate-button-shadow-inset: rgba(255, 255, 255, 0.35);
	--generate-button-shadow-outline: 3px;
	color: #fff;
	background-color: #6d44f4;

	display: flex;
	gap: 3px;
	align-items: center;
	justify-content: center;
	margin: 30px auto;

	appearance: none;
	outline: none;
	border: none;
	margin: 0;
	color: white;
	width: fit-content;
	display: flex;
	position: relative;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	font-size: 1em;

	box-shadow:
		0px 0px 120px var(--generate-button-shadow-wide, transparent),
		0px 4px 12px rgba(0, 0, 0, 0.05),
		0px 1px 2px rgba(0, 0, 0, 0.1),
		inset 0px 1px 1px var(--generate-button-shadow-inset, rgba(255, 255, 255, 0.04)),
		0 0 0 var(--generate-button-shadow-outline, 0px) rgba(109, 68, 244, 0.4);
	transition:
		transform 0.15s,
		background-color 0.15s,
		box-shadow 0.15s,
		color 0.15s;

	&:before {
		content: "";
		display: block;
		position: absolute;
		right: 20%;
		height: 20px;
		left: 20%;
		bottom: -10px;
		background: #d3b3ff;
		filter: blur(12.5px);
		clip-path: inset(-200% -30% 10px -30% round 29px);
		opacity: 0;
		transition: opacity 0.4s;
		transform: translateZ(0);
	}

	.ai-prompt-container {
		all: initial;
		color: white;
		fill: white;
		width: 20px;
		height: 20px;
		svg {
			all: initial;
			color: white;
			width: 100%;
			height: 100%;
			color: white;
			fill: white;
		}
	}

	span {
		position: relative;
		background-color: transparent;
	}

	.stroke {
		mix-blend-mode: hard-light;

		svg {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			fill: none;
			stroke-width: 0.75px;
			stroke: #e2d9ff;
			stroke-dasharray: 1.5 14;
			stroke-dashoffset: 22;
			opacity: 0;

			&:nth-child(2) {
				stroke-width: 1px;
				stroke-opacity: 0.5;
				filter: blur(3px);
			}
		}
	}

	svg {
		display: block;
		overflow: visible;
		pointer-events: none;

		&.dots {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			fill: #d3b2ff;
			opacity: var(--generate-button-dots-opacity);
		}

		&.icon {
			width: 24px;
			height: 26px;
			margin-right: 12px;
			fill: currentColor;

			path {
				&:nth-child(1) {
					opacity: var(--generate-button-star-1-opacity);
					transform: scale(var(--generate-button-star-1-scale)) translateZ(0);
					transform-origin: 25% 14.58%;
				}

				&:nth-child(2) {
					opacity: var(--generate-button-star-2-opacity);
					transform: scale(var(--generate-button-star-2-scale)) translateZ(0);
					transform-origin: 60.42% 50%;
				}

				&:nth-child(3) {
					opacity: var(--generate-button-star-3-opacity);
					transform: scale(var(--generate-button-star-3-scale)) translateZ(0);
					transform-origin: 25% 85.42%;
				}
			}
		}
	}

	--generate-button-scale: 1.1;
	--generate-button-shadow-wide: rgba(208, 173, 255, 0.4);
	--generate-button-shadow-inset: rgba(255, 255, 255, 0.35);
	--generate-button-shadow-outline: 3px;

	color: #fff;
	background-color: #6d44f4 !important;
	background: #6d44f4 !important;

	.stroke {
		svg {
			animation: stroke 2s linear infinite;
		}
	}

	&:before {
		opacity: 1;
	}

	span {
		&:before {
			opacity: 0;
		}
	}

	&:active {
		--generate-button-scale: 1.05;
	}

	&:hover {
		transform: scale(var(--generate-button-scale, 1)) translateZ(0);
	}

	.disabled {
		background-color: transparent;
		background: none;
		pointer-events: none;
	}
}
.preview-file-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0;
}

#image-preview {
	margin: 0;
	background: #eeeeee;
	width: fit-content;
	width: 100%;
	min-width: min(95%, 550px);
	max-width: min(95%, $contentMaxWidth);
	height: fit-content;
	padding: 10px;
	border-radius: 10px;
	display: flex;
	position: relative;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	.preview-icon {
		flex: 0 0 55px;
		width: 55px;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 100%;
		}
	}
	.image-preview-content {
		flex: 1;
	}
	.btn {
		flex: 0 0 fit-content;
		width: fit-content;
		max-width: 100%;
		padding: 10px 15px;
		font-size: 0.9em;
		display: flex;
		gap: 0.75em;
		align-items: center;
		justify-content: center;
		&.preview {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			padding: 8px;
			align-items: center;
			justify-content: center;
			min-width: fit-content;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		img {
			width: 20px;
			height: auto;
		}

		&#new-tab {
			span {
				font-size: 1.15em;
			}
			svg {
				width: 24px;
				height: auto;
			}
		}
	}

	p {
		flex: 1;
		overflow-wrap: break-word;
	}
	#download-link {
		flex: 0 0 36px;
		width: 36px;
		height: 36px;
		background-color: $tmsblue;
		padding: 3px 5px;
		border-radius: 50%;
		position: relative;
		img {
			width: 100%;
		}
		svg {
			margin: 0 !important;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.close {
		height: 20px;
		width: 20px;
		border-radius: 5px;
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -25px;
	}

	@media screen and (max-width: $tabletBreak) {
		max-width: 100%;
	}
}

.file-upload {
	border-radius: 20px;
	margin: 0;
	background: $tmsblue;
	border: none;
	padding: 10px 20px;
	font-family: "Source Sans Pro";
	display: block;
	width: min(225px, 100%);
	cursor: pointer;
	font-weight: normal;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1em;
	color: white;
	* {
		font-size: 1.1em;
	}
	&.darkblue {
		background: $darkblue;
	}
	input {
		display: none;
	}
	svg {
		display: none;
	}
}

.not-found-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	min-height: fit-content;
	max-height: 100svh;
	flex: 1;
	padding: 2rem;
	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		flex-direction: column;
		gap: 1rem;
		svg {
			flex: 0 0 100px;
			width: 100px;
			height: 100px;
			fill: $darkblue;
		}
		:is(h1, h2, h3, h4) {
			margin: 0;
			color: $darkblue;
			line-height: normal;
		}
		h1 {
			font-size: 5.6rem;
			font-family: "Source Sans Pro";
			line-height: 0.7em;
		}
		h2 {
			font-size: 2.8rem;
		}
		h5 {
			margin: 0;
			font-size: 1.25rem;
		}
	}
}

.global-search {
	display: flex;
	flex-direction: column;
	width: 100%;
	* {
		order: 0;
	}
	.search-placeholder {
		padding: 10px 18px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		background: rgb(0 0 0 / 52%);
		border-radius: 20px;
		cursor: text;
		.search-input {
			flex: 1;
			width: 100%;
			padding: 5px;
			background: none;
			color: white;
			font-size: 1.2em;
			border-radius: 20px;
			border: none;
			line-height: 1.2em;
			&:focus {
				outline: none;
			}
			&::placeholder {
				color: rgb(255 255 255 / 50%);
			}
		}
		svg {
			color: white;
			width: 20px;
			height: 20px;
		}
	}

	.search-container {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		z-index: 9999;
		background-color: rgba($color: #000, $alpha: 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#loading-box {
		order: 2;
		position: relative;
		width: 100%;
		height: fit-content;
		min-height: auto;
		background: transparent;
		z-index: 0;
		svg {
			margin: 0;
			width: 100px;
			height: 100px;
		}
	}
}

.search-icon-container {
	display: flex;
	font-size: 32px;
	flex: 0 0 26px;
	width: 26px;
	aspect-ratio: 1/1;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid rgba($color: $defaultNeutral, $alpha: 0.7);
	cursor: pointer;

	.searching-icon {
		width: 16px;
		height: auto;
		color: gray;
		background-color: transparent;
		transition: all 0.15s;
	}
	&:hover {
		background-color: $darkblue;
		border-color: $darkblue;
		.searching-icon {
			color: white;
		}
	}
}

.search-bar-container {
	background-color: white;
	padding: 45px 25px;
	width: min(90%, 950px);
	background: rgb(0 0 0 / 58%);
	backdrop-filter: blur(3px);
	border-radius: 15px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: end;
	.search-close {
		position: absolute;
		top: 5px;
		color: white;
		right: 5px;
		background: none;
		border: none;
		width: 25px;
		height: 25px;
		cursor: pointer;
	}
}

.embr-disabled-banner {
	display: block;
	position: relative;
	width: 90%;
	z-index: 0;
	background-color: $danger;
	padding: 12px 15px;
	margin: 30px auto;
	#banner-text {
		display: flex;
		width: fit-content;
		margin: auto;
		height: 100%;
		text-align: center;
		h3 {
			width: fit-content;
			color: white;
			font-family: "Bariol";
			margin: auto 15px auto 0;
			font-size: 1.15em;
			letter-spacing: 1px;
		}
		a {
			text-decoration: none;
			font-family: "Bariol";
			color: $lightblue;
			span {
				background-color: white;
				color: $danger;
				border-radius: 50px;
				width: fit-content;
				padding: 0 20px;
				display: flex;
				height: 100%;
				cursor: pointer;
				svg {
					width: 23px;
					height: 23px;
					margin: auto 0px;
				}
				p {
					width: fit-content;
					font-family: "Bariol Bold";
					font-size: 1.2em;
					margin: auto 5px;
				}
			}
			&:visited,
			&:active {
				color: white;
				text-decoration: none;
			}
		}
		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	#close {
		height: 15px;
		width: 15px;
		border-radius: 5px;
	}
	#close:after {
		position: absolute;
		content: "\d7";
		font-size: 30px;
		color: white;
		font-weight: bold;
		cursor: pointer;
		top: 7px;
		right: 20px;
	}
}

.react-datepicker-wrapper,
.react-datetime-picker__wrapper {
	cursor: pointer;
	width: min(100%, 325px) !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	* {
		cursor: pointer;
	}

	.react-datepicker__input-container {
		width: 100% !important;
		input {
			border: 1px solid $defaultNeutral !important;
			box-shadow: none;
			width: 100% !important;
			min-width: 100% !important;
			max-width: 100% !important;
			padding: 5px !important;
			&:focus {
				border: px solid $tmsgreen !important;
				outline: none;
			}
		}

		.react-datepicker__calendar-icon {
			padding: 0 !important;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			fill: rgba($color: #000, $alpha: 1);
		}
	}
}

.disclaimer-text {
	color: $neutral;
	font-size: 0.95em;
	text-align: center;
	width: 100%;
	margin: 0;
	line-height: 1.5em;
	font-style: italic;
	font-family: "Source Sans Light", sans-serif;
	font-weight: normal;
}

.form-item {
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 0.65em;
	&.toggle {
		display: flex;
		align-items: center;
		.switch {
			position: relative;
			display: inline-block;
			width: 48px;
			height: 26px;
		}
		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border: 1px solid #a8a8a8;
			border-radius: 34px;
		}
		.slider:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 2px;
			bottom: 2px;
			background-color: #a8a8a8;
			-webkit-transition: 0.25s;
			transition: 0.25s;
			border-radius: 50%;
		}
		input:checked + .slider {
			background-color: white;
			border: 1px solid $tmsblue;
		}
		input:checked + .slider:before {
			-webkit-transform: translateX(22px);
			-ms-transform: translateX(22px);
			transform: translateX(22px);
			background-color: $tmsblue;
		}
	}
	label {
		text-align: left;
		color: $tmsblue;
		text-transform: lowercase;
		font-weight: normal !important;
		font-family: "Source Sans Pro";
		font-size: 1.15em;
		// overflow-wrap: anywhere;
		width: fit-content;
	}
	input,
	select {
		width: fit-content;
		min-width: 225px;
		padding: 8px 15px;
		border-radius: 20px;
		font-size: 1em;
		border: 1px solid #c4c4c4;
		&:focus {
			outline: none;
		}
	}
	input[type="checkbox"] {
		min-width: auto;
	}
	textarea {
		width: 300px;
		height: 200px;
		padding: 0.5em;
		border-radius: 0.5em;
		font-size: 1em;
		border: 1px solid #c4c4c4;
		&:focus {
			outline: none;
		}
	}
	&.checklist-group {
		text-align: center;
		width: fit-content;
		display: flex;
		gap: 15px;
		margin: 15px 0;
		position: relative;
		justify-content: center;
		align-items: center;
		&.onboarding {
			flex-direction: column;
			justify-content: flex-start;
			.checklist-group-container {
				text-align: center;
				width: 100%;
				display: flex;
				gap: 15px;
				margin: 0;
				position: relative;
				justify-content: flex-start;
				align-items: center;
				.checkbox-custom {
					margin: 0;
				}
				&:nth-of-type(2) {
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 20px;
				}
			}
		}
		label {
			margin: 0 !important;
			font-size: 1.25em;
			color: #888;
			font-family: "Bariol";
			padding: 0;
			text-align: left;
		}
		input[type="checkbox"] {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			width: 24px !important;
			height: 24px !important;
			left: 0;
			top: 50%;
			margin: 0;
			transform: translateY(-50%);
			z-index: 1;
		}
		input[type="text"] {
			margin: 0;
			font-size: 18px;
		}
		.checkbox-custom {
			margin: auto;
			height: 24px;
			width: 24px;
			flex: 0 0 24px;
			background-color: white;
			border-radius: 50%;
			border: 1px solid #888;
			cursor: pointer;
			z-index: 0;
			&::after {
				position: absolute;
				content: "";
				left: 12px;
				top: 12px;
				height: 0;
				width: 0;
				border-radius: 5px;
				border: solid $tmsblue;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(0deg) scale(0);
				-ms-transform: rotate(0deg) scale(0);
				transform: rotate(0deg) scale(0);
				opacity: 0;
				z-index: 0;
			}
		}
		input:checked ~ .checkbox-custom {
			background-color: white;
			-webkit-transform: rotate(0deg) scale(1);
			-ms-transform: rotate(0deg) scale(1);
			transform: rotate(0deg) scale(1);
			opacity: 1;
			z-index: 0;
			border: 1px solid $tmsblue;
		}
		input:checked ~ .checkbox-custom::after {
			-webkit-transform: rotate(40deg) scale(1);
			-ms-transform: rotate(40deg) scale(1);
			transform: rotate(40deg) scale(1);
			opacity: 1;
			left: 8px;
			top: 2px;
			height: 16px;
			width: 7px;
			background-color: transparent;
			border-radius: 0;
			z-index: 0;
		}
	}

	.input-length {
		text-align: right;
		margin-top: -5px;
		font-size: 0.9em;
		font-style: oblique;
		font-weight: 100;
		margin-left: auto;
		&.invalid {
			color: $danger;
		}
	}

	.time-range-picker {
		width: 100%;
		display: flex;
		gap: 1em;
		& > * {
			flex: 1;
		}
	}

	&.filter-select {
	}
}
