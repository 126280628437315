// media queries
$tabletBreak: 1247px;
$mobileXLargeBreak: 900px;
$mobileLargeBreak: 600px;
$mobileMediumBreak: 514px;
$mobileSmallBreak: 350px;

// $contentMaxWidth: 2560px;
$contentMaxWidth: 100%;

$sidebar-width-collapsed: 5rem;
$sidebar-width-expanded: 15rem;

@font-face {
	font-family: "Bariol";
	src: url("../fonts/bariol_regular-webfont.eot");
	src:
		url("../fonts/font-name.eot?#iefix") format("embedded-opentype"),
		url("../fonts/bariol_regular-webfont.woff") format("woff"),
		url("../fonts/bariol.ttf") format("truetype"),
		url("../fonts/bariol_regular-webfont.svg#bariol") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Bariol Light";
	src:
		url("../fonts/Bariol_Light.ttf") format("truetype"),
		url("../fonts/Bariol_Light.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Bariol Bold";
	src: url("../fonts/Bariol_Bold.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Honeymoon Avenue Script";
	src:
		url("../fonts/Honeymoon_Avenue_Script.ttf") format("truetype"),
		url("../fonts/Honeymoon_Avenue_Script.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Source Sans Light";
	src: url("../fonts/SourceSansPro-Light.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Arial";
	src: url("../fonts/Arial.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Arial Bold";
	src: url("../fonts/Arial_Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Arial Italic";
	src: url("../fonts/Arial_Italic.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins Regular";
	src: url("../fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins Bold";
	src: url("../fonts/Poppins-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

$lightblue: #4e81be;
$tmsblue: #0060aa;
$tmsgreen: #5bb65d;
$success: $tmsgreen;
$danger: #da534f;
$warning: #f0ad4e;
$neutral: gray;
$normal: #418aca;
$darkblue: #083953;
$salesforceblue: #00a1e1;
$secBlue: #4d7fbd;

$defaultNeutral: #c5c5c5;
$defaultNeutralLight: #f5f7fa;
$dashboardBlue: #e6f0f7;

// project colors
$created: #74b369;
$reopened: #90cac7;
$overdue: #b86258;
$duetoday: #df8960;
$awaitingApproval: #dab26c;
$nearDeadline: #8682b9;
$printOrdered: #94c8a0;
$assigned: #0060a9;
$open: #013b56;
$newHire: #4b80b5;

// mixins
@mixin arrow-down($size, $color) {
	width: 0;
	height: 0;
	border-left: $size solid transparent;
	border-right: $size solid transparent;
	border-top: $size solid $color;
}

@mixin arrow-up($size, $color) {
	width: 0;
	height: 0;
	border-left: $size solid transparent;
	border-right: $size solid transparent;

	border-bottom: $size solid $color;
}

@mixin spherize($size, $border: 1px solid #aaa) {
	width: $size;
	height: $size;
	border-radius: calc($size / 2);
	-webkit-border-radius: calc($size / 2);
	-moz-border-radius: calc($size / 2);
	border: $border;
	background-position: -27px -7px;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideDown {
	// from {top: 0px;}
	// to {top: 2000px;}
}

@keyframes bounce {
	0% {
		transform: translateY(-3px);
	}
	50% {
		transform: translateY(-2px);
	}
	100% {
		transform: translateY(0px);
	}
}

.sk-fading-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
	height: 100px;
	position: relative;
}

.sk-fading-circle .sk-circle {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.sk-fading-circle .sk-circle:before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: white;
	border-radius: 100%;
	-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
	animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

@keyframes sk-circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(-10%);
		opacity: 0;
		height: 0;
	}
	25% {
		transform: translateY(-7.5%);
		opacity: 0.25;
	}
	50% {
		transform: translateY(-5%);
		opacity: 0.5;
	}
	75% {
		transform: translateY(-2.5%);
		opacity: 0.75;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
		height: 100%;
	}
}

@-webkit-keyframes slideDown {
	0% {
		transform: translateY(-10%);
	}
	50% {
		transform: translateY(-5%);
	}
	100% {
		transform: translateY(0%);
	}
}

@keyframes slideUp {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 0.25;
	}
	50% {
		opacity: 0.5;
	}
	75% {
		opacity: 0.75;
	}
	100% {
		opacity: 1;
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

// 5 pointed star
.star-wrapper {
	margin: 0 auto;
	padding: 0;
	display: flex;
	justify-content: center;
}
.star {
	margin: -15px -50px; // NEGATIVE margin spacing based off of SCALE and horizontal/vertical
	width: 0px;
	height: 0px;
	display: block;
	position: relative;
	color: $tmsgreen;
	border-right: 100px solid transparent;
	border-bottom: 70px solid $warning;
	border-left: 100px solid transparent;
	-webkit-transform: rotate(35deg) scale(0.5); // scale maintains border ratios
	-moz-transform: rotate(35deg) scale(0.5);
	-ms-transform: rotate(35deg) scale(0.5);
	-o-transform: rotate(35deg) scale(0.5);
	&.blue {
		color: $tmsblue;
		border-bottom: 70px solid $tmsblue;
		&:before {
			border-bottom: 80px solid $tmsblue;
		}
		&:after {
			color: $tmsblue;
			border-bottom: 70px solid $tmsblue;
		}
	}
	&:before {
		width: 0;
		height: 0;
		display: block;
		position: absolute;
		top: -45px;
		left: -65px;
		content: "";
		border-bottom: 80px solid #ffbf16;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		-webkit-transform: rotate(-35deg);
		-moz-transform: rotate(-35deg);
		-ms-transform: rotate(-35deg);
		-o-transform: rotate(-35deg);
		transform: rotate(-35deg);
	}
	&:after {
		width: 0px;
		height: 0px;
		display: block;
		position: absolute;
		top: 3px;
		left: -105px;
		content: "";
		color: $warning;
		border-right: 100px solid transparent;
		border-bottom: 70px solid $warning;
		border-left: 100px solid transparent;
		-webkit-transform: rotate(-70deg);
		-moz-transform: rotate(-70deg);
		-ms-transform: rotate(-70deg);
		-o-transform: rotate(-70deg);
		transform: rotate(-70deg);
	}
}

@-webkit-keyframes slideIn {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideIn {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideOut {
	0% {
		transform: translateX(0px);
		opacity: 1;
	}
	100% {
		transform: translateX(-1000px);
		opacity: 0;
	}
}

@-webkit-keyframes slideOut {
	0% {
		transform: translateX(0px);
		opacity: 1;
	}
	100% {
		transform: translateX(-1000px);
		opacity: 0;
	}
}

@keyframes stroke {
	0% {
		opacity: 0;
	}

	25%,
	75% {
		opacity: 1;
	}

	95%,
	100% {
		stroke-dashoffset: 6;
		opacity: 0;
	}
}

@keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: attr(value);
	}
}
