#pages {
    width: 100%;
    margin: 0 auto;
    // margin-top: 10px;
    // padding: 0 5px;
}

#header-container {
    background: none;
    background-size: none;
    height: fit-content;
    max-height: 750px;
    display: block;
    // margin-top: 15px;
    margin-bottom: 40px;
    background-size: cover;
    position: relative;
    padding: 0px 0 55px 0;
    .header-background-image {
        width: 100%;
        position: absolute;
        height: 100% !important;
        z-index: -999;
    }
    .header-content {
        margin: 0 auto;
        position: relative;
        height: fit-content;
        width: fit-content;
        max-width: 90%;
        display: block;
        z-index: 0;
        .header-icon {
            display: block;
            width: auto;
            margin: 0 auto;
            padding-top: 80px;
            max-height: 180px;
            max-width: 250px;
        }
        .header-title {
            display: block;
            text-align: center;
            margin: auto;
            width: auto;
            padding-top: 15px;
            font-size: 58px;
            color: white;
            font-family: "Bariol";
            font-weight: bold;
        }
        .heading-text {
            display: block;
            margin: auto;
            padding-top: 15px;
            padding-bottom: 10px;
            font-size: 34px;
            line-height: 45px;
            color: white;
            font-family: "Bariol";
            font-weight: 100;
            width: 550px;
            text-align: center;
            span {
                display: block;
                line-height: 55px;
                margin-left: 14px;
            }
        }
        .request-account-button {
            margin: 10px auto;
            width: 250px;
            border: none;
            display: block;
            text-align: center;
            border-radius: 30px;
            text-decoration: none;
            font-size: 18px;
            padding: 10px;

            cursor: pointer;
            img {
                transform: translate(20%, 28%);
            }
        }
        #demo.request_account_button {
            margin: 0 auto;
            min-width: 150px;
            max-width: 320px;
            margin-top: 20px;
            background-color: white;
            border: none;
            color: #bd2133;
            display: block;
            text-align: center;
            border-radius: 30px;
            text-decoration: none;
            font-size: 18px;
            padding: 15px 20px;
            #button-content {
                display: flex;
                width: fit-content;
                margin: 0 auto;
                color: $tmsblue;
                p {
                    margin-right: 4px;
                    font-size: 20px;
                    max-width: 200px;
                }
                #arrow {
                    height: 1.9em;
                    display: block;
                    margin: auto;
                    width: auto;
                }
            }
            cursor: pointer;
            img {
                transform: translate(15%, 20%);
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #header-container {
        .header-content {
            .header-icon {
                max-height: 150px;
                max-width: 80%;
                height: auto;
            }
            .header-title {
                font-size: 50px;
            }
            .heading-text {
                width: 75%;
                font-size: 30px;
            }
            #demo.request_account_button {
                #button-content {
                    p {
                        max-width: 150px;
                    }
                    #arrow {
                        height: 1.9em;
                    }
                }
            }
        }
    }
}

#template-2 {
    background: none;
    height: 450px;
    max-height: 450px;
    display: block;
    margin-bottom: 40px;
    background-size: cover;
    position: relative;
    padding: 0 0 55px 0;
    .header-background-image {
        width: 100%;
        position: absolute;
        height: 100%;
        z-index: -999;
        object-fit: cover;
        object-position: center;
    }
    .header-content {
        margin: 0 auto;
        height: fit-content;
        height: 450px;
        max-height: 450px;
        max-width: 90%;
        display: block;
        width: 60%;
        z-index: 0;
        padding: 130px 0;
        text-align: center;
        .header-icon {
            display: block;
            margin: 0 auto;
            height: 80px;
            width: 80px;
            background: white;
            border-radius: 50%;
            padding: 0px;
            display: block;
            position: relative;
            img {
                width: 50px;
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
        .header-title {
            display: block;
            text-align: center;
            margin: auto;
            width: auto;
            padding-top: 5px;
            font-size: 52px;
            color: white;
            font-family: "Bariol Light";
            font-weight: normal;
        }
        .heading-text {
            display: block;
            margin: auto;
            padding-top: 15px;
            padding-bottom: 10px;
            font-size: 34px;
            line-height: 45px;
            color: white;
            font-family: "Bariol";
            font-weight: 100;
            width: 550px;
            text-align: center;
            span {
                display: block;
                line-height: 55px;
                margin-left: 14px;
            }
        }
    }
    .container-1 {
        height: fit-content;
        min-height: 400px;
        max-height: 700px;
        margin: 80px auto;
        display: flex;
        width: 100%;
        flex-direction: row;
        position: relative;
        justify-content: space-between;
        #video-container {
            width: 46%;
            #video {
                display: block;
                height: 450px;
                width: 50%;
                width: 550px;
                position: absolute;
                top: 60px;
                left: 50%;
            }
            iframe {
                width: calc(100%) !important;
                height: 100% !important;
                min-width: 100%;
                min-height: 100%;
                display: block;
                position: absolute;
                // left: -1px !important;
            }
        }
        .image-container {
            position: relative;
            width: 49%;
            max-width: 800px;
            margin: 0 auto;
            img {
                display: block;
                // position: absolute;
                width: auto;
                height: 100%;
                margin: auto;
                max-height: 400px;
                z-index: 99999;
                box-shadow: none;
                padding-right: 20px;
            }
        }
        #content-1 {
            position: relative;
            width: 51%;
            max-width: 800px;
            padding: 0 25px;
            min-height: 400px;
            max-height: 700px;
            height: auto;
            h1 {
                font-size: 45px;
                font-family: "Bariol";
                font-weight: 700;
                line-height: 50px;
                padding-left: 0px;
                margin-bottom: 15px;
                width: 100%;
                letter-spacing: 1.5px;
                color: #244759;
                span {
                    display: block;
                }
            }
            p {
                padding-top: 10px;
                width: 75%;
                padding-left: 0;
                line-height: 1.6em;
            }
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-top: 10px;
                color: $darkblue;
                &:first-of-type {
                    margin-bottom: 10px;
                    letter-spacing: 1px;
                }
            }
            ul {
                margin-left: 25px;
                margin-bottom: 15px;
                li {
                    padding: 2px 0;
                    &:first-of-type {
                        margin-top: 5px;
                    }
                }
            }
        }
        &.blue {
            height: auto;
            background: $tmsblue;
            #content-1 {
                padding: 55px 0 0 0;
                margin-right: 0;
                height: auto;
                min-height: 550px;
                max-height: 650px;
                font-family: "Bariol";
                color: white !important;
                h1 {
                    color: white !important;
                    width: 100%;
                }
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: white !important;
                }
                div {
                    // position: absolute;
                    // right: 100px;
                    height: fit-content;
                    width: 500px;
                    margin: 0 auto;
                }
                #video-container {
                    #video {
                        position: relative;
                        width: 400px;
                        margin: 0 auto;
                    }
                }
            }
        }
        &.reverse {
            flex-direction: row-reverse;
            justify-content: space-between;
            .image-container {
            }
            #content-1 {
                max-width: 800px;
            }
        }
        #demo.request_account_button {
            margin: 0 auto;
            min-width: 200px;
            width: fit-content;
            max-width: 550px;
            background-color: $darkblue;
            border: none;
            color: white;
            display: block;
            text-align: center;
            border-radius: 30px;
            text-decoration: none;
            font-size: 18px;
            margin-top: 30px;
            padding: 8px 25px;
            #button-content {
                display: flex;
                width: fit-content;
                margin: 0 auto;
                p {
                    margin-right: 4px;
                    color: white;
                    font-size: 22px;
                    font-family: "Bariol";
                    max-width: 550px;
                }
                #arrow {
                    height: 1.9em;
                    display: block;
                    margin: auto;
                    width: auto;
                }
            }
            cursor: pointer;
            img {
                transform: translate(15%, 20%);
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    #template-2 {
        .header-content {
            .header-icon {
                width: 70px;
                height: 70px;
                img {
                    width: 45px;
                }
            }
        }
        .container-1 {
            max-height: fit-content;
            margin: 25px auto;
            #content-1 {
                width: 60%;
                margin-bottom: 20px;
                padding-right: 20px;
                p {
                    width: 100%;
                }
            }
            &.blue {
                .image-container,
                #video-container {
                    width: 60%;
                }
                #content-1 {
                    padding: 50px 0 10px 0;
                    height: auto;
                    min-height: fit-content;
                    div {
                        position: initial;
                        padding-left: 25px;
                        right: 0;
                        width: 100%;
                    }
                }
            }
            .image-container {
                width: 40%;
                height: fit-content;
                img {
                    position: initial;
                    margin: 30px auto;
                }
            }
            #video-container {
                width: 50%;
                #video {
                    position: absolute;
                    width: 45%;
                    top: 100px;
                    height: auto;
                    padding-bottom: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #template-2 {
        .header-content {
            .header-icon {
                width: 70px;
                height: 70px;
                img {
                    width: 45px;
                }
            }
        }
        .container-1 {
            max-height: fit-content;
            flex-direction: column;
            margin: 0 auto;
            #content-1 {
                width: 100%;
                margin-bottom: 20px;

                p {
                    width: 100%;
                }
            }
            &.blue {
                .image-container,
                #video-container {
                    width: 100%;
                }
                #content-1 {
                    div {
                        position: initial;
                        right: 0;
                        width: 100%;
                    }
                }
            }
            .image-container {
                width: 100%;
                height: fit-content;
                img {
                    position: initial;
                    margin: 30px auto;
                }
            }
            #video-container {
                width: 100%;
                #video {
                    position: initial;
                    width: 100%;
                }
            }
            &.reverse {
                flex-direction: column-reverse;
            }
        }
    }
}

#triangle-up {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -999;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid grey;
}

.circle {
    width: 90px;
    position: absolute;
    z-index: -999;
    left: -45px;
    top: -45px;
    background: $tmsgreen;
    border-radius: 50%;
    height: 90px;
    .inner-circle {
        display: none;
    }
    &.odd {
        right: -50px;
        top: -45px;
        left: initial;
        width: 150px;
        height: 150px;
    }
    &.content {
        left: inherit;
        right: 0;
        top: 10px;
        opacity: 0.1;
    }
    &.reverse {
        display: block;
        left: 20px;
        top: -35px;
        width: 145px;
        .inner-circle {
            display: block;
            width: 125px;
            margin: 10px 0 0 11px;
            height: 80px;
            border-radius: 50%;
            position: relative;
            background: #fff;
        }
    }
}

.triangle {
    position: absolute;
    opacity: 0.1;
    left: -30px;
    top: -5px;
    z-index: -2;
    width: 0;
    height: 0;
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
    border-bottom: 120px solid black;
    div {
        opacity: 1;
        position: relative;
        left: -80px;
        top: 10px;
        display: block;
        width: 0;
        height: 0;
        border-left: 80px solid transparent;
        border-right: 80px solid transparent;
        border-bottom: 105px solid white;
    }
    &.reverse {
    }
}

@media screen and (max-width: 800px) {
    .triangle {
        position: absolute;
        opacity: 0.1;
        left: -10px;
        top: -10px;
        z-index: -2;
        width: 0;
        height: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-bottom: 90px solid black;
        div {
            opacity: 1;
            position: relative;
            left: -60px;
            top: 10px;
            display: block;
            width: 0;
            height: 0;
            border-left: 60px solid transparent;
            border-right: 60px solid transparent;
            border-bottom: 75px solid white;
        }
    }
}

.rectangle {
    width: 20px;
    transform: rotate(0deg);
    height: 100px;
    background: $tmsgreen;
    position: absolute;
    right: -25px;
    &.odd {
        // display: block;
        bottom: 15px;
        left: -15px;
        width: 35px;
        height: 35px;
    }
    &.reverse {
        bottom: -20px;
        z-index: -999;
        width: 150px;
        left: -30px;
        height: 40px;
        transform: rotate(0deg);
    }
}
.container-1 {
    height: fit-content;
    margin: 100px auto;
    display: flex;
    width: 92%;
    flex-direction: row;
    justify-content: space-between;
    .image-container {
        position: relative;
        width: fit-content;
        max-width: 800px;
        margin: 0 auto;
        align-content: right;
        img {
            width: 100%;
            max-width: 800px;
            // max-height: 500px;
            box-shadow: 2px 5px 8px 3px #888;
        }
    }
    #content-1 {
        position: relative;
        width: 44%;
        max-width: 800px;
        padding-top: 50px;
        min-height: 150px;
        margin-right: 100px;
        .shape1 {
            position: absolute;
            margin-left: 0px;
            width: 200px;
        }
        h1 {
            font-size: 50px;
            font-family: "Bariol";
            font-weight: 700;
            line-height: 50px;
            padding-left: 20px;
            letter-spacing: 1.5px;
            color: #244759;
            span {
                display: block;
            }
        }
        p {
            padding-top: 10px;
            width: 550px;
            padding-left: 20px;
            line-height: 1.6em;
            &:first-of-type {
                padding-top: 20px;
            }
        }
    }
    &.reverse {
        flex-direction: row-reverse;
        justify-content: space-between;
        .image-container {
            width: fit-content;
            margin-left: 20px;
            img {
                width: 100%;
            }
        }
        #content-1 {
            width: 56%;
            max-width: 800px;
            padding-left: 50px;
            padding-top: 35px;
            margin-right: 0;
            h1 {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .container-1 {
        flex-direction: column;
        margin: 30px 0 35px 0;
        width: 100%;
        padding: 0 20px;
        .image-container {
            width: fit-content;
            margin: 35px auto 0 auto;
            .circle {
                top: -20px;
                left: -20px;
                &.odd {
                    right: 2px;
                    left: initial;
                }
            }
            .rectangle {
                right: -10px;
                top: 20px;
                &.odd {
                    bottom: 30px;
                    top: initial;
                    left: 0px;
                }
            }
            img {
                margin: 20px auto;
                margin-left: 20px;
                width: 90%;
            }
        }
        #content-1 {
            padding-top: 20px;
            width: 100%;
            h1 {
                font-size: 46px;
                width: 100%;
            }
            p {
                width: 100%;
            }
        }
        &.reverse {
            flex-direction: column;
            margin-bottom: 40px;
            #content-1 {
                width: 100%;
                padding-top: 20px;
                padding-left: 0;
                p {
                    width: 100%;
                }
            }
            .image-container {
                margin-top: 50px;
                margin-left: 0;
                width: 100%;
                img {
                    width: 90%;
                }
                .circle {
                    &.reverse {
                        left: 40px;
                        top: -20px;
                    }
                }
                .rectangle {
                    &.reverse {
                        bottom: 0;
                        left: -10px;
                        top: initial;
                    }
                }
            }
        }
    }
}
#page-form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .left-col,
    .right-col {
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex: 1;

        .form-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            &.checkbox {
                flex-direction: row;
            }
        }

        @media screen and (max-width: $tabletBreak) {
            flex: 1 1 100%;
        }
    }
    #nav_order_container {
        margin-bottom: 10px;
    }
    #nav_order {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        .form-item {
            padding-bottom: 5px;
            width: fit-content;
            input {
                width: 60px !important;
                min-width: auto !important;
            }
        }
    }
    .upload-container {
        label {
            padding: 10px 20px;
            color: white;
        }
    }
}

#section-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .upload-container {
        label {
            padding: 10px 20px;
            color: white;
        }
    }
    #section {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        .btn {
            width: fit-content;
        }
        #sections-form {
            flex: 1 0 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 100%;
            gap: 20px;

            .block {
                margin: 20px;
                margin-bottom: 0;
            }
            .left-col,
            .right-col {
                display: flex;
                flex-direction: column;
                gap: 15px;
                flex: 1;
                textarea {
                    min-height: 100px;
                    height: 100px !important;
                    resize: none;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #page-form {
        flex-direction: column;
    }

    #section-container {
        margin-top: 20px;
        #section {
            #sections-form {
                flex-direction: column;
                .left-col,
                .right-col {
                    width: 50%;
                    margin: 0;
                }
            }
        }
    }
}

#header-section {
    margin-top: 30px;
    h2 {
        padding: 10px 0;
    }
    .form-item {
        padding-bottom: 15px;
        .upload-container {
            margin: 10px 0;
        }
    }
}

.role-permissions {
    p {
        margin-bottom: 10px;
    }
}

#category-table {
    width: 70%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin: 50px auto;
    #category {
        width: 30vw;
        margin-bottom: 65px;
        h3 {
            margin-bottom: 15px;
            position: relative;
            width: 100%;
            .block {
                position: absolute;
                right: 0px;
            }
            a,
            .link-like {
                font-size: 17px;
                font-weight: 400;
            }
        }
        p {
            text-align: center;
        }
    }
    table {
        border-collapse: collapse;
        width: 100%;
        th {
            text-align: left;
            background: #6e6e6e;
            padding: 5px 20px;
            color: white;
        }

        td {
            text-align: right;
            padding: 5px 0;
            margin: 0;
            width: fit-content;
            &:first-of-type {
                width: 90%;
                text-align: left;
            }
        }
    }
    #users-head {
        background: transparent;
        th {
            cursor: pointer;
        }
    }
    tr {
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }
    tr:nth-child(even) {
        background-color: #ccc;
        td {
            color: black;
        }
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #category-table {
        display: flex;
        width: 60%;
        flex-direction: column;
        #category {
            margin-bottom: 50px;
            width: 100%;
            h3 {
                .block {
                    right: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #category-table {
        width: 80%;
    }
}

#pages-table {
    width: fit-content;
    max-width: 75%;
    margin: 0px auto 60px;
    h3 {
        margin: 10px 0;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        text-align: left;
        background: #6e6e6e;
        padding: 5px 20px;
        color: white;
    }
    td {
        width: 12%;
        max-width: 12%;
        text-align: left;
        padding: 5px 20px;
        margin: 0;
        &:first-of-type {
            width: 28%;
            max-width: 30%;
            overflow-wrap: break-word;
        }
    }
    #users-head {
        background: transparent;
        th {
            cursor: pointer;
        }
    }
    tr {
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }
    tr:nth-child(even) {
        background-color: #ccc;
        td {
            color: black;
        }
        &.inactive {
            td {
                color: #888;
                a {
                    color: #888;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #pages-table {
        width: calc(100% - 40px);
        margin: 0 20px;
        overflow: auto;
        table {
            margin-bottom: 10px;
        }
    }
}

.tm-header {
    display: flex;
    width: min(100%, $contentMaxWidth);
    justify-content: space-between;
    margin: 0 auto;
    padding: 1em;
    flex-wrap: wrap;
    border-bottom: 1px solid #d4d4d4;
    box-shadow: 0px 1px 7px rgba(182, 182, 182, 0.6);
    align-items: center;
    gap: 1em;
    #logo {
        width: min(100%, 300px);
        height: auto;
    }

    #phone {
        text-decoration: none;
        color: #083953;
        font-size: 1.75em;
        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: $mobileXLargeBreak) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.privacy-container {
    width: 100%;
    max-width: $contentMaxWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.75em;
    padding: 1em;
    * {
        font-family: "Source Sans Pro";
        // font-size: 1em;
        line-height: 1.5em;
        color: $darkblue;
    }
    &:is(h1, h2, h3) {
        font-family: "Source Sans Pro";
    }
    h1 {
        font-size: 1.6em;
    }
    h2 {
        font-size: 1.2em;
    }
    ul,
    ol {
        padding-left: 30px;
    }
    address {
        text-align: center;
    }
}
