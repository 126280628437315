.no-scroll {
	overflow: hidden;
}
.selector-container {
	input[type="text"],
	select {
		width: 225px;
		padding: 7px;
		font-size: 16px;
	}
}

input,
select {
	background-color: white;
}

@media screen and (max-width: 600px) {
	.selector-container {
		input[type="text"],
		select {
			width: 75%;
			margin: 0 auto;
			padding: 7px;
			font-size: 16px;
		}
	}
}
//logos page

.logo-img-container {
	padding-top: 20px;
	img {
		margin-bottom: 20px;
	}
}

.close-logo {
	font-weight: bold;
	font-size: 22px;
	position: absolute;
	top: 10px;
	right: 20px;
	cursor: pointer;
	color: #666;
	z-index: 100;
}

.logo-preview {
	margin: 20px 5%;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	position: relative;
	border: 10px transparent;
	&.active {
		.preview-box {
			border: solid 10px;
			border-color: $tmsgreen;
		}
	}
	&.active:after {
		content: "";
		display: block;
		width: 0;
		position: absolute;
		height: 0;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: -40px;
		z-index: 0;
		border-left: 60px solid transparent;
		border-right: 60px solid transparent;

		border-bottom: 30px solid $defaultNeutralLight;
	}
	p {
		text-align: center;
		font-size: 18px;
	}
	.preview-box {
		height: 186px;
		background: $defaultNeutralLight;
		position: relative;
		width: 375px;
	}
	img {
		width: 90%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
}

.selector-container {
	background-color: white;
	text-align: left;
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 0;
	gap: 20px;

	.left-col {
		display: flex;
		flex-direction: column;
		gap: 1em;
		flex: 1;
		.request-information {
			padding: 25px 0;
			margin-top: 25px;
			border-top: 1px solid #999;
			h1 {
				color: $darkblue;
			}
			.project-content {
				padding: 20px 0;
			}
			#title {
				font-size: 1.8em;
			}
		}
		#title {
			font-family: "Source Sans Pro";
			color: $tmsblue;
			font-size: 2.5em;
			text-transform: none;
			font-weight: 100;
		}
		h2 {
			color: $darkblue;
			font-family: "Bariol";
			font-weight: bold;
		}
		p {
		}
		.form-item {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin: 0;
			input {
				width: 100% !important;
			}
			label {
				text-align: left;
				text-transform: none;
				font-family: "Source Sans Pro";
				font-weight: 400;
			}
			&.branch {
				display: flex;
				gap: 20px;
				h3 {
					color: $darkblue;
					font-size: 1.5em;
					text-transform: lowercase;
					font-family: "Bariol";
					margin: auto 0 !important;
					display: block;
				}
				select {
					width: fit-content !important;
					min-width: 100px !important;
				}
			}
		}
	}
	.right-col {
		display: inline-block;
		width: fit-content;
		margin: 0px;
		flex: 0 0 28%;
		text-align: center;
		img {
			margin-bottom: 20px;
			max-width: 236px;
			cursor: pointer;
		}
	}
}

.logo-delete {
	width: 100%;
	display: block;
	margin: 10px auto;
	text-align: center;
	cursor: pointer;
	svg {
		height: 15px;
		width: 15px;
	}
}

.sub-sub-header {
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
}

#flyers-container {
	#search {
		input {
			padding: 5px;
			width: 175px;
		}
	}
}

.oh-flyers-container,
.graphics-container,
.event-flyers-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	margin-top: 20px;
	#title {
		font-family: "Source Sans Pro";
		color: $tmsblue;
		font-size: 2.5em;
		text-transform: none;
		font-weight: 100;
		text-align: left;
		margin: 0;
	}
	h2 {
		text-transform: lowercase;
		color: $darkblue;
		font-family: "Bariol";
		text-align: left;
		font-weight: bold;
		margin: 0;
	}
	p {
		margin-bottom: 10px;
		margin: 0;
		text-align: left;
	}
	.selector-container {
		background-color: white;
		text-align: left;
		flex-wrap: wrap;
		display: flex;
		width: 100%;
		margin: 0;
		margin-bottom: 0;
		justify-content: space-between;
		gap: 50px;
		padding: 0;
		hr {
			border: 1px solid #d3d3d3;
		}
		.col {
			display: flex;
			flex-direction: column;
			gap: 0.75em;
			* {
				text-align: left;
			}
			h3 {
				color: $danger;
				font-family: "Bariol";
				font-size: 1.4em;
				font-weight: bold;
			}

			.form-item {
				flex: 1 0 450px;
			}
			.btn {
				margin: 0;
				text-decoration: none;
				text-align: center;
			}
			#zillow-link {
				display: flex;
				flex-wrap: wrap;
				gap: 1em;
				label {
					display: none;
				}
				input {
					width: 100% !important;
					border-radius: 20px;
					padding: 8px 15px;
				}
				.btn {
					flex: 0 0 min(100%, 150px);
					text-decoration: none;
					height: fit-content;
					margin: 0;
					padding: 7px 20px;
					font-family: "Source Sans Light";
					font-weight: 600;
					font-size: 18px;
					&.disable {
						pointer-events: none;
						opacity: 0.5;
					}
					@media screen and (max-width: $mobileLargeBreak) {
						margin: 0 auto;
						width: 100%;
					}
				}
				@media screen and (max-width: $mobileLargeBreak) {
					flex-direction: column;
				}
			}
			&:nth-of-type(2) {
			}
		}
		&.oh-flyer-details {
			transform: none;
			.btn-wrapper {
				display: flex;
				gap: 1em;
				flex-wrap: wrap;
				align-items: center;
				.btn {
					flex: 0 0 fit-content;
					max-width: 100%;
				}
			}
		}
	}
	#library-filters {
		justify-content: flex-start;
		margin: 0;
		gap: 10px;
		width: min(100%, 500px);
		.tab1 {
			flex: 1;
			justify-content: center;
			align-items: center;
			gap: 5px;
			border: 1px solid #a1a1a1;
			border-radius: 20px;
			a {
				margin: 0;
			}
			svg {
				margin: 0;
			}
			&.active {
				border: 2px solid $danger;
			}
		}
	}
	.oh-flyer-history {
		display: flex;
		flex-direction: column;
		gap: 30px;
		.selector-container {
			margin: 0;
			padding: 0;
			#zillow-link {
				flex-direction: column;
				.form-item {
					width: 100%;
					input {
						width: 100%;
						max-width: 100% !important;
					}
				}
			}
		}
	}
}

#dl-preview-container {
	padding: 8px 10px;
	background: $defaultNeutralLight;
	width: fit-content;
	max-width: 256px;
	margin: auto;
	margin-bottom: 15px;
	img {
		margin-bottom: 0;
	}
}

#flyers-container {
	.flyer-row {
		display: flex;
		justify-content: center;
		column-gap: 40px;
		row-gap: 40px;
		flex-wrap: wrap;
		margin: 30px auto 20px;
		width: 100%;
		max-width: 1500px;
		.active .preview-box {
		}
		.flyer-preview {
			display: flex;
			flex-direction: column;
			cursor: pointer;
			position: relative;
			width: 100%;
			max-width: 300px;
			max-height: 325px;
			gap: 10px;
			box-sizing: content-box;
			.flyer-header {
				height: 50px;
				display: flex;
				h3 {
					font-weight: normal;
					font-size: 1.17em;
					text-align: left;
					word-wrap: break-word;
					margin-top: auto;
					height: fit-content;
				}
			}
			.preview-box {
				border: 10px solid $defaultNeutralLight;
				max-width: 300px;
				height: 100%;
				max-height: calc(100% - 60px);
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				.lazy-load-image-background {
					width: auto;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
				}
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
			}
			&.active .preview-box {
				border-color: $danger;
			}
			&.active:after {
				content: "";
				display: block;
				width: 0;
				position: absolute;
				height: 0;
				left: 50%;
				transform: translate(-50%, 0);
				bottom: -40px;
				z-index: 0;
				border-left: 60px solid transparent;
				border-right: 60px solid transparent;
				border-bottom: 30px solid $defaultNeutralLight;
			}
		}
	}
}

#info-form {
	h2 {
		font-weight: normal;
		font-size: 2em;
		color: #555;
		margin-top: 20px;
	}

	img {
		@include spherize(90px);
		display: inline-block;
	}
}

@media screen and (max-width: 600px) {
	#info-form {
		display: flex;
		flex-direction: column;
	}
}

.material-delete-link {
	width: 100%;
	display: block;
	margin: 0;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	text-decoration: underline;
	color: #4e81be;
	svg {
		height: 15px;
		width: 15px;
	}
}

//printOrder
#print-order {
	display: inline-block;
	margin-top: 30px;
	.form-item {
		select {
		}
	}
	h3 {
		margin-bottom: 5px;
		color: $darkblue;
		font-family: "Bariol";
		font-size: 1.5em;
	}
	#order-button {
		background-color: $tmsblue;
		border-radius: 40px;
		color: white;
		text-transform: lowercase;
		font-family: "Bariol";
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 1.1px;
		width: 230px;
		padding: 10px 0;
		margin: 20px 0;
	}
	p {
		// font-weight: bold;
		font-family: sans-serif;
	}
}

// Business card stylings
.business-card-row {
	width: 100%;
	text-align: center;
	.active .preview-box {
		border: 20px solid #5bb65d;
	}
}
.business-card-preview {
	margin: 20px 1.5%;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	position: relative;
	h3 {
		font-weight: normal;
		font-size: 1.4em;
		margin-bottom: 10px;
		text-align: left;
	}
	.preview-box {
		border: 10px solid $defaultNeutralLight;
	}
}

// #business-card-container .selector-container {
//     background-color: $defaultNeutralLight;
//     width:75%;
//     display: block;
//     h2:nth-of-type(2){
//         margin-top: 20px;
//     }
//     .left-col{
//         display: inline-block;
//         vertical-align: top;
//         width: 70%;
//         p{
//             margin-bottom: 30px;
//         }
//         .form-item {
//             img {
//                 @include spherize(90px);
//             }
//         }
//     }
//     .right-col{
//         display: inline-block;
//         width: 30%;
//         margin-left: 0px;
//         text-align: center;
//         img{
//             max-width: 236px;
//         }
//         h4{
//             text-align: left;
//         }
//     }
//     #flyerForm {
//         input[type="text"], select{
//             padding: 5px;
//             font-size: 16px;
//         }
//     }
// }

// @media screen and (max-width: 600px){
//     #business-card-container .selector-container {
//         width: calc(100% - 40px);
//         padding: 20px;
//         display: flex;
//         flex-direction: column;
//         .left-col {
//             // all: initial;
//             width: 100%;
//             max-width: inherit;
//             margin-bottom: 30px;
//         }
//         .right-col{
//             width: 100%;
//         }
//     }
// }

// @media screen and (max-width: 1300px){
//     #business-card-container .selector-container {
//         // margin: 0 25px;
//         margin: 0 80px;
//         // width: 75vw;
//     }
// }

@media screen and (max-width: 1024px) {
	#business-card-container .selector-container {
		// width: 90%;
		margin: 0 auto;
		width: 90%;
	}
}

.business-card-delete {
	width: 100%;
	display: block;
	margin: 10px auto;
	text-align: center;
	cursor: pointer;
	svg {
		height: 15px;
		width: 15px;
	}
}

//emails
#email-page {
	#search input {
		padding: 5px;
		width: 175px;
	}
}

#email-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -9999;
	background: rgba(0, 0, 0, 0.55);
	opacity: 0;
	transition: all 0.3s linear;
	&.show {
		display: block;
		opacity: 1;
		z-index: 999;
		.email-modal-container {
			display: block;
			padding: 30px 30px 20px;
		}
	}
	.email-modal-container {
		display: none;
		padding: 0;
		background-color: white;
		margin: 0px auto;
		width: 90%;
		max-width: 650px;
		height: fit-content;
		max-height: 80svh;
		border-radius: 20px;
		z-index: 99999;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		h3 {
			color: $danger;
			font-family: "Source Sans Pro";
			font-size: 1.3em;
		}
		#desc {
			display: flex;
			margin-top: 2px;
			border-bottom: 1px solid $danger;
			padding-bottom: 15px;
			p {
				padding: 0 10px;
				&.created-at {
					padding-left: 0;
					border-right: 1px solid;
				}
			}
		}
		#email-list {
			padding: 10px 0;
			max-height: calc(80vh - 200px);
			height: fit-content;
			display: flex;
			flex-wrap: wrap;
			overflow: auto;
			column-gap: 10px;
			justify-content: space-between;
			p {
				flex: 0 0 45%;
				line-height: 1.7em;
			}
		}
		#search {
			display: flex;
			justify-content: center;
			margin: 10px 0;
			text-align: left;
			.form-item {
				position: relative;
				width: 250px !important;
			}
			input {
				padding: 5px 20px 5px 10px !important;
				width: 100% !important;
				// font-size: 0.9em;
			}
			svg {
				position: absolute;
				margin: 0;
				right: 12px;
				height: 17px;
				top: 7px;
			}
		}
		#close {
			height: 20px;
			width: 20px;
			border-radius: 5px;
			cursor: pointer;
		}
		#close:after {
			position: absolute;
			content: "\d7";
			font-size: 35px;
			color: black;
			font-weight: bold;
			top: 5px;
			right: 18px;
		}
	}
}

@media screen and (max-width: $tabletBreak) {
	#email-modal {
		.email-modal-container {
			width: 80%;
			min-height: 400px;
			top: 15%;
			margin-top: -30px;
			max-height: 80svh;
			#email-list {
				max-height: 400px;
				p {
					flex: 1;
				}
			}
		}
	}
}

#library-filters,
#social-ads-filters {
	display: flex;
	width: fit-content;
	margin: 0 auto;
	justify-content: space-between;
	gap: 0;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	row-gap: 1em;
	column-gap: 0;
	.tab1 {
		flex: 0 0 fit-content;
		min-width: 175px;
		max-width: 100%;
		display: flex;
		border-bottom: 1px solid #a1a1a1;
		padding: 4px 15px;
		margin: 0;
		justify-content: center;
		align-items: flex-end;
		gap: 5px;
		cursor: pointer;
		a,
		a:visited {
			color: #a1a1a1;
			text-decoration: none;
			font-size: 1em;
		}
		svg {
			color: #a1a1a1;
			margin: 0;
			width: 20px;
			height: auto;
		}
		&.active {
			border-bottom: 4px solid $danger;
			svg,
			a {
				color: $danger;
			}
		}
	}
}

#social-ads-filters {
	.tab1 {
		flex: 0 0 20%;
		justify-content: center;
		display: flex;
		gap: 10px;
		padding: 6px 10px;
		min-width: 200px;
		svg {
			margin: auto 0;
		}
		a {
			margin: 0;
		}
	}
	@media screen and (max-width: $mobileLargeBreak) {
		width: 90%;
		justify-content: center;
		max-width: none;
		.tab1 {
			flex: 0 0 45%;
		}
	}
	@media screen and (max-width: $mobileMediumBreak) {
		.tab1 {
			flex: 0 0 100%;
		}
	}
}

.email-row {
	display: flex;
	justify-content: center;
	gap: 40px;
	flex-wrap: wrap;
	margin: 0px auto;
	width: 100%;
	flex-direction: row;
	.selector-container {
		gap: 1em;
		.left-col {
			.header-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}
		.right-col {
			flex: 0 0 300px;
			.btn {
				width: 100%;
				margin: 20px auto 10px auto;
				border-radius: 40px;
				text-decoration: none;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
			}
		}
	}

	.active .email-thumb {
		border: 10px solid #5bb65d;
	}

	.email-thumb {
		border: 10px solid $defaultNeutralLight;
		max-width: 300px;
		height: 100%;
		max-height: calc(100% - 60px);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			display: block;
		}
	}
	.file-upload {
		text-align: center !important;
		color: black;
		background-color: #5bb65d;
		color: white;
		border: 0;
		font-weight: 100;
		padding: 8px 15px;
		border-radius: 40px;
		width: 230px;
		span {
			display: none;
		}
	}

	.email {
		display: flex;
		flex-direction: column;
		cursor: pointer;
		position: relative;
		width: 100%;
		max-width: 300px;
		max-height: 400px;
		height: 400px;
		gap: 10px;
		box-sizing: content-box;
		justify-content: space-between;
		.history-data {
			margin-top: 10px;
			text-align: center;
			font-family: "Bariol";
			a {
				color: $danger;
				text-decoration: underline;
				font-size: 1em;
			}
		}
		&:nth-child(3) {
			margin-right: 0;
		}
		&.active:after {
			content: "";
			display: block;
			width: 0;
			position: absolute;
			height: 0;
			left: 50%;
			transform: translate(-50%, 0);
			bottom: -40px;
			z-index: 0;
			border-left: 60px solid transparent;
			border-right: 60px solid transparent;
			border-bottom: 30px solid $defaultNeutralLight;
		}
		.file-upload {
			margin-top: 20px;
		}
		.email-header {
			flex: 1;
			display: flex;
			h3 {
				font-weight: normal;
				font-size: 1.17em;
				text-align: left;
				word-wrap: break-word;
				margin-top: auto;
				height: fit-content;

				display: -webkit-box;
				max-width: 100%;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.email-row {
		margin: 0 auto 20px auto;
		.selector-container {
			padding: 0;
			display: flex;
			flex-direction: column;
			.left-col {
				width: 100%;
				max-width: inherit;
				margin-bottom: 30px;
			}
			.right-col {
				width: 100%;
			}
			.check-label {
				display: flex;
				width: 100%;
			}
		}
	}
}

#email-text {
	display: block;
	width: 1px;
	height: 1px;
	border-color: $defaultNeutralLight;
}

#sending-options {
	.check-label {
		width: fit-content;
		input[type="checkbox"] {
			width: fit-content !important;
			margin-right: 7px;
			min-width: fit-content;
		}
	}
	.form-item {
		&:nth-of-type(2) {
			padding-top: 20px;
			border-top: 1px solid #999;
		}
	}
	.container {
		display: block;
		position: relative;
		font-size: 1.1em;
		line-height: 1.1em;
		padding-left: 30px;
		margin-bottom: 8px;
		cursor: pointer;
		font-size: 16px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #737272;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 50%;
		left: 0;
		height: 22px;
		width: 22px;
		background-color: $defaultNeutralLight;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
		margin-top: 2px;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-color: $danger;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 8px;
		top: 3px;
		width: 6px;
		height: 14px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.error-input {
		color: $danger;
		font-weight: bold;
	}

	.date-picker-wrapper {
		display: flex;
		gap: 1em;
		justify-content: flex-start;
		.btn {
			margin: 0;
		}
	}
}

.photo {
	display: flex;
}

.photo-left,
img {
	display: inline-block;
	width: auto;
	max-width: 100%;
}

.photo-left {
	width: 55%;
	text-align: left;
	padding-top: 15px;
	p {
		font-size: 14px;
	}
}

.my-photo {
	margin-top: 25px;
}

@media screen and (max-width: 1200px) {
	.my-photo {
		margin-top: 25px;
	}
	.photo-left {
		width: 60%;
		margin-right: 10px;
	}
}

.image-preview {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 110;
	background: rgba(0, 0, 0, 0.55);
	.preview-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		width: min(95vw, $contentMaxWidth);
		height: 100%;
		max-height: 90svh;
		overflow: auto;
		gap: 10px;

		position: relative;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;

		img {
			width: auto;
			height: auto;
			max-height: 80svh;
			max-width: min(850px, 90vw);
		}
		p {
			&.click-to-close {
				color: white;
			}
		}
		// p {
		// 	color: white;
		// }
	}

	&.social-details {
		.preview-content {
			text-align: center;
			height: 100%;
			max-height: 90svh;
			padding: 20px 30px;
			width: min(95vw, $contentMaxWidth);
			overflow: unset;
			background: #fff;
			top: 50%;
			left: 50%;
			margin: 0;
			border-radius: 35px;
			z-index: 99999;
			position: relative;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			position: absolute;
			transform: translate(-50%, -50%);
			gap: 10px;
			#header {
				width: 100%;
				position: relative;
				h1 {
					text-align: center;
					font-family: "Poppins Regular";
					color: rgba($color: $darkblue, $alpha: 1);
					line-height: normal;
				}
				#close {
					position: absolute;
					right: 0px;
					top: 50%;
					transform: translateY(-50%);
					width: 28px;
					height: 28px;
					cursor: pointer;
				}
			}
			.wrapper {
				width: 100%;
				overflow-y: auto;
				height: fit-content;
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 1em;
				justify-content: flex-start;
				::-webkit-scrollbar {
					width: 2px;
				}
			}
			.social-details-error {
				display: flex;
				justify-content: center;
				margin: 0;
				width: 100%;
				.authorize-banner {
					margin: 0;
					p {
						position: relative;
						top: auto;
						left: auto;
						right: auto;
						bottom: auto;
						span {
							text-transform: capitalize;
						}
					}
				}
			}
			.social-details-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: center;
				gap: 2em;
				flex: 1;
				align-items: flex-start;
				&:nth-of-type(2) {
					justify-content: space-between;
				}
				#header {
					position: relative;
					width: 100%;
					h1 {
						text-align: center;
						margin: 0 auto;
						color: $darkblue;
					}
					#close {
						height: 25px;
						width: 25px;
						cursor: pointer;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 0px;
					}
				}

				.result-wrapper {
					width: 100%;
					height: 100%;
					margin: 0px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					gap: 1em;
					* {
						margin: 0;
						padding: 0;
					}
					svg {
						width: min(60%, 145px);
						display: block;
					}
					.path {
						stroke-dasharray: 1000;
						stroke-dashoffset: 0;
						&.circle {
							-webkit-animation: dash 1.2s ease-in-out;
							animation: dash 1.2s ease-in-out;
						}
						&.line {
							stroke-dashoffset: 1000;
							-webkit-animation: dash 1.2s 0.35s ease-in-out forwards;
							animation: dash 1.2s 0.35s ease-in-out forwards;
						}
						&.check {
							stroke-dashoffset: -100;
							-webkit-animation: dash-check 1.2s 0.35s ease-in-out forwards;
							animation: dash-check 1.2s 0.35s ease-in-out forwards;
						}
					}
					h2 {
						font-size: 1.8em;
						color: $darkblue;
						font-family: "Bariol";
					}
				}

				@media screen and (max-width: $mobileLargeBreak) {
					flex-direction: column;
					margin: 10px 0;
				}
				.carousel-wrapper {
					display: flex;
					flex-direction: row;
					gap: 25px;
					flex-wrap: wrap;
					.carousel-slider {
						width: 315px !important;
						@media screen and (max-width: $tabletBreak) {
							width: 100% !important;
							max-width: 600px !important;
						}
						@media screen and (max-width: $mobileLargeBreak) {
							max-width: 100% !important;
							width: 100% !important;
							margin: 0;
							.social-image {
								width: auto;
								height: auto;
								max-width: 90%;
								max-height: 300px;
							}
						}
					}
					.thumbs-wrapper {
						margin: 20px 0;
						.thumbs {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							gap: 5px;
							li.thumb {
								max-width: 75px;
								max-height: 100px;
								display: flex;
								justify-content: center;
								align-items: center;
								margin: 0;
								img {
									max-width: 100%;
									max-height: 100%;
								}
							}
						}
						@media screen and (max-width: $mobileLargeBreak) {
							.thumbs {
								li.thumb {
									max-width: 75px;
									max-height: 75px;
									img {
										padding: 0;
									}
								}
							}
						}
					}
					.carousel-image-wrapper {
						padding-bottom: 20px;
						p {
							margin: 0;
						}
					}
				}
			}

			.footer-wrapper {
				#media-buttons {
					display: flex;
					flex-direction: row;
					gap: 1em;
					justify-content: center;
					.btn {
						min-width: 125px;
					}
				}
			}

			.col-md-2 {
				height: 100%;
				flex: 1;
				width: 100%;
				.social-details-content {
					display: flex;
					flex-direction: column;
					gap: 1em;
					.header {
						display: flex;
						gap: 10px;
						.content {
							display: flex;
							flex-direction: column;
							justify-content: center;
						}
						img {
							width: 40px;
							border-radius: 50%;
							margin: auto 0;
							display: block;
							padding: 0;
						}
						h2 {
							text-align: left;
							color: $tmsblue;
							font-size: 1.2em;
							text-align: left;
							font-family: "Source Sans Pro";
						}
						h5 {
							text-align: left;
							color: #9197a3;
							font-size: 0.8em;
							font-family: "Source Sans Light";
							display: flex;
							justify-content: space-between;
							gap: 7px;
							width: fit-content;
							svg {
								margin: 3px 0;
								height: fit-content;
							}
						}
					}
					@media screen and (max-width: $mobileLargeBreak) {
						flex-direction: column;
						.col {
							img {
								padding: 20px;
								margin: 0 auto;
							}
						}
					}
					.form-item {
						margin: 0;
						position: relative;
						label {
							display: none;
						}
						textarea {
							height: 100px;
							min-width: 100%;
							max-width: 800px;
							min-height: 100px;
							max-height: 200px;
							border-radius: 0;
							margin: 0 !important;
							position: relative;
						}
					}
					.text-suggestions {
						display: flex;
						flex-direction: column;
						gap: 1em;
						width: 100%;
						h2 {
						}
						.text-wrapper {
							display: flex;
							flex-direction: row;
							gap: 1em;
							position: relative;
							justify-content: space-between;
							align-items: center;
							p {
								color: black;
								margin: 0;
								font-size: 1.1em;
							}
							.text {
								position: relative;
								text-align: left;
								font-size: 1em;
								br {
									display: none;
								}
							}
							.copy-button {
								border-radius: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 0.5em 1.25em;
								transition: all 0.1s ease-in-out;
								svg {
									width: 1em;
									height: auto;
								}
								cursor: pointer;
								&.active {
									background: $tmsgreen;
									color: white;
									border-color: $tmsgreen;
									svg {
										width: 1.15em;
									}
								}
							}

							&:last-of-type {
								#divider {
									border: 0;
								}
							}
						}

						hr {
							border: none;
							border-bottom: 1px solid #d3d3d3;
							&:last-of-type {
								display: none;
							}
						}
					}

					.schedule-wrapper {
						display: flex;
						flex-direction: column;
						gap: 10px;
						h3 {
							text-align: left;
						}
						i {
							text-align: left;
						}
						input {
							text-align: left;
							margin: 0;
							font-size: 1em;
							display: block;
							border: 1px solid black;
							padding: 5px;
							width: min(100%, 325px);
						}

						.input-error {
							color: $danger;
							text-align: left;
							position: relative;
						}
						.disclaimer {
							position: relative;
							top: auto;
							color: black;
							text-align: left;
							right: auto;
						}
					}
					.option-wrapper {
						display: flex;
						flex-direction: row;
						margin: 0;
						gap: 1em;
						.container {
							display: block;
							width: fit-content;
							position: relative;
							line-height: 1.4em;
							padding-left: 30px;
							cursor: pointer;
							font-size: 1.1em;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
							color: #737272;
							font-family: "Source Sans Pro";
							&.checked {
								color: $danger;
								font-weight: bold !important;
							}
						}
						/* Hide the browser's default checkbox */
						.container input {
							position: absolute;
							opacity: 0;
							cursor: pointer;
							height: 0;
							width: 0;
						}
						/* Create a custom checkbox */
						.checkmark {
							position: absolute;
							top: 2px;
							left: 0;
							height: 22px;
							width: 22px;
							background-color: white;
							border-radius: 50%;
							border: 1px solid #d3d3d3;
						}

						/* When the checkbox is checked, add a blue background */
						.container input:checked ~ .checkmark {
							// background-color: $danger;
							border: 1px solid $danger;
						}

						/* Create the checkmark/indicator (hidden when not checked) */
						.checkmark:after {
							content: "";
							position: absolute;
							display: none;
						}

						/* Show the checkmark when checked */
						.container input:checked ~ .checkmark:after {
							display: block;
						}

						/* Style the checkmark/indicator */
						.container .checkmark:after {
							left: 1px;
							top: 1px;
							width: 18px;
							height: 18px;
							border: 2px solid white;
							background-color: $danger;
							border-radius: 50%;
						}
					}
				}
				.embed-link-wrapper {
					h3 {
						text-align: left;
						color: $tmsblue;
					}
					p {
						position: relative;
						color: black;
						text-align: left;
						right: auto;
						top: auto;
					}
					.embed-container {
						position: relative;
						padding-bottom: 35%;
						height: 0;
						overflow: hidden;
						width: 100%;
						min-height: 200px;
						margin: 10px 0 0 0;
						iframe {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
						@media screen and (max-width: $tabletBreak) {
							padding-bottom: 45%;
						}
						@media screen and (max-width: $tabletBreak) {
							padding-bottom: 40%;
							min-height: 200px;
						}
					}
				}
				#media-footer {
					border: none;
					justify-content: center;
					.btn {
						width: fit-content;
						margin: 20px auto;
						min-width: 175px;
					}
				}
				&:nth-of-type(2) {
					flex: 0 0 min(325px, 25%);
					height: fit-content;
					padding: 0;
					max-width: 325px;
					margin: 0 auto;
					img {
						width: 100%;
						max-width: 100%;
						height: auto;
						max-height: auto;
						padding: 0;
					}
				}
			}
		}
	}
	&.user-calendar {
		.preview-content {
			width: min(95vw, $contentMaxWidth);
			height: 90svh;
			overflow: hidden;
			padding: 0;
			background-color: white;
			text-align: center;
			border-radius: 35px;
			z-index: 999999;
			position: absolute;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			box-sizing: border-box;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.preview-content-wrapper {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
			}
			#header {
				border: none;
				justify-content: center;
				background-color: rgb(240, 240, 240);
				padding: 15px 50px;
				display: flex;
				flex-direction: row;
				gap: 10px;
				justify-content: center;
				align-items: center;
				flex: 0 0 fit-content;
				width: 100%;
				position: relative;
				h1 {
					text-align: center;
					margin: 0;
					font-size: 1.6em;
					color: $darkblue;
					width: fit-content;
				}
				.social-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					flex: 0 0 fit-content;
					svg {
						flex: 0 0 25px;
						width: 25px;
						height: 25px;
						display: block;
						color: $darkblue;
						fill: $darkblue;
					}
				}
				#close {
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
				#close:after {
					position: absolute;
					content: "\d7";
					font-size: 35px;
					color: black;
					font-weight: bold;
					top: 0;
					left: 0;
				}
			}
			.wrapper {
				flex: 1;
				overflow-y: auto;
				padding: 20px;
			}
			.container {
				display: flex;
				gap: 1em;
				margin: 0;
				flex-wrap: wrap;
				height: fit-content;
				min-height: 100%;
				.content-wrapper {
					flex: 1;
				}
				@media screen and (max-width: $tabletBreak) {
					flex-direction: column-reverse;
					.content-wrapper {
						border: none;
						flex: 0 0 fit-content;
						width: 100%;
					}
				}
			}
			.content-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				gap: 1em;
				width: 100%;
				flex-wrap: wrap;
				.col-md-2 {
					flex: 1;
					position: relative;
					display: flex;
					flex-direction: column;
					gap: 1em;

					* {
						text-align: left;
					}

					&:nth-of-type(2) {
						flex: 0 0 min(100%, 325px);
					}
					p {
						color: black;
						margin: 0;
						font-size: 1.1em;
						position: relative;
						right: auto;
						top: auto;
						font-size: 1em;
					}
					img {
						width: min(100%, 450px);
						display: block;
						margin: 0 auto;
					}
					.embed-container {
						position: relative;
						padding-bottom: 35%;
						height: 0;
						overflow: hidden;
						width: 100%;
						min-height: 200px;
						margin: 10px 0 0 0;
						iframe {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
						@media screen and (max-width: $tabletBreak) {
							padding-bottom: 45%;
						}
						@media screen and (max-width: $tabletBreak) {
							padding-bottom: 40%;
							min-height: 200px;
						}
					}

					.btn-wrapper {
						display: flex;
						gap: 1em;
						flex-wrap: wrap;
					}
					.btn {
						width: fit-content;
						margin: 0;
						text-decoration: none;
						min-width: 125px;
					}
					.img-wrapper {
						width: 100%;
						max-width: 450px;
						img {
							display: block;
							width: 100%;
						}
					}
					.subheader {
						font-size: 1.15em;
						color: $darkblue;
						text-align: left;
					}

					.metadata-wrapper {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 1em 0 0;
						gap: 0.5em;
						* {
							padding: 0;
							margin: 0;
						}
						h3 {
							color: $darkblue;
						}
					}
				}
				&.scheduled {
					border-right: 1px solid #d3d3d3;
					flex-direction: column;
					justify-content: flex-start;
					flex: 0 0 min(300px, 100%);
					.col-md-2 {
						padding: 0;
						flex: 0;
						h3 {
							text-align: left;
							font-weight: normal;
							line-height: normal;
							span {
								font-weight: bold;
							}
						}
						p {
							text-align: left;
							padding: 5px 0;
						}
					}
					&:nth-of-type(2) {
						border: none;
					}
				}
				.header {
					display: flex;
					gap: 10px;
					align-items: center;
					.content {
						display: flex;
						flex-direction: column;
						justify-content: center;
						gap: 0.35em;
					}
					img {
						width: 40px;
						border-radius: 50%;
						margin: auto 0;
						display: block;
						padding: 0;
					}
					h2 {
						text-align: left;
						color: $tmsblue;
						font-size: 1.2em;
						text-align: left;
						font-family: "Source Sans Pro";
					}
					h5 {
						text-align: left;
						color: #9197a3;
						font-size: 0.8em;
						font-family: "Source Sans Light";
						display: flex;
						justify-content: space-between;
						gap: 7px;
						width: fit-content;
						svg {
							margin: 3px 0;
							height: fit-content;
						}
					}
				}
				.recipients-wrapper {
					display: flex;

					.recipients-list {
						width: 100%;
						display: flex;
						height: fit-content;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-between;
						height: fit-content;
						max-height: 300px;
						p {
							text-align: center;
							margin: 0 auto;
							font-size: 1.25em;
							width: fit-content;
						}
						li {
							padding: 5px 0;
							max-width: 240px;
							margin-left: 0px;
							overflow-wrap: break-word;
						}
						table {
							border-radius: 10px;
							width: 100%;
							margin: 0;
							thead {
								tr {
									border-bottom: 1px solid #d3d3d3;
									&:last-of-type {
										border-bottom: 1px solid #d3d3d3;
									}
								}
								td {
									font-weight: bold;
									color: $tmsblue;
									text-align: center;
								}
							}
							tr {
								width: 100%;
								border-bottom: 1px solid #d3d3d3;
								display: flex;
								flex-direction: row;
								&:last-of-type {
									border: none;
								}
								td {
									flex: 1;
									text-align: left;
									padding: 10px;
									text-transform: none;
								}
							}
						}
					}
				}

				@media screen and (max-width: $tabletBreak) {
					flex-direction: column;
					padding-bottom: 20px;
					.col-md-2 {
						flex: 1;
					}
				}

				.carousel-wrapper {
					display: flex;
					flex-direction: row;
					gap: 25px;
					flex-wrap: wrap;
					justify-content: center;
					.carousel-slider {
						width: 350px !important;
						margin: 0 auto;
						.social-image {
							width: auto;
							height: auto;
							max-width: 75% !important;
							max-height: 300px !important;
						}
						@media screen and (max-width: $mobileLargeBreak) {
							max-width: 90% !important;
							margin: 0;
							.social-image {
								width: auto;
								height: auto;
								max-width: 75%;
								max-height: 150px;
							}
						}
					}
					.carousel-status {
						display: none;
					}
					.thumbs-wrapper {
						margin: 20px 0;
						.thumbs {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							gap: 10px;
							li.thumb {
								max-width: 100px;
								max-height: 100px;
								display: flex;
								justify-content: center;
								align-items: center;
								margin: 0;
								img {
									max-width: 100%;
									max-height: 100%;
								}
							}
						}
					}
					.carousel-image-wrapper {
						padding-bottom: 20px;
						p {
							margin: 0;
						}
					}
				}
				.analytics-wrapper {
					display: flex;
					flex-direction: column;
					gap: 1em;

					.header {
						justify-content: space-between;
						.btn {
							margin: 0;
						}
					}
					.authorize-banner {
						text-align: center;
						color: white;
						padding: 7px 10px;
						align-items: center;
						justify-content: center;
						width: fit-content;
						margin-top: 20px;
					}
					.chart-container {
						display: flex;
						gap: 10px;
						width: 100%;
						justify-content: center;
						flex-wrap: wrap;
						p {
							text-align: right;
							&.last-updated {
								flex: 0 0 100%;
							}
						}
						.pie-chart-container {
							flex: 1;
							max-width: 400px;
							max-height: 400px;
							// max-width: min(100%, 400px);
							// min-width: min(100%, 400px);
							// height: min(100%, 400px);
							@media screen and (max-width: $tabletBreak) {
								flex: 1 1 100%;
							}
						}
					}
				}
			}

			#media-footer {
				border: none;
				justify-content: center;
				background-color: rgb(240, 240, 240);
				padding: 15px 30px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 1em;
				.btn {
					width: fit-content;
					margin: 0 auto;
					min-width: 115px;
				}
				.failed-wrapper {
					display: flex;
					gap: 5px;
					width: fit-content;
					svg {
						margin: auto 0;
						display: block;
						width: 100%;
						height: fit-content;
						max-width: 26px;
						fill: $danger;
						flex: 0 0 10%;
					}
					p {
						color: $danger;
						padding: 0px;
						margin: 0;
						font-size: 1.1em;
						position: relative;
						right: auto;
						top: auto;
						text-align: left;
						font-size: 1em;
						flex: 1;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $tabletBreak) {
	.image-preview {
		&.social-details {
			display: block;
			.preview-content {
				max-width: 95vw;
				top: 25%;
				transform: translate(-50%, -25%);
				left: 50%;
				.social-details-wrapper {
					flex-direction: column;
					.result-wrapper {
						img {
							padding: 20px;
						}
					}
				}
				.col-md-2 {
					img {
						padding: 20px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $mobileLargeBreak) {
	.image-preview {
		// display: none;
		.preview-content {
			width: 100%;
			padding: 0;
			img {
				width: calc(100%);
				margin: 20px auto 0 auto;
				padding: 70px;
			}
		}
		&.user-calendar {
			display: block;
			.preview-content {
				width: 90%;
				top: 100px;
				left: 50%;
				transform: translate(-50%, 0);
				.content-wrapper {
					.col-md-2 {
						padding: 0;
						&:last-of-type {
							padding: 0px;
							img {
								padding: 0;
							}
						}
						// .failed-wrapper{
						//     position: relative;
						//     margin: 20px 0;
						//     svg{
						//         margin: 0;
						//         width: 28px;
						//         height: 28px;
						//     }
						// }
					}
				}
			}
		}
		&.social-details {
			display: block;
			.preview-content {
				max-width: 90%;
				top: 100px;
				transform: translateX(-50%);
				left: 50%;
				max-height: 80svh;
				padding: 10px;
				.wrapper {
					max-height: calc(70vh);
					padding: 10px;
				}
				.social-details-wrapper {
					.social-details-content {
						padding: 0;
					}
					.result-wrapper {
						img {
							padding: 20px;
						}
					}
				}
			}
		}
	}
}

.postcard-row {
	width: 100%;
	text-align: center;
	.active .preview-box {
		border: 10px solid #5bb65d;
	}
}

.sub-paragraph {
	font-size: 18px;
	color: blue;
}

#brochures-wrapper {
	width: 100%;
	display: block;
	margin: 0 auto;
	.brochures-row {
		display: flex;
		justify-content: center;
		column-gap: 40px;
		row-gap: 1em;
		flex-wrap: wrap;
		margin: 30px auto 20px;
		width: 100%;
		max-width: 1500px;
		.brochures-preview {
			display: flex;
			flex-direction: column;
			cursor: pointer;
			position: relative;
			width: 100%;
			max-width: 300px;
			max-height: 325px;
			gap: 10px;
			box-sizing: content-box;
			.brochures-header {
				height: 50px;
				display: flex;
				h3 {
					font-weight: normal;
					font-size: 1.17em;
					text-align: left;
					word-wrap: break-word;
					margin-top: auto;
					height: fit-content;
				}
			}
			.preview-box {
				border: 10px solid $defaultNeutralLight;
				max-width: 300px;
				height: 100%;
				max-height: calc(100% - 60px);
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				.lazy-load-image-background {
					width: auto;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
				}
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
			}
			&.active:after {
				content: "";
				display: block;
				width: 0;
				position: absolute;
				height: 0;
				left: 50%;
				transform: translate(-50%, 0);
				bottom: -40px;
				z-index: 0;
				border-left: 60px solid transparent;
				border-right: 60px solid transparent;
				border-bottom: 30px solid $defaultNeutralLight;
			}
		}
		.brochures-container {
			.selector-container {
				#image-preview {
					width: 100%;
					max-width: 550px;
					.image-preview-content {
						flex: 1;
					}
					#new-tab {
						display: flex;
						gap: 10px;
						align-items: center;
						justify-content: center;
						img {
							width: 20px;
						}
					}
				}
			}
		}
	}
}

#playbook-wrapper {
	width: 100%;
	display: block;
	margin: 0 auto;
	.playbook-row {
		display: flex;
		justify-content: center;
		column-gap: 40px;
		row-gap: 1em;
		flex-wrap: wrap;
		margin: 30px auto 20px;
		width: 100%;
		max-width: 1500px;
		.playbook-preview {
			display: flex;
			flex-direction: column;
			cursor: pointer;
			position: relative;
			width: 100%;
			max-width: 300px;
			max-height: 325px;
			gap: 10px;
			box-sizing: content-box;
			.playbook-header {
				height: 50px;
				display: flex;
				h3 {
					font-weight: normal;
					font-size: 1.17em;
					text-align: left;
					word-wrap: break-word;
					margin-top: auto;
					height: fit-content;
				}
			}
			.preview-box {
				border: 10px solid $defaultNeutralLight;
				max-width: 300px;
				height: 100%;
				max-height: calc(100% - 60px);
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				.lazy-load-image-background {
					width: auto;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
				}
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
			}
			&.active:after {
				content: "";
				display: block;
				width: 0;
				position: absolute;
				height: 0;
				left: 50%;
				transform: translate(-50%, 0);
				bottom: -40px;
				z-index: 0;
				border-left: 60px solid transparent;
				border-right: 60px solid transparent;
				border-bottom: 30px solid $defaultNeutralLight;
			}
		}
		.playbook-container {
			.selector-container {
				#image-preview {
					width: 100%;
					max-width: 550px;
					.image-preview-content {
						flex: 1;
					}
					#new-tab {
						display: flex;
						gap: 10px;
						align-items: center;
						justify-content: center;
						img {
							width: 20px;
						}
					}
				}
			}
		}
	}
}

.postcard-image {
	margin: 20px 1.5%;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	position: relative;
	h3 {
		font-weight: normal;
		font-size: 1.17em;
		margin-bottom: 10px;
		text-align: left;
	}
	.preview-box {
		border: 10px solid $defaultNeutralLight;
		max-width: 300px;
		img {
			width: 276px;
			height: auto;
		}
	}
	&.active:after {
		content: "";
		display: block;
		width: 0;
		position: absolute;
		height: 0;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: -40px;
		z-index: 0;
		border-left: 60px solid transparent;
		border-right: 60px solid transparent;
		border-bottom: 30px solid $defaultNeutralLight;
	}
}

#post-card-sig {
	.contact {
		width: 3.233in;
		height: 1.3in;
		//top: 224px;
	}
	.legal {
		width: 3.023in;
		height: 0.96in;
		//top: 130px;
		//left: 367px;
	}
	.company {
		width: 2.2in;
		height: 1.26in;
	}
	.location {
		width: 3.023in;
		height: 0.093in;
		//top: 117px;
		//left: 367px;
		p {
			font-size: 9px;
			line-height: 1;
		}
	}
}

#brochure-signature {
	position: relative;
	width: fit-content;
	max-width: 100%;
	border: 1px dotted #888;
	img {
		width: auto;
	}
	.contact {
		width: 2.45in;
		height: 1.4in;
	}
}

#playbook-signature {
	position: relative;
	width: fit-content;
	max-width: 850px;
	border: 1px dotted #888;
	img {
		width: auto;
		max-width: 100%;
	}
	.contact {
		width: 3.9in;
		height: 1.25in;
	}
}

#icon {
	@include spherize(50px);
	border: none;
	border-radius: 50%;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-content: center;
	#CustomTag {
		position: relative;
		margin: 0;
		width: 28px;
		height: 28px;
		color: white;
	}
}

.category-tagging-wrapper {
	width: 100%;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 1em;
	flex: 1 1 100%;
	h3 {
		text-align: left;
		font-family: "Bariol";
		font-size: 1.5em;
	}
	#category-row {
		display: flex;
		flex-direction: column;
		gap: 1em;
		flex-wrap: wrap;
		width: 100%;
		#category {
			width: 100%;
			text-align: left;
			display: flex;
			flex-direction: column;
			gap: 10px;
			* {
				font-size: 1em;
			}
			h4 {
				font-family: "Source Sans Pro";
				font-size: 1.1em;
			}
			.tags {
				display: flex;
				gap: 1em;
				flex-wrap: wrap;

				.form-item {
					margin: 0;
					width: fit-content;
					display: flex;
					gap: 5px;
					flex-direction: row;
					align-items: center;
					* {
						cursor: pointer;
					}
					label {
						padding: 0;
						display: block;
						width: fit-content;
						font-family: "Source Sans Pro";
						font-weight: 100;
						text-transform: none;
					}
					input[type="checkbox"] {
						width: 18px !important;
						height: 18px !important;
						margin: auto 0;
						min-width: fit-content;
					}
					input {
						width: fit-content !important;
						min-width: auto;
					}
				}
			}
		}
	}
	#save-tag {
		width: 150px;
		display: block;
		border-radius: 40px;
		font-family: "Bariol";
		font-size: 18px;
		width: 230px;
		padding: 10px 0;
	}
}

#socialads-page {
	width: 100%;
	.selector-container {
		.left-col {
			width: 100%;
			padding: 0;
			h2 {
				color: $darkblue;
			}
			hr {
				margin: 0;
			}
			.actions-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				padding: 0;
			}
			.preview-box {
				position: relative;
				.preview-overlay {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba($color: #fff, $alpha: 0.6);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					opacity: 0;
					visibility: hidden;
					transition: 0.3s;

					svg {
						color: $darkblue;
						width: 30px;
						height: auto;
					}
				}

				&:has(.preview-overlay) {
					&:hover {
						.preview-overlay {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
			.image-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				height: fit-content;
				flex-wrap: wrap;
				gap: 2.5em;
				&.carousel {
					flex-direction: column;
					justify-content: flex-start;
					& > * {
						flex: 0 0 fit-content;
					}
					h3 {
						color: $darkblue;
						font-family: "Source Sans Pro";
					}
					#social-ads-filters {
						justify-content: flex-start;
						.tab1 {
							&.active {
								border-color: $tmsblue;
								svg,
								a {
									color: $tmsblue;
								}
							}
						}
					}

					.social-media-accounts-wrapper {
						flex: 0 0 fit-content;
					}
				}
				.image-heading {
					padding: 10px 0;
				}
				.image-wrapper {
					flex: 0 0 min(20%, 250px);
					height: fit-content;
					display: flex;
					flex-direction: column;
					gap: 10px;
					align-items: center;
					.social-image {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 300px;
					}
					@media screen and (max-width: $mobileLargeBreak) {
						flex: 0 0 100%;
					}
					p {
						color: rgba($color: $darkblue, $alpha: 1);
					}
					.btn {
						width: fit-content;
						min-width: auto;
						text-decoration: none;
						margin: 0;
					}
					&.carousel {
						flex: 0 0 min(100%, 450px);
						.carousel-wrapper {
							display: flex;
							flex-direction: row;
							gap: 25px;
							flex-wrap: wrap;
							width: 100%;
							.carousel-root {
								width: 100%;
							}
							.carousel-slider {
								width: min(100%, 500px) !important;
								margin: 0;
								padding: 20px;
								@media screen and (max-width: $mobileLargeBreak) {
									.social-image {
										width: auto;
										height: auto;
										max-width: 75%;
										max-height: 200px;
									}
								}
							}
							.control-arrow {
								background-color: rgba($color: $defaultNeutral, $alpha: 1);
							}
							.thumbs-wrapper {
								margin: 20px 0;
								.thumbs {
									display: flex;
									flex-direction: row;
									flex-wrap: wrap;
									gap: 5px;
									li.thumb {
										max-width: 100px;
										max-height: 100px;
										display: flex;
										justify-content: center;
										align-items: center;
										margin: 0;
										padding: 0;
										cursor: pointer;
										img {
											max-width: 100%;
											max-height: 100%;
										}
										&.selected,
										&:hover {
											border-color: $tmsgreen;
										}
									}
								}
							}
							.carousel-image-wrapper {
								padding-bottom: 20px;
								p {
									margin: 0;
								}
							}
						}
					}
				}
				.social-media-accounts-wrapper {
					display: flex;
					flex-direction: column;
					flex: 1 0 min(100%, 750px);
				}
			}
			.custom-card-wrapper {
				display: flex;
				flex-direction: column;
				gap: 1em;
				.col {
					display: flex;
					flex-direction: column;
					gap: 0.75em;
					h3 {
						color: rgba($color: $darkblue, $alpha: 1);
						font-family: "Bariol Light";
						font-size: 1.4em;
					}
					#blog-link {
						display: flex;
						gap: 1em;
						align-items: flex-end;
						.form-item {
							flex: 1;
							gap: 0.5em;
							label {
							}
						}
					}
				}
			}
			.social-ad-account {
				margin: 0;
				padding: 1em;
				display: flex;
				flex-direction: column;
				gap: 1em;
				background-color: white;
				&:nth-of-type(2n + 1) {
					background-color: $defaultNeutralLight;
				}
				.wrapper {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 1em;
					align-items: flex-start;
					@media screen and (max-width: $mobileLargeBreak) {
						flex-direction: column;
					}
				}
				&:last-of-type {
					border-bottom: none;
				}
				#category-title {
					position: relative;
					cursor: pointer;
					display: flex;
					gap: 0.5em;
					align-items: center;
					height: fit-content;
					flex: 0 0 fit-content;
					align-items: center;
					#checkbox {
						border: 1px solid $darkblue;
						width: 26px;
						height: 26px;
						border-radius: 50%;
						display: block;
						margin: auto 0;
					}
					h3 {
					}
				}
				#dropdown {
					display: none;
					flex-direction: row;
					margin: 0;
					gap: 12px;
					flex-wrap: wrap;
					justify-content: flex-end;
					.btn {
						margin: 0;
						flex: 0 0 max-content;
					}
				}
				.authorize-banner {
					display: none;
					// color: white !important;
				}
				&.active {
					#category-title {
						#checkbox {
							background-color: white;
							position: relative;
							&::after {
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 16px;
								height: 16px;
								background: $tmsblue;
								border-radius: 50%;
							}
						}
					}
					#dropdown {
						display: flex;
					}
					.authorize-banner {
						display: flex;
					}
				}
			}
			.text-suggestions {
				padding: 20px 0;
			}
			.blog-embed-container {
				.image-wrapper {
					flex: 0 0 min(30%, 450px);
				}
				a {
					cursor: pointer;
					width: 100%;
				}
				.btn {
					width: fit-content;
					min-width: 150px;
					margin: 10px 0;
					text-decoration: none;
				}
				img {
					width: 100%;
					max-width: 500px;
					@media screen and (max-width: $mobileLargeBreak) {
						max-width: 80%;
					}
				}
			}
			.embed-wrapper {
				flex: 0 0 min(100%, 450px);
				width: min(100%, 450px);
				.embed-container {
					position: relative;
					overflow: hidden;
					width: 100%;
					min-height: 425px;
					margin: 10px 0 0 0;
					min-height: 325px;
					background-color: rgba($color: $defaultNeutralLight, $alpha: 1);
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					@media screen and (max-width: $tabletBreak) {
						padding-bottom: 45%;
					}
					@media screen and (max-width: $tabletBreak) {
						padding-bottom: 60%;
						min-height: 300px;
					}
				}
				.btn {
					width: fit-content;
					min-width: 150px;
					margin: 20px 0;
					text-decoration: none;
				}
			}
			.image-container-2 {
				height: 350px;
				margin-bottom: 25px;
				.left-image-div {
					.left-image {
						height: 270px;
						width: auto;
					}
				}
			}
			.text-suggestions {
				h2 {
					color: black;
					margin-bottom: 10px;
				}
				.text-wrapper {
					display: flex;
					flex-direction: row;
					gap: 30px;
					margin: 40px 0;
					position: relative;
					#divider {
						position: absolute;
						bottom: -20px;
						width: 75px;
						border-top: 1px solid black;
					}
					.text {
						padding: 0;
						margin: auto 0;
					}
					&:first-of-type {
						margin-top: 20px;
					}
					&:last-of-type {
						#divider {
							border: 0;
						}
					}
				}
				p {
					color: black;
					padding: 10px 0px;
					margin: 0;
					font-size: 1.1em;
				}
				.copy-button {
					margin: auto 0;
					padding: 8px 14px;
					color: white;
					font-size: 1em;
					background: #9f9f9f;
					text-shadow: none;
					border: none;
					height: fit-content;
					cursor: pointer;
				}
			}
		}
		.right-col {
			width: 25%;
			padding: 10px 20px;
			text-align: center;
			height: 400px;
			//vertical-align: middle;
			.right-col-alignment {
				margin-top: 180px;
			}
		}
	}
}

#material-container {
	width: 100%;
	margin: 0;
	display: flex;
	gap: 40px;
	position: relative;
}

.library-material-wrapper {
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 1em;
	&:has(> #category-filter) {
		width: calc(100% - 280px);
		margin-left: 280px;
	}
	.material-content-wrapper {
		display: flex;
		gap: 2em;
		position: relative;
		flex-wrap: wrap;

		.material-content {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			column-gap: 60px;
			row-gap: 0px;
			width: 100%;
			margin: 0 auto;
			flex: 1 0 450px;
		}

		.material-tag-wrapper {
			flex: 1 1 100%;
		}

		.material-tag {
			display: flex;
			flex-direction: column;
			width: 100%;

			summary {
				display: inline-flex;
				align-items: center;
				border: 1px solid #d3d3d3;
				padding: 15px 20px;
				color: $darkblue;
				background-color: white;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				* {
					transition: all 0.1s ease-in-out;
				}

				&::marker {
					// display: none;
				}

				h3 {
					font-weight: normal;
					font-size: 1.2em;
					text-align: left;
					word-wrap: break-word;
					margin: 0;
				}
				svg {
					width: 20px;
					height: 20px;
					// transform: rotate(0deg);
					transition: all 0.15s ease-in-out;
					&.open {
						display: block;
					}
					&.close {
						display: none;
					}
				}
			}
			&[open] {
				summary {
					background-color: rgba($color: $darkblue, $alpha: 1);
					border-color: $darkblue;
					color: white;
					svg {
						&.open {
							display: none;
						}
						&.close {
							display: block;
						}
					}
				}
			}
		}

		.content-list-container {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			flex-wrap: wrap;
			margin: 0;
			width: 100%;
			padding: 1em;
			gap: 3.5em;
			height: fit-content;

			.no-content {
				margin: 0;
				padding: 1em 0;
				font-size: 1.15em;
			}

			.content-preview {
				display: flex;
				flex-direction: column;
				cursor: pointer;
				position: relative;
				gap: 5px;
				width: min(100%, 225px);

				.content-preview-header {
					display: flex;
					flex-direction: column;
					gap: 5px;
					flex: 0 0 fit-content;
					* {
						line-height: normal;
					}
					h3 {
						font-weight: normal;
						font-size: 1.2em;
						text-align: left;
						word-wrap: break-word;
						width: 100%;
					}
					.subheader {
						font-style: italic;
						font-size: 1em;
					}
				}
				.preview-box {
					width: 100%;
					height: 250px;
					border: 5px solid $defaultNeutralLight;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					padding: 5px;
					.lazy-load-image-background {
						width: 100%;
						max-width: 100%;
						height: 100%;
						max-height: 100%;
						display: flex !important;
						align-items: center;
						justify-content: center;
						box-sizing: border-box;
					}
					img {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
						display: block;
					}
					&.logos {
						background-color: rgba($color: $defaultNeutralLight, $alpha: 1);
					}
				}
				&.active {
					.preview-box {
						border-color: $tmsblue;
					}
				}
			}
			@media screen and (max-width: $tabletBreak) {
				.content-preview {
					// width: calc(50% - 25px);
				}
			}

			@media screen and (max-width: $mobileLargeBreak) {
				justify-content: center;
				flex: 1 0 100%;
				gap: 2em;
				.content-preview {
					width: min(300px, 100%);
				}
			}
		}

		// .material-overlay {
		// 	position: fixed;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100vw;
		// 	height: 100svh;
		// 	background-color: rgba($color: $defaultNeutral, $alpha: 1);
		// 	z-index: 100;
		// 	@media screen and (min-width: $tabletBreak) {
		// 		display: none;
		// 	}
		// }

		.material-content-container {
			width: min(100%, $contentMaxWidth);
			border: 1px solid $defaultNeutralLight;
			margin: auto;
			padding: 2em;
			transform: translateY(0);
			margin-bottom: 25px;
			box-shadow: 0px 0px 10px 0 $defaultNeutralLight;
			position: sticky;
			top: 1em;
			height: fit-content;
			max-height: calc(100vh - 2em);
			overflow-y: auto;
			flex: 1;
			margin: 0;

			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 2em;

			.admin-actions {
				display: flex;
				flex-direction: row;
				gap: 10px;
				* {
					flex: 0 0 fit-content;
					width: fit-content;
				}
			}

			.header-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				#title {
					font-family: "Source Sans Pro";
					color: $tmsblue;
					font-size: 2.5em;
					text-transform: none;
					font-weight: 100;
				}
			}

			hr {
				border: none;
				border-top: 1px solid #d3d3d3;
			}

			.edit-button {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
			}

			.selector-content-wrapper {
				display: flex;
				flex-direction: column;
				gap: 2em;
				flex: 1 0 450px;
				.selector-container {
					display: flex;
					gap: 2em;
					flex-direction: column;
					.user-actions-wrapper {
						display: flex;
						flex-wrap: wrap;
						gap: 0.75em;
						width: 100%;
						.btn {
							width: fit-content;
							max-width: 100%;
						}
					}
					.preview-file-wrapper {
						#image-preview {
							max-width: 550px;
							.image-preview-content {
								flex: 1;
							}
						}
					}
					#heading-photo {
						display: flex;
						width: 100%;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;
						padding: 0;
						gap: 1em;
						h2 {
							margin: 0;
							font-size: 1.8em;
							flex: 0 0 fit-content;
							font-family: "Bariol";
						}
						#my-photo-container {
							display: flex;
							flex: 0 0 fit-content;
							align-items: center;
							gap: 0.5em;
							img {
								width: 65px;
								aspect-ratio: 1 / 1;
								border-radius: 50%;
							}
							label {
								display: flex;
								align-items: center;
								position: relative;
								gap: 0.5em;
								input[type="checkbox"] {
									position: absolute;
									opacity: 0;
									cursor: pointer;
									min-width: fit-content;
								}
								.checkbox-custom {
									margin: auto;
									height: 22px;
									width: 22px;
									background-color: transparent;
									border-radius: 5px;
									border: 1px solid #c4c4c4;
									cursor: pointer;
									&::after {
										position: absolute;
										content: "";
										left: 12px;
										top: 12px;
										height: 0;
										width: 0;
										border-radius: 5px;
										border: solid $tmsblue;
										border-width: 0 3px 3px 0;
										-webkit-transform: rotate(0deg) scale(0);
										-ms-transform: rotate(0deg) scale(0);
										transform: rotate(0deg) scale(0);
										opacity: 0;
									}
								}
								input:checked ~ .checkbox-custom {
									background-color: white;
									border-radius: 5px;
									-webkit-transform: rotate(0deg) scale(1);
									-ms-transform: rotate(0deg) scale(1);
									transform: rotate(0deg) scale(1);
									opacity: 1;
									border: 2px solid #c4c4c4;
								}
								input:checked ~ .checkbox-custom::after {
									-webkit-transform: rotate(40deg) scale(1);
									-ms-transform: rotate(40deg) scale(1);
									transform: rotate(40deg) scale(1);
									opacity: 1;
									left: 9px;
									top: -10px;
									height: 28px;
									width: 10px;
									border: solid $tmsblue;
									border-width: 0 2px 2px 0;
									background-color: transparent;
									border-radius: 0;
								}
								p {
									margin: 0;
									font-weight: 500;
									font-size: 1.15em;
									line-height: 1.05em;
									color: $tmsblue;
									span {
										display: block;
										font-size: 0.75em;
										color: #333;
										opacity: 0.6;
										font-weight: 300;
									}
								}
							}
						}
					}

					@media screen and (max-width: $mobileLargeBreak) {
						#heading-photo {
							// flex-direction: column;
							// text-align: center;
							// margin-bottom: 10px;
							h2 {
								width: 100%;
							}
							#my-photo-container {
								height: fit-content;
								width: fit-content;
								text-align: center !important;
								label {
									text-align: left;
									p {
										line-height: 1.2em;
									}
								}
							}
						}
					}
					#info-form {
						text-align: center;
						width: 100%;

						display: grid;
						grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
						gap: 1.5em;

						.form-item {
							margin: 0;
							width: 100%;
							display: flex;
							flex-direction: column;
							gap: 5px;
							label {
								text-align: left;
							}
							input,
							select {
								width: 100% !important;
								border-radius: 5px;
								padding: 8px;
							}
						}
						.branch_address {
							h3 {
								text-align: left;
								font-size: 1.2em;
								margin-bottom: 5px;
							}
						}
					}
				}
			}

			.dropdown-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 15px;
				padding: 0;
				width: 100%;
				.btn {
					min-width: 155px;
					max-width: 100%;
				}
				.dd-text {
					text-transform: lowercase;
					text-align: center;
					width: 100%;
					color: white;
					font-family: "Bariol";
					font-size: 1.1em;
				}
			}

			.history-wrapper {
				display: flex;
				flex-direction: column;
				gap: 1em;
				h3 {
					text-align: left;
					font-family: "Bariol";
					font-size: 1.5em;
				}

				.history-container {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					gap: 30px;
					.history-card {
						padding: 20px;
						flex: 0 0 min(100%, 275px);
						box-shadow: 0px 0px 10px 0 $defaultNeutralLight;
						border-radius: 20px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 5px;
						h4 {
							text-align: center;
							width: 100%;
							color: $darkblue;
							font-size: 1.25em;
						}
						.preview-box {
							max-height: 245px;
							width: 100%;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
						}
						.btn {
							margin: 20px auto 0;
							width: fit-content;
							max-width: 100%;
						}
					}
				}
			}

			#close {
				width: 25px;
				height: 25px;
				position: absolute;
				right: 7px;
				top: 7px;
				cursor: pointer;
			}

			.right-col-wrapper {
				flex: 0 0 min(25%, 300px);
				display: flex;
				flex-direction: column;
				gap: 15px;
				align-items: center;
				.preview-box {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 10px;
					border: none;
					width: 100%;
					position: relative;
					img {
						width: 100%;
						max-width: 100%;
						height: auto;
					}

					.preview-overlay {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: rgba($color: #fff, $alpha: 0.6);
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						opacity: 0;
						visibility: hidden;
						transition: 0.3s;

						svg {
							color: $darkblue;
							width: 30px;
							height: auto;
						}
					}

					&:has(.preview-overlay) {
						&:hover {
							.preview-overlay {
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
			.embed-container {
				position: relative;
				width: min(100%, 750px);
				padding-top: calc(9 / 16 * 100%);
				overflow: hidden;
				background-color: rgba($color: $defaultNeutral, $alpha: 1);
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 0;
				}
			}
		}
		&:has(.material-content-container) {
			.material-tag-wrapper {
				flex: 0 0 min(35%, 225px) !important;
			}

			.content-list-container {
				gap: 3em;
				justify-content: space-between;
			}

			.material-content {
				flex: 0 0 min(25%, 1250px) !important;
				height: fit-content;
			}

			.material-content,
			.material-tag-wrapper {
				.content-preview {
					flex: 1 0 max(20%, 175px);
					.preview-box {
						height: 195px;
					}
				}
			}
		}
		@media screen and (max-width: $tabletBreak) {
			.material-content-container {
				position: fixed;
				top: 75px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 110;
				width: 90vw;
				height: fit-content;
				max-height: calc(95svh - 75px);
				background-color: white;

				.selector-content-wrapper {
					flex: 0 0 100%;
					height: fit-content;
				}

				.selector-container {
					.col {
						flex: 0 0 fit-content;
					}
				}
				.right-col-wrapper {
					width: 100%;
					flex: 0 0 min(100%, 295px);
				}
				.form-item {
					flex: 0 0 fit-content;
					width: 100%;
				}
			}
		}
	}
}

.arrow {
	border: solid black;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	position: absolute;
	right: 7px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	margin-top: 10px;
	&.active {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		margin-top: 6px;
	}
}

#pages {
	#sub-header {
		color: $darkblue;
	}
}

#portal-page {
	padding-top: 20px;
	#header-container {
		max-height: fit-content;
		margin-bottom: 30px;
		padding: 0;
	}
	.container-1 {
		width: 100%;
		margin: 0;
		#block-container,
		#block-container-2 {
			height: 600px;
			width: 60%;
			position: relative;
			display: block;
			align-items: center;
			display: flex;
			justify-content: center;
			.icon {
				margin: auto;
				display: block;
				width: 90px;
				height: 90px;
				background: white;
				border-radius: 50%;
				padding: 0px;
				display: block;
				position: relative;
				img {
					width: 55px;
					position: absolute;
					margin: auto;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
			}
			h2 {
				text-align: center;
				margin-top: 5px;
				font-size: 52px;
				font-family: "Bariol Light";
				color: white;
				cursor: pointer;
				font-weight: 100;
			}
			#medium-block {
				height: 450px;
				position: relative;
				align-items: center;
				justify-content: center;
			}
			.background {
				width: 100%;
				position: absolute;
				height: 100%;
				z-index: -999;
				object-fit: cover;
				object-position: center;
			}
			&.show {
				opacity: 0.9;
			}
		}
		#block-container-2 {
			width: 40%;
			justify-content: initial;
			height: fit-content;
			flex-direction: column;
			align-items: initial;
			.icon {
				width: 55px;
				height: 55px;
				img {
					width: 35px;
				}
			}
			h2 {
				font-size: 30px;
			}
			#medium-block {
				justify-content: center;
				#medium-content {
					justify-content: center;
				}
				#content {
					position: relative;
					top: 150px;
				}
				iframe {
					width: calc(100% + 1px) !important;
					height: 100% !important;
					min-width: 100%;
					min-height: 100%;
					display: block;
					position: absolute;
					left: -1px !important;
				}
			}
			#small-block {
				height: 140px !important;
				background: #fff;
				align-items: center;
				display: flex;
				justify-content: center;
			}
		}
	}
}

@media screen and (max-width: 1800px) {
	#portal-page {
		.container-1 {
			padding: 0;
			#block-container {
				width: 60%;
				height: 600px;
			}
			#block-container-2 {
				width: 41%;
				height: 600px;
				#medium-block {
					height: 460px;
				}
			}
		}
	}
}

@media screen and (max-width: 1700px) {
	#portal-page {
		.container-1 {
			#block-container {
				width: 55%;
				height: 550px;
			}
			#block-container-2 {
				width: 45%;
				height: 550px;
				#medium-block {
					height: 410px;
				}
			}
		}
	}
}

@media screen and (max-width: 1370px) {
	#portal-page {
		.container-1 {
			#block-container {
				width: 52%;
				height: 550px;
			}
			#block-container-2 {
				width: 48%;
				height: 550px;
				#medium-block {
					height: 410px;
				}
			}
		}
	}
}

@media screen and (max-width: $tabletBreak) {
	#portal-page {
		.container-1 {
			#block-container {
				width: 52%;
				height: 550px;
			}
			#block-container-2 {
				width: 49%;
				height: 550px;
				#medium-block {
					height: 410px;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	#portal-page {
		.container-1 {
			#block-container {
				width: 50%;
				height: 550px;
			}
			#block-container-2 {
				width: 50%;
				height: 500px;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	#portal-page {
		padding-top: 5px;
		.container-1 {
			flex-direction: column;
			#block-container,
			#block-container-2 {
				width: 100%;
				height: 470px;
				max-height: 500px;
				flex-direction: column;
				#medium-block {
					min-height: 300px;
					max-height: 360px;
					height: auto;
					#content {
						top: 100px;
						.icon {
							width: 60px;
							height: 60px;
						}
					}
				}
				#small-block {
					height: 290px !important;
					#content {
						.icon {
							width: 60px;
							height: 60px;
						}
					}
				}
			}
			&.reverse {
				flex-direction: column-reverse;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	#portal-page {
		padding-top: 5px;
		.container-1 {
			flex-direction: column;
			#block-container,
			#block-container-2 {
				width: 100%;
				height: 450px;
				max-height: 480px;
				flex-direction: column;
				#medium-block {
					height: 300px;
					max-height: 320px;
					#content {
						top: 120px;
						.icon {
							width: 60px;
							height: 60px;
						}
					}
				}
				#small-block {
					height: 150px !important;
					#content {
						.icon {
							width: 60px;
							height: 60px;
						}
					}
				}
			}
			&.reverse {
				flex-direction: column-reverse;
			}
		}
	}
}

.request-form {
	input {
		width: fit-content !important;
	}
}

@media screen and (max-width: 1200px) {
	#material-container {
		flex-direction: column;
		&.open-house-flyer-page {
			#category-filter {
				display: none;
			}
		}
	}

	#category-filter-media {
		display: block;
	}
}

#spotlight {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 110;
	background: rgba(0, 0, 0, 0.45);
	opacity: 1;
	.spotlight-container {
		text-align: center;
		height: fit-content;
		max-height: 90svh;
		padding-bottom: 20px;
		width: 505px;
		overflow: unset;
		background: #fff;
		margin: auto;
		top: 12%;
		margin-top: -10px;
		border-radius: 35px;
		z-index: inherit;
		position: relative;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		#top-row {
			background-color: $danger;
			height: 220px;
			padding-top: 20px;
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			h2 {
				font-family: "Bariol Light";
				font-size: 5em;
				font-weight: 100;
				color: white;
				opacity: 0.3;
			}
		}
		.icon1 {
			margin-top: -90px;
			width: 200px;
		}
		.spotlight-content {
			padding-top: 10px;
			text-align: center;
			h3 {
				margin: auto;
				font-family: "Bariol";
				font-size: 2.2em;
				color: $danger;
			}
			p {
				font-family: "Bariol";
				font-size: 1.24em;
				padding: 3px 0;
				margin-bottom: 15px;
			}
			.upgradeBtn,
			.enrollBtn {
				display: flex;
				padding: 15px 20px;
				text-align: center;
				cursor: pointer;
				border-radius: 20px;
				width: 300px;
				margin: 12px auto;
				color: white;
				text-decoration: none;
				position: relative;
				svg,
				span {
					margin: auto;
					font-weight: bold;
					font-family: "Bariol";
					font-size: 1.05em;
				}
			}
			.upgradeBtn {
				background-color: $danger;
			}

			.enrollBtn {
				background-color: #942779;
			}
			.compare-button {
				padding: 10px 20px;
				margin: 10px auto;
				color: black;
				font-size: 1.2em;
				font-family: "Bariol";
				display: block;
			}
		}
	}
}

@media screen and (max-height: 900px) {
	#spotlight {
		.spotlight-container {
			max-width: 450px;
			min-width: initial;
			top: 12%;
			margin-top: -10px;
			#top-row {
				height: 190px;
			}
			.icon1 {
				width: 185px;
				margin-top: -80px;
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	#spotlight {
		.spotlight-container {
			width: 85vw;
			max-width: 450px;
			min-width: initial;
			top: 12%;
			margin-top: -10px;
		}
	}
}

#spotlight-payment-page {
	display: flex;
	width: 1200px;
	margin: 0 auto;
	justify-content: space-between;
	margin-top: 120px;
	.left-col {
		width: 580px;
		margin-left: 0px;
		height: fit-content;
		position: relative;
		max-width: 610px;
		#hexagon-align {
			position: absolute;
			right: 65px;
			top: 5px;
			#hexagon {
				background-color: $danger;
				color: #fff;
				padding: 10.5px 18px;
				text-align: center;
				position: absolute;
				font-family: "Bariol";
				font-size: 1.1em;
				font-weight: bold;
				&::after {
					content: " ";
					position: absolute;
					left: -25px;
					top: 0px;
					border-top: 22px solid transparent;
					border-right: 25px solid $danger;
					border-left: none;
					border-bottom: 22px solid transparent;
				}
			}
		}
		h3 {
			color: $danger;
			font-size: 2.4em;
			font-family: "Bariol Bold";
			font-weight: 600;
			z-index: 1;
		}
		.one {
			right: 35px;
			left: initial;
			background: $danger;
			width: 170px;
			height: 170px;
			opacity: 0.15;
			z-index: -99999;
		}
		.two {
			left: -40px;
			background: $danger;
			width: 125px;
			bottom: 95px;
			top: initial;
			height: 125px;
			opacity: 0.15;
			z-index: -99999;
		}
		p {
			font-size: 1.2em;
			width: 80%;
		}
		#features {
			position: relative;
			padding: 15px 70px;
			li {
				position: relative;
				font-size: 1.15em;
				list-style: none;
				padding: 14px 0;
				margin-left: 30px;
				span {
					display: inline-block;
					position: absolute;
					left: -45px;
					top: 5px;
					transform: rotate(45deg);
					height: 28px;
					width: 14px;
					border-bottom: 5px solid $danger;
					border-right: 5px solid $danger;
				}
			}
		}
		#features-2 {
			padding-left: 25px;
			li {
				list-style-type: disc;
				padding: 4px 0;
				font-weight: normal;
				font-size: 1.15em;
			}
		}
		#spotlight-unlimited-component {
			margin: 40px 0;
			display: flex;
			height: fit-content;
			padding-top: 40px;
			border-top: 1px solid #666;
			position: relative;
			p {
				padding: 5px 0;
				width: fit-content;
				font-size: 1.25em;
			}
			a {
				display: block;
				padding: 7px 0;
				font-family: "Bariol";
				font-weight: bold;
				font-size: 1.15em;
				text-align: center;
				cursor: pointer;
				width: 150px;
				border-radius: 20px;
				color: white;
				text-decoration: none;
				position: absolute;
				right: 0;
				background-color: #942779;
				svg,
				span {
					margin: auto;
				}
			}
		}
	}
	.right-col {
		width: 450px;
		#payment {
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;
			h3 {
				color: $danger;
				font-size: 2.4em;
				font-family: "Bariol Bold";
				font-weight: 600;
				z-index: 1;
			}
			#cards {
				margin: auto 0;
				height: fit-content;
			}
			img {
				margin: auto;
				display: block;
				max-width: 220px;
			}
		}
		form {
			.submit-payment {
				display: block;
				padding: 15px 0;
				font-weight: bold;
				font-family: "Bariol";
				font-size: 1.3em;
				text-align: center;
				cursor: pointer;
				border-radius: 20px;
				width: 100%;
				margin: 0px auto;
				margin-top: 20px;
				color: white;
				text-decoration: none;
				position: relative;
				background-color: $danger;
				svg,
				span {
					margin: auto;
				}
			}
		}
	}
}

@media screen and (max-width: 1550px) {
	#spotlight-payment-page {
		width: 90vw;
	}
}

@media screen and (max-width: $tabletBreak) {
	#spotlight-payment-page {
		flex-direction: column;
		margin-top: 60px;
		width: 95vw;
		margin-bottom: 80px;
		.left-col {
			width: 100%;
			margin: 0 auto;
			max-width: 625px;
			.one {
				width: 140px;
				height: 140px;
				right: 50px;
			}
			.two {
				left: -10px;
				bottom: 130px;
			}
			h3 {
				font-size: 2em;
				max-width: 75vw;
			}
			p {
				font-size: 1em;
				padding-top: 7px;
			}
			#hexagon-align {
				right: 84px;
				top: 0;
			}
			#features {
				width: 100%;
				padding: 15px 50px;
			}
			#features-2 {
				li {
					padding: 6px 0;
				}
			}
			#spotlight-unlimited-component {
				p {
					width: calc(100% - 153px);
				}
			}
		}
		.right-col {
			position: relative;
			right: initial;
			width: 625px;
			margin-bottom: 40px;
			margin: 0 auto;
			max-width: 625px;
			form {
				.submit-payment {
					padding: 30px 0;
					width: 100%;
					margin: 0px auto;
					color: white;
					text-decoration: none;
					font-size: 2.1em;
					position: fixed;
					bottom: 0px;
					left: 0;
					z-index: 110;
					border-radius: 0;
					text-align: center;
					background-color: $danger;
					svg,
					span {
						margin: auto;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#spotlight-payment-page {
		.right-col {
			width: 95%;
			margin-bottom: 60px;
		}
	}
}

#payment-page {
	#payment-page-header {
		height: fit-content;
		width: 100%;
		position: relative;
		background: url(/images/spotlight/payment_confirmation_header.png) no-repeat center;
		background-size: cover;
		padding: 40px 0;
		text-align: center;
		img {
			display: block;
			margin: auto;
			padding: 5px 0;
			width: 60px;
		}
		h1 {
			color: white;
			font-family: "Bariol Light";
			font-size: 4em;
			font-weight: 100;
		}
	}
	#cart-columns {
		display: flex;
		width: 1100px;
		max-width: 100%;
		justify-content: space-between;
		margin: 80px auto;
		.left-col {
			width: fit-content;
			position: relative;
			width: 535px;
			height: fit-content;
			.one {
				right: 65px;
				left: initial;
				background: $tmsblue;
				width: 170px;
				height: 170px;
				opacity: 0.05;
				z-index: -99999;
			}
			.two {
				left: -80px;
				background: $tmsblue;
				width: 115px;
				bottom: -15px;
				top: initial;
				height: 115px;
				opacity: 0.05;
				z-index: -99999;
			}
			h2 {
				color: $darkblue;
				font-family: "Bariol Bold";
				font-size: 2.75em;
				margin: 0;
			}
			h3 {
				color: $tmsblue;
				font-family: "Source Sans Light";
				font-size: 1.8em;
				font-weight: 100;
				margin-top: 10px;
			}
			p {
				padding: 7px 0;
				font-size: 1.05em;
				font-family: "Source Sans Pro";
			}
			#features {
				margin: 15px 0px 15px 35px;
				li {
					padding: 2px 0;
					font-size: 1.2em;
				}
			}
		}
		.right-col {
			width: 450px;
			#payment {
				margin-bottom: 15px;
				h2 {
					color: $darkblue;
					font-size: 2.75em;
					font-family: "Bariol Bold";
					font-weight: 600;
					z-index: 1;
				}
			}
			#cart-items {
				border-bottom: 2px solid #777;
				padding: 5px 0;
				#item {
					margin: 25px 0;
					box-shadow: none;
					position: relative;
					display: flex;
					justify-content: space-between;
					text-align: left;
					&:last-of-type {
						margin-bottom: 10px;
					}
					h4 {
						color: #0060a9;
						font-size: 1.4em;
						font-family: "Bariol";
						padding-bottom: 3px;
						max-width: 280px;
						height: fit-content;
						margin: auto 0;
					}
					h5 {
						font-size: 1.1em;
						font-family: "Source Sans Light";
						font-weight: 100;
						max-width: 120px;
						height: fit-content;
						color: #666;
						margin: auto 0;
					}
				}
				p {
					text-align: right;
					margin: 20px 0;
					font-size: 1.4em;
					color: $darkblue;
					font-family: "Bariol Bold";
				}
			}
			#payment-items {
				padding: 20px 0;
				#items {
					display: flex;
					justify-content: space-between;
					padding: 10px 0;
					h5 {
						color: #0060a9;
						font-size: 1.4em;
						font-family: "Source Sans Light";
						padding-bottom: 3px;
						max-width: 280px;
						height: fit-content;
						margin: auto 0;
					}
					p {
						margin-left: 8px;
						font-size: 18px;
						color: #777;
					}
					#card-container {
						display: flex;
						img {
							width: 50px;
							height: auto;
						}
					}
				}
			}
		}
	}
	.submit-payment {
		display: block;
		padding: 15px 0;
		font-weight: bold;
		font-family: "Bariol";
		font-size: 1.3em;
		text-align: center;
		cursor: pointer;
		border-radius: 40px;
		width: 100%;
		margin: 0px auto;
		color: white;
		text-decoration: none;
		position: relative;
		background-color: $darkblue;
		svg,
		span {
			margin: auto;
		}
	}
}

@media screen and (max-width: 1200px) {
	#payment-page {
		#payment-page-header {
			padding: 50px 0;
			text-align: center;
			img {
				width: 50px;
			}
			h1 {
				font-size: 2.7em;
			}
		}
		#cart-columns {
			width: 100%;
			max-width: 100%;
			padding: 0 20px;
			margin: 60px auto;
			flex-direction: column;
			.left-col {
				width: 100%;
				max-width: 750px;
				margin: 0 auto;
				.one {
					right: 25px;
					left: initial;
					background: $tmsblue;
					width: 135px;
					height: 135px;
					opacity: 0.05;
					z-index: -99999;
				}
				.two {
					left: -80px;
					background: $tmsblue;
					width: 115px;
					bottom: -15px;
					top: initial;
					height: 115px;
					opacity: 0.05;
					z-index: -99999;
				}
				h2 {
					color: $darkblue;
					font-family: "Bariol Bold";
					font-size: 2.75em;
					margin: 0;
				}
				h3 {
					color: $tmsblue;
					font-family: "Source Sans Light";
					font-size: 1.8em;
					font-weight: 100;
					margin-top: 10px;
				}
				p {
					padding: 7px 0;
					font-size: 1.05em;
					font-family: "Source Sans Pro";
				}
				#features {
					margin: 15px 0px 15px 35px;
					li {
						padding: 2px 0;
						font-size: 1.2em;
					}
				}
			}
			.right-col {
				width: 100%;
				max-width: 750px;
				margin: 0 auto;
				margin-top: 40px;
				#cart-items {
					#item {
						margin: 20px 0;
						&:last-of-type {
							margin-bottom: 10px;
						}
					}
					p {
						text-align: right;
						margin: 20px 0;
						font-size: 1.4em;
						color: $darkblue;
						font-family: "Bariol Bold";
					}
				}
				#payment-items {
					padding: 20px 0;
					#items {
						display: flex;
						justify-content: space-between;
						padding: 10px 0;
						h5 {
							color: #0060a9;
							font-size: 1.4em;
							font-family: "Source Sans Light";
							padding-bottom: 3px;
							max-width: 280px;
							height: fit-content;
							margin: auto 0;
						}
						p {
							margin-left: 8px;
							font-size: 18px;
							color: #777;
						}
						#card-container {
							display: flex;
							img {
								width: 50px;
								height: auto;
							}
						}
					}
				}
			}
		}
	}
	.submit-payment {
		max-width: 700px;
	}
}

#cart-page {
	#cart-page-header {
		height: fit-content;
		width: 100%;
		position: relative;
		background: url(/images/spotlight/spotlight_services_header.png) no-repeat center;
		background-size: cover;
		text-align: center;
		// margin-bottom: 175px;
		img {
			display: block;
			margin: auto;
			padding: 40px 0;
			width: 390px;
		}
	}
	#cart-columns {
		display: flex;
		width: 1300px;
		max-width: 100%;
		justify-content: space-between;
		margin: 80px auto;
		.left-col {
			width: fit-content;
			position: relative;
			width: 635px;
			height: fit-content;
			.one {
				right: 65px;
				left: initial;
				background: $tmsblue;
				width: 170px;
				height: 170px;
				opacity: 0.05;
				z-index: -99999;
			}
			.two {
				left: -80px;
				background: $tmsblue;
				width: 135px;
				bottom: -35px;
				top: initial;
				height: 135px;
				opacity: 0.05;
				z-index: -99999;
			}
			h1,
			h2 {
				color: rgb(0, 96, 170);
				font-family: "Bariol";
				font-size: 2.35em;
				margin: 25px 0;
			}
			h3,
			h4 {
				font-family: "Bariol";
				font-size: 1.8em;
				font-weight: 100;
			}
			p {
				padding: 3px 0;
				font-size: 1.2em;
			}
			ul {
				margin: 15px 0px 15px 35px;
				li {
					padding: 2px 0;
					font-size: 1.2em;
				}
			}
		}
		.right-col {
			width: 450px;
			#cart {
				margin-bottom: 50px;
				#cart-header-wrapper {
					display: flex;
					justify-content: space-between;
					#cart-header {
						display: flex;
						width: fit-content;
						h3 {
							color: $darkblue;
							font-size: 2em;
							font-family: "Bariol";
							margin: auto 0;
							font-weight: 600;
							z-index: 1;
							position: relative;
							width: 90px;
							span {
								position: absolute;
								top: 0px;
								right: 5px;
								width: 23px;
								height: 23px;
								border-radius: 50%;
								background-color: $danger;
								padding: 0px;
								line-height: 23px;
								color: white;
								font-size: 0.5em;
								font-weight: bold;
								display: inline-block;
								text-align: center;
								vertical-align: middle;
								box-sizing: initial;
							}
						}
					}
					#cost-container {
						p {
							width: fit-content;
							font-size: 1.05em;
							height: fit-content;
							margin: auto 0;
						}
					}
				}

				#cart-items {
					h4 {
						padding: 25px 0 0;
						font-size: 1.2em;
						font-family: "Source Sans Light";
						a {
							text-decoration: none;
							cursor: pointer;
							font-family: "Source Sans Light";
						}
					}
					#item {
						margin: 15px 0;
						padding: 25px;
						border-radius: 20px;
						box-shadow: -3px 3px 3px #c8c8c8;
						position: relative;
						h4 {
							color: #0060a9;
							font-size: 1.25em;
							font-family: "Bariol";
							padding-top: 0;
							max-width: 90%;
							padding-bottom: 3px;
						}
						h5 {
							font-size: 1.15em;
							font-family: "Bariol";
							font-weight: 100;
							max-width: 90%;
						}
						#close {
							cursor: pointer;
						}
						#close:after {
							position: absolute;
							content: "\d7";
							font-size: 35px;
							color: black;
							font-weight: bold;
							top: 50%;
							margin-top: -25px;
							right: 18px;
						}
					}
				}
			}
			.card {
				margin: 10px 0 10px;
				padding: 15px 25px;
				border-radius: 20px;
				width: 100%;
				height: 96px;
				box-shadow: -3px 3px 3px #c8c8c8;
				position: relative;
				display: flex;
				justify-content: space-between;
				&.active {
					border: 2px solid $tmsblue;
				}
				#card-image-container {
					height: fit-content;
					margin: auto 0;
					width: 60px;
					img {
						margin: auto;
						width: 100%;
					}
				}
				&:last-of-type {
					margin-top: 0;
				}
				#card-container {
					display: block;
					h4 {
						font-size: 1.25em;
						font-family: "Bariol";
						padding-bottom: 3px;
					}
					div {
						display: flex;
						vertical-align: middle;
						p {
							height: fit-content;
							margin: auto 0;
							font-size: 1.25em;
						}
						span {
							font-size: 2.5em;
							padding-bottom: 3px;
							margin-right: 14px;
							height: 45px;
							margin-top: -10px;
						}
					}
				}
				p {
					height: fit-content;
					margin: 15px 0;
					display: block;
					font-size: 1.25em;
				}
			}
			.remove_coupon {
				margin-top: 7px;
				display: block;
			}
			.form-item {
				margin: 20px 0 0;
				label {
					text-align: left;
					text-transform: none;
					font-family: "Bariol";
				}
				.error_coupon {
					color: $danger;
					padding-bottom: 5px;
				}
			}
			#coupon_applied {
				display: flex;
				width: 150px;
				justify-content: space-between;
				padding-top: 5px;
				p {
					font-size: 1.1em;
				}
				a {
					height: fit-content;
					margin: auto 0;
				}
			}
			#payment {
				display: flex;
				justify-content: space-between;
				margin-bottom: 25px;
				h3 {
					color: $darkblue;
					font-size: 2em;
					font-family: "Bariol";
					font-weight: 600;
					z-index: 1;
				}
				#cards {
					margin: auto 0;
					height: fit-content;
				}
				img {
					margin: auto;
					display: block;
					max-width: 220px;
				}
			}
			form {
				.submit-payment {
					display: block;
					padding: 15px 0;
					font-weight: bold;
					font-family: "Bariol";
					font-size: 1.3em;
					text-align: center;
					cursor: pointer;
					border-radius: 20px;
					width: 100%;
					margin: 0px auto;
					margin-top: 20px;
					color: white;
					text-decoration: none;
					position: relative;
					background-color: $darkblue;
					svg,
					span {
						margin: auto;
					}
					&.empty {
						background-color: #d3d3d3;
						cursor: initial;
						pointer-events: none;
					}
				}
			}
		}
		.submit-payment {
			display: block;
			padding: 12px 0;
			font-weight: bold;
			font-family: "Bariol";
			font-size: 1.3em;
			text-align: center;
			cursor: pointer;
			border-radius: 20px;
			width: 100%;
			margin: 0px auto;
			color: white;
			text-decoration: none;
			position: relative;
			background-color: $darkblue;
			svg,
			span {
				margin: auto;
			}
		}
	}
}

@media screen and (max-width: 1550px) {
	#cart-page {
		#cart-columns {
			width: 90%;
			.left-col {
				width: 100%;
				max-width: 48vw;
				min-width: initial;
			}
			.right-col {
				width: 100%;
				max-width: 30vw;
				margin-top: 20px;
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	#cart-page {
		#cart-page-header {
			height: 180px;
			width: 100%;
			position: relative;
			background: url(/images/spotlight/spotlight_services_header.png) no-repeat center;
			background-size: cover;
			text-align: center;
			// margin-bottom: 175px;
			img {
				padding: 50px 0;
				width: 300px;
			}
		}
		#cart-columns {
			width: 93vw;
			flex-direction: column;
			margin: 50px auto;
			.left-col {
				margin-bottom: 40px;
				width: 100%;
				min-width: initial;
				max-width: initial;
				padding-left: 0px;
				.one {
					right: 10px;
					width: 155px;
					top: -20px;
					height: 155px;
				}
				.two {
					left: -10px;
				}
				h2 {
					font-size: 2.35em;
					margin: 30px 0;
				}
			}
			.right-col {
				width: 100%;
				margin-bottom: 65px;
				min-width: initial;
				max-width: initial;
				#cart {
					h3 {
						font-size: 2.4em;
						width: 85px;
						span {
							width: 27px;
							height: 27px;
							right: -13px;
							line-height: 27px;
							font-size: 0.6em;
						}
					}
					#cart-items {
						#item {
							margin: 15px 0;
							padding: 25px;
							border-radius: 20px;
							box-shadow: -3px 3px 3px #c8c8c8;
							position: relative;
							h4 {
								color: #0060a9;
								font-size: 1.25em;
								font-family: "Bariol";
								padding-bottom: 3px;
							}
							h5 {
								font-size: 1.15em;
								font-family: "Bariol";
								font-weight: 100;
							}
							#close {
								cursor: pointer;
							}
							#close:after {
								position: absolute;
								content: "\d7";
								font-size: 35px;
								color: black;
								font-weight: bold;
								top: 50%;
								margin-top: -25px;
								right: 18px;
							}
						}
					}
				}
				#payment {
					display: flex;
					justify-content: space-between;
					margin-bottom: 25px;
					h3 {
						color: $darkblue;
						font-size: 2em;
						font-family: "Bariol";
						font-weight: 600;
						z-index: 1;
					}
					#cards {
						margin: auto 0;
						height: fit-content;
					}
					img {
						margin: auto;
						display: block;
						max-width: 220px;
					}
				}
				form {
					.submit-payment {
						svg,
						span {
							margin: auto;
						}
					}
					.card {
						margin: 15px auto;
					}
					.submit-payment {
						padding: 30px 0;
						width: 100%;
						margin: 0px auto;
						color: white;
						text-decoration: none;
						font-size: 2.1em;
						position: fixed;
						bottom: 0px;
						left: 0;
						z-index: 110;
						border-radius: 0;
						background-color: $darkblue;
						svg,
						span {
							margin: auto;
						}
						&.empty {
							background-color: #d3d3d3;
							cursor: initial;
							pointer-events: none;
						}
					}
				}
			}
		}
	}
}

#services-payment-page {
	#top-row {
		height: fit-content;
		position: relative;
		padding-top: 35px;
		margin: 15px auto;
		.tooltip-icon {
			position: absolute;
			line-height: 30px;
			top: 0;
			left: 50%;
			margin-left: -20px;
			width: 30px;
			height: 30px;
			background-color: #d3d3d3;
			font-size: 20px;
			color: white;
			text-align: center;
			border-radius: 50%;
		}
		h3 {
			width: fit-content;
			max-width: 350px;
			text-align: center;
			margin: 0 auto;
			color: #0060a9;
			font-size: 1.6em;
			position: relative;
			font-family: "Bariol Bold";
		}
		h4 {
			font-size: 1.2em;
			font-family: "Source Sans Light";
			font-weight: 100;
		}
	}
	.tooltip-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 110;
		background: rgba(0, 0, 0, 0.45);
		opacity: 0;
		display: none;
		&.show {
			display: block;
			opacity: 1;
		}
		#tooltip-container {
			text-align: center;
			height: fit-content;
			max-height: 90svh;
			padding-bottom: 20px;
			width: 505px;
			overflow: unset;
			background: #fff;
			margin: auto;
			top: 12%;
			margin-top: -10px;
			border-radius: 35px;
			z-index: inherit;
			position: relative;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			#image-carousel {
				max-width: 400px;
				max-height: 450px;
				margin: 0 auto;
				li {
					width: fit-content;
				}
				img {
					max-height: 450px;
					max-width: 400px;
					height: auto;
					margin: auto;
					width: 400px;
				}
				.carousel-root {
					.carousel,
					.carousel-status {
						display: none;
					}
					.carousel-slider {
						display: block;
						.slider-wrapper {
							.slider {
								&.animated {
									transition: all 5.35s ease-in-out;
								}
								.slide {
									.legend {
										display: none;
									}
								}
							}
						}
					}
				}
			}
			p {
				padding: 15px 0;
				width: 420px;
				margin: 10px auto;
			}
			#close {
				height: 20px;
				width: 20px;
				border-radius: 5px;
				cursor: pointer;
			}
			#close:after {
				position: absolute;
				content: "\d7";
				font-size: 35px;
				color: black;
				font-weight: bold;
				top: 5px;
				right: 18px;
			}
		}
	}
	.addons-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 110;
		background: rgba(0, 0, 0, 0.45);
		opacity: 0;
		display: none;
		&.show {
			display: block;
			opacity: 1;
		}
		#top-row {
			h3 {
				width: fit-content;
				max-width: 90%;
				text-align: center;
				margin: 0 auto;
				color: #0060a9;
				font-size: 1.6em;
				position: relative;
				font-family: "Bariol Bold";
			}
			h4 {
				font-size: 1.1em;
				font-family: "Source Sans Light";
				font-weight: 100;
				max-width: 90%;
				text-align: center;
				margin: 5px auto;
			}
		}
		#addons-container {
			text-align: center;
			height: fit-content;
			max-height: 90svh;
			padding: 20px;
			width: 505px;
			overflow: unset;
			background: #fff;
			margin: auto;
			top: 12%;
			margin-top: -10px;
			border-radius: 35px;
			z-index: inherit;
			position: relative;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			.addon {
				position: relative;
				justify-content: space-between;
				display: flex;
				min-height: fit-content;
				margin: 15px 0;
				p {
					text-align: left;
					width: calc(100% - 160px);
					margin: auto 0;
				}
			}
			.cartButton {
				display: block;
				padding: 5px 10px;
				width: 159px;
				border-style: none;
				text-align: center;
				cursor: pointer;
				border-radius: 20px;
				color: white;
				text-decoration: none;
				position: relative;
				background-color: white;
				border: 2px solid #0060a9;
				color: #0060a9;
				font-family: "Bariol";
				font-size: 1.15em;
				height: fit-content;
				list-style: none;
				outline: none;
				font-weight: bold;
				margin: auto;
				span {
					display: inline-block;
					transform: rotate(45deg);
					height: 18px;
					width: 8px;
					margin-right: 10px;
					border-bottom: 3px solid white;
					border-right: 3px solid white;
				}
				&.enrolled {
					span {
						display: none;
					}
				}
				&.active {
					border: 1px solid #0060a9;
					background-color: #0060a9;
					color: white;
				}
			}

			#close {
				height: 20px;
				width: 20px;
				border-radius: 5px;
				cursor: pointer;
			}
			#close:after {
				position: absolute;
				content: "\d7";
				font-size: 35px;
				color: black;
				font-weight: bold;
				top: 5px;
				right: 18px;
			}
		}
	}

	#services-page-header {
		height: fit-content;
		width: 100%;
		position: relative;
		background: url(/images/spotlight/spotlight_services_header.png) no-repeat center;
		background-size: cover;
		text-align: center;
		img {
			display: block;
			margin: auto;
			padding: 40px 0;
			width: 390px;
		}
	}
	#services-columns {
		display: flex;
		width: 1300px;
		max-width: 1300px;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: 90px;
		.left-col {
			width: fit-content;
			// margin-left: 250px;
			position: relative;
			width: fit-content;
			max-width: 635px;
			.services-heading {
				font-size: 1.9em;
				font-family: "Source Sans Pro";
				margin-bottom: 5px;
			}
			.services-para {
				font-size: 1.6em;
				font-family: "Source Sans Light";
			}
			.services {
				position: relative;
				padding: 15px 0px;
				#service {
					width: 100%;
					padding: 20px 0;
					#service-container {
						display: flex;
						justify-content: space-between;
						width: calc(100% - 165px);
						.cartButton {
							display: block;
							padding: 5px 10px;
							width: 159px;
							border-style: none;
							text-align: center;
							cursor: pointer;
							border-radius: 20px;
							color: white;
							text-decoration: none;
							position: relative;
							background-color: white;
							border: 2px solid #0060a9;
							color: #0060a9;
							font-family: "Bariol";
							font-size: 1.15em;
							height: fit-content;
							position: absolute;
							right: 0;
							list-style: none;
							outline: none;
							font-weight: bold;
							span {
								display: inline-block;
								transform: rotate(45deg);
								height: 18px;
								width: 8px;
								margin-right: 10px;
								border-bottom: 3px solid white;
								border-right: 3px solid white;
							}
							&.enrolled {
								span {
									display: none;
								}
							}
							&.active {
								border: 1px solid #0060a9;
								background-color: #0060a9;
								color: white;
							}
						}
					}
					h4 {
						color: #0060a9;
						font-size: 1.6em;
						position: relative;
						font-family: "Bariol Bold";
						width: 100%;
						.tooltip-icon {
							position: absolute;
							margin-left: 8px;
							margin-top: 8px;
							line-height: 21px;
							width: 20px;
							height: 20px;
							background-color: #d3d3d3;
							font-size: 13px;
							color: white;
							text-align: center;
							border-radius: 50%;
						}
					}
					h5 {
						font-size: 1.2em;
						font-family: "Source Sans Light";
						font-weight: 100;
					}
					p {
						padding: 8px 0;
					}
					.addons-button {
						display: block;
						padding: 5px 10px;
						width: fit-content;
						border-style: none;
						text-align: center;
						cursor: pointer;
						border-radius: 20px;
						text-decoration: none;
						background-color: white;
						border: 1px solid #0060a9;
						color: #0060a9;
						font-family: "Bariol";
						font-size: 1.15em;
						height: fit-content;
						list-style: none;
						outline: none;
					}
				}
			}
			#services-component {
				margin: 40px 0;
				display: flex;
				height: fit-content;
				padding-top: 40px;
				border-top: 1px solid #666;
				position: relative;
				p {
					padding: 5px 0;
					width: fit-content;
					font-size: 1.15em;
				}
				a {
					display: block;
					padding: 5px 10px;
					width: 159px;
					border-style: none;
					text-align: center;
					cursor: pointer;
					border-radius: 20px;
					text-decoration: none;
					background-color: white;
					border: 1px solid #0060a9;
					color: #0060a9;
					font-family: "Bariol";
					font-size: 1.25em;
					height: fit-content;
					position: absolute;
					right: 0;
					list-style: none;
					outline: none;
				}
				.button-2 {
					background-color: $danger;
					color: white;
					border: none;
				}
			}
		}
		.right-col {
			width: 450px;
			#cart {
				margin-bottom: 50px;
				#cart-header {
					display: flex;
					justify-content: space-between;
					h3 {
						color: $darkblue;
						font-size: 2em;
						font-family: "Bariol";
						font-weight: 600;
						z-index: 1;
						position: relative;
						margin: auto 0;
						width: 75px;
						span {
							position: absolute;
							top: 0px;
							right: -7px;
							width: 23px;
							height: 23px;
							border-radius: 50%;
							background-color: $danger;
							padding: 0px;
							line-height: 23px;
							color: white;
							font-size: 0.5em;
							font-weight: bold;
							display: inline-block;
							text-align: center;
							vertical-align: middle;
							box-sizing: initial;
						}
					}
					#cost-container {
						p {
							width: fit-content;
							font-size: 1.05em;
							height: fit-content;
							margin: auto 0;
						}
					}
				}

				#cart-items {
					h4 {
						padding: 25px 0 0;
						font-size: 1.2em;
						font-family: "Source Sans Light";
					}
					#item {
						margin: 15px 0;
						padding: 25px 40px 25px 25px;
						border-radius: 20px;
						box-shadow: -3px 3px 3px #c8c8c8;
						position: relative;
						h4 {
							color: #0060a9;
							font-size: 1.3em;
							font-family: "Bariol";
							padding-top: 0;
							padding-bottom: 3px;
						}
						h5 {
							font-size: 1.05em;
							font-family: "Source Sans Light";
							font-weight: 100;
						}
						#close {
							cursor: pointer;
						}
						#close:after {
							position: absolute;
							content: "\d7";
							font-size: 35px;
							color: $darkblue;
							font-weight: bold;
							top: 50%;
							margin-top: -25px;
							right: 18px;
						}
					}
				}
			}
		}
		.submit-payment {
			display: block;
			padding: 12px 0;
			font-weight: bold;
			font-family: "Bariol";
			font-size: 1.3em;
			text-align: center;
			cursor: pointer;
			border-radius: 40px;
			width: 100%;
			margin: 0px auto;
			color: white;
			text-decoration: none;
			position: relative;
			background-color: $darkblue;
			svg,
			span {
				margin: auto;
			}
			&.empty {
				background-color: #d3d3d3;
				cursor: initial;
				pointer-events: none;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#services-payment-page {
		#tooltip-wrapper {
			z-index: 99999;
			#tooltip-container {
				top: 10%;
				width: 480px;
				z-index: 99999;
				margin: -10px auto;
				#close {
					&::after {
						font-size: 38px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#services-payment-page {
		#tooltip-wrapper {
			z-index: 99999;
			#tooltip-container {
				top: 10px;
				height: 96%;
				max-height: 96%;
				width: 96vw;
				border-radius: 0;
				z-index: 99999;
				margin: 0 auto;
				#close {
					&::after {
						font-size: 38px;
					}
				}
				#image-carousel {
					max-width: 85%;
					max-height: 550px;
					img {
						max-height: 550px;
						max-width: 85%;
						height: auto;
						width: auto;
					}
				}
			}
		}

		#services-columns {
			width: 90%;
			// flex-direction: column;
			margin: 50px auto;
			.left-col {
				width: 100%;
				max-width: 45vw;
				min-width: initial;
				.services {
					padding: 15px 0 0;
					#service {
						#service-container {
							width: calc(100% - 170px);
							#services-wrapper {
								width: 100%;
							}
							h4 {
								margin-bottom: 5px;
								width: calc(100% - 20px);
							}
						}
					}
				}
				#services-component {
					margin: 35px auto;
					p {
						width: 65%;
					}
				}
			}
			.right-col {
				width: 100%;
				max-width: 30vw;
				margin-top: 20px;
				#cart {
					h3 {
						font-size: 2.3em;
						span {
							right: -12px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: $tabletBreak) {
	#services-payment-page {
		#services-columns {
			width: 100%;
			flex-direction: column;
			margin: 50px auto;
			.left-col {
				width: 100%;
				margin: 0 auto;
				padding: 0 20px;
				max-width: initial;
				min-width: initial;
				.services {
					padding: 15px 0 0;
					#service {
						#service-container {
							width: calc(100% - 170px);
							flex-direction: column;
							#services-wrapper {
								width: 100%;
							}
							h4 {
								margin-bottom: 5px;
								width: calc(100% - 20px);
							}
						}
					}
				}
				#services-component {
					margin: 35px auto;
					p {
						width: 65%;
					}
				}
			}
			.right-col {
				width: 100%;
				margin: 20px auto 20px;
				padding: 0 20px;
				max-width: initial;
				min-width: initial;
				position: relative;
				#cart {
					h3 {
						font-size: 2.3em;
						span {
							right: -12px;
						}
					}
				}
				.submit-payment {
					padding: 30px 0;
					width: 100%;
					margin: 0px auto;
					color: white;
					text-decoration: none;
					font-size: 2.1em;
					position: fixed;
					bottom: 0px;
					left: 0;
					z-index: 110;
					border-radius: 0;
					background-color: $darkblue;
					svg,
					span {
						margin: auto;
					}
					&.empty {
						background-color: #d3d3d3;
						cursor: initial;
						pointer-events: none;
					}
				}
			}
		}
	}
}

#spotlight-option-page {
	display: flex;
	width: 1220px;
	margin: 110px auto 50px;
	justify-content: space-between;
	.option-container {
		width: 400px;
		height: fit-content;
		position: relative;
		padding: 35px 25px;
		#header-wrapper {
			height: 86px;
			h4 {
				font-size: 2em;
				font-family: "Bariol";
				font-weight: 600;
				z-index: 1;
			}
			h5 {
				font-size: 1em;
				font-family: "Source Sans Light";
				font-weight: 100;
			}
		}
		a {
			display: block;
			padding: 10px 0;
			font-weight: bold;
			font-family: "Bariol";
			font-size: 1.3em;
			text-align: center;
			cursor: pointer;
			border-radius: 30px;
			width: 100%;
			margin: 0px auto;
			margin-top: 20px;
			color: white;
			text-decoration: none;
			position: relative;
			svg,
			span {
				margin: auto;
			}
			&.inactive {
				background-color: #d3d3d3 !important;
				cursor: initial;
				pointer-events: none;
			}
		}
		.one {
			right: 35px;
			left: initial;
			top: -50px;
			width: 170px;
			height: 170px;
			opacity: 0.1;
			z-index: -99999;
		}
		#features {
			position: relative;
			padding: 15px 20px;
			li {
				position: relative;
				list-style: none;
				padding: 14px 0;
				list-style-position: inside;
				margin-left: 30px;
				span {
					display: inline-block;
					position: absolute;
					left: -45px;
					top: 50%;
					margin-top: -18px;
					transform: rotate(45deg);
					height: 28px;
					width: 14px;
				}
			}
		}
		p {
			font-size: 12px;
		}
		#features-2 {
			padding-left: 25px;
			li {
				list-style-type: disc;
				padding: 4px 0;
			}
		}
		&.blue {
			#header-wrapper {
				h4 {
					color: $tmsblue;
				}
				h5 {
					color: $tmsblue;
				}
			}
			a {
				background-color: $tmsblue;
			}
			.one {
				background: $tmsblue;
			}
			#features {
				li {
					span {
						border-bottom: 5px solid $tmsblue;
						border-right: 5px solid $tmsblue;
					}
				}
			}
		}
		&.red {
			border-left: 1px solid $defaultNeutralLight;
			padding: 35px;
			#header-wrapper {
				h4 {
					color: $danger;
				}
				h5 {
					color: $danger;
				}
			}
			a {
				background-color: $danger;
			}
			.one {
				background: $danger;
			}
			#features {
				li {
					span {
						border-bottom: 5px solid $danger;
						border-right: 5px solid $danger;
					}
				}
			}
		}
		&.purple {
			background: rgba(148, 39, 121, 0.05);
			padding: 35px 30px 45px;
			#header-wrapper {
				h4 {
					color: #942779;
				}
				h5 {
					color: #942779;
				}
			}
			a {
				background-color: #942779;
			}
			.one {
				background: #942779;
			}
			#features {
				li {
					span {
						border-bottom: 5px solid #942779;
						border-right: 5px solid #942779;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#spotlight-option-page {
		flex-direction: row;
		flex-wrap: wrap;
		width: 90%;
		margin: 45px auto;
		.option-container {
			border: none !important;
			padding: 35px 15px 45px;
			.one {
				left: 175px;
				width: 155px;
				height: 155px;
				top: -30px;
			}
			&.blue,
			&.purple,
			&.red {
				padding: 35px 15px;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#spotlight-option-page {
		flex-direction: column;
		width: 95%;
		.option-container {
			width: 100%;
		}
	}
}

.cards-container {
	&.show {
		margin-bottom: 15px;
	}
}

.marketing-wrapper {
	width: 100%;
	margin-bottom: 20px;
	.header-wrapper {
		img {
			width: 100vw;
			position: relative;
			background-size: cover;
			text-align: center;
			margin-bottom: 45px;
		}
		#sub-header {
			font-size: 3.45em;
			line-height: 1em;
			color: $darkblue;
			font-family: "Source Sans Light";
			margin-bottom: 20px;
		}
		#sub-header-2 {
			font-size: 1.4em;
			color: $darkblue;
			text-transform: lowercase;
			font-weight: bold;
			font-family: "Source Sans Pro";
		}
	}
	.marketing-enroll-wrapper {
		display: flex;
		width: 75vw;
		max-width: 1550px;
		margin: auto;
		justify-content: space-between;
		padding: 30px 0;
		@media screen and (max-width: $tabletBreak) {
			max-width: 85vw;
			width: 700px;
		}
		.typeform {
			width: 100%;
			height: 100%;
		}
		@media screen and (max-width: $tabletBreak) {
			flex-direction: column-reverse;
		}
		.col-md-6 {
			border: 1px solid #d6d6d6;
			padding: 20px 35px;
			border-radius: 10px;
			box-shadow: 0 4px 5px 0 #d6d6d6;
			border-top: none;
			box-sizing: border-box;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: $tabletBreak) {
				padding: 20px;
			}
			.enroll-actions {
				--gap: 1em;
				width: 250px;
				display: flex;
				justify-content: space-between;
				@media screen and (max-width: $tabletBreak) {
					width: 200px;
				}
				p {
					margin: auto 0;
				}
				.enroll-option {
					height: fit-content;
					max-height: 100%;
					margin: auto;
					width: fit-content;
					svg {
						width: 26px;
						height: 26px;
						color: #989898;
					}
					p {
						width: fit-content;
						margin: -7px auto;
						color: #989898;
						&.watch {
							text-decoration: underline;
						}
					}
					.watch {
						margin-right: -20px;
						cursor: pointer;
					}
				}
				.enroll-status {
					min-width: 150px;
					height: fit-content;
					margin: auto 0;
					@media screen and (max-width: $tabletBreak) {
						min-width: 135px;
					}
					@media screen and (max-width: $mobileLargeBreak) {
						min-width: 110px;
					}
					img {
						width: 40px;
						color: white;
						margin: auto;
						display: block;
					}
				}
			}
			.btn {
				width: 150px;
				height: fit-content;
				font-size: 1.2em;
				font-family: "Bariol Bold";
				padding: 9px 18px;
				margin: auto 0;
				@media screen and (max-width: $tabletBreak) {
					width: 135px;
				}
				@media screen and (max-width: $mobileLargeBreak) {
					width: 110px;
				}
			}
			h2 {
				font-size: 1.3em;
				margin: auto 0;
				font-family: "Bariol Bold";
				color: $tmsblue;
			}
		}
		.enroll-container {
			flex: 0 60%;
		}
		.progress-container {
			flex: 0 30%;
			@media screen and (max-width: $tabletBreak) {
				margin-bottom: 25px;
			}

			h3,
			h1 {
				color: $darkblue;
				font-family: "Bariol Bold";
				margin-bottom: 18px;
				font-size: 1.25em !important;
				margin: 0;
				text-align: left !important;
			}
			.col-md-6 {
				display: block;
				max-width: none;
				margin: auto;
				#list {
					.form-item {
						margin: 10px 0;
						input {
							width: fit-content !important;
						}

						label {
							text-align: left;
							color: black;

							&.container {
								position: relative;
								cursor: pointer;
								/* Hide the browser's default checkbox */

								.checkmark {
									position: absolute;
									top: 7px;
									left: 2px;
									height: 19px;
									width: 19px;
									background-color: white;
									border: 1px solid #ccc;
									@media screen and (max-width: $tabletBreak) {
										top: 5px;
										width: 22px;
										height: 22px;
									}
								}
								&:hover input ~ .checkmark {
									background-color: #ccc;
								}
								input:checked ~ .checkmark {
									background-color: $tmsgreen;
									border: none;
								}
								.checkmark:after {
									content: "";
									position: absolute;
									display: none;
								}
								input:checked ~ .checkmark:after {
									display: block;
								}
								.checkmark:after {
									left: 7px;
									top: 2px;
									width: 6px;
									height: 13px;
									border: solid white;
									border-width: 0 2px 2px 0;
									-webkit-transform: rotate(45deg);
									-ms-transform: rotate(45deg);
									transform: rotate(45deg);
									@media screen and (max-width: $tabletBreak) {
										width: 7px;
										height: 14px;
										left: 8px;
									}
								}
							}
							input {
								margin-right: 12px;
							}
						}
					}
				}
			}
			.progress-bar-wrapper {
				margin-bottom: 40px;
				.progress-bar {
					position: relative;
					height: 15px;
					width: 100%;
					border-radius: 50px;
					background: #dedede;
					margin-top: 10px;
					border: 1px hidden #333;
					.progress-filler {
						background: $tmsgreen;
						height: 100%;
						border-radius: inherit;
						animation: progressBar 2s ease-in-out;
						animation-fill-mode: both;
						/* box-shadow: inset 100px 0 0 0 #1DA598 */
						span:first-child {
							display: inline;
							z-index: 200;
						}
						span:first-child::after {
							margin-left: 32vw;
							position: absolute;
							top: 3px;
							right: 20px;
							width: 20px;
							content: attr(value) "%";
						}
					}

					@media (max-width: 500px) {
						.progress-filler {
							animation: none;
						}
					}
				}
			}
		}
	}
	#service-modal {
		display: block;
		position: fixed;
		width: 100vw;
		left: 0;
		height: 100%;
		background: rgba(0, 0, 0, 0.65);
		top: 0;
		z-index: 9999;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 100svh;
		.service-wrapper {
			position: relative;
			background-color: white;
			width: 650px;
			max-width: 80vw;
			height: fit-content;
			max-height: 80svh;
			min-height: 400px;
			padding: 30px;
			border-radius: 20px;
			overflow-y: auto;
			h1 {
				margin: 0 0 10px;
				text-align: left;
				color: $tmsblue;
				font-family: "Bariol";
			}
			.description {
				text-align: left;
				margin: 20px 0 10px;
			}
			iframe {
				margin: 10px auto 20px;
				max-width: 100%;
				width: 100%;
				height: 330px;
				border: none;
			}
			@media screen and (max-width: $tabletBreak) {
			}
			@media screen and (max-width: $mobileLargeBreak) {
				width: 80vw;
				max-width: initial;
				iframe {
					height: auto;
					width: 100%;
					min-height: 200px;
				}
			}
			.button-wrapper {
				display: flex;
				justify-content: right;
				.btn {
					width: 150px;
					height: fit-content;
					font-size: 1.2em;
					font-family: "Bariol Bold";
					padding: 6px 18px;
					margin: auto 0;
					margin-left: 15px;
					@media screen and (max-width: $tabletBreak) {
						width: 135px;
					}
					@media screen and (max-width: $mobileLargeBreak) {
						width: 110px;
					}
					div {
						height: fit-content;
						text-align: center;
						width: fit-content;
						margin: 0 auto;
						display: flex;
						text-align: center;
					}
					span {
						height: fit-content;
						font-family: "Bariol Bold";
						margin: auto 4px;
					}
					svg {
						display: block;
						margin: auto 0;
						width: 28px;
						height: 28px;
					}
				}
				p {
					margin: auto 0;
					width: fit-content;
					color: lightgrey;
				}
			}
		}
		#close {
			height: auto;
			width: auto;
			border-radius: 5px;
		}
		#close:after {
			position: absolute;
			content: "\d7";
			font-size: 31px;
			color: black;
			font-weight: bold;
			cursor: pointer;
			top: 8px;
			right: 17px;
		}
	}
}

.to-do-list-wrapper {
	display: block;
	max-width: 400px;
	margin: 20px auto;
	#list {
		.form-item {
			margin: 10px 0;
			input {
				width: fit-content !important;
			}
			label {
				text-align: left;
				color: black;

				&.container {
					position: relative;
					cursor: pointer;
					input {
						opacity: 0;
						cursor: pointer;
						width: 19px !important;
						height: 18px !important;
					}
					.checkmark {
						position: absolute;
						top: 7px;
						left: 2px;
						height: 19px;
						width: 19px;
						background-color: white;
						z-index: -9999;
						border: 1px solid #ccc;
						@media screen and (max-width: $tabletBreak) {
							top: 5px;
							width: 22px;
							height: 22px;
						}
					}
					&:hover input ~ .checkmark {
						background-color: #ccc;
					}
					input:checked ~ .checkmark {
						background-color: $tmsgreen;
						border: none;
					}
					.checkmark:after {
						content: "";
						position: absolute;
						display: none;
					}
					input:checked ~ .checkmark:after {
						display: block;
					}
					.checkmark:after {
						left: 7px;
						top: 2px;
						width: 6px;
						height: 13px;
						border: solid white;
						border-width: 0 2px 2px 0;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
						@media screen and (max-width: $tabletBreak) {
							width: 7px;
							height: 14px;
							left: 8px;
						}
					}
				}
				input {
					margin-right: 12px;
				}
			}
		}
	}
}

#marketing-to-do-list {
	width: 100%;
	margin: 0 auto;
	max-width: 800px;
	.btn {
		max-width: 250px;
		margin: auto;
	}
}

.requests-files-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	max-width: $contentMaxWidth;
	margin: 0px auto;
	justify-content: space-between;
	padding: 30px;
	.flyer-preview {
		.preview-box {
			width: 350px;
			height: fit-content;
			height: 250px;
			img {
				max-width: 100%;
				width: auto;
				margin: auto;
				display: block;
				max-height: 100%;
			}
		}
	}
	.ideas-container {
		transform: translateY(-10px);
		width: 100%;
	}
}

.social-calendar {
	width: 100%;
	max-width: 1450px;
	padding: 20px 40px;
	margin: 20px auto;
	height: 100%;
	overflow-y: auto;
	@media screen and (max-width: $mobileLargeBreak) {
		padding: 20px 15px;
	}
	.fc-scroller {
		height: fit-content !important;
	}
	.fc-list-empty {
		padding: 20px;
		font-family: "Bariol Bold";
		font-size: 1.2em;
	}
	.calendar-index {
		display: flex;
		flex-direction: row;
		gap: 15px;
		margin: 20px 0;
		justify-content: flex-end;
		.index {
			display: flex;
			gap: 4px;
			align-content: center;
			height: fit-content;
			span {
				width: 20px;
				height: 20px;

				border-radius: 50%;
				display: block;
				margin: auto 0;
				&.scheduled {
					background-color: $salesforceblue;
				}
				&.failed {
					background-color: $danger;
				}
				&.published {
					background-color: $tmsgreen;
				}
			}
			p {
				margin: auto 0;
				font-size: 1em;
			}
		}
	}
	.fc-event {
		display: flex !important;
		flex-direction: row-reverse !important;
		justify-content: flex-end !important;
		gap: 5px;
		background-color: white !important;
		color: black !important;
		border: 0 !important;
		font-size: 14px !important;
		margin: 4px 0 !important;
		padding: 5px 2px 5px 8px;
		border-left: 3px solid $tmsgreen !important;
		border-radius: 0 !important;
		&.scheduled {
			border-left: 3px solid $salesforceblue !important;
		}
		&.failed {
			border-left: 3px solid $danger !important;
		}
		&.published {
			border-left: 3px solid $tmsgreen !important;
		}
		.fc-content {
			padding-right: 0px;
			text-overflow: ellipsis;
			max-width: 90%;
			.fc-title {
				margin-left: 2px;
			}
		}
		div {
			height: 100%;
			margin: auto 0;
		}
		img {
			width: 16px;
			height: auto;
			margin: auto 0;
			display: block;
		}
	}
	// list view
	.fc-list-table {
		.fc-list-heading {
			display: flex;
			.fc-widget-header {
				width: 100%;
				padding: 7px 10px;
				background-color: $darkblue;
				color: white;
				.fc-list-heading-main {
					margin-right: 5px;
				}
			}
		}
		.fc-list-item-marker {
			display: none;
		}
		tbody {
			display: flex;
			flex-direction: column;
		}
		tr {
			&.fc-list-item {
				cursor: pointer;
				box-sizing: content-box;
				height: fit-content;
				display: flex;
				flex-direction: row;
				gap: 10px;
				display: flex;
				align-items: center;
				width: 100%;
				margin: 5px 0;
				border-left: 3px solid $tmsgreen !important;
				&:hover {
					background-color: #eeeeee;
					td {
						background-color: #eeeeee;
					}
					#calendar-icon {
						background-color: #eeeeee;
					}
				}
				& * {
					width: 100%;
				}
				#calendar-icon {
					width: fit-content;
					order: 2;
					height: fit-content;
					margin: auto 0;
					padding: 10px 0;
					img {
						width: 20px;
						height: auto;
						margin: auto 0;
						display: block;
					}
				}
				td {
					border: none;
					padding: 10px;
					&:nth-of-type(1) {
						order: 1;
						width: 200px;
					}
					&:nth-of-type(2) {
						display: none;
					}
					&:nth-of-type(3) {
						order: 3;
					}
					&:nth-of-type(4) {
						order: 4;
						width: 100px;
					}
				}
				&.scheduled {
					border-left: 3px solid $salesforceblue !important;
				}
				&.failed {
					border-left: 3px solid $danger !important;
				}
				&.published {
					border-left: 3px solid $tmsgreen !important;
				}
			}
		}
		@media screen and (max-width: $tabletBreak) {
			width: 100%;
			margin: 0 auto;
			tr {
				&.fc-list-item {
					gap: 0px;
					padding: 0;
					td {
						font-size: 0.85em;
						&:nth-of-type(1) {
							width: fit-content;
							padding: 0;
							font-size: 0.8em;
							min-width: 135px;
							margin-right: 10px;
							padding-left: 5px;
						}
						&:nth-of-type(2) {
							display: none;
						}
						&:nth-of-type(3) {
							order: 3;
						}
					}
				}
			}
		}
	}
}

.calendar-wrapper {
	width: 100%;
	max-width: 2560px;
	padding: 0;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;

	:not(.image-preview.user-calendar) {
		// z-index: 0;
	}
	#sub-header {
		margin: 0;
	}
	.fc-scroller {
		height: fit-content !important;
	}
	.fc-theme-standard {
		* {
			z-index: 0;
		}
	}
	.fc-toolbar {
		.fc-toolbar-title {
			color: rgba($color: $darkblue, $alpha: 1);
		}
	}
	.fc-list-empty {
		padding: 20px;
		font-family: "Bariol Bold";
		font-size: 1.2em;
	}
	.calendar-legend {
		display: flex;
		flex-direction: row;
		gap: 15px;
		justify-content: flex-end;
		flex-wrap: wrap;
		position: relative;
		cursor: pointer;
		width: fit-content;
		margin-left: auto;

		.legend-header {
			display: flex;
			align-items: center;
			gap: 5px;
			svg {
				width: 20px;
				height: 20px;
			}
		}

		.calendar-index-wrapper {
			position: absolute;
			top: 100%;
			right: 0;
			z-index: 9999;
			padding: 20px;
			background-color: white;
			border-radius: 20px;
			box-shadow: 0px 1px 10px #d3d3d3;
			width: min(300px, 90vw);

			display: none;
			flex-direction: column;
			gap: 1em;

			.calendar-index {
				display: flex;
				flex-direction: column;
				gap: 5px;
				.header {
					text-align: center;
					border-bottom: 1px solid #d3d3d3;
					padding-bottom: 8px;
				}
				.index {
					flex: 0 0 fit-content;
					display: flex;
					gap: 5px;
					align-content: center;
					height: fit-content;
					align-items: center;
					span {
						width: 20px;
						height: 20px;
						display: block;
						margin: 0;
						&.sphere {
							border-radius: 50%;
						}
						&.scheduled {
							background-color: $salesforceblue;
						}
						&.failed {
							background-color: $danger;
						}
						&.published {
							background-color: $tmsgreen;
						}
						&.tmsgreen {
							background-color: $tmsgreen;
						}
						&.tmsblue {
							background-color: $tmsblue;
						}
						&.darkblue {
							background-color: $darkblue;
						}
					}
					p {
						margin: auto 0;
						font-size: 1em;
					}
				}
			}
		}

		&:hover {
			.calendar-index-wrapper {
				display: flex;
			}
		}
	}

	.calendar-loaders {
		display: flex;
		flex-direction: row;
		gap: 15px;
		justify-content: flex-end;
		#loading-box {
			position: relative;
			width: fit-content;
			height: fit-content;
			left: auto;
			z-index: inherit;
			min-height: fit-content;
			background-color: transparent;
			background: transparent;
			display: flex;
			flex-direction: row-reverse;
			gap: 0;
			align-items: center;
			* {
				position: relative;
				top: auto;
				left: auto;
			}
			svg {
				flex: 0 0 50px;
				width: 50px;
				height: 50px;
			}
			h1 {
				color: rgba($color: $darkblue, $alpha: 1);
				font-size: 1.1em;
			}
		}
		&:not(:has(#loading-box)) {
			display: none;
		}
	}

	.fc-event {
		display: flex !important;
		flex-direction: row-reverse !important;
		justify-content: flex-end !important;
		gap: 5px;
		background-color: white !important;
		color: black !important;
		border: 0 !important;
		font-size: 14px !important;
		margin: 4px 0 !important;
		padding: 5px 2px 5px 4px;
		border-left: 3px solid transparent !important;
		border-radius: 0 !important;

		.fc-content {
			padding-right: 0px;
			text-overflow: ellipsis;
			max-width: 90%;
			.fc-title {
				margin-left: 2px;
			}
		}
		div {
			height: 100%;
			margin: 0;
		}
		img {
			width: 16px;
			height: auto;
			margin: auto 0;
			display: block;
		}
	}
	.fc-event {
		// color codes for status
		&.scheduled {
			border-left: 3px solid $salesforceblue !important;
		}
		&.failed {
			border-left: 3px solid $danger !important;
		}
		&.published {
			border-left: 3px solid $tmsgreen !important;
		}
	}

	.fc-event {
		// color codes for calendar events
		&.tmsgreen {
			border-color: $tmsgreen !important;
		}
		&.tmsblue {
			border-color: $tmsblue !important;
		}
		&.darkblue {
			border-color: $darkblue !important;
		}
	}

	// list view
	.fc-list-table {
		.fc-list-heading {
			display: flex;
			.fc-widget-header {
				width: 100%;
				padding: 7px 10px;
				background-color: $darkblue;
				color: white;
				.fc-list-heading-main {
					margin-right: 5px;
				}
			}
		}
		.fc-list-item-marker {
			display: none;
		}
		tbody {
			display: flex;
			flex-direction: column;
		}
		tr {
			&.fc-list-item {
				cursor: pointer;
				box-sizing: content-box;
				height: fit-content;
				display: flex;
				flex-direction: row;
				gap: 10px;
				display: flex;
				align-items: center;
				width: 100%;
				margin: 5px 0;
				border-left: 3px solid $tmsgreen !important;
				&:hover {
					background-color: #eeeeee;
					td {
						background-color: #eeeeee;
					}
					#calendar-icon {
						background-color: #eeeeee;
					}
				}
				& * {
					width: 100%;
				}
				#calendar-icon {
					width: fit-content;
					order: 2;
					height: fit-content;
					margin: auto 0;
					padding: 10px 0;
					img {
						width: 20px;
						height: auto;
						margin: auto 0;
						display: block;
					}
				}
				td {
					border: none;
					padding: 10px;
					&:nth-of-type(1) {
						order: 1;
						width: 200px;
					}
					&:nth-of-type(2) {
						display: none;
					}
					&:nth-of-type(3) {
						order: 3;
					}
					&:nth-of-type(4) {
						order: 4;
						width: 100px;
					}
				}
				&.scheduled {
					border-left: 3px solid $salesforceblue !important;
				}
				&.failed {
					border-left: 3px solid $danger !important;
				}
				&.published {
					border-left: 3px solid $tmsgreen !important;
				}
			}
		}
		@media screen and (max-width: $tabletBreak) {
			width: 100%;
			margin: 0 auto;
			tr {
				&.fc-list-item {
					gap: 0px;
					padding: 0;
					td {
						font-size: 0.85em;
						&:nth-of-type(1) {
							width: fit-content;
							padding: 0;
							font-size: 0.8em;
							min-width: 135px;
							margin-right: 10px;
							padding-left: 5px;
						}
						&:nth-of-type(2) {
							display: none;
						}
						&:nth-of-type(3) {
							order: 3;
						}
					}
				}
			}
		}
	}
	&.dashboard {
		.fc-header-toolbar {
			margin: 0 0 10px !important;
		}
		.fc-event {
			gap: 2px;
			padding: 2px;
			margin: 2px 0 !important;
			img {
				width: 12px;
			}
		}
		.fc-event-time {
			display: none;
		}
		.fc-event-title {
			font-size: 0.8125em;
		}
		.calendar-icon {
			img {
				width: 10px;
			}
		}
	}
}

.library-filter-results-wrapper {
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	padding: 0;
	max-width: $contentMaxWidth;
	gap: 1em;
	flex-wrap: wrap;

	// position: absolute;
	// top: 0px;
	// right: 0px;

	@media screen and (max-width: $mobileLargeBreak) {
		position: relative;
		top: auto;
		right: auto;
	}

	.filters-btn {
		position: relative;
		width: fit-content;
		min-width: 150px;
		background-color: white !important;
		border: 2px solid $danger !important;
		color: $danger !important;
		padding: 8px 20px !important;
	}

	#library-filters-container {
		position: absolute;
		top: 60px;
		right: 0;
		width: 100%;
		max-width: 450px;
		box-shadow: 1px 1px 14px #d3d3d3;
		border-radius: 20px;
		z-index: 9999;
		background-color: white;
		@media screen and (max-width: $mobileLargeBreak) {
			left: 50%;
			transform: translateX(-50%);
			width: 95vw;
		}
		.container {
			border-radius: 20px;
			.header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 1em;
				border-bottom: 1px solid #d3d3d3;
				padding: 15px 20px;
				position: relative;
				background-color: $tmsblue;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				position: relative;
				h3 {
					font-family: "Bariol Bold";
					font-weight: bold;
					font-size: 1.35em;
					text-align: left;
					color: white;
					width: fit-content;
				}
				.btn {
					margin: 0;
					min-width: 150px;
					padding: 6px;
				}
				#close {
					border-radius: 5px;
					cursor: pointer;
					font-family: "Bariol Bold";
					position: absolute;
					top: 50%;
					right: 18px;
					transform: translateY(-50%);
					color: white;
					font-size: 1.55em;
				}
			}
			.filters-container {
				padding: 10px 10px 30px;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				.col-md-4 {
					flex: 1 0 50%;
					padding: 10px;
					h3 {
						padding-bottom: 10px;
					}
					&:nth-of-type() {
						border-left: 1px solid #d3d3d3;
						padding-right: 0;
					}
					&:nth-of-type(2n) {
						border-left: 1px solid #d3d3d3;
						padding-left: 20px;
					}
					.container {
						display: block;
						position: relative;
						line-height: 1.4em;
						padding-left: 35px;
						margin-bottom: 8px;
						margin-top: 3px;
						cursor: pointer;
						font-size: 16px;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
						color: #737272;
						&:last-of-type {
							margin-bottom: 0;
						}
					}

					/* Hide the browser's default checkbox */
					.container input {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 0;
						width: 0;
					}

					/* Create a custom checkbox */
					.checkmark {
						position: absolute;
						top: 0;
						left: 0;
						height: 22px;
						width: 22px;
						background-color: $defaultNeutralLight;
						border-radius: 50%;
					}

					/* On mouse-over, add a grey background color */
					.container:hover input ~ .checkmark {
						background-color: #ccc;
						margin-top: 2px;
					}

					/* When the checkbox is checked, add a blue background */
					.container input:checked ~ .checkmark {
						background-color: $danger;
					}

					/* Create the checkmark/indicator (hidden when not checked) */
					.checkmark:after {
						content: "";
						position: absolute;
						display: none;
					}

					/* Show the checkmark when checked */
					.container input:checked ~ .checkmark:after {
						display: block;
					}

					/* Style the checkmark/indicator */
					.container .checkmark:after {
						left: 8px;
						top: 3px;
						width: 6px;
						height: 14px;
						border: solid white;
						border-width: 0 2px 2px 0;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	.filters-menu-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		background: $danger;
		color: white;
		gap: 5px;
		flex: 0 0 fit-content;
		height: fit-content;
		cursor: pointer;
		padding: 8px 20px;
		p {
			font-size: 1.15em;
			font-family: "Bariol";
		}
		svg {
			width: 24px;
			height: 24px;
			flex: 0 0 fit-content;
		}
	}

	.filters-modal-container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 85svh;
		width: min(90%, 1250px);
		border-radius: 20px;
		padding: 20px;
		background-color: white;
		#category-filter {
			position: relative;
			transition: left 0.5s;
			display: flex;
			flex-direction: column;
			gap: 1em;
			width: 100%;
			height: 100%;

			.header {
				display: flex;
				flex-direction: column;
				gap: 1em;
				align-items: center;
				justify-content: center;
				justify-content: center;
				flex: 0 0 fit-content;
				h2 {
					font-size: 1.85em;
					color: $darkblue;
					font-family: "Bariol Bold";
				}
				svg {
					position: absolute;
					right: 0;
					top: 0;
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
			#search {
				position: relative;
				.form-item {
					width: 100%;
					position: relative;
					input {
						border-radius: 20px;
						width: 100% !important;
						min-width: 100% !important;
						max-width: 100% !important;
						padding: 8px 35px 8px 10px !important;
						height: 35px;
						color: #555;
						border: 1px solid #c4c4c4;
						&::placeholder {
							color: #555;
							opacity: 0.5;
						}
					}
					svg {
						position: absolute;
						right: 10px;
						margin: 0;
						height: 17px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
			.content-wrapper {
				flex: 1;
				overflow-y: auto;
				display: flex;
				flex-direction: column;
				gap: 30px;
				h3 {
					color: $darkblue;
					font-size: 18px;
					padding-bottom: 5px;
					border-bottom: 1px solid #eeeeee;
					width: 100%;
					text-transform: uppercase;
				}
				.col-md-4 {
					display: flex;
					flex-direction: column;
					gap: 15px;
				}
				#category {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 15px;

					#category-parent {
						position: relative;
						cursor: pointer;
						display: flex;
						flex-direction: column;
						gap: 8px;
						&.active {
							#tags {
								display: flex;
							}
						}
						#category-title {
							position: relative;
							.arrow {
								margin: 0;
								top: 50%;
								transform: translateY(-50%) rotate(-45deg);
							}
						}
						p {
							color: #7c7c7c;
							font-weight: 400;
							font-size: 1.05em;
							padding-right: 20px;
						}

						&.active {
							#category-title {
								position: relative;
								p {
									font-weight: 600;
									color: $danger;
								}
								.arrow {
									margin: 0;
									top: 50%;
									transform: translateY(-100%) rotate(45deg);
								}
							}
							#tags {
								display: flex;
								flex-direction: column;
								gap: 15px;
							}
						}
					}
					#tags {
						display: none;
						flex-direction: column;
						padding-left: 15px;
					}
				}

				.sortby-container {
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					position: relative;
					gap: 15px;
					.col-md-4 {
						display: flex;
						flex-direction: column;
						gap: 1em;
						width: 100%;
					}
				}

				.container {
					display: block;
					position: relative;
					padding-left: 35px;
					cursor: pointer;
					font-size: 1.05em;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					color: #737272;
					&:last-of-type {
						margin-bottom: 0;
					}
				}

				/* Hide the browser's default checkbox */
				.container input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					height: 0;
					width: 0;
				}

				/* Create a custom checkbox */
				.checkmark {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					height: 22px;
					width: 22px;
					background-color: rgba($color: $defaultNeutralLight, $alpha: 1);
					border-radius: 50%;
				}

				/* On mouse-over, add a grey background color */
				.container:hover input ~ .checkmark {
					background-color: rgba($color: $defaultNeutral, $alpha: 1);
				}

				/* When the checkbox is checked, add a blue background */
				.container input:checked ~ .checkmark {
					background-color: $danger;
				}

				/* Create the checkmark/indicator (hidden when not checked) */
				.checkmark:after {
					content: "";
					position: absolute;
					display: none;
				}

				/* Show the checkmark when checked */
				.container input:checked ~ .checkmark:after {
					display: block;
				}

				/* Style the checkmark/indicator */
				.container .checkmark:after {
					left: 8px;
					top: 3px;
					width: 6px;
					height: 14px;
					border: solid white;
					border-width: 0 2px 2px 0;
					-webkit-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
			.footer {
				flex: 0 0 fit-content;
				#filter-button-container {
					position: relative;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					gap: 1em;
					flex: 0 0 fit-content;
					flex-wrap: wrap;
					border-top: 1px solid #d3d3d3;
					padding-top: 20px;

					* {
						width: fit-content;
						margin: 0;
					}
					.btn {
					}
					#filter-button {
						text-transform: uppercase;
					}

					#clear-filter {
						width: fit-content;
						display: block;
						color: #666;
						text-decoration: underline;
					}
				}
			}

			&.type-2 {
				#category {
					#category-parent {
						#tags {
							gap: 1em;
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

#print-order {
	.info-form {
		display: flex;
		//justify-content: space-between;
		gap: 10px;
		align-items: start;
		vertical-align: top;
		.col-left,
		.col-right {
			//flex: 0 0 50%;
			margin-top: 20px;
			.form-item {
				padding: 0;
				margin: 0;
			}
		}
	}
}

#print-order-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 110;
	background: rgba(0, 0, 0, 0.55);
	.modal {
		width: min(90vw, 950px);
		height: fit-content;
		padding: 20px;
		background-color: white;
		text-align: center;
		border-radius: 35px;
		z-index: 999999;
		position: relative;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		box-sizing: border-box;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	/* Modal Content */
	.modal-content {
		overflow-y: auto;
		max-height: calc(100vh - 150px);
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 1em;
		transition: all 0.15s ease-in-out;
		h2 {
			text-align: center;
		}
		form {
			display: flex;
			flex-direction: column;
			gap: 5px;
			justify-content: center;
			align-items: center;
			.main-form,
			.info-form-wrapper {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 0;
				gap: 1em;
				width: 100%;
				justify-content: center;
				.form-item {
					flex: 1;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-direction: column;
					width: 100%;
					&.textcustom {
						flex-basis: 100%;
						flex-grow: 1;
						height: 100px;
					}
					input,
					select,
					textarea {
						border-radius: 20px;
						width: 100%;
						resize: vertical;
					}
					.react-datepicker-wrapper {
						width: 100% !important;
					}
				}
				.address-type-field {
					flex: 0 0 100%;
					#options {
						display: flex;
						gap: 30px;
						width: 100%;
						.option-row {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							flex: 1;
							gap: 2px;
							input[type="radio"] {
								width: 20px;
								margin: 0;
								aspect-ratio: 1/1;
								cursor: pointer;
							}
							#address_type {
								min-width: 0;
							}
						}
					}
				}
			}
			#info-form {
				display: flex;
				flex-direction: column;
				gap: 5px;
				justify-content: center;
				align-items: center;
				padding: 1em 0;
				h3 {
					text-align: center;
				}
			}
			.btn {
				background-color: $danger;
				color: white;
				width: fit-content;
			}
		}
	}

	/* Close Button */
	.close {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;
		font-size: 20px;
		color: #000;
	}

	/* Center the Form */
	form {
		//display: flex;
		//flex-direction: column;
		//align-items: center;
	}

	/* Form Fields */
	.form-group {
		margin: 10px 0;
	}

	label {
		font-weight: bold;
	}

	input {
		width: 100%;
		padding: 8px;
		margin-top: 5px;
		border: 1px solid #ccc;
		border-radius: 3px;
	}

	/* Submit Button */
	button[type="submit"] {
		background-color: #007bff;
		color: #fff;
		padding: 10px 20px;
		border: none;
		border-radius: 5px;
		cursor: pointer;
	}

	button[type="submit"]:hover {
		background-color: #0056b3;
	}
}

.required-label {
	position: absolute;
	top: -2px;
	font-size: 14px;
	color: $danger;
	left: calc(100% + 0.25rem);
}

#customEmailFooter {
	p {
		font-family: "Source Sans Pro";
		color: $darkblue;
		font-size: 16px;
		display: block;
	}
	.name {
		color: $tmsblue;
		font-size: 24px !important;
		font-weight: bold;
	}
	.nmls {
		color: grey;
	}
	.title {
		font-size: 18px;
		font-weight: bold;
	}
}

#custom-email-preview-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	#loading-box {
		position: relative;
		width: 100%;
		background-color: transparent;
		min-height: fit-content;
		z-index: 0;
		* {
			z-index: 0;
		}
		h1 {
			position: relative;
			color: $darkblue;
			top: auto;
			font-size: 1.95em;
		}
		svg {
			width: 150px;
			height: 150px;
			margin: 0;
		}
	}
	.custom-email-preview-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 1em;
		h2 {
			margin: 0;
		}
	}
	h4 {
		font-size: 1.1em;
		font-weight: normal;
	}
	.email-metadata-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1em;
		width: 100%;
		h4 {
		}
		.btn-wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: 1em;
			align-items: center;
			padding-bottom: 0.5em;
			.btn {
				width: fit-content;
				min-width: auto;
				max-width: 100%;
				&.sf-blue {
					svg {
						width: 20px;
						height: 20px;
						align-items: center;
						justify-content: center;
					}
				}
				&.success {
					pointer-events: none;
				}
			}
			.authorize-banner {
				margin: 0;
				&.denied {
					padding: 4px 20px;
					color: white;
					font-size: 1.25em;
					font-family: "Bariol";
				}
			}
		}
	}
	.email-preview {
		width: 100%;
		// max-width: 950px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		iframe {
			width: 100%;
			height: 100%;
			border: none;
			overflow: hidden;
		}
	}
	.image-preview.send-email-preview-modal {
		.preview-content {
			justify-content: center;
			align-items: center;
			display: flex;
			width: min(95vw, 950px);

			.preview-content-wrapper {
				width: 100%;
				background-color: white;
				border-radius: 1em;
				padding: 1em;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 1em;
				.form-item {
					width: min(100%, 650px);
					label {
						color: rgba($color: $darkblue, $alpha: 1);
					}
				}
				.btn-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 1em;
					flex-wrap: wrap;
					.btn {
						width: fit-content;
						min-width: auto;
						max-width: 100%;
					}
				}
			}
		}
	}

	.email-attachments {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;
		.email-attachments-list {
			display: flex;
			flex-direction: column;
			gap: 15px;
			width: 100%;
			.email-attachment {
				padding: 8px 20px;
				display: flex;
				align-items: center;
				gap: 10px;
				background-color: $defaultNeutralLight;
				width: min(100%, 750px);
				border-radius: 10px;
				text-decoration: none;
				* {
					color: $darkblue;
					text-decoration: none;
				}
				svg {
					width: 22px;
					height: 22px;
					flex: 0 0 22px;
				}
				p,
				a {
					word-break: break-all;
					overflow-wrap: break-word;
				}
			}
		}
	}
}

.qrcode-form-wrapper {
	display: flex;
	flex-direction: column;
	h3 {
		color: $darkblue;
		font-family: "Bariol";
		font-weight: bold;
	}
	.qrcode-form {
		display: flex;
		flex-direction: column;
	}
}

.company-logo-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1em;
	h2 {
		margin: 0;
		margin-bottom: 0;
		font-size: 1.8em;
		flex: 0 0 fit-content;
		font-family: "Bariol";
	}
	img {
		width: auto;
		height: auto;
		max-width: 225px;
		max-height: max(150px, 100%);
	}

	.sub-col {
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		text-align: left;
		h4 {
			margin: 0;
			font-size: 1.1em;
			font-family: "Bariol";
		}
	}

	.detail-options {
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: $contentMaxWidth;
		justify-content: flex-start;
		gap: 30px;
		flex-wrap: wrap;
		margin: 0;
		.option {
			flex: 0 0 200px;
			color: #656565;
			padding: 15px 30px;
			border-radius: 5px;
			position: relative;
			border: 1px solid #656565;
			text-align: center;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.check {
				display: none;
			}
			&.active {
				border: 2px solid $danger;
				color: $danger;
				font-weight: bold;
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.4;
			}
		}
	}
}

.socialmedia-history-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	h3 {
		text-align: center;
		font-family: "Bariol";
		font-size: 1.5em;
	}
	.socialmedia-history-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		p {
			text-align: center;
			width: 100%;
		}
		.history-card {
			display: flex;
			flex-direction: column;
			flex: 0 0 (min(300px, 100%));
			align-items: center;
			box-shadow: 1px 1px 10px #d3d3d3;
			padding: 0.75em;
			border-radius: 0.75em;
			gap: 10px;
			p {
				display: flex;
				gap: 5px;
				text-align: left;
				flex-direction: column;
			}
			h4 {
				text-transform: capitalize;
				font-size: 1.1em;
				color: $darkblue;
			}
			img {
				max-width: 100%;
				width: auto;
				max-height: 200px;
			}
		}
	}
}

.social-media-account-wrapper {
	h3 {
		color: $darkblue;
		font-size: 1.4em;
	}
	.social-media-account {
		margin: 0;
		padding: 20px 15px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: white;
		&:nth-of-type(2n) {
			background-color: $defaultNeutralLight;
		}
		&:last-of-type {
			border-bottom: none;
		}
		#category-title {
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			height: fit-content;
			label {
				font-size: 1.1em;
				color: $darkblue;
			}
			#checkbox {
				border: 1px solid $darkblue;
				width: 26px;
				height: 26px;
				border-radius: 50%;
				display: block;
				margin: auto 0;
			}
		}
		#dropdown {
			display: flex;
			flex-direction: row;
			margin: 0;
			gap: 15px;
			.btn {
				margin: 0;
			}
		}
		.authorize-banner {
			display: flex;
			align-items: center;
			margin: 0;
		}
		&.active {
			#category-title {
				#checkbox {
					background-color: white;
					position: relative;
					&::after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 16px;
						height: 16px;
						background: $tmsblue;
						border-radius: 50%;
					}
				}
			}
		}
	}
}

.ai-prompt-container {
	position: absolute;
	right: 8px;
	top: 8px;
	width: fit-content;
	height: fit-content;
	.ai-prompt-icon {
		width: 25px;
		height: 25px;
		cursor: pointer;
		&:hover {
			color: $tmsgreen;
			fill: $tmsgreen;
			transition: all 0.15s ease-in-out;
		}
	}
	&.loading {
		.ai-prompt-icon {
			width: 35px;
			height: 35px;
			transition: width 0.75s ease-out;
		}
	}
}

.ai-text-suggestions-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
	padding: 20px;
	h3 {
		text-align: center;
		width: 100%;
	}
	.ai-text-suggestions {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		gap: 25px;
		.text-card {
			border-radius: 20px;
			box-shadow: 1px 1px 10px #d3d3d3;
			padding: 15px;
			gap: 10px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.content {
				display: flex;
				flex-direction: column;
				gap: 10px;
				flex: 1;
			}
			p {
				text-align: left;
				width: 100%;
				color: black;
			}
			.copy-button {
				width: fit-content;
				min-width: 150px;
				max-width: 100%;
				padding: 8px 15px;
				color: #fff;
				font-size: 1em;
				background-color: $lightblue;
				text-shadow: none;
				border: none;
				height: fit-content;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				&.active {
					background: $tmsgreen;
				}
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

.image-preview {
	&.ai-generator {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		background: rgba(0, 0, 0, 0.55);
		.preview-content {
			width: 100%;
			height: 85svh;
			max-width: min(95vw, $contentMaxWidth);
			overflow: hidden;
			padding: 20px;
			background-color: white;
			text-align: center;
			border-radius: 35px;
			z-index: 999999;
			position: relative;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			box-sizing: border-box;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.wrapper {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				gap: 1em;
				position: relative;
				::-webkit-scrollbar {
					width: 4px;
				}
				#header {
					display: flex;
					justify-content: center;
					position: relative;
					flex: 0 0 fit-content;
					#close {
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						width: 25px;
						height: 25px;
						cursor: pointer;
					}
					h1 {
						color: $darkblue;
						text-transform: capitalize;
						border: none;
						padding: 0;
						margin: 0;
					}
				}
				.footer {
					flex: 0 0 fit-content;
					display: flex;
					justify-content: space-around;
					align-items: center;
					flex-wrap: wrap;
					gap: 1em;
					.btn {
						margin: 0;
						min-width: 155px;
						max-width: 100%;
					}
				}
			}
			.animated-generate-button {
				flex: 0 0 fit-content;
			}
			.ai-text-suggestions-wrapper {
				flex: 1;
				overflow-y: auto;
				width: 100%;
				justify-content: flex-start;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: column;
				h3 {
					font-size: 1.3em;
					flex: 0 0 fit-content;
				}
			}
		}
	}
	&.intro-demo {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		background: rgba(0, 0, 0, 0.55);
		.preview-content {
			width: 100%;
			height: fit-content;
			max-height: 95svh;
			width: min(95vw, 550px);
			padding: 1.5rem 2.5rem;
			background-color: white;
			text-align: center;
			border-radius: 35px;
			z-index: 999999;
			position: relative;
			transition: all 0.3s ease-in-out;
			opacity: 1;
			box-sizing: border-box;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			#close {
				position: absolute;
				right: 1em;
				top: 1em;
				width: 25px;
				height: 25px;
				cursor: pointer;
			}
			.wrapper {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 15px;
				position: relative;
				flex: 1;
				overflow-y: auto;
				* {
					color: $darkblue;
					font-family: "Source Sans Pro";
					text-align: left;
					width: 100%;
				}

				::-webkit-scrollbar {
					width: 4px;
				}

				#header {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					flex: 0 0 fit-content;
					gap: 1em;
					width: 100%;

					h1 {
						text-align: center;
						font-family: "Source Sans Pro";
						font-weight: bold;
						margin: 0;
						font-size: 1.6em;
						background-color: $darkblue;
						color: #fff;
						letter-spacing: 4px;
						text-transform: uppercase;
						padding: 10px 20px;
						width: fit-content;
						max-width: 100%;
					}
				}
				.logo {
					width: min(100%, 185px);
					max-width: 100%;
					height: auto;
					padding: 0;
					margin: 0;
				}

				.list {
					display: flex;
					flex-direction: column;
					gap: 10px;
					.list-content {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 10px;
						img,
						.lazy-load-image-background {
							width: 40px;
							aspect-ratio: 1/1;
							padding: 0;
							margin: 0;
						}
						p {
							text-align: left;
						}
					}
				}
				.demo-text {
					color: $darkblue;
					text-align: center;
					a {
						color: $darkblue;
						text-decoration: underline;
						&:hover,
						&:visited,
						&:active {
							color: $darkblue;
							text-decoration: underline;
						}
					}
				}
				hr {
					width: 100%;
					border: none;
					border-bottom: 1px solid $darkblue;
				}
			}
		}
	}
}

.global-search-page-wrapper {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 0 2em;
	width: 100%;
	margin: 0 auto;
	.global-search-header {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;
		flex-wrap: wrap;
		#icon {
			background-color: $darkblue;
			width: 50px;
			height: 50px;
			svg {
				width: 26px;
				height: 26px;
			}
		}
		#subheader {
			font-size: 3.4em;
			font-weight: 300;
			text-align: center;
			// line-height: 0.75em;
		}
	}

	.results-wrapper {
		display: flex;
		flex-direction: column;
		gap: 60px;
		.row-md-2 {
			display: flex;
			flex-direction: column;
			gap: 1em;
			h3 {
				font-size: 1.8em;
				color: $darkblue;
			}
			.results {
				display: flex;
				justify-content: flex-start;
				gap: 30px;
				flex-wrap: wrap;
				.result {
					display: flex;
					flex-direction: column;
					gap: 10px;
					flex: 0 0 min(100%, 275px);
					aspect-ratio: 1/1;
					text-decoration: none;
					padding: 10px;
					border: 1px solid #d3d3d3;
					position: relative;
					align-items: center;
					&:hover {
						border: 5px solid $tmsblue;
					}
					* {
						color: $darkblue;
					}
					h4 {
						font-size: 1.15em;
						flex: 0 0 fit-content;
						text-align: center;
						width: 90%;
					}
					figure {
						flex: 1;
						width: 100%;
						position: relative;
						.lazy-load-image-background {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 100%;
							height: 100%;
							display: flex !important;
							align-items: center;
							justify-content: center;
						}
					}
					.recommended {
						position: absolute;
						top: 0;
						left: 0;
						padding: 2px;
						display: block;
						z-index: 0;
						color: white;
						svg {
							width: 24px;
							height: 24px;
							fill: white;
							color: white;
						}
						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 50px 50px 0px 0px;
							border-color: $tmsblue transparent transparent transparent;
							z-index: -1;
						}
					}

					&:has(.recommended) {
						padding-top: 30px;
					}
				}

				@media screen and (max-width: $tabletBreak) {
					.result {
						flex: 0 0 min(250px, 45%);
					}
				}
			}
		}
	}
}

.materials-page {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	#materials-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 10px;
		#icon {
			margin: 0;
		}
		#sub-header {
			width: fit-content;
			margin: 0;
		}
	}
}

.logo-dl-link-container {
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

#flyerForm {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

#email-editor-wrapper {
	display: flex;
	flex-direction: column;
	gap: 30px;
	.header {
		display: flex;
		justify-content: space-between;
		gap: 1em;
		flex-wrap: wrap;
		align-items: center;
		.btn-wrapper {
			display: flex;
			gap: 10px;
			flex-wrap: wrap;
		}
	}
	#loading-box {
		position: relative;
		background-color: transparent;
		z-index: 0;
		width: 100%;
		height: 100%;
		min-height: 500px;
		h1 {
			color: $darkblue;
		}
	}
	iframe {
		min-height: 90svh !important;
	}
}

.image-preview.push-notifications {
	.preview-content {
		width: min(750px, 90vw);
		height: fit-content;
		max-height: 60vh;
		overflow: hidden;
		background-color: white;
		text-align: center;
		border-radius: 35px;
		z-index: 999999;
		position: absolute;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		box-sizing: border-box;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 1em 1.5em 1.5em;
		gap: 1em;

		#header {
			border: none;
			justify-content: center;
			display: flex;
			flex-direction: row;
			gap: 10px;
			justify-content: center;
			align-items: center;
			flex: 0 0 fit-content;
			width: 100%;
			position: relative;
			h1 {
				text-align: center;
				margin: 0;
				font-size: 1.6em;
				color: $darkblue;
				width: fit-content;
			}
			#close {
				position: absolute;
				right: 0;
				top: 0;
				width: 20px;
				height: auto;
				cursor: pointer;
			}
		}

		.content-wrapper {
			display: flex;
			flex-direction: column;
			gap: 1em;
			width: 100%;
			align-items: flex-start;

			ol {
				display: flex;
				flex-direction: column;
				gap: 1em;
				counter-reset: item; // Initialize the counter

				li {
					counter-increment: item;
					list-style: none;
					font-size: 1.2em;
					display: flex;
					align-items: center;
					gap: 0.25em;

					&::before {
						content: counter(item) ". ";
						font-weight: bold;
					}
				}
			}
			p {
				text-align: left;
			}
		}
	}
}

.email-analytics-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.5em;

	.email-analytics-header {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1em;
		svg {
			width: 40px;
			height: auto;
		}
		#sub-header {
			line-height: 1em;
			width: fit-content;
		}
	}

	.col-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		box-shadow:
			rgba(10, 22, 70, 0.1) 1px 1px 5px 0px,
			rgba(10, 22, 70, 0.06) 0px 0px 1px 0px;
		padding: 1em 0;
		border-radius: 4px;
		.header {
			display: flex;
			flex-direction: column;
			gap: 0.75em;
			padding: 0 1em 1em;
			border-bottom: 1px solid $defaultNeutral;
			h2 {
				color: $darkblue;
				font-family: "Bariol";
			}

			.filters-wrapper {
				display: flex;
				align-items: center;
				gap: 0.5em;
				.filter {
					background-color: rgb(220, 226, 229);
					color: $darkblue;
					padding: 0.25em 0.75em;
					border-radius: 4px;
					font-size: 0.9em;
					display: flex;
					align-items: center;
					gap: 0.5em;
					svg {
						width: 20px;
						height: auto;
						cursor: pointer;
					}
				}
			}
		}

		.content {
			padding: 0.75em 1em 0;
			display: flex;
			flex-direction: column;
			gap: 0.5em;

			.last-updated {
				text-align: right;
				color: $darkblue;
			}
		}
	}

	.email-metadata-wrapper {
	}

	.statistics-wrapper {
	}

	.charts-wrapper {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		gap: 3em;
		.pie-chart-container {
			flex: 0 0 min(450px, 100%);
			display: flex;
			flex-direction: column;
			gap: 1em;
			align-items: center;
			justify-content: flex-start;
			canvas {
				width: 100% !important;
			}
			.pie-chart-text {
				width: 100%;
				p {
					text-align: center;
					width: 100%;
					color: $darkblue;
					font-weight: bold;
					font-size: 1.25em;
				}
			}
		}
	}

	.engagement-metrics-wrapper {
		display: flex;
		flex-direction: column;
		.metrics-wrapper {
			display: flex;
			flex-direction: column;
			gap: 0.5em;
			.metric {
				display: flex;
				flex-wrap: wrap;
				gap: 1.5em;
				align-items: center;
				padding: 0.75em;
				&:nth-of-type(2n) {
					background-color: rgb(235, 238, 240);
				}
				.metric-label {
					flex: 0 0 300px;
				}
				.metric-status-list {
					display: flex;
					gap: 0.75em;
					flex-wrap: wrap;
					.status {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 0.25em;
						border-radius: 2em;
						padding: 0.5em 1em;
						cursor: pointer;
						text-transform: capitalize;
						color: white;
					}
				}
			}
		}
	}
}
