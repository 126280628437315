@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
#notifications {
    position: absolute;
    right: 40px;
    top: 70px;
    width: 400px;
    background: #ccc;
    z-index: 100;
    height: auto;
    .top {
        background: #333;
        color: white;
        padding: 10px 20px;
        p {
            display: inline-block;
            text-align: left;
            font-size: 18px;
        }
        .close {
            color: white;
            border-radius: 100%;
            border: 1px solid white;
            font-size: 12px;
            padding: 0px 5px;
            position: absolute;
            right: 20px;
            cursor: pointer;
            font-weight: bold;
        }
    }
    .bottom {
        background: #333;
        bottom: 0;
        text-align: center;
        p {
            color: white;
        }
    }
    ul {
        //overflow:hidden;
        overflow-y: scroll;
        height: auto;
        max-height: 300px;
    }
    li {
        display: flex;
        list-style-type: none;
        border-top: 1px solid #333;
        min-height: 75px;
        .symbol {
            width: 10%;
            padding: 10px;
            position: relative;
            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .content {
            width: 90%;
            padding: 10px;
            text-align: center;
            align-self: center;
            p:first-child {
                font-size: 16px;
            }
        }
    }
}

#notifications-new {
    position: absolute;
    right: 40px;
    top: 60px;
    width: 400px;
    background: #fff;
    z-index: 100;
    padding: 0 10px;
    height: auto;
    text-align: center;
    border-radius: 30px;
    color: $tmsblue !important;
    .top {
        padding: 8px 20px 5px;
        display: block;
        width: 100%;
        p {
            text-align: right;
            font-size: 28px;
            letter-spacing: 1px;
            font-family: "Bariol", sans-serif;
            font-weight: bolder;
            cursor: initial;
            color: $darkblue !important;
        }
    }
    .bottom {
        background: white;
        bottom: 0;
        border-radius: 30px;
        text-align: center;
        p {
            font-size: 18px;
            color: $darkblue !important;
            text-decoration: none;
            font-weight: 600;
        }
    }
    ul {
        //overflow:hidden;
        overflow-y: scroll;
        height: auto;
        max-height: 300px;
        padding: 0 15px;
    }
    li {
        display: flex;
        list-style-type: none;
        border-top: 2px solid #ccc;
        min-height: 25px;
        .no-notifications {
            padding: 10px 0;
            width: fit-content;
            margin: 0 auto;
            color: black !important;
            text-align: center;
        }
        .symbol {
            display: none;
        }
        .content {
            margin: 0 auto;
            width: 95%;
            color: $tmsblue !important;
            padding: 10px 0;
            text-align: center;
            align-self: center;
            a {
                display: block;
            }
            p:first-child {
                line-height: 1.35em;
                font-size: 16px;
                color: $tmsblue !important;
            }
            .reply-message {
                margin-top: 5px;
                line-height: 1.2em;
                padding: 5px 0;
                color: black !important;
                font-size: 16px;
            }
            .quick-reply {
                width: 300px;
                max-width: 100%;
                margin: 0 auto;
            }
            .quick-reply > input {
                width: 100%;
                border-radius: 20px;
                padding: 5px 10px;
                &:focus {
                    outline: none;
                }
            }
            a,
            a:visited,
            a:active {
                font-size: 16px;
                text-decoration: underline !important;
                font-weight: bold;
                padding-top: 5px;
                letter-spacing: 0.6px;
                font-family: "Bariol";
                color: $darkblue !important;
            }
        }
    }
}

#notifications-modal {
    #notifications-new {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    #notifications-modal {
        #notifications-new {
            display: none;
            top: 75px;
            max-width: 355px;
            width: 90%;
            right: 25px;
            z-index: 99999;
            max-height: 600px;
            -moz-box-shadow: 0px 10px 45px #333;
            -webkit-box-shadow: 0px 10px 45px #333;
            box-shadow: 0px 10px 45px #333;
            padding: 0 0 20px;
            animation: appear 0.1s ease-in 0.75;
            &:before {
                content: "";
                position: absolute;
                @include arrow-up(20px, white);
                top: -14px;
                right: 40px;
            }
            &.show {
                display: block;
            }
            .top {
                p {
                    text-align: center;
                }
            }
            ul {
                padding: 0 25px;
                li {
                    min-height: 60px;
                    padding: 10px 0;
                    .no-notifications {
                        padding: 20px 0;
                    }
                    .content {
                        p,
                        a {
                            font-size: 18px;
                        }
                        .reply-message {
                            padding: 10px 0;
                            margin: 0 auto;
                            width: 400px;
                            overflow-wrap: break-word;
                            max-width: 75%;
                        }
                        .quick-reply {
                            margin: 15px auto;
                        }
                    }
                }
            }
            .bottom {
                margin-top: 10px;
            }
        }
    }
}

.notification-message {
    p {
        font-size: 12px;
        &.reply-message {
            font-size: 16px;
        }
    }
    input {
        width: 100%;
    }
}

#toast-container {
    position: fixed;
    top: calc(10px + 55px + var(--banner-top)); //55 is height of nav and banner top
    right: 30px;
    z-index: 99999999;
    width: fit-content;
    .toast {
        width: 325px;
        padding: 20px;
        border-radius: 20px;
        position: relative;
        box-shadow: 1px 1px 5px #cfcfcf;
        background: white;
        color: white;
        position: relative;
        padding: 15px 20px;
        margin-bottom: 10px;
        &.notification {
            .container {
                display: flex;
                gap: 12px;
                align-items: center;
                span {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    flex: 1;
                }
                p {
                    height: fit-content;
                    font-size: 1em;
                    flex: 1;
                    color: $darkblue;
                }
                .btn-container {
                    display: flex;
                    gap: 10px;
                    .btn {
                        width: fit-content;
                        padding: 5px 20px;
                        height: fit-content;
                        text-decoration: none;
                        font-size: 1em;
                        flex: 1;
                    }
                }
                img {
                    width: 45px;
                }
            }
            .progress-div {
                background-color: rgb(233, 233, 233);
                border-radius: 0.5rem;
                position: absolute;
                bottom: 10px;
                width: calc(100% - 20px);
                left: 50%;
                transform: translateX(-50%);
            }

            .progress {
                background-color: $danger;
                height: 10px;
                border-radius: 1rem;
                transition: 1.5s ease-out;
                width: 100%;
            }
        }
        &.error {
            background: #d60404;
            color: white;
            border: 1px solid rgb(78.8%, 36%, 40.2%);
        }
        &.success {
            background: #13c422;
            color: white;
            border: 1px solid #b1efb6;
        }
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 0.95;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 0.95;
    transition: opacity 500ms ease-in;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}
