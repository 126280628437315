.remember-forgot-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    a {
        color: #c4c4c4 !important;
        padding: 0;
        font-weight: 100;
        font-size: 1.1em;
        font-family: "Bariol";
    }
}

#login-container,
#register-container {
    .btn {
        max-width: 100%;
        min-width: 150px;
        width: fit-content;
        margin: 0 auto;
        font-size: 1.05em;
        color: white;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .alert {
        font-size: 1.1em;
        &.alert-success {
            width: 100%;
            color: $tmsgreen;
            text-align: center;
        }
    }
}

#login-container,
#register-container {
    width: 100vw;
    height: 100svh;
    position: relative;
    background: url("/images/embr_login_background_image.png");
    background-size: cover;
    background-position: center;
    #container {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        width: min(90%, 2560px);
        height: fit-content;
        min-height: 405px;
        border-radius: 30px;
        justify-content: center;
        * {
            box-sizing: border-box;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;
            height: 100%;
        }
        .error {
            color: white;
            text-align: center;
            color: $danger;
            background: #f2dede;
            border-radius: 5px;
            width: auto;
            margin: 0;
            padding: 8px 20px;
        }

        .tms-logo-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            border-radius: 50%;
            background: white;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            img {
                margin: 0 auto;
                text-align: center;
                vertical-align: middle;
                max-height: 50px;
                max-width: 160px;
                width: auto;
            }
        }
        #embr-logo {
            flex: 1;
            background-color: #053751;
            width: 100%;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            #icons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .btn-link {
                    color: white;
                    font-size: 1.2em;
                    padding: 0;
                    width: fit-content;
                    text-align: center;
                    &.hide {
                        display: none;
                    }
                    a {
                        color: white;
                    }
                }
                .embr {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    width: min(100%, 395px);
                }
            }
        }
        #login-form {
            background: white;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            #section {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                padding: 2.5em 3.5em;
            }
            h2 {
                font-family: "Source Sans pro";
                color: $tmsblue;
                font-size: 2.7em;
                display: block;
                text-align: center;
                font-weight: 100;
                margin: 0;
            }
            .form-horizontal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;
                .form-item {
                    width: 100%;
                    margin: 0;
                    position: relative;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &.remember-forgot-container {
                        display: flex;
                        flex-direction: row;
                        gap: 1em;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        a {
                            color: #c4c4c4;
                            font-weight: 100;
                            font-size: 1.1em;
                            font-family: "Bariol";
                            &.back-login {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                text-decoration: underline;
                                svg {
                                    fill: #c4c4c4;
                                    width: 22px;
                                    height: fit-content;
                                }
                            }
                        }
                    }
                    .col-md-6 {
                        position: relative;
                        label {
                            width: fit-content;
                            display: flex;
                            p {
                                padding: 0;
                                color: #c4c4c4;
                                font-weight: 100;
                            }
                            input:checked ~ p {
                                color: $tmsblue;
                            }
                            input[type="checkbox"] {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }
                            .checkbox-custom {
                                margin: auto;
                                height: 18px;
                                width: 18px;
                                background-color: transparent;
                                border-radius: 5px;
                                border: 1px solid $tmsblue;
                                margin-right: 5px;
                                cursor: pointer;
                                &::after {
                                    position: absolute;
                                    content: "";
                                    left: 12px;
                                    top: 12px;
                                    height: 0;
                                    width: 0;
                                    border-radius: 5px;
                                    border: solid $tmsblue;
                                    border-width: 0 3px 3px 0;
                                    -webkit-transform: rotate(0deg) scale(0);
                                    -ms-transform: rotate(0deg) scale(0);
                                    transform: rotate(0deg) scale(0);
                                    opacity: 0;
                                }
                            }
                            input:checked ~ .checkbox-custom {
                                background-color: white;
                                border-radius: 5px;
                                -webkit-transform: rotate(0deg) scale(1);
                                -ms-transform: rotate(0deg) scale(1);
                                transform: rotate(0deg) scale(1);
                                opacity: 1;
                                border: 1px solid $tmsblue;
                            }
                            input:checked ~ .checkbox-custom::after {
                                -webkit-transform: rotate(40deg) scale(1);
                                -ms-transform: rotate(40deg) scale(1);
                                transform: rotate(40deg) scale(1);
                                opacity: 1;
                                left: 5px;
                                top: 1px;
                                height: 12px;
                                width: 6px;
                                border: solid $tmsblue;
                                border-width: 0 2px 2px 0;
                                background-color: transparent;
                                border-radius: 0;
                            }
                        }
                        input {
                            width: 100% !important;
                            padding: 6px 10px;
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid #c4c4c4;
                            background: white;
                            font-size: 1.1em;
                            font-family: "Bariol";
                            position: relative;
                            &:-internal-autofill-selected {
                                background-color: white !important;
                                background: white !important;
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                        .checkbox {
                            text-align: center;
                        }
                        input[type="checkbox"] {
                            width: fit-content !important;
                            margin-right: 3px;
                            color: #333;
                            padding: 3px;
                        }
                        .md.hydrated {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }
                    }
                }
                .separator {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: $defaultNeutral;
                    width: 60%;
                    hr {
                        flex: 1;
                        border: none;
                        border-top: 1px solid $defaultNeutral;
                    }
                    p {
                        font-size: 1.1em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tabletBreak) {
    #login-container,
    #register-container {
        .lightbox {
            width: 100%;
            .lightbox-container {
                width: 95%;
                max-height: 80svh;
                height: 80svh;
                .content {
                    width: 100%;
                    padding: 0;
                    height: calc(100% - 50px);

                    #header {
                        width: 310px;
                        h1 {
                            font-size: 1.6em;
                        }
                        img {
                            width: 80px;
                        }
                    }
                    h3 {
                        font-size: 1.2em;
                    }
                    p {
                        font-size: 15px;
                    }
                    #col-md-6 {
                        h4 {
                            font-size: 1.1em;
                        }
                        ol {
                            font-size: 15px;
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
        #container {
            height: fit-content;
            max-height: fit-content;
            width: 90%;
            position: relative;
            flex-direction: column;
            flex: 1 1 auto;
            .wrapper {
                flex-direction: column;
            }
            .tms-logo-icon {
                display: none;
            }
            #embr-logo {
                height: 180px;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                #icons {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    margin: auto;
                    padding: 30px 0;
                    position: relative;
                    .embr {
                        margin: 0;
                        &.active {
                            display: flex;
                        }
                    }
                    .tms_spotlight_new {
                        max-width: 350px;
                        margin: auto;
                    }
                    .embr_icon_white {
                        width: 60px;
                        margin: 0;
                        margin-right: 10px;
                    }
                    #icons-horizontal {
                        display: flex;
                        flex-direction: column-reverse;
                        transform: translateY(-5px);
                    }
                    .embr_white {
                        width: 180px;
                        height: auto;
                        margin-top: 0;
                    }
                    .embr_white_new {
                        max-width: 240px;
                    }
                    .tms_logo_white {
                        width: 100px;
                        margin: 0;
                        margin-left: 2px;
                        transform: translateY(6px);
                    }
                }
            }
            #login-form {
                height: 65%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                #section {
                    padding: 40px 20px;
                }
                .form-horizontal {
                    .form-item {
                        .col-md-6 {
                            ion-icon {
                                margin-top: -13px;
                                width: 26px;
                                height: 26px;
                                right: 12px;
                                cursor: pointer;
                                svg {
                                    color: #777;
                                    fill: #777;
                                }
                            }
                            button {
                                border-radius: 35px;
                                padding: 5px 12px;
                                width: 50%;
                                border: none;
                                font-size: 1.4em;
                                font-family: "Bariol";
                                letter-spacing: 1.2px;
                            }
                        }
                    }
                }
            }
        }
    }
    #register-container {
        #container {
            .tms-logo-icon {
                top: 185px;
            }
            #embr-logo {
                height: 175px;
            }
            #login-form {
                #section {
                    padding-top: 40px;
                    h2 {
                        font-size: 5em;
                    }
                }
            }
        }
    }
    #login-container {
        #login-form {
            h2 {
                // transform: translateY(-25px);
                font-size: 2em !important;
            }
            button {
                border-radius: 35px;
                padding: 12px;
                width: 90%;
                border: none;
                font-size: 2.2em;
                font-family: "Bariol";
                letter-spacing: 1.2px;
            }
        }
    }
}

#accounts-wrapper {
    display: block;
    width: 100%;
    background: url("/images/embr_login_background_image.png");
    background-size: cover;
    background-position: center;
    height: 100svh;
    filter: drop-shadow(0 0 0.75rem $defaultNeutralLight);
    #container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: min(95vw, $contentMaxWidth);
        height: fit-content;
        border-radius: 30px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 30px;
        h2 {
            font-family: "Source Sans pro";
            color: $tmsblue;
            font-size: 2.7em;
            display: block;
            text-align: center;
            font-weight: 100;
            margin: 0 auto;
        }
        .accounts-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-around;
            gap: 50px;
            margin: 20px 0;
            flex-wrap: wrap;
            #account {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                box-shadow: 1px 1px 10px $defaultNeutral;
                border-radius: 20px;
                flex: 0 0 min(100%, 250px);
                cursor: pointer;
                img {
                    max-width: 100%;
                    max-height: 120px;
                    height: auto;
                }
            }
            p {
                flex: 1;
            }
        }
    }
}

.navigation-container {
    width: $sidebar-width-expanded;
    background-color: #ffffff;
    padding: 0.6em 1em;
    position: sticky;
    top: 0;
    left: 0;
    border-right: 3px solid $defaultNeutralLight;
    height: 100%;
    z-index: 2;
    transition: all 0.3s;
    @media screen and (max-width: $tabletBreak) {
        position: fixed;
        width: 100%;
        z-index: 901;
        left: -100%;
        transition: left 0.3s ease;
        &.open {
            left: 0;
        }
    }

    & > #collapse-icon {
        width: 1.8em;
        height: auto;
        color: $darkblue;
        cursor: pointer;
        transition: all 0.3s;

        background-color: white;
        border: 1px solid $defaultNeutralLight;
        border-radius: 50%;
        padding: 0.15em;

        position: absolute;
        top: 2em;
        transform: translateY(-50%);
        right: -0.9em;

        &.open {
            transform: translateY(-50%) rotate(180deg);
        }

        &:hover {
            color: $tmsblue;
        }

        @media screen and (max-width: $tabletBreak) {
            display: none;
        }
    }

    .close {
        display: none;
        @media screen and (max-width: $tabletBreak) {
            position: absolute;
            display: block;
            right: 10px;
            top: 5px;
            z-index: 3;
            height: 30px;
            width: 30px;
            cursor: pointer;
        }
    }

    &.collapsed {
        width: $sidebar-width-collapsed;
        .navigation-header {
            .embr-nav-logo {
                flex-direction: column;
                #collapse-icon {
                }
            }
        }
    }

    .side-navigation {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        z-index: 2;
        height: 100%;
        gap: 0.75em;
        color: $darkblue;
        .navigation-header {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.75em;
            flex-direction: column;
            padding: 0;
            width: 100%;
            .help-icons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.75em;
                transition: all 0.3s;
                align-items: center;
                justify-content: center;
                &.rotated {
                }
            }
            .embr-nav-logo {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 0.75em;
                a {
                    width: min(100%, 135px);
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                }
                #collapse-icon {
                    width: 25px;
                    height: auto;
                    color: $darkblue;
                    cursor: pointer;
                    transition: all 0.3s;
                }
            }
        }
    }

    .nav-container {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 0.75em;
        padding-bottom: 1em;
        a {
            color: $darkblue;
            text-decoration: none;
        }
        &.user-options-container {
            border: none;
            flex: 0 0 fit-content;
            justify-content: flex-end;
            padding: 0.6em 0px 0;
            border-top: 1px solid $defaultNeutral;
            @media screen and (max-width: $tabletBreak) {
                padding: 1em 0 0;
            }
        }
        .nav-top-level-text {
            align-items: center;
            width: 100%;
            cursor: pointer;
            position: relative;

            .content-wrapper {
                display: flex;
                align-items: center;
                text-align: left;
                transition: all 0.3s;
                gap: 0.5em;
                &:hover {
                    .nav-circle {
                        background-color: $darkblue;
                        color: white;
                        svg {
                            fill: white;
                            color: white;
                        }
                    }
                }
                .nav-arrow {
                    flex: 0 0 18px;
                    width: 18px;
                    height: 18px;
                    margin: 0;
                    transition: all 0.3s;

                    &.rotated {
                        transform: rotate(180deg);
                    }
                }
                &.collapsed {
                    margin: 0;
                    justify-content: center;
                    .nav-text {
                        display: none;
                    }
                }
            }

            .nav-circle {
                flex: 0 0 26px;
                width: 26px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: $defaultNeutralLight;
                transition: background-color 0.3s;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 0.95em;
                svg {
                    fill: $darkblue;
                    width: 16px;
                    height: 16px;
                }
                &.active {
                    background-color: $darkblue;
                    color: white;
                    svg {
                        fill: white;
                        color: white;
                    }
                }
                & > :has(.active) {
                    background-color: $darkblue;
                    svg {
                        fill: white;
                    }
                }
                .photo-container {
                    width: 100%;
                    height: 100%;
                    #profile-photo-large {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .nav-text {
                text-align: left;
                transition: all 0.3s;
                font-family: "Bariol";
                font-weight: bold;
                flex: 1;
                &.collapsed {
                    visibility: hidden;
                    margin-left: 0px;
                }
            }

            .dropdown-sub-item-container {
                background-color: #fff;
                border: 1px solid $defaultNeutralLight;
                border-radius: 0.2rem;
                box-shadow: 0 0.2rem 0.4rem #11111114;
                left: 0;
                opacity: 1;
                pointer-events: all;
                position: absolute;
                right: 0;
                bottom: calc(100% + 1.1em);
                transform: translateY(0) translateZ(0);
                transform-origin: 50% 0;
                transition: all 0.3s cubic-bezier(0.23, 1.2, 0.32, 1);
                padding: 0.75em;
                width: 100%;
                z-index: -99999;

                display: none;
                flex-direction: column;
                gap: 0.75em;
                opacity: 0;
            }
            &.active {
                .dropdown-sub-item-container {
                    display: flex;
                    opacity: 1;
                    z-index: 11111;
                }
            }
        }
    }

    .active {
        color: $darkblue;
    }

    .navigation-top-content {
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #d9d9d9;
    }

    .navigation-bottom-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        justify-content: flex-end;
    }

    .create-project-container {
        position: relative;
        width: 100%;
    }

    .create-project-button {
        cursor: pointer;
        padding: 1em;
        border-radius: 10px;
        width: 100%;
        background: #093953;
        color: #fff;
        font-size: 16px;
        font-family: "Source Sans Pro", sans-serif;
        box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        svg {
            width: 15px;
            height: 15px;
        }
        &.button-collapsed {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .sub-list-container {
        max-height: max-content;
        overflow: hidden;
        transition: max-height 0.3s;
        padding: 10px 0 0;
        &.collapsed {
            max-height: 0px;
            display: none;
        }
        .sub-level-list {
            list-style-type: none;
            padding: 0;
            padding-inline-start: 1.5em;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .nav-sub-level-text {
                position: relative;
                padding: 0.25rem 0.25rem 0.25rem 0.7em;
                transition: all 0.15s;
                &:hover,
                &.active {
                    border-radius: 5px;
                    background-color: $defaultNeutralLight;
                }
            }
            &.hidden {
                display: none;
            }
        }
    }

    .sub-link-style {
        text-decoration: none;
    }

    .project-select-container {
        position: absolute;
        left: calc(100% + 0.75em);
        background-color: $darkblue;
        border-radius: 10px;
        padding: 35px 15px 15px;
        top: 50%;
        transform: translateY(-50%);
        transition:
            left 0.5s ease-in-out,
            opacity 0.3s ease-in-out;

        width: max-content;
        max-width: 235px;

        .close-button {
            position: absolute;
            top: 8px;
            right: 8px;
            background: none;
            border: none;
            font-size: 1.3em;
            color: #fff;
            cursor: pointer;
            width: fit-content;
            height: fit-content;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 0.75em;
            .project-create-text-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.75em;
            }
            .project-create-text {
                color: white;
                flex: 1;
                @media screen and (max-width: $tabletBreak) {
                    justify-content: center;
                    display: flex;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
            .project-create-link-text {
                text-decoration: none;
                font-family: "Bariol";
                color: white;
            }
        }

        .info-container {
            .info {
                width: 1.2em;
                height: 1.2em;
                border-radius: 50%;
                background-color: #fff;
                font-size: 0.8em;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                padding-bottom: 0.35em;
                cursor: pointer;
                position: relative;
                .content {
                    position: absolute;
                    background: #fff;
                    border: 1px solid black;
                    max-width: 30ch;
                    width: 30ch;
                    padding: 1.5em;
                    border-radius: 10px;
                    top: calc(100% + 1.5em);
                    z-index: 1;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: all 0.3s ease;
                }
                &:hover .content {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: initial;
                }
            }
        }
        @media screen and (max-width: $tabletBreak) {
            right: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 14%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays above other content */
}

#nav-container-banner {
    height: fit-content;
    width: 100%;
    height: 55px;
    background: #083953 !important;
    position: sticky;
    z-index: 1;
    transition: left 0.5s;
    top: 0;
    padding: 0.75em 0.5em;

    display: block;
    z-index: 900;
    height: auto;

    @media screen and (min-width: $tabletBreak) {
        display: none;
    }

    #mobile-nav {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        height: 100%;
        color: #fff;
        padding: 0;
        svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 36px;
            height: auto;
        }
        .center-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                flex: 0 0 95px;
                height: 100%;
                position: relative;
                .center-image {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
