@import "variables";
@import "common";
@import "pages";
@import "myMarketing";
@import "admin";
@import "requests";
@import "user";
@import "react-crop";
@import "notifications";
@import "resources";
@import "reports";
@import "react-select";
@import "services";
@import "select-search";
@import "marketing-services";
@import "survey";
@import "layouts";
@import "dashboard";
@import "stripe";
@import "generators";

*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Source Sans Pro", sans-serif;
}

body {
    scrollbar-face-color: $tmsblue;
}

a {
    color: $lightblue;
    cursor: pointer;
    // text-decoration: underline;
}

html {
    box-sizing: border-box;
    overflow-x: hidden;
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    &.tox-dialog__disable-scroll {
        overflow: visible !important;
    }
}

.clear {
    clear: both;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
li {
    line-height: normal;
}

.data-feedbackfin-button {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background: $tmsblue;
    width: 100px;
    color: white;
    will-change: transform;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    text-align: center;
    font-size: 17px;
    position: fixed;
    right: -40px;
    top: 45%;
    font-family: "Bariol Bold", helvetica, arial, sans-serif;
    z-index: 999;
}

// Makes an image circular
.spherize {
    margin-top: 6px;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border: 1px solid #aaaaaa;
}

:root {
    --banner-top: 0px;
}

.app-banner-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;

    &:empty {
        display: none;
    }
}

.banner,
.impersonate-banner {
    display: block;
    position: relative;
    width: fit-content;
    max-width: min(90%, 1250px);
    background-color: rgba($color: $danger, $alpha: 1);
    padding: 8px 15px;
    height: fit-content;
    margin: 0;
    &.warning {
        background-color: $warning;
    }
    &.success {
        background-color: $tmsgreen;
    }
    #banner-text {
        display: flex;
        width: fit-content;
        margin: auto;
        height: 100%;
        text-align: center;
        * {
            line-height: normal;
        }
        h3 {
            width: fit-content;
            color: white;
            font-family: "Bariol Light";
            font-size: 1.05em;
            letter-spacing: normal;
        }
        a {
            text-decoration: none;
            font-family: "Bariol";
            color: white;
            text-decoration: underline;
            span {
                background-color: white;
                color: $danger;
                border-radius: 50px;
                width: fit-content;
                padding: 0 20px;
                display: flex;
                height: 100%;
                cursor: pointer;
                svg {
                    width: 23px;
                    height: 23px;
                    margin: auto 0px;
                }
                p {
                    width: fit-content;
                    font-family: "Bariol Bold";
                    font-size: 1.2em;
                    margin: auto 5px;
                }
            }
            &:visited,
            &:active {
                color: white;
                text-decoration: none;
            }
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    #close {
        height: 15px;
        width: 15px;
        border-radius: 5px;
    }
    #close:after {
        position: absolute;
        content: "\d7";
        font-size: 30px;
        color: white;
        font-weight: bold;
        cursor: pointer;
        top: 7px;
        right: 20px;
    }

    @media screen and (max-width: $mobileLargeBreak) {
        #banner-text {
            h3 {
                font-size: 0.85em;
            }
            a {
                span {
                    svg {
                        width: 19px;
                        height: 19px;
                    }
                    p {
                        font-size: 0.9em;
                    }
                }
            }
        }
    }
}

#loading-box {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100dvh;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    top: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    * {
        transition: none;
    }

    h1 {
        width: 100%;
        color: white;
        text-align: center;
        z-index: 950;
        font-family: "Bariol Bold";
        font-size: 2.2em;
        border: none;
    }
    svg {
        width: 200px;
        height: 200px;
    }
}

//prevent child elements of svgs from firing events
svg * {
    pointer-events: none;
}
.lrg-text {
    display: block;
    margin: 0 auto;
}

.error {
    color: white;
    text-align: center;
    font-size: 1em;
    background: #b84242;
    padding: 8px;
    width: fit-content;
    max-width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: normal;
    svg {
        color: white;
        width: 24px;
        height: 24px;
    }
}

.warning {
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: 20px;
    background: $warning;
    padding: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: normal;
    width: fit-content;
    svg {
        color: white;
        width: 24px;
        height: 24px;
    }
}

.input-error {
    color: $danger;
    margin: 0;
    font-style: italic;
    font-size: 1rem;
    text-align: left;
    padding: 0;
    line-height: 1em;
}

.dropdown-container {
    border-radius: 0;
    cursor: pointer;
    position: relative;

    #dd-opts {
        position: absolute;
        z-index: 999;
        top: 105%;
        left: 0%;
        border: 1px solid #ccc;
        width: 232.9px;
        background: white;
        left: 50%;
        transform: translateX(-54%);
        li {
            list-style-type: none;
            background: white;
            border-bottom: 1px solid #ccc;
            width: 100%;
            text-align: center;
            span {
                display: inline-block;
                width: 15px;
                vertical-align: middle;
                margin-right: 10px;
            }
            &:last-child {
                border: 0;
            }
            &:hover {
                background: #ccc;
            }
            a {
                color: #333;
                text-decoration: none;
                display: block;
                padding: 10px 0;
            }
        }
    }
}

#app {
    width: 100%;
    height: 100%;
}

.app-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: min(100%, $contentMaxWidth);
}

.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    // padding: 0 1.5rem 0 calc($sidebar-width-collapsed + 1rem);
    padding: 0 1.15em 0 1em;
    transition: all 0.3s;
    min-height: 100svh;
    height: fit-content;
    width: 100%;
    gap: 1em;
    @media screen and (max-width: $tabletBreak) {
        padding: 0;
    }
}

#main-content {
    height: 100%;
    color: #444;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0.5em 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: $tabletBreak) {
        padding: 0 1em;
    }
}

#app-layout {
    width: 100%;
    height: 100%;
}

.form-item {
    label {
        display: block;
        position: relative;
        &.inline {
            display: inline;
        }
    }
}

#section-header {
    display: block;
    width: 100%;
    color: $tmsblue;
    font-family: "Bariol";
    letter-spacing: 0.5px;
    text-align: center;
    font-size: 1.55em;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
}

#sub-header {
    font-size: 3.4em;
    font-weight: 300;
    width: 100%;
    text-align: center;
    line-height: normal;
    @media screen and (max-width: $mobileLargeBreak) {
        font-size: 2.7em;
    }
}

#sub-header-2 {
    font-size: 1.4em;
    font-weight: 100;
    width: 100%;
    text-align: center;
    line-height: 0.75em;
}

.my-photos {
    margin-top: 40px;
}

p {
    line-height: 1.5em;
}

.full-width {
    width: 100%;
}

.center {
    text-align: center;
}

#hub-container {
    width: 100%;
    position: relative;
    text-align: center;
    .image-wrapper {
        background: url(/images/embr_desktop.png) no-repeat center;
        background-size: cover;
        height: 150px;
    }
    img {
        width: 100%;
    }
    h1 {
        font-family: "Source Sans Pro";
        position: relative;
        text-align: center;
        margin: 20px auto;
        letter-spacing: 0px;
        font-size: 5.6em;
        font-weight: 100;
        color: $darkblue;
    }
    .embr {
        position: absolute;
        bottom: 30px;
        right: 25px;
        width: 110px;
        height: auto;
    }
}

@media screen and (max-width: 1200px) {
    #hub-container {
        .image-wrapper {
            background: url(/images/embr_mobile.png) no-repeat center;
            background-size: cover;
        }
        h1 {
            font-size: 4.5em;
        }
    }
}

#tile-container {
    display: flex;
    align-content: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: fit-content;
    margin: 50px auto 60px auto;
    flex-grow: unset;
}

#rows {
    display: block;
    // width: 100%;
    margin-bottom: 100px;
}

.tile {
    margin-top: 30px;
    margin-right: 100px;
    border-radius: 20px;
    height: 155px;
    position: relative;
    -moz-box-shadow: -2px 2px 1.5px #c8c8c8;
    -webkit-box-shadow: -2px 2px 1.5px #c8c8c8;
    box-shadow: -3px 3px 3px #c8c8c8;
    transform: translateY(0px);
    &:hover {
        background: #fefefe;
        transform: translateY(-3px);
        // animation-name: bounce;
        //             -webkit-animation-name: bounce;
        //             animation-duration: 0.2s;
        //             -webkit-animation-duration: 0.22s;
        //             animation-timing-function: ease-in-out;
        //             -webkit-animation-timing-function: ease-in-out;
        //             visibility: visible !important;
    }
    a {
        text-decoration: none;
    }
    p {
        line-height: 1.2em;
        width: 100%;
        height: fit-content;
        max-height: calc(100% - 45px);
        overflow: hidden;
        text-align: center;
        padding: 5px 15px 0 15px;
        font-size: 24px;
        font-family: "Bariol";
        color: $tmsblue;
        text-decoration: none;
        position: absolute;
        top: 50%;
        margin-top: -30px;
        span {
            display: block;
            font-size: 16px;
            line-height: 1.15em;
            padding-top: 5px;
        }
    }
    #icon {
        width: 60px;
        height: 60px;
        // background: $tmsblue;
        border-radius: 50%;
        margin: 0 auto;
        align-content: center;
        margin-top: -25px;
    }
    #CustomTag {
        position: relative;
        margin: 15px auto;
        width: inherit;
    }
    &:last-child {
        margin-right: 0px;
    }
}

.single {
    width: 240px;
}

.double {
    width: 580px;
}

@media screen and (max-width: 800px) {
    #tile-container {
        width: 85vw;
        justify-content: space-between;
        margin: 20px auto;
        .tile {
            margin: 30px 0;
            height: 160px;
            p {
                // padding: 30px 15px;
            }
        }
    }
    .single,
    .double {
        width: 205px;
    }
}

@media screen and (max-width: 450px) {
    #tile-container {
        width: 85vw;
        .tile {
            height: 130px;
            p {
                padding: 0 15px;
            }
        }
    }
    .single,
    .double {
        width: 170px;
    }
}

.divider {
    content: "";
    border-left: 2px solid #fff;
}

.plus {
    position: relative;
    font-size: 30px;
    margin-right: 7px;
    top: -2px;
}

// modal for nav
#modal {
    padding: 0;
    // display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 15px;
    left: 12px;
    width: 96%;
    height: 100%;
    z-index: 99999;
    background: #fff;
    border-radius: 6px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    #burger-box {
        // margin: 30px auto;
        width: fit-content;
        position: absolute;
        right: 15px;
        top: 12px;
        // height: 35px;
        z-index: 9999;
    }
    #burger,
    #burger1 {
        margin-top: 12px;
        position: relative;
        width: 30px;
        height: 5px;
        background: white;
        z-index: 9999;
        transform-origin: center;
        &:before,
        &:after {
            position: absolute;
            width: inherit;
            height: inherit;
            background: inherit;
            content: "";
            transform: rotate(0deg);
            // transition-duration: 0.05s;
        }
        &:before {
            top: -10px;
            right: 0;
        }
        &:after {
            bottom: -10px;
            right: 0;
        }
        &.active {
            background-color: none;
            &:before,
            &:after {
                background-color: black;
                top: 0;
                // transition-duration: 0.05s;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    &.show {
        // display: block;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s ease-in-out;
    }
    #nav-items-mobile {
        position: relative;
        height: 100%;
        #items {
            // height: fit-content;
            display: block;
            height: calc(100% - 110px);
            overflow: scroll;
            &.show {
                #nav-parent {
                    margin-bottom: 15px;
                }
            }
        }
        #profile {
            list-style-type: none;
            margin-top: 50px;
            text-align: center;
            min-height: max-content;
            max-height: 360px;
            text-decoration: none;
            margin-bottom: 25px;
            color: $tmsblue;
            img {
                margin-bottom: 15px;
                width: 175px;
                height: 175px;
            }
            .arrow-down {
                width: 35px;
                margin-bottom: 3px;
                height: 35px;
            }
            .columns {
                display: none;
                &.show {
                    width: fit-content;
                    margin: 0 auto;
                    text-align: left;
                    display: block;
                    list-style-type: none;

                    animation-name: slideDown;
                    -webkit-animation-name: slideDown;
                    animation-duration: 0.2s;
                    -webkit-animation-duration: 0.2s;
                    animation-timing-function: ease-in;
                    -webkit-animation-timing-function: ease-in;
                    visibility: visible !important;

                    li {
                        font-size: 20px;
                        padding: 5px 0;
                        text-decoration: none;
                        a,
                        a:visited {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .project {
            margin: 0px;
            text-align: center;
            position: absolute;
            background: $tmsblue;
            bottom: 20px;
            height: 80px;
            font-size: 32px;
            color: white;
            width: 100%;
            padding: 12px 0;
            p {
                z-index: 99999;
            }
            a,
            a:visited {
                text-decoration: none;
                color: white;
            }
        }
        #nav-parent {
            margin-bottom: 30px;
            height: fit-content;
            color: $darkblue;
            width: 100%;
            padding: 0 30px;
            text-align: left;
            .arrow-down {
                width: 35px;
                margin-bottom: 3px;
                height: 35px;
            }
            #nav_title {
                font-size: 28px;
                padding-left: 10px;
                padding-top: 5px;
                font-weight: 400;
            }
            &.upgrade {
                width: 100%;
                margin-left: 0;
                padding-left: 5px;
                padding-right: 5px;
                .submit-payment {
                    display: block;
                    padding: 17px 0;
                    font-weight: bold;
                    font-family: "Bariol";
                    font-size: 1.4em;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 50px;
                    width: 90%;
                    margin: 0px auto;
                    color: white;
                    text-decoration: none;
                    position: relative;
                    background-color: #d85553;
                }
            }
            .child-nav {
                width: 100%;
                // display: none;
                max-height: 0;
                overflow: hidden;
                padding-left: 60px;
                -webkit-transition: max-height 0.35s ease;
                -moz-transition: max-height 0.35s ease;
                -o-transition: max-height 0.35s ease;
                transition: max-height 0.35s ease;
                &.show {
                    max-height: 400px;
                }
                .child-nav-heading {
                    p {
                        font-size: 22px;
                        margin-bottom: 3px;
                    }
                }
                li {
                    a {
                        color: $darkblue;
                    }
                }
                .columns {
                    display: flex;
                    flex-direction: column;
                    padding-top: 8px;
                    padding-left: 10px;
                    span {
                        display: flex;
                        flex-direction: row;
                        padding-bottom: 12px;
                        color: $darkblue;
                        font-size: 20px;
                        #icon {
                            margin: 0;
                            margin-right: 7px;
                            height: 30px;
                            margin-top: -12px;
                        }
                        li {
                            margin: 0;
                            list-style-type: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #nav-parent {
        position: relative;
    }
    #mobile-nav-heading {
        display: flex;
        flex-direction: row;
        #icon {
            padding: 9px 0 0 0;
            background: $tmsblue;
            border-radius: 50%;
            margin: 0;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            svg {
                margin: 1.5px auto 0 auto;
            }
            .nav-title {
                flex: 1;
                position: relative;
            }
        }
        .arrow-down {
            position: absolute;
            top: 10px;
            right: 0;
        }
        #CustomTag {
            width: inherit;
        }
    }
}

.create-project {
    background: $tmsblue;
}

::-webkit-scrollbar {
    height: 12px;
    width: 8px;
    background: white;
}

::-webkit-scrollbar-thumb {
    height: 12px;
    width: 5px;
    background: #888;
}

@media screen and (max-width: 1200px) {
    #nav-container {
        width: 50px;
        opacity: 0.7;
        transition: all 0.75s ease;
        z-index: 999999;
        overflow-y: hidden;
        .nav-parent {
            padding: 12px 0;
            font-size: 19px;
            .child-nav {
                font-size: 18px;
                li {
                    margin: 15px 0;
                }
            }
        }
        #burger-box {
            margin: 30px auto;
            width: fit-content;
            height: 35px;
            z-index: 9999;
        }
        #burger {
            margin-top: 12px;
            position: relative;
            width: 30px;
            height: 5px;
            background: #fff;
            transform-origin: center;
            &:before,
            &:after {
                position: absolute;
                width: inherit;
                height: inherit;
                background: inherit;
                content: "";
                transform: rotate(0deg);
                transition-duration: 0.15s;
            }
            &:before {
                top: -10px;
            }
            &:after {
                bottom: -10px;
            }
            &.active {
                background-color: transparent;
                &:before,
                &:after {
                    background-color: #fff;
                    top: 0;
                    transition-duration: 0.45s;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
        #mobile-trigger {
            display: block;
            // text-align: center;
            color: white;
            font-size: 42px;
            .burger-box {
                display: block;
            }
        }
        &.show {
            opacity: 1;
            width: 300px;
            overflow-y: scroll;
            #nav {
                display: block;
            }
            #mobile-trigger {
                position: absolute;
                top: 5px;
                right: 20px;
                span.minus {
                    display: block;
                }
                span.plus {
                    display: none;
                }
            }
        }
        #nav {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    #nav-container {
        height: 100%;
        padding-bottom: 30px;
        overflow: hidden;
        transition: all 0.45s ease;
        &.show {
            overflow-y: scroll;
            #nav {
                // margin-bottom: 60px;
            }
        }
    }
}
#header-logo {
    width: 100%;
    background: #043954;
    padding: 20px 0 10px 0;
    img {
        display: block;
        margin: 0 auto;
    }
    #user-name {
        color: white;
        width: 100%;
        font-size: 22px;
        margin-top: 20px;
        text-align: center;
        height: 40px;
        text-decoration: none;
    }
    a {
        display: block;
        color: white;
        width: 100%;
        text-align: center;
    }
}
@media screen and (max-width: 1200px) {
    #header-logo {
        padding: 50px 0 10px 0;
    }
}

@media screen and (max-width: 600px) {
    #nav-items {
        // margin-bottom: 40px;
    }
}

#nav-items {
    //margin-top:-20px;
    .child-nav {
        list-style-type: none;
    }
    svg {
        margin-left: 7px;
    }
    div {
        font-size: 20px;
        padding: 10px 0px;
        p {
            color: white;
            cursor: pointer;
        }
        ul {
            &#team-nav li {
                color: #81b5f2;
            }
        }
        li {
            color: #81b5f2;
            &.inactive {
                padding: 3px 0px 3px 60px;
            }
            &.active {
                &:hover {
                    background: $tmsblue;
                }
                a {
                    color: white;
                    cursor: pointer;
                    display: block;
                    width: 100%;
                    padding: 3px 0px 3px 60px;
                }
            }
        }
    }
    .child-nav {
        display: none;
    }
}

//home
#home-top {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    #icon {
        margin: 0;
    }
}

#greeting {
    font-family: "Bariol";
    font-weight: normal;
    font-size: 2.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 0;
    justify-content: flex-start;
    .svg-gear {
        width: 32px;
        height: 32px;
    }
}

@media screen and (max-width: 600px) {
    #greeting {
        font-size: 2.5em;
    }
}

#table-container {
    width: 100%;
    margin: 0 auto;
    .csv-download {
        width: fit-content;
        margin-left: auto;
        text-decoration: none;
    }
    .table-banner {
        font-size: 1.3em;
        text-align: center;
        color: $darkblue;
    }
}

@media screen and (max-width: $tabletBreak) {
    #table-container {
        width: 100%;
        max-width: 100%;
    }
}

.table {
    display: table;
    border-collapse: collapse;
}

.table-row {
    display: table-row;
    position: relative;
}

.tbody {
    display: table-row-group;
    animation: slideDown 0.25s ease-in-out forwards;
    &.forward {
        animation: slideDown 0.25s ease-in-out forwards;
    }
    &.reverse {
        animation: slideDown 0.25s ease-in reverse;
    }
}

.table-cell {
    display: table-cell;
    padding: 10px 0px 10px 10px;
}

.colspan {
    display: absolute;
    width: 100%;
}

.column-span-all {
    -webkit-column-span: 2;
    -moz-column-span: 2;
    column-span: 2;
}

div.table-wrapper {
    position: relative;
    margin-bottom: 40px;
    .table-chevron {
        position: absolute;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        cursor: pointer;
    }
    div.row-count {
        position: absolute;
        right: -20px;
        top: -7px;
        font-size: 28px;
        color: #fff;
        @include spherize(70px, none);
        &.neutral {
            background-color: $neutral;
        }
        &.success {
            background-color: $tmsgreen;
        }
        &.danger {
            background-color: $danger;
        }
        &.normal {
            background-color: $normal;
        }
        &.warning {
            background-color: $warning;
        }
        span {
            line-height: 70px;
            text-align: center;
            display: block;
        }
    }
}

@media screen and (max-width: 600px) {
    div.table-wrapper {
        margin-top: 40px;
        div.row-count {
            font-size: 21px;
            @include spherize(50px, none);
            span {
                line-height: 50px;
            }
        }
    }
}

.indicator {
    width: 15px;
    height: 15px;
    background: $normal;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    &-success {
        background-color: $tmsgreen;
    }
    &-danger {
        background-color: $danger;
    }
    &-current {
        background-color: $tmsgreen;
    }
    &-neutral {
        background-color: $neutral;
    }
    &-warning {
        background-color: $warning;
    }

    &-reopened {
        background-color: $reopened;
    }
    &-default {
        background-color: $tmsblue;
    }
    &-print-ordered {
        background-color: $printOrdered;
    }
    &-new-hire {
        background-color: $newHire;
    }
    &-creative-review {
        background-color: #f987c5;
    }
}

.indicator-status {
    border-radius: 1em !important;
    padding: 0.15em 0.75em !important;
    text-align: center !important;

    background-color: rgba($normal, 0.1);
    border: 1px solid $normal;
    color: $normal;

    &-default {
        @extend .indicator-status;
        background-color: rgba($tmsblue, 0.1);
        border: 1px solid $tmsblue;
        color: $tmsblue;
    }

    &-success {
        @extend .indicator-status;
        background-color: rgba($tmsgreen, 0.1);
        border: 1px solid $tmsgreen;
        color: $tmsgreen;
    }

    &-danger {
        @extend .indicator-status;
        background-color: rgba($danger, 0.1);
        border: 1px solid $danger;
        color: $danger;
    }

    &-current {
        @extend .indicator-status;
        background-color: rgba($tmsgreen, 0.1);
        border: 1px solid $tmsgreen;
        color: $tmsgreen;
    }

    &-neutral {
        @extend .indicator-status;
        background-color: rgba($neutral, 0.1);
        border: 1px solid $neutral;
        color: $neutral;
    }

    &-warning {
        @extend .indicator-status;
        background-color: rgba($warning, 0.1);
        border: 1px solid $warning;
        color: $warning;
    }

    &-reopened {
        @extend .indicator-status;
        background-color: rgba($reopened, 0.1);
        border: 1px solid $reopened;
        color: $reopened;
    }

    &-print-ordered {
        @extend .indicator-status;
        background-color: rgba($printOrdered, 0.1);
        border: 1px solid $printOrdered;
        color: $printOrdered;
    }

    &-new-hire {
        @extend .indicator-status;
        background-color: rgba($newHire, 0.1);
        border: 1px solid $newHire;
        color: $newHire;
    }

    &-creative-review {
        @extend .indicator-status;
        background-color: rgba(#f987c5, 0.1);
        border: 1px solid #f987c5;
        color: #f987c5;
    }
}

.project-table {
    width: 100%;
    border-collapse: collapse;
    text-align: middle;
    margin: 0 auto;
    svg {
        cursor: pointer;
    }
    thead {
        cursor: pointer;
    }
    .active {
        background-color: $darkblue !important;
        color: #fff;
        td {
            color: #fff !important;
        }
    }
    tr {
        display: flex;
        width: 100%;
        .table-cell,
        td {
            flex: 0 0 18%;
            &.count {
                position: relative;
                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 30px;
                    width: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 22px;
                    border: none;
                    background: white;
                }
            }
            &:first-of-type {
                width: 80px;
                flex: 0 0 80px;
            }
            &:nth-of-type(2) {
                flex: 1;
            }
            &.misc {
                flex: 0 0 50px;
                position: relative;
                .priority-indicator {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    &.indicator-success {
                        background-color: $tmsgreen;
                        box-shadow:
                            0px 0px 0px 3px white,
                            0px 0px 0px 5px $tmsgreen;
                    }
                    &.indicator-danger {
                        background-color: $danger;
                        box-shadow:
                            0px 0px 0px 3px white,
                            0px 0px 0px 5px $danger;
                    }
                    &.indicator-warning {
                        background-color: $warning;
                        box-shadow:
                            0px 0px 0px 3px white,
                            0px 0px 0px 5px $warning;
                    }
                }
            }
        }
    }

    .success .count {
        color: $tmsgreen;
    }
    .danger .count {
        color: $danger;
    }
    .neutral .count {
        color: $neutral;
    }
    .warning .count {
        color: $warning;
    }
    .current .count {
        color: #f08456;
    }
    .normal .count {
        color: $normal;
    }
    tbody:nth-child(odd) tr,
    .tbody:nth-child(odd) .table-row {
        background-color: #eeeeee;
    }
    div.th::first-of-type {
        font-size: 22px;
    }

    .created {
        th {
            background-color: $created;
            &.count {
                color: $created;
            }
        }
    }

    .reopened {
        th {
            background-color: $reopened;
            &.count {
                color: $reopened;
            }
        }
    }

    .overdue {
        th {
            background-color: $overdue;
            &.count {
                color: $overdue;
            }
        }
    }

    .duetoday {
        th {
            background-color: $duetoday;
            &.count {
                color: $duetoday;
            }
        }
    }

    .awaiting-approval {
        th {
            background-color: $awaitingApproval;
            &.count {
                color: $awaitingApproval;
            }
        }
    }

    .near-deadline {
        th {
            background-color: $nearDeadline;
            &.count {
                color: $nearDeadline;
            }
        }
    }

    .print-ordered {
        th {
            background-color: $printOrdered;
            &.count {
                color: $printOrdered;
            }
        }
    }

    .assigned {
        th {
            background-color: $assigned;
            &.count {
                color: $assigned;
            }
        }
    }

    .open {
        th {
            background-color: $darkblue;
            &.count {
                color: $darkblue;
            }
        }
    }

    .new-hire {
        th {
            background-color: $newHire;
            &.count {
                color: $newHire;
            }
        }
    }

    &.interactive-table > tbody > tr {
        &.project-select-row {
            background-color: #fff;
            padding-top: 0;
            td {
                padding: 0;
                flex: 1;
            }
        }
        &.normal-row {
            cursor: pointer;
            // &:hover {
            //     background-color: #ccc;
            //     color: #fff;
            // }
            td {
                position: relative;
                font-family: "Source Sans Pro";
            }
        }
        .arrow {
            border: solid $darkblue;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            position: absolute;
            right: 50%;
            margin-right: -4px;
            top: 50%;
            margin-top: -5px;
            margin-left: -20px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            &.active {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                margin-top: -6px;
                border-color: white;
            }
        }
    }
    &.interactive-table {
        .table-row:first-child {
            cursor: default;
        }
    }

    th,
    .th {
        background-color: #6e6e6e;
        text-transform: lowercase;
        color: white;
        font-family: "Bariol";
        letter-spacing: 1px;
        text-align: left;
        font-size: 1.2em;
        width: 18%;
        padding: 5px 0px 5px 10px;
        font-weight: 600;
        vertical-align: center;
        &:first-of-type {
            width: 80px;
        }
    }
    th:first-child {
        font-size: 20px;
        width: 6vw;
    }
    td,
    .table-cell {
        padding: 16px 0px 16px 10px;
    }
    &.stat-table {
        th,
        td {
            text-align: center;
        }
        th:first-child,
        td:first-child {
            text-align: left;
        }
    }
}

@media screen and (max-width: 800px) {
    .project-table-container {
        max-width: 100%;
        overflow: scroll;
        margin: 20px 0px;
    }
    .project-table {
        overflow: auto;
        border-collapse: collapse;
        th,
        .th {
            max-width: 20vw;
            text-align: left;
            width: 15vw;
            &:first-of-type {
                width: 50px;
            }
            &:nth-of-type(2) {
                width: 33vw;
            }
        }
        td,
        .table-cell {
            padding: 16px 5px;
            &:nth-of-type(2) {
                width: 33vw;
            }
        }
        th:first-child {
            // font-size: 16px;
            width: 22vw;
        }
        .table-title {
            width: 20vw;
        }
    }
}

#columns {
    display: block;
    width: 100%;
    margin: 30px auto;
    text-align: center;
}

.news-columns {
    display: inline-block;
    width: 261px;
    height: 294px;
    border: 1px solid #ccc;
    padding: 15px;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 30px;
    &:last-child {
        background: #f0f0f0;
        li {
            a {
                text-align: left;
                color: #333;
            }
        }
        .news-date {
            font-size: 14px;
            text-align: left;
            color: #333;
        }
    }
    & > p {
        width: 100%;
        text-align: center;
        font-size: 22px;
    }
    ul {
        width: 100%;
        li {
            list-style-type: none;
            padding: 10px 0;
            border-bottom: 1px solid #ccc;
            &:first-child {
                margin-top: 10px;
            }
            &:last-child {
                border-bottom: 0;
            }
            a {
                display: block;
                width: 100%;
                text-align: center;
                color: $tmsblue;
                text-decoration: none;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

//photos page
#profile-photo-large {
    width: 245px;
    height: 245px;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
    border: 1px solid #ccc;
}

.user-image {
    display: block;
    cursor: pointer;
    position: relative;
    width: 150px;
    height: 150px;
    &:hover > .trash-icon {
        display: block;
    }
    .trash-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 20px;
        width: 20px;
        fill: #d3d3d3;
    }
    svg {
        display: none;
    }
    &.primary {
        .star-icon {
            display: block;
        }
        &:hover > .trash-icon {
            display: none;
        }
    }

    img {
        border: 1px solid #ccc;
        width: 150px;
        height: 150px;
        &.selected {
            border: 4px solid $tmsgreen;
        }
    }
}

.help-error {
    display: block;
    color: $danger;
    padding-top: 5px;
}

.btn {
    display: block;
    padding: 0.5em 1.5em;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 1em;
    outline: none;
    text-decoration: none;
    background-color: transparent;
    width: fit-content;
    min-width: fit-content;
    max-width: 100%;
    font-size: 1em;
    &.red,
    &.danger {
        background-color: $danger;
        color: white;
    }
    &.gray {
        background-color: #d3d3d3;
        color: white;
    }
    &.blue {
        background: $tmsblue;
        color: white;
    }
    &.filter {
        background: $danger;
        color: white;
        border-radius: 20px;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 15px;
    }
    &.green {
        background: $tmsgreen;
        color: white;
    }
    &.neutral {
        background: $neutral;
        color: white;
        cursor: default;
    }
    &.center {
        margin: 0 auto;
    }
    &.darkblue {
        background: $darkblue;
        color: white;
    }
    &.unenroll-btn {
        width: fit-content;
        margin: 0 auto;
        min-width: 150px;
    }
    &.disabled {
        opacity: 0.7;
        pointer-events: none;
        background: #d3d3d3 !important;
        background-color: #d3d3d3 !important;
    }
    &.facebook {
        background-color: #1877f2;
    }
    &.instagram {
        background: -webkit-radial-gradient(
            30% 107%,
            circle,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
        );
        background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
        background: -webkit-radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
        );
    }
    &.linkedin {
        background-color: #0077b5;
    }
    &.sf-blue {
        background-color: $salesforceblue;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    &.load-more {
        width: 100%;
        max-width: 200px;
        margin: 30px auto;
    }
    &.transparent {
        background: transparent;
        color: $darkblue;
        border: 1px solid $darkblue;
        &:hover {
            background: $darkblue;
            color: white;
        }
    }
}
#crop-modal {
    display: block;
    position: fixed;
    width: 100vw;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    z-index: 999;
    overflow-y: scroll;
    padding: 20px;
    * {
        transition: none;
    }
    .ReactCrop {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        max-width: min(90vw, 1450px);

        & > div:not([class]) {
            width: 100%;
            height: 100%;
        }

        .ReactCrop__image {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .crop-buttons {
        position: absolute;
        right: 40px;
        top: 20px;
        h2 {
            position: relative;
            color: white;
            margin-bottom: 20px;
            z-index: 100;
        }
        .btn {
            width: 150px;
            margin-bottom: 20px;
            &.red {
                background: $danger;
                color: white;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #crop-modal {
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        .ReactCrop {
            position: relative;
            left: 0px;
            transform: none;
            width: calc(100% - 150px);
            margin: 50px auto;
        }
        .crop-buttons {
            clear: initial;
            position: initial;
            margin: 0 auto;
            h2 {
                font-size: 1.6em;
            }
            .btn {
                width: 100%;
            }
        }
    }
}

#schedule-photo {
    width: 100%;
    padding-top: 20px;
    padding: 40px 20px 20px;
    border-top: 1px solid #ccc;
    text-align: center;
    p,
    a {
        font-size: 17px;
    }
    p:first-child {
        font-weight: 600;
        font-size: 22px;
    }
    a {
        color: white;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        max-width: fit-content;
        margin: 20px auto;
        &:visited,
        &:hover {
            color: white;
        }
    }
}

#nav-tab {
    display: none;
}

#project-count-box {
    width: 85%;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    .box-container {
        margin-bottom: 30px;
        width: 100%;
        margin-right: 0;
        a {
            display: inline-block;
            margin-right: 20px;
        }
    }
    div {
        display: inline-block;
        width: 206px;
        height: 122px;
        margin-right: 20px;
        padding-top: 3px;
        color: #fff;
        span:first-of-type {
            display: block;
            font-size: 68px;
        }
    }
    div:last-child {
        margin: 0 auto;
    }
    .success {
        background-color: $tmsgreen;
    }
    .normal {
        background-color: $normal;
    }
    .warning {
        background-color: $warning;
    }
    .danger {
        background-color: $danger;
    }
    .neutral {
        background-color: $neutral;
    }
}

@media screen and (max-width: 1200px) {
    #project-count-box {
        div {
            display: flex;
            flex-direction: column;
        }
        .box-container {
            width: max-content;
            a {
                text-decoration: none;
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
    }
}

.col-right,
.col-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .col-left,
    .col-right {
        width: 100%;
        .form-item {
            margin-bottom: 10px;
        }
        .form-item:nth-of-type() {
            margin-bottom: 20px;
        }
    }
}

.large-link {
    font-size: 26px;
    text-decoration: none;
    color: $tmsblue;
}
.checkBox {
    width: 300px;
}

#search {
    width: 100%;
    text-align: center;
    input,
    div,
    svg {
        display: inline-block;
    }
    svg {
        vertical-align: middle;
        margin-right: 10px;
    }

    .btn {
        padding: 4px 0;
        margin-left: 10px;
    }
    input {
        padding-left: 5px !important;
    }
}

.date-pick {
    padding: 5px 20px;
    font-size: 16px;
}

.link-like {
    color: $lightblue;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 10px;
}

.embr-icons {
    position: absolute;
    z-index: 99999;
    top: 0;
    right: 15px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 10px;
    .notify-main {
        position: relative;
        margin-right: 20px;
        &.active {
            &:after {
                content: "";
                position: absolute;
                @include arrow-up(15px, #333);
                bottom: -29px;
                left: 0;
            }
        }
        .n-count {
            border-radius: 30px;
            background: $danger;
            text-align: center;
            width: 20px;
            height: 20px;
            padding-top: 3px;
            font-size: 10px;
            color: white;
            position: absolute;
            top: 0;
            right: -5px;
        }
        svg {
            cursor: pointer;
        }
    }
    img {
        width: 79px;
        height: 23px;
        align-self: center;
    }
}

// React Day Picker Styles
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
    width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
}
.InputFromTo {
    margin-bottom: 10px;
}
.DayPickerInput input {
    padding: 5px;
}

//

.authorize-banner {
    color: white;
    background-color: $danger;
    padding: 7px 10px;
    color: white;
    display: flex;
    gap: 5px;
    width: fit-content;
    max-width: 100%;
    font-size: 1em;
    align-items: center;
    margin: 0;
    justify-content: flex-start;
    text-align: left;
    svg {
        display: block;
        width: 24px;
        height: auto;
        flex: 0 0 24px;
    }
    a {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
    &.success {
        width: 100%;
        background-color: $tmsgreen;
    }
    &.notice,
    &.warning {
        width: 100%;
        background-color: $warning;
    }
    &.danger {
        background-color: $danger;
        color: white;
    }
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

.refresh-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
    width: fit-content;
    svg {
        width: 27px;
        height: 27px;
        transition: transform 1.25s ease-in-out;
        color: $tmsblue;
    }
    h4 {
        margin: 0 !important;
        color: $tmsblue;
    }
    &.rotate {
        svg {
            transform: rotate(360deg);
        }
    }
}

#switch-wrapper,
.switch-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    align-items: center;
    width: fit-content;
    margin: 0;
    label {
        color: $darkblue;
    }
    h3 {
        height: fit-content;
        width: fit-content;
        margin: auto 0;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 28px;
        flex: 0 0 52px;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #a8a8a8;
        border-radius: 34px;
        margin: 0;
        width: 55px;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 2px;
        bottom: 2px;
        background-color: #a8a8a8;
        -webkit-transition: 0.25s;
        transition: 0.25s;
        border-radius: 50%;
    }
    input:checked + .slider {
        background-color: white;
        border: 1px solid $tmsgreen;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(27px);
        -ms-transform: translateX(27px);
        transform: translateX(27px);
        background-color: $tmsgreen;
    }
}

@media screen and (max-width: $tabletBreak) {
    #switch-wrapper {
        .switch {
            position: relative;
            display: inline-block;
            width: 55px;
            height: 30px;
        }
        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider:before {
            height: 24px;
            width: 24px;
            left: 2px;
            bottom: 2px;
        }
        input:checked + .slider:before {
            -webkit-transform: translateX(25px);
            -ms-transform: translateX(25px);
            transform: translateX(25px);
            background-color: $tmsgreen;
        }
    }
}

.checkbox-container-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 25px;
    row-gap: 15px;
    margin: 20px 0;
    .checkbox-container {
        margin: 0;
    }
}

.checkbox-container {
    display: flex;
    position: relative;
    gap: 10px;
    align-items: center;
    margin: 20px 0;
    width: fit-content;
    input[type="checkbox"],
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 22px !important;
        width: 100% !important;
        z-index: 1;
    }
    .checkbox-custom {
        margin: 0;
        height: 22px;
        width: 22px;
        background-color: transparent;
        border-radius: 0px;
        border: 1px solid #c4c4c4;
        cursor: pointer;
        flex: 0 0 22px;
        &::after {
            position: absolute;
            content: "";
            left: 12px;
            top: 12px;
            height: 0;
            width: 0;
            border-radius: 5px;
            border: solid $tmsblue;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 0;
        }
    }
    input:checked ~ .checkbox-custom {
        background-color: white;
        border-radius: 5px;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 2px solid #c4c4c4;
        display: block;
        width: 22px;
        height: 22px;
    }
    input:checked ~ .checkbox-custom::after {
        -webkit-transform: rotate(40deg) scale(1);
        -ms-transform: rotate(40deg) scale(1);
        transform: rotate(40deg) scale(1);
        opacity: 1;
        left: 9px;
        top: -10px;
        height: 28px;
        width: 10px;
        border: solid $tmsblue;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
    }
    label {
        padding: 0 !important;
        font-size: 1.05em;
    }
}

.Toastify {
    --toastify-color-progress-success: #5bb65d;
    --toastify-color-success: #5bb65d;
    --toast-container-width: 450px;

    .Toastify__toast-container {
        width: var(--toast-container-width);
        top: var(--banner-top);
        right: 10px;
        max-width: 90vw;
        @media screen and (max-width: $mobileLargeBreak) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        .Toastify__toast {
            padding: 12px;
        }
        .Toastify__toast-body {
            gap: 8px;
            .Toastify__toast-icon {
                margin: 0;
                width: 27px;
                svg {
                    width: 27px;
                    height: 27px;
                }
            }
            div {
                line-height: 1.35em;
            }
        }
        .Toastify__toast--error {
            svg {
                fill: var(--toastify-icon-color-error);
            }
        }
        .Toastify__toast--success {
            svg {
                fill: var(--toastify-color-success);
            }
        }
        @media screen and (max-width: $tabletBreak) {
            top: 4em;
        }
    }
}

.team-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin: 0;
    .team-section {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .section-heading {
            p {
                font-weight: 200;
                color: $tmsblue;
                font-size: 3em;
                text-transform: uppercase;
            }
        }
        .team-members-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
    }

    .team-member-wrapper {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;
        @media screen and (max-width: $tabletBreak) {
            width: 100%;
        }
        .headshot-wrapper {
            flex: 0 0 150px;
            flex-shrink: 0;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center; /* Center items horizontally */
            align-items: center;
            object-fit: cover;
            img {
                object-fit: cover;
                width: 100%;
                height: auto;
            }
        }
        .team-member-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: start;
            .member-title {
                font-weight: bold;
                font-size: 0.8em;
                text-transform: uppercase;
            }
            .member-name {
                font-size: 1.4em;
                line-height: 1;
            }
        }
    }
}

.social-media-health-check-wrapper {
    .container {
        // position: absolute;
        // top: 0;
        // right: 0;
        // background-color: white;
        // width: min(750px, 35%);
        // height: 100%;
        // box-shadow:
        //     -6px 0 16px -8px rgba(0, 0, 0, 0.08),
        //     -9px 0 28px 0 rgba(0, 0, 0, 0.05),
        //     -12px 0 48px 16px rgba(0, 0, 0, 0.03);

        // display: flex;
        // flex-direction: column;
        // animation: slideIn 1.2s ease-in-out;
        // animation-delay: 0ms;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        gap: 15px;

        .header {
            flex: 0 0 fit-content;
            $padding: 15px;
            padding: $padding;
            border-bottom: 1px solid #d3d3d3;
            position: relative;
            svg {
                position: absolute;
                top: 50%;
                right: calc($padding + 0px);
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        .content {
            flex: 1;
            overflow: hidden;
            // overflow-x: hidden;
            // overflow-y: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;
            opacity: 1;
            visibility: visible;
            flex: 1;

            .connection {
                flex: 0 0 fit-content;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 10px;
                    flex-wrap: wrap;
                    align-items: center;
                    &.response {
                        flex: 1 1 100%;
                        align-items: center;
                        .btn {
                            margin: 0;
                            width: fit-content;
                        }
                        .message {
                            color: #fff;
                            text-align: left;
                            font-size: 1em;
                            padding: 8px;
                            width: 100%;
                            border: none;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 5px;
                            font-weight: normal;
                            margin: 0;
                            &.success {
                                background-color: $tmsgreen;
                            }
                            &.error {
                                background-color: $danger;
                            }
                        }
                    }
                }

                svg {
                    flex: 0 0 30px;
                    width: 30px;
                    height: auto;
                    &.loading {
                        -webkit-animation: spin 1.2s infinite ease-in-out;
                        animation: spin 1.2s infinite ease-in-out both;
                    }
                    &.success {
                        fill: $tmsgreen;
                        color: $tmsgreen;
                    }
                    &.invalid {
                        fill: $danger;
                        color: $danger;
                    }
                }
                p {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    flex: 1;
                }
            }
        }

        .footer {
            flex: 0 0 fit-content;
            padding: 0;
            padding-top: 15px;
            border-top: 1px solid #d3d3d3;
            display: flex;
            justify-content: center;
            .btn {
                width: fit-content;
                min-width: 150px;
                max-width: 100%;
            }
        }
    }
}
